import { PlatformEnum } from '../../graphql/__generated__/graphql'

import { UserContentFrance } from './france/user-content-france'
import { UserContent } from './user-content'
import { useUserContext } from './user-context'

export function LocalizedUserContent() {
  const { user } = useUserContext()

  switch (user.mainPlatform) {
    case PlatformEnum.QasaFrance:
      return <UserContentFrance />
    default:
      return <UserContent />
  }
}
