import styled from '@emotion/styled'
import { Button, Label, Paragraph, Stack } from '@qasa/qds-ui'

const Wrapper = Stack
const SettingsButtonAndContent = Stack
const ActiveFilters = Stack
const Options = styled(Stack)(({ theme }) => ({ flexWrap: 'wrap', rowGap: theme.spacing['2x'] }))

type SearchStatusProps = {
  searchString: string
  filteredStates: string[] | (string | null)[]
  shouldIncludeClosedProcesses: boolean
  onFilterChange: (
    filteredStates: string[] | (string | null)[],
    shouldIncludeClosedProcesses: boolean,
  ) => void
}

export function SearchStatus({
  searchString,
  onFilterChange,
  filteredStates,
  shouldIncludeClosedProcesses,
}: SearchStatusProps) {
  return (
    <Wrapper direction="row" gap="8x">
      <SettingsButtonAndContent direction="row" gap="4x">
        <ActiveFilters direction="row" gap="8x" alignItems="center">
          <Label>Showing results for:</Label>
          <Options direction="row" gap="4x" alignItems="center">
            <Paragraph size={'sm'}>{searchString}</Paragraph>
            <Button
              variant={'tertiary'}
              size={'xs'}
              onClick={() => {
                onFilterChange(filteredStates, shouldIncludeClosedProcesses)
              }}
            >
              Clear
            </Button>
          </Options>
        </ActiveFilters>
      </SettingsButtonAndContent>
    </Wrapper>
  )
}
