import { Paragraph, Button, Stack } from '@qasa/qds-ui'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Controller, useForm } from 'react-hook-form'

import { RadioGroup } from '../../../components/_core/radio-group/radio-group'
import { UPSERT_FRANCE_CONTRACT } from '../france/upsert-france-contract.gql'
import { Dialog } from '../../../components/_core/dialog'
import type { FranceMarketDetails } from '../../../graphql/__generated__/graphql'
import { notifyFailure, notifySuccess } from '../../../utils'

type FormValue = {
  depositMonths: FranceMarketDetails['depositMonths']
}

type UpdateDepositMonthProps = {
  contractId: string
  currentDepositMonths: FranceMarketDetails['depositMonths']
}

export function UpdateDepositMonth({ contractId, currentDepositMonths }: UpdateDepositMonthProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [upsertFranceContract, { error, loading: isLoading }] = useMutation(UPSERT_FRANCE_CONTRACT, {
    onCompleted: () => {
      notifySuccess('Deposit months updated')
      setIsOpen(false)
    },
  })

  const { handleSubmit, control } = useForm({
    defaultValues: { depositMonths: currentDepositMonths },
  })

  const handleUpdateDepositMonths = async ({ depositMonths }: FormValue) => {
    await upsertFranceContract({
      variables: {
        franceUpsertContractId: contractId,
        input: {
          marketDetails: {
            depositMonths,
          },
        },
      },
    }).catch((e) => notifyFailure(e?.message))
  }

  const hasError = Boolean(error?.message)
  return (
    <Dialog isOpen={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger>
        <Button variant="tertiary">{'Update deposit months'}</Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <Dialog.Body>
          <Stack gap="2x">
            <Paragraph size="sm" color="subtle">
              {`Current deposit months: ${currentDepositMonths ?? 'Not set'}`}
            </Paragraph>
            <Controller
              name="depositMonths"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  value={String(field.value)} // RadioGroup expects a string
                  onChange={(val: string) => field.onChange(Number(val))} // RadioGroup returns a string
                  label={'Deposit months'}
                >
                  <RadioGroup.Item label="1 month" value="1" />
                  <RadioGroup.Item label="2 months" value="2" />
                </RadioGroup>
              )}
            />
            {hasError && <Paragraph>{error?.message}</Paragraph>}
          </Stack>
        </Dialog.Body>
        <Dialog.Footer>
          <Stack gap="2x" direction="row-reverse" justifyContent="space-between">
            <Button
              isLoading={isLoading}
              onClick={handleSubmit(handleUpdateDepositMonths)}
              variant="secondary"
            >
              {'Save'}
            </Button>
            <Button onClick={() => setIsOpen(false)} variant="tertiary">
              {'Close'}
            </Button>
          </Stack>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}
