import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Button, LoadingDots } from '@qasa/qds-ui'

import { Dialog } from '../../../../components/_core/dialog'
import type { KanyeUserContractType } from '../../contracts/contracts.gql'

import { COMPENSATION_CLAIM } from './compensation-claim.gql'
import { NoClaimContent } from './no-claim-content'
import { ClaimContent } from './claim-content'

type CompensationClaimProps = {
  contract: KanyeUserContractType
}
export function CompensationClaim({ contract }: CompensationClaimProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { data, loading: isCompensationClaimLoading } = useQuery(COMPENSATION_CLAIM, {
    variables: {
      contractId: contract.id,
    },
    skip: !isDialogOpen,
  })

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  const handleOpenDialog = () => {
    setIsDialogOpen(true)
  }

  return (
    <>
      <Button variant={'tertiary'} onClick={handleOpenDialog}>
        Split deposit options
      </Button>
      <Dialog isOpen={isDialogOpen} onOpenChange={handleCloseDialog}>
        <Dialog.Content>
          {isCompensationClaimLoading ? (
            <LoadingDots />
          ) : Boolean(data?.compensationClaim) ? (
            <ClaimContent contract={contract} compensationClaim={data!.compensationClaim!} />
          ) : (
            <NoClaimContent contractId={contract.id} />
          )}
        </Dialog.Content>
      </Dialog>
    </>
  )
}
