import React, { useState } from 'react'
import { Stack, Button, TextField } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useMutation } from '@apollo/client'

import { Dialog } from '../../../../components/_core/dialog'
import { Checkbox } from '../../../../components/_core/checkbox'
import { notifyFailure, notifySuccess } from '../../../../utils'
import type { CompensationClaimQuery } from '../../../../graphql/__generated__/graphql'
import { type KanyeUserContractType } from '../../contracts/contracts.gql'

import { omocomReservationAmount, getReservationsToCreate } from './utils'
import { Reservation } from './reservation'
import { CREATE_RESERVATIONS } from './compensation-claim.gql'

const GrayBox = styled.div(({ theme }) => ({
  marginBottom: theme.spacing['4x'],
  backgroundColor: theme.colors.core.gray10,
  padding: theme.spacing['4x'],
  borderRadius: theme.radii['sm'],
}))

type Props = {
  contract: KanyeUserContractType
  compensationClaim: NonNullable<NonNullable<CompensationClaimQuery>['compensationClaim']>
}

export function ClaimContent({ contract, compensationClaim }: Props) {
  const currency = contract.currency
  const [landlordCompensationAmount, setLandlordCompensationAmount] = useState(0)
  const [shouldReserveForOmocom, setShouldReserveForOmocom] = useState(false)
  const [shouldPayBackTenant, setShouldPayBackTenant] = useState(false)

  const setInitialState = () => {
    setLandlordCompensationAmount(0)
    setShouldReserveForOmocom(false)
    setShouldPayBackTenant(false)
  }

  const [createReservations, { loading: isLoadingCreateReservations }] = useMutation(CREATE_RESERVATIONS, {
    onCompleted: () => notifySuccess('Reservations created'),
    onError: (error) => notifyFailure(error.message),
  })

  const getBalance = () => {
    const balance = compensationClaim.balance || 0
    return balance - landlordCompensationAmount - (shouldReserveForOmocom ? omocomReservationAmount : 0)
  }

  const reservationsToCreate = () =>
    getReservationsToCreate({
      landlordCompensationAmount,
      shouldReserveForOmocom,
      shouldPayBackTenant,
      balance: getBalance(),
      contract,
    })

  const handleCreateReservations = () => {
    if (reservationsToCreate().length === 0) {
      return
    }

    createReservations({
      variables: {
        contractId: contract.id,
        reservations: reservationsToCreate(),
      },
    }).then(setInitialState)
  }

  return (
    <>
      <Dialog.Header> Split deposit </Dialog.Header>
      <Dialog.Body>
        {compensationClaim?.reservations.map((reservation) => (
          <Reservation key={reservation.id} reservation={reservation} contract={contract} />
        ))}
        <Stack gap={'4x'}>
          <TextField
            type={'number'}
            value={landlordCompensationAmount}
            onChange={(ev) => setLandlordCompensationAmount(parseInt(ev.target.value, 10))}
            label={'Amount to compensate landlord with:'}
            helperText={`Available funds: ${getBalance()} ${currency}`}
          />

          <Stack direction={'row'} gap={'8x'}>
            <Checkbox
              isChecked={shouldReserveForOmocom}
              label={`Reserve ${omocomReservationAmount} ${currency} for Omocom`}
              onChange={() => setShouldReserveForOmocom(!shouldReserveForOmocom)}
            />

            {(getBalance() > 0 || shouldPayBackTenant) && (
              <Checkbox
                isChecked={shouldPayBackTenant}
                label={'Payback rest to the tenant'}
                onChange={() => setShouldPayBackTenant(!shouldPayBackTenant)}
              />
            )}
          </Stack>
          {reservationsToCreate().length > 0 && getBalance() > 0 && shouldPayBackTenant && (
            <GrayBox>{`${getBalance()} ${currency} will be paid back to the tenant.`}</GrayBox>
          )}
          {reservationsToCreate().length > 0 && getBalance() < 0 && (
            <GrayBox>{`${-1 * getBalance()} ${currency} will be invoiced from the tenant.`}</GrayBox>
          )}
        </Stack>
      </Dialog.Body>
      <Dialog.Footer>
        <Button
          disabled={reservationsToCreate().length === 0}
          onClick={handleCreateReservations}
          isLoading={isLoadingCreateReservations}
        >
          Split
        </Button>
      </Dialog.Footer>
    </>
  )
}
