import { graphql } from '../../graphql/__generated__'

export const MARKET_SETTINGS_QUERY = graphql(`
  query MarketSetting($marketName: MarketNameTypeEnum!) {
    marketSettings(marketName: $marketName) {
      id
      shortcutEnlistNewHomes
      shortcutMaxPendingListings
      shortcutStartRange {
        to
        from
      }
    }
  }
`)

export const UPDATE_MARKET_SETTINGS = graphql(`
  mutation UpdateMarketSettings(
    $updateMarketSettingsMarketName: MarketNameTypeEnum!
    $updateMarketSettingsSettingsInput: UpdateMarketSettingsInput!
  ) {
    updateMarketSettings(
      marketName: $updateMarketSettingsMarketName
      settingsInput: $updateMarketSettingsSettingsInput
    ) {
      errors {
        codes
      }
    }
  }
`)

export const TRANSITION_SHORTCUT_HOME = graphql(`
  mutation transitionShortcutHome($transitionShortcutInput: TransitionShortcutInput!) {
    transitionShortcut(input: $transitionShortcutInput) {
      shortcut {
        id
      }
      errors {
        codes
      }
    }
  }
`)
