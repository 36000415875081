import { Controller, useForm } from 'react-hook-form'
import { Paragraph, Stack, TextField } from '@qasa/qds-ui'
import { addYears } from 'date-fns'

import type { UpdateMarketSettingsInput } from '../../graphql/__generated__/graphql'
import { RadioGroup } from '../../components/_core/radio-group'
import { FormDateField } from '../user/lease-takeover/form-date-field'

import { FORM_ID } from './edit-shortcut-settings-dialog'

const REQUIRED_ERROR_MESSAGE = 'This field is required'

type ShortcutSettingsFormProps = {
  defaultValues: UpdateMarketSettingsInput
  onSubmit: (input: UpdateMarketSettingsInput) => void
}

export function ShortcutSettingsForm({ defaultValues, onSubmit }: ShortcutSettingsFormProps) {
  const {
    register,
    control,
    watch,
    formState: { errors },
    handleSubmit: reactHookFormSubmit,
  } = useForm({
    defaultValues,
  })

  const shortcutEnlistNewHomesError = errors.shortcutEnlistNewHomes?.message
  const shortcutMaxPendingListingsError = errors.shortcutMaxPendingListings?.message
  const shortcutMoveInRangeFromError = errors.shortcutStartRange?.from?.message
  const shortcutMoveInRangeToError = errors.shortcutStartRange?.to?.message

  const earliestMoveInDate = watch('shortcutStartRange.from')

  return (
    <Stack as="form" id={FORM_ID} onSubmit={reactHookFormSubmit(onSubmit)} gap="6x">
      <Controller
        control={control}
        name="shortcutEnlistNewHomes"
        render={({ field }) => (
          <RadioGroup
            {...field}
            label={'Shortcut opt-in'}
            value={String(field.value)} // RadioGroup expects a string
            onChange={(val: string) => field.onChange(val === 'true')}
          >
            <Stack gap="2x">
              <RadioGroup.Item value={'true'} label={'Enabled'} />
              <RadioGroup.Item value={'false'} label={'Disabled'} />
            </Stack>
            {shortcutEnlistNewHomesError && (
              <Paragraph color="negative">{'shortcutEnlistNewHomesError'}</Paragraph>
            )}
          </RadioGroup>
        )}
      />
      <TextField
        {...register('shortcutMaxPendingListings', {
          valueAsNumber: true,
          required: REQUIRED_ERROR_MESSAGE,
          min: {
            value: 1,
            message: 'Must be at least 1',
          },
        })}
        label={'Number of allowed pending homes'}
        type="number"
        errorMessage={shortcutMaxPendingListingsError}
        isInvalid={Boolean(shortcutMaxPendingListingsError)}
      />
      <FormDateField
        name="shortcutStartRange.from"
        label="Earliest move in date"
        control={control}
        errorMessage={shortcutMoveInRangeFromError}
        min={new Date()}
        // Set max just to prevent input from accepting 5 digit years
        max={addYears(new Date(), 100)}
      />
      <FormDateField
        name="shortcutStartRange.to"
        label="Latest move in date"
        control={control}
        errorMessage={shortcutMoveInRangeToError}
        min={earliestMoveInDate ? new Date(earliestMoveInDate) : new Date()}
        // Set max just to prevent input from accepting 5 digit years
        max={addYears(new Date(), 100)}
      />
    </Stack>
  )
}
