import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Label, Paragraph, Stack } from '@qasa/qds-ui'

import { LoginAsUserButton } from '../user/login-as-user-button'
import { ToggleableIdNumber } from '../../components/toggled-numbers'

import { outputDurationStart } from './utils'
import type { Home } from './tabs/in-review/home-type'

const Wrapper = styled(Stack)(({ theme }) => ({
  width: 250,
  borderRadius: theme.radii.xs,
  overflow: 'hidden',
  borderColor: theme.colors.bg.brandTertiary,
  borderWidth: 2,
}))
const HomePreviewListItem = styled(Stack)(({ theme }) => ({
  padding: theme.spacing['3x'],
  ':nth-child(even)': {
    backgroundColor: theme.colors.bg.brandTertiary,
  },
}))

HomePreview.propTypes = {
  home: PropTypes.object,
}

export function HomePreview({ home }: { home: Home | null }) {
  if (!home) {
    return null
  }

  const mainPlatform = home.mainPlatform
  const uid = home.uid
  const idNumber = home.idNumber
  const idConfirmed = Boolean(home.idNumberConfirmedAt)
  const hasRiskExplanation = home.adReviewModelOutput?.explanation
  const isShortcut = Boolean(home.shortcut)

  const selectedHomeData = [
    ...(idNumber
      ? [
          {
            Component: () => (
              <ToggleableIdNumber
                idConfirmed={idConfirmed}
                idNumber={idNumber}
                mainPlatform={mainPlatform}
                showRowTitle
              />
            ),
          },
        ]
      : [
          {
            title: 'ID Number: ',
            content: 'n/a',
          },
        ]),
    {
      title: 'Phone number: ',
      content: home.phoneNumber,
    },
    {
      title: 'Home ID: ',
      content: home.id,
    },
    {
      title: 'User UID: ',
      content: home.uid,
    },
    {
      title: 'Published homes: ',
      content: home.publishedHomesCount,
    },
    {
      title: 'Archived homes: ',
      content: home.archivedHomesCount,
    },
    ...(hasRiskExplanation
      ? [
          {
            title: 'Risk explanation: ',
            content: home.adReviewModelOutput?.explanation,
            hasRiskExplanation,
          },
        ]
      : []),
    ...(isShortcut
      ? [
          {
            title: 'Recommended rent: ',
            content: home.recommendedRent,
          },
          {
            title: 'Move in: ',
            content: outputDurationStart(home),
          },
        ]
      : []),

    {
      Component: () => <LoginAsUserButton mainPlatform={mainPlatform} uid={uid} />,
    },
  ]

  return (
    <Wrapper>
      {selectedHomeData.map(({ title, content, Component }, index) => (
        <HomePreviewListItem key={index} wrap="wrap" gap="3x">
          {title && <Label>{title}</Label>}
          {content && <Paragraph as="span">{content}</Paragraph>}
          {Component && <Component />}
        </HomePreviewListItem>
      ))}
    </Wrapper>
  )
}
