import {
  GET_PROCESSES_LOADING,
  GET_PROCESSES_SUCCESS,
  GET_PROCESSES_FAILED,
  UPDATE_PROCESS_SUCCESS,
  UPDATE_PROCESS_LOADING,
  UPDATE_PROCESS_FAILED,
  ADD_COMMENT_TO_PROCESS_SUCCESS,
  ADD_COMMENT_TO_PROCESS_FAILED,
  ADD_COMMENT_TO_PROCESS_LOADING,
} from './overdue-payments-actions'

export const initState = {
  usersWithOverduePayments: {
    data: null,
    isLoading: false,
    error: null,
    totalPages: 0,
  },
  updateProcess: {
    isLoading: false,
    error: null,
  },
  addComment: {
    isLoading: false,
    error: null,
  },
}

const replaceUsers = (users: any, newUsers: any) => {
  newUsers.forEach((newUser: any) => {
    if (users.find((oldUser: any) => oldUser.id === newUser.id)) {
      users[users.findIndex((oldUser: any) => oldUser.id === newUser.id)] = newUser
    }
  })
  return users
}

export const overduePaymentsReducer = (state: any, action: any) => {
  switch (action.type) {
    case GET_PROCESSES_SUCCESS:
      const totalPages = action.totalPages ?? state.usersWithOverduePayments.totalPages
      return {
        ...state,
        usersWithOverduePayments: {
          data: action.users,
          totalPages,
          isLoading: false,
          error: null,
        },
      }

    case GET_PROCESSES_LOADING:
      return {
        ...state,
        usersWithOverduePayments: { isLoading: true },
      }

    case GET_PROCESSES_FAILED:
      return {
        ...state,
        usersWithOverduePayments: {
          data: null,
          isLoading: false,
          error: action.error,
        },
      }

    case UPDATE_PROCESS_SUCCESS: {
      const usersWithOverdueProcesses = state.usersWithOverduePayments
      const users = state.usersWithOverduePayments.data
      return {
        ...state,
        usersWithOverduePayments: {
          ...usersWithOverdueProcesses,
          data: replaceUsers(users, action.users),
        },
        updateProcess: { isLoading: false, error: null },
      }
    }

    case UPDATE_PROCESS_LOADING:
      return {
        ...state,
        updateProcess: { isLoading: true },
      }

    case UPDATE_PROCESS_FAILED:
      return {
        ...state,
        updateProcess: { isLoading: false, error: action.error },
      }

    case ADD_COMMENT_TO_PROCESS_SUCCESS: {
      const usersWithOverdueProcesses = state.usersWithOverduePayments
      const users = state.usersWithOverduePayments.data
      return {
        usersWithOverduePayments: {
          ...usersWithOverdueProcesses,
          data: replaceUsers(users, action.users),
        },
        addComment: { isLoading: false, error: null },
      }
    }

    case ADD_COMMENT_TO_PROCESS_FAILED:
      return {
        ...state,
        addComment: { isLoading: false, error: action.error },
      }

    case ADD_COMMENT_TO_PROCESS_LOADING:
      return {
        ...state,
        addComment: { isLoading: true, error: null },
      }

    default:
      return state
  }
}
