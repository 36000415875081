import { Stack, Paragraph, IconButton, TrashIcon } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import type { AdminNote } from '../../graphql/__generated__/graphql'

const NoteWrapper = styled(Stack)(({ theme }) => ({
  background: theme.colors.core.white,
  ':nth-child(odd)': {
    background: theme.colors.core.gray20,
  },
  borderRadius: theme.radii.md,
  padding: theme.spacing['3x'],
}))
const TopRow = Stack

export type AdminNoteType = Pick<AdminNote, 'createdAt' | 'note' | 'id'>

type NoteProps = {
  authorName: string
  handleRemoveNote: () => void
} & AdminNoteType

export function Note({ authorName, createdAt, note, id, handleRemoveNote }: NoteProps) {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  } as const

  // NOTE: en-us is needed to format date lik dd/mm/yy and not yy/mm/dd
  const noteTimeStamp = new Intl.DateTimeFormat('en-us', options).format(new Date(createdAt))

  return (
    <NoteWrapper gap="3x">
      <TopRow justifyContent="space-between" direction="row">
        {/* NOTE: Admins can sometimes enter super-long words that needs to break to not break the UI */}
        <Paragraph style={{ wordBreak: 'break-word' }}>{note}</Paragraph>
        {id !== 'no_notes_to_show' && (
          <IconButton label="remove" size="xs" icon={TrashIcon} onClick={handleRemoveNote}>
            {'Remove note'}
          </IconButton>
        )}
      </TopRow>
      <Stack direction="row" justifyContent="space-between">
        <Paragraph size="xs" color="subtle">
          {authorName}
        </Paragraph>
        <Paragraph textAlign="right" size="xs" color="subtle">
          {noteTimeStamp}
        </Paragraph>
      </Stack>
    </NoteWrapper>
  )
}
