import { Button, Paragraph, Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useMutation } from '@apollo/client'

import { notifyFailure, notifySuccess } from '../../../../utils'
import type { CompensationClaimQuery } from '../../../../graphql/__generated__/graphql'
import type { KanyeUserContractType } from '../../contracts/contracts.gql'

import { PAYOUT_COMPENSATION_CLAIM_RESERVATION } from './compensation-claim.gql'

const GrayBox = styled.div(({ theme }) => ({
  marginBottom: theme.spacing['4x'],
  backgroundColor: theme.colors.core.gray10,
  padding: theme.spacing['4x'],
  borderRadius: theme.radii['sm'],
}))

type Props = {
  reservation: NonNullable<CompensationClaimQuery['compensationClaim']>['reservations'][number]
  contract: KanyeUserContractType
}

export function Reservation({ reservation, contract }: Props) {
  const resolvePayoutToString = (payoutTo: any) => {
    if (!payoutTo?.uid) return 'Reserved'
    if (payoutTo?.uid === contract.landlord?.uid) return 'Invoice created for Landlord'
    return 'Invoice created for Tenant'
  }

  const [payoutCompensationClaim, { loading: isPayoutCompensationClaimLoading }] = useMutation(
    PAYOUT_COMPENSATION_CLAIM_RESERVATION,
    {
      onCompleted: () => notifySuccess('Reservations created'),
      onError: (error) => notifyFailure(error.message),
    },
  )

  const handlePayoutCompensationClaim = (reservationId: string, userUid: string) => {
    payoutCompensationClaim({
      variables: {
        reservationId,
        payoutToUid: userUid,
      },
    })
  }

  const currency = contract.currency

  return (
    <GrayBox key={reservation.id}>
      <Stack direction={'row'} gap={'4x'} justifyContent="space-between">
        <Paragraph>
          {reservation.amount} {currency}
        </Paragraph>
        <Stack gap={'2x'}>
          <Paragraph textAlign={'right'}> {resolvePayoutToString(reservation.payoutTo)}</Paragraph>
          <Stack direction={'row'} gap={'4x'}>
            {reservation.payoutTo === null && (
              <div>
                <Button
                  variant={'tertiary'}
                  size={'xs'}
                  onClick={() => handlePayoutCompensationClaim(reservation.id, contract.landlord.uid)}
                  isLoading={isPayoutCompensationClaimLoading}
                >
                  Pay to landlord
                </Button>
                {contract.tenant?.uid && (
                  <Button
                    variant={'tertiary'}
                    size={'xs'}
                    onClick={() => handlePayoutCompensationClaim(reservation.id, contract.tenant!.uid)}
                    isLoading={isPayoutCompensationClaimLoading}
                  >
                    Pay to tenant
                  </Button>
                )}
              </div>
            )}
          </Stack>
        </Stack>
      </Stack>
    </GrayBox>
  )
}
