import { Heading, Paragraph, Stack, TextField } from '@qasa/qds-ui'
import { addYears } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'
import styled from '@emotion/styled'

import { RadioGroup } from '../../../components/_core/radio-group/radio-group'
import type { CreateExternalContractTakeoverInput } from '../../../graphql/__generated__/graphql'
import { FranceRecoverableChargesPaymentPlanEnum } from '../../../graphql/__generated__/graphql'

import { FormDateField } from './form-date-field'
import { FORM_ID } from './lease-takeover-dialog'

const FormSection = styled(Stack)(({ theme }) => ({
  boxShadow: theme.shadows.md,
  borderRadius: theme.radii.md,
  padding: theme.spacing['6x'],
}))

const DEFAULT_ERROR_MESSAGE = 'This field is required'

type LeaseTakeOverFormProps = {
  onSubmit: (formValues: CreateExternalContractTakeoverInput) => void
  defaultValues: CreateExternalContractTakeoverInput
}

export function LeaseTakeoverForm({ onSubmit, defaultValues }: LeaseTakeOverFormProps) {
  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit: reactHookFormSubmit,
  } = useForm({
    defaultValues,
  })

  const isPaymentPlanIncluded =
    watch('contract.recoverableCharges.paymentPlan') === FranceRecoverableChargesPaymentPlanEnum.Included

  const furnitureError = errors.contract?.furniture?.message
  const recoverableChargesError = errors.contract?.recoverableCharges?.paymentPlan?.message
  const recoverableChargesMonthlyCostError = errors.contract?.recoverableCharges?.monthlyCost?.message
  const scriveIdError = errors.contract?.scriveId?.message
  const startDateError = errors.duration?.startDate?.message
  const endDateError = errors.duration?.shortenedEndDate?.message
  const streetNumberError = errors.location?.streetNumber?.message
  const routeError = errors.location?.route?.message
  const postalCodeError = errors.location?.postalCode?.message
  const localityError = errors.location?.locality?.message
  const tenantEmailError = errors.tenant?.email?.message
  const tenantFirstNameError = errors.tenant?.firstName?.message
  const tenantFamilyNameError = errors.tenant?.familyName?.message
  const tenantPhoneNumberError = errors.tenant?.phoneNumber?.message
  const milaIdError = errors.milaId?.message

  return (
    <Stack as="form" id={FORM_ID} onSubmit={reactHookFormSubmit(onSubmit)} gap="8x">
      <FormSection gap="8x">
        <Heading>{'Contract'}</Heading>
        <Stack gap="6x">
          <Controller
            name="contract.furniture"
            control={control}
            rules={{ validate: (val) => (val !== null ? undefined : DEFAULT_ERROR_MESSAGE) }}
            render={({ field }) => (
              <RadioGroup
                {...field}
                value={String(field.value)} // RadioGroup expects a string
                onChange={(val: string) => field.onChange(val === 'true')} // RadioGroup returns a string
                label={'Furnished'}
              >
                <RadioGroup.Item label="Unfurnished" value="false" />
                <RadioGroup.Item label="Furnished" value="true" />
              </RadioGroup>
            )}
          />
          {furnitureError && <Paragraph color="negative">{furnitureError}</Paragraph>}
          <Controller
            name="contract.recoverableCharges.paymentPlan"
            control={control}
            rules={{ required: DEFAULT_ERROR_MESSAGE }}
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(value: FranceRecoverableChargesPaymentPlanEnum) => {
                  //NOTE: Monthly cost needs to be null if included
                  if (value === FranceRecoverableChargesPaymentPlanEnum.Included) {
                    setValue('contract.recoverableCharges.monthlyCost', null)
                  }
                  field.onChange(value)
                }}
                label={'Recoverable Charges'}
              >
                <RadioGroup.Item label="Included" value={FranceRecoverableChargesPaymentPlanEnum.Included} />
                <RadioGroup.Item label="Fixed" value={FranceRecoverableChargesPaymentPlanEnum.Fixed} />
                <RadioGroup.Item
                  label="Provision"
                  value={FranceRecoverableChargesPaymentPlanEnum.Provision}
                />
              </RadioGroup>
            )}
          />
          {!isPaymentPlanIncluded && (
            <TextField
              {...register('contract.recoverableCharges.monthlyCost', {
                required: !isPaymentPlanIncluded ? DEFAULT_ERROR_MESSAGE : undefined,
                valueAsNumber: true,
              })}
              label={'Monthly cost'}
              type="number"
              step={1}
              inputMode="numeric"
              min={0}
              errorMessage={recoverableChargesMonthlyCostError}
              isInvalid={Boolean(recoverableChargesMonthlyCostError)}
            />
          )}
          {recoverableChargesError && <Paragraph color="negative">{recoverableChargesError}</Paragraph>}
          <TextField
            {...register('contract.scriveId', { required: DEFAULT_ERROR_MESSAGE })}
            label="Scrive ID"
            errorMessage={scriveIdError}
            isInvalid={Boolean(scriveIdError)}
          />
          <TextField
            {...register('milaId', { required: DEFAULT_ERROR_MESSAGE })}
            label="Mila ID"
            errorMessage={milaIdError}
            isInvalid={Boolean(milaIdError)}
          />
        </Stack>
      </FormSection>
      <FormSection gap="8x">
        <Heading>{'Duration'}</Heading>
        <Stack gap="6x">
          <FormDateField
            control={control}
            rules={{ required: DEFAULT_ERROR_MESSAGE }}
            name="duration.startDate"
            label={'Start date of takeover'}
            min={new Date()}
            // Set max just to prevent input from accepting 5 digit years
            max={addYears(new Date(), 100)}
            errorMessage={startDateError}
            isInvalid={Boolean(startDateError)}
          />

          <FormDateField
            control={control}
            rules={{ required: DEFAULT_ERROR_MESSAGE }}
            name="duration.shortenedEndDate"
            label={'End date'}
            // Set max just to prevent input from accepting 5 digit years
            max={addYears(new Date(), 100)}
            errorMessage={endDateError}
            isInvalid={Boolean(endDateError)}
          />
        </Stack>
      </FormSection>
      <FormSection gap="8x">
        <Heading>{'Location'}</Heading>
        <Stack gap="6x">
          <TextField
            {...register('location.streetNumber', { required: DEFAULT_ERROR_MESSAGE })}
            label={'Street number'}
            errorMessage={streetNumberError}
            isInvalid={Boolean(streetNumberError)}
          />
          <TextField
            {...register('location.route', { required: DEFAULT_ERROR_MESSAGE })}
            label={'Street name'}
            errorMessage={routeError}
            isInvalid={Boolean(routeError)}
          />
          <TextField
            {...register('location.postalCode', { required: DEFAULT_ERROR_MESSAGE })}
            label={'Postal code'}
            inputMode="numeric"
            errorMessage={postalCodeError}
            isInvalid={Boolean(postalCodeError)}
          />
          <TextField
            {...register('location.locality', { required: DEFAULT_ERROR_MESSAGE })}
            label={'City'}
            errorMessage={localityError}
            isInvalid={Boolean(localityError)}
          />
        </Stack>
      </FormSection>
      <FormSection gap="8x">
        <Heading>{'Tenant'}</Heading>
        <Stack gap="6x">
          <TextField
            {...register('tenant.email', { required: DEFAULT_ERROR_MESSAGE })}
            label={'Email'}
            errorMessage={tenantEmailError}
            isInvalid={Boolean(tenantEmailError)}
          />
          <TextField
            {...register('tenant.firstName', { required: DEFAULT_ERROR_MESSAGE })}
            label={'First Name'}
            errorMessage={tenantFirstNameError}
            isInvalid={Boolean(tenantFirstNameError)}
          />
          <TextField
            {...register('tenant.familyName', { required: DEFAULT_ERROR_MESSAGE })}
            label={'Last name'}
            errorMessage={tenantFamilyNameError}
            isInvalid={Boolean(tenantFamilyNameError)}
          />
          <TextField
            {...register('tenant.phoneNumber', { required: DEFAULT_ERROR_MESSAGE })}
            label={'Phone Number'}
            type="number"
            errorMessage={tenantPhoneNumberError}
            isInvalid={Boolean(tenantPhoneNumberError)}
          />
        </Stack>
      </FormSection>
    </Stack>
  )
}
