import { useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styled from '@emotion/styled'
import { Link, LoadingDots, Button, Stack, Paragraph, Heading } from '@qasa/qds-ui'

import { Table2 } from '../../components/table/table2'
import { config } from '../../config'
import { Api } from '../../api'
import { date } from '../../utils/helpers'
import { notifySuccess, displayApiErrors } from '../../utils'
import type { PlatformEnum } from '../../graphql/__generated__/graphql'

import { SuspiciousUserPreview } from './suspicious-user-preview'

const Wrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.bg.default,
  padding: theme.spacing['5x'],
}))

type User = {
  uid: string
  professional: string
  companyName: string
  firstName: string
  familyName: string
  email: string
  emailConfirmedAt: string
  mainPlatform: PlatformEnum
  identityConfirmedAt: string
  tenant: boolean
  suspicionData: {
    activeSuspicionsCount: number
    activeSuspiciousEventsCount: number
  }
}
type Home = {
  identityConfirmedAt: string
}

export function SuspiciousUsers() {
  const [suspiciousUsers, setSuspiciousUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState<any[]>([])
  const [suspicionData, setSuspicionData] = useState<any>(null)
  const [shouldFetchUsers, setShouldFetchUsers] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const retchSuspiciousUsers = () => setShouldFetchUsers(true)
  const resetSelection = () => {
    setSuspicionData(null)
    setSelectedUsers([])
    setSuspiciousUsers([])
  }

  useEffect(() => {
    if (shouldFetchUsers) {
      setIsLoading(true)
      Api.getSuspiciousUsers()
        .then((res) => setSuspiciousUsers(res.data.users))
        .catch(displayApiErrors)
        .finally(() => {
          setIsLoading(false)
          setShouldFetchUsers(false)
        })
    }
  }, [shouldFetchUsers])

  const onSelectionChanged = (selection: any) => {
    const selectedUsersArray = selection.map((selectedIndex: number) => suspiciousUsers[selectedIndex])

    setSelectedUsers(selectedUsersArray)

    const selectedUser: any = selection.length === 1 ? suspiciousUsers[0] : null

    setSuspicionData(selectedUser ? selectedUser.suspicionData : null)
  }

  const handleSuspendUser = () => {
    Api.userDelete(selectedUsers[0].uid, true)
      .then(() => {
        notifySuccess('User inactivated and suspended')
        resetSelection()
        retchSuspiciousUsers()
      })
      .catch(displayApiErrors)
  }

  const activeSuspicions = suspicionData ? suspicionData.activeSuspicions : null

  return (
    <>
      <Wrapper>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Paragraph>Suspicious Users ({suspiciousUsers ? suspiciousUsers.length : 0})</Paragraph>
          <Button variant="primary" disabled={selectedUsers.length !== 1} onClick={() => handleSuspendUser()}>
            Suspend user
          </Button>
        </Stack>
        {isLoading ? (
          <LoadingDots />
        ) : (
          <>
            {suspiciousUsers.length === 0 ? (
              <Heading size="xs">There are no suspicious users right now 🤷‍♀️</Heading>
            ) : (
              <Table2
                enableRowSelection
                onSelectionChanged={onSelectionChanged}
                rows={suspiciousUsers}
                getRowKey={(user) => user.uid}
                columnsConfig={[
                  {
                    title: 'User',
                    render: (user: User) => (
                      <Link as={RouterLink} to={`/user?user=${user.uid}`} isExternal>
                        {user.professional
                          ? '🏢 ' + user.companyName
                          : `${user.firstName} ${user.familyName}`}
                      </Link>
                    ),
                  },
                  {
                    title: 'Email',
                    render: (user: User) => user.email,
                    getCellClassName: (user: User) => (Boolean(user.emailConfirmedAt) ? 'Success' : 'Danger'),
                  },
                  {
                    title: 'Main Platform',
                    render: (user: User) => {
                      const baseUrl = config[user.mainPlatform]
                      return (
                        <Link href={`${baseUrl}/profile/${user.uid}`} isExternal>
                          {user.mainPlatform}
                        </Link>
                      )
                    },
                  },
                  { title: 'Role', render: (user: User) => (user.tenant === true ? 'tenant' : 'landlord') },
                  {
                    title: 'Verified ID',
                    render: (user: User) =>
                      user.identityConfirmedAt
                        ? `Verified at: ${date(user.identityConfirmedAt)}`
                        : 'Not verified',
                    getCellClassName: (home: Home) => (home.identityConfirmedAt ? 'Success' : 'Danger'),
                  },
                  {
                    title: 'Active Suspicions/Events',
                    render: (user: User) => {
                      const suspicionData = user.suspicionData
                      return (
                        <>
                          {`${suspicionData.activeSuspicionsCount} /
                        ${suspicionData.activeSuspiciousEventsCount}`}
                        </>
                      )
                    },
                  },
                ]}
              />
            )}
          </>
        )}
      </Wrapper>
      {activeSuspicions && (
        <SuspiciousUserPreview activeSuspicions={activeSuspicions} refetch={retchSuspiciousUsers} />
      )}
    </>
  )
}
