import styled from '@emotion/styled'
import { Label } from '@qasa/qds-ui'
import PropTypes from 'prop-types'
import React from 'react'

const StyledLabel = styled(Label)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing['3x'],
  alignItems: 'center',
  marginTop: theme.spacing['3x'],
  padding: theme.spacing['3x'],
}))

Checkbox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentValues: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export function Checkbox({ label, value, currentValues, onClick, className }) {
  return (
    <StyledLabel className={className}>
      <input
        type="checkbox"
        onChange={() => onClick(value)}
        checked={currentValues.length > 0 && currentValues.includes(value)}
      />
      {label}
    </StyledLabel>
  )
}
