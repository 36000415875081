import { Stack, TextField, Button } from '@qasa/qds-ui'
import { useState } from 'react'

import { useChangeSignedPdf } from '../../../queries'

type ChangeSignedPdfProps = {
  contractId: string
}
export function ChangeSignedPdf({ contractId }: ChangeSignedPdfProps) {
  const [scriveId, setScriveId] = useState('')
  const { mutate: changeSignedPdf, isLoading: isChangeSignedPdfLoading } = useChangeSignedPdf()

  const handleChangeSignedPdf = () => {
    changeSignedPdf({ id: contractId, scriveId })
    setScriveId('')
  }

  return (
    <Stack gap="3x">
      <TextField
        label="Change signed pdf"
        value={scriveId}
        onChange={(ev) => setScriveId(ev.target.value)}
        name="new_scrive_id"
        placeholder="Scrive ID"
      />
      <Button variant="tertiary" isLoading={isChangeSignedPdfLoading} onClick={handleChangeSignedPdf}>
        Send
      </Button>
    </Stack>
  )
}
