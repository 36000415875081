import { useMutation } from '@tanstack/react-query'

import {
  terminateContract,
  createInvoice,
  scheduleInvoices,
  changeSignedPdf,
  changeContractEndDate,
  updateContractRent,
  syncInvoiceBatch,
  deleteInvoiceBatch,
  sendTerminationPDF,
  cancelContractAfterSigning,
} from '../api/api-service'
import { notifySuccess } from '../utils/notifications'
import { displayApiErrors } from '../utils/error'
import type { EndpointInfo } from '../components/requires-clearance-level/use-user-endpoint-clearance'

export const useTerminateContract = () => {
  return {
    ...useMutation({
      mutationFn: terminateContract,
      onSuccess: () => {
        notifySuccess('Contract terminated successfully')
      },
      onError: displayApiErrors,
    }),
    endpointInfo: {
      endpoint: 'contracts/:id/terminate',
      endpointType: 'post',
    } as EndpointInfo,
  }
}

export const useCreateInvoice = () => {
  return {
    ...useMutation({
      mutationFn: createInvoice,
      onSuccess: () => {
        notifySuccess('Invoice created successfully! ')
      },
      onError: displayApiErrors,
    }),
  }
}

export const useScheduleInvoices = () => {
  return {
    ...useMutation({
      mutationFn: scheduleInvoices,
      onSuccess: () => {
        notifySuccess('Invoices scheduled')
      },
      onError: displayApiErrors,
    }),
    endpointInfo: {
      endpoint: '/invoices/contract/:contract_id/schedule',
      endpointType: 'post',
    } as EndpointInfo,
  }
}

export const useChangeSignedPdf = () => {
  return useMutation(changeSignedPdf, {
    onSuccess: () => {
      notifySuccess('Signed PDF changed successfully!')
    },
    onError: displayApiErrors,
  })
}

export const useChangeContractEndDate = () => {
  return useMutation(changeContractEndDate, {
    onSuccess: () => {
      notifySuccess('End date changed')
    },
    onError: displayApiErrors,
  })
}

export const useUpdateContractRent = () => {
  return {
    ...useMutation({
      mutationFn: updateContractRent,
      onSuccess: () => {
        notifySuccess('Rent updated!')
      },
      onError: displayApiErrors,
    }),
    endpointInfo: {
      endpoint: '/contracts/:id/change_rent',
      endpointType: 'put',
    } as EndpointInfo,
  }
}

export const useSyncInvoiceBatch = () => {
  return useMutation(syncInvoiceBatch, {
    onSuccess: () => {
      notifySuccess('Batch synced!')
    },
    onError: displayApiErrors,
  })
}

export const useDeleteInvoiceBatch = () => {
  return useMutation(deleteInvoiceBatch, {
    onSuccess: () => {
      notifySuccess('Invoice batch deleted!')
    },
    onError: displayApiErrors,
  })
}

export const useSendTerminationPDF = () => {
  return {
    ...useMutation({
      mutationFn: sendTerminationPDF,
      onSuccess: () => {
        notifySuccess('Termination PDF sent successfully!')
      },
      onError: displayApiErrors,
    }),
    endpointInfo: {
      endpoint: ' /contracts/:id/send_termination_pdf',
      endpointType: 'post',
    } as EndpointInfo,
  }
}

export const useCancelContractAfterSigning = ({ id }: { id: string }) => {
  return {
    ...useMutation({
      mutationFn: () => cancelContractAfterSigning({ id }),
      onSuccess: () => {
        notifySuccess('Contract canceled')
      },
      onError: displayApiErrors,
    }),
    endpointInfo: {
      endpoint: '/contracts/:id/cancel_after_sign',
      endpointType: 'put',
    } as EndpointInfo,
  }
}
