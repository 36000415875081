import { Button } from '@qasa/qds-ui'
import type { ReactNode } from 'react'
import React from 'react'

import { RequiresClearanceLevel } from '../../../components/requires-clearance-level'
import { useCancelContractAfterSigning } from '../../../queries'

type CancelContractAfterSigningButtonProps = {
  id: string
  children?: ReactNode
}

export function CancelContractAfterSigningButton({ id, children }: CancelContractAfterSigningButtonProps) {
  const {
    mutate: cancelContractAfterSigning,
    endpointInfo: cancelContractAfterSigningEndpointInfo,
    isLoading,
    isSuccess,
  } = useCancelContractAfterSigning({ id })

  return (
    <RequiresClearanceLevel endpoints={[cancelContractAfterSigningEndpointInfo]}>
      <Button
        variant="tertiary"
        onClick={() => cancelContractAfterSigning()}
        isDisabled={isSuccess}
        isLoading={isLoading}
      >
        {children || 'Cancel contract'}
      </Button>
    </RequiresClearanceLevel>
  )
}
