import { useMutation } from '@tanstack/react-query'

import { syncBBQNotifications } from '../api/api-service'
import { notifySuccess } from '../utils/notifications'
import { displayApiErrors } from '../utils/error'

export const useSyncBBQNotifications = () => {
  return useMutation({
    mutationFn: syncBBQNotifications,
    onSuccess: () => {
      notifySuccess('Notifications sync started! (may take a couple minutes)')
    },
    onError: displayApiErrors,
  })
}
