import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { TextField, Select, Stack } from '@qasa/qds-ui'
import upperFirst from 'lodash/upperFirst'

import { splitSnakeCase } from '../../../utils/strings'
import { getContractNames } from '../user-contract-meta.utils'
import { VISIBLE_CONTRACT_STATUSES_FRANCE } from '../constants'
import type { KanyeUserContractsType } from '../contracts/contracts.gql'

const InputContainer = styled(Stack)(({ theme }) => ({
  width: '80%',
  [theme.mediaQueries.lgUp]: {
    width: '40%',
  },
}))
const SearchInput = TextField
const ContractStatusSelect = Select

const STATUS_OPTIONS = ['all', ...VISIBLE_CONTRACT_STATUSES_FRANCE]

type SearchContractsProps = {
  setSearchResultContracts: (contracts: KanyeUserContractsType) => void
  contracts: KanyeUserContractsType
  setHasResults: (value: boolean) => void
  handleSearchInUse?: (value: string) => void
}
//TODO Combine again when we have GQL in place for all "brands"
export function SearchContractsFrance({
  setSearchResultContracts,
  contracts,
  setHasResults,
  handleSearchInUse,
}: SearchContractsProps) {
  const [contractStatus, setContractStatus] = useState('all')
  const [searchQuery, setSearchQuery] = useState('')

  const getContractsByStatus = () => {
    const allContractTypes = [...contracts]
    if (contractStatus === 'all') {
      return allContractTypes
    }

    return allContractTypes.filter((contract) => contract.status.includes(contractStatus))
  }

  const handleSearch = (value: string) => {
    const allResults: KanyeUserContractsType = []
    setHasResults(true)
    setSearchQuery(value)
    value.trim()
    const contractsByStatus = getContractsByStatus()
    contractsByStatus.forEach((contract) => {
      const { landlordName, tenantName } = getContractNames({ contract })
      const location = contract.home?.location
      const address = `${location?.route ?? ''} ${location?.streetNumber ?? ''}, ${
        location?.postalCode ?? ''
      } ${location?.locality ?? ''}`
      const dataToQuery = [
        contract.landlord?.private?.email,
        landlordName,
        tenantName,
        contract.tenant?.private?.email,
        contract.id,
        address,
      ]
      const dataToQueryIncludesValue = dataToQuery.filter((element) => element?.toLowerCase().includes(value))
      if (Boolean(dataToQueryIncludesValue.length)) {
        allResults.push(contract)
      }
    })

    if (!allResults.length) {
      setHasResults(false)
    } else {
      setSearchResultContracts(allResults)
    }
    if (!value && contractStatus === 'all') {
      setSearchResultContracts([])
    }
  }

  useEffect(() => {
    setHasResults(true)
    if (searchQuery) {
      handleSearch(searchQuery)
    } else {
      const contractsByStatus = getContractsByStatus()

      if (!contractsByStatus.length) {
        setHasResults(false)
      }

      setSearchResultContracts(contractsByStatus)
    }
  }, [contractStatus, contracts])

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase())
    handleSearchInUse?.(e.target.value)
  }

  return (
    <InputContainer gap="1x" direction="row">
      <SearchInput
        label="Search for a contract"
        helperText="Search for email, name, address, or contract ID"
        placeholder="🔍"
        onChange={handleOnChange}
      />
      <ContractStatusSelect label="Contract status" onChange={(e) => setContractStatus(e.target.value)}>
        {STATUS_OPTIONS.map((value, i) => {
          const optionText = upperFirst(splitSnakeCase(value))
          return (
            <ContractStatusSelect.Option key={i} value={value}>
              {optionText}
            </ContractStatusSelect.Option>
          )
        })}
      </ContractStatusSelect>
    </InputContainer>
  )
}
