import { Button } from '@qasa/qds-ui'
import { useMutation } from '@apollo/client'

import { notifyFailure } from '../../../../utils'
import { DOCUMENT_REQUEST_QUERY } from '../document-requests/document-requests.gql'
import { FranceInsuranceRequestStateEnum } from '../../../../graphql/__generated__/graphql'
import type { KanyeUserContractType } from '../../contracts/contracts.gql'

import { TRANSITION_INSURANCE_REQUEST_MUTATION } from './transition-mila-insurance-request.gql'

const POSSIBLE_TRANSITION_STATES = [FranceInsuranceRequestStateEnum.PendingRejection]

type RentalInsuranceMilaRequest = {
  rentalInsuranceMilaRequest: KanyeUserContractType['rentalInsuranceMilaRequest']
  contractId: string
}

/*
Note:
- we can update our knowledge of documents Mila actually requires. Documents that were previously required from the T may no longer be required.
- self heal button, used when we've made manual changes and need to resets the flow, get it back on track
*/
export function TransitionMilaInsuranceRequest({
  rentalInsuranceMilaRequest,
  contractId,
}: RentalInsuranceMilaRequest) {
  const [transitionInsuranceRequest, { loading: isTransitionInsuranceRequestLoading }] = useMutation(
    TRANSITION_INSURANCE_REQUEST_MUTATION,
    {
      refetchQueries: [{ query: DOCUMENT_REQUEST_QUERY, variables: { contractId } }],
      onError: () => notifyFailure(`Failed to transition insurance request`),
    },
  )

  const currentMilaRequestState = rentalInsuranceMilaRequest?.currentState

  const isTransitionMilaRequestPossible = currentMilaRequestState
    ? POSSIBLE_TRANSITION_STATES.includes(currentMilaRequestState)
    : false

  const handleTransitionInsuranceRequest = () => {
    if (rentalInsuranceMilaRequest?.id) {
      transitionInsuranceRequest({
        variables: {
          franceTransitionInsuranceRequestId: rentalInsuranceMilaRequest.id,
          toState: FranceInsuranceRequestStateEnum.FetchingRequiredDocuments,
        },
      })
    }
  }

  return (
    <Button
      variant="tertiary"
      onClick={handleTransitionInsuranceRequest}
      isLoading={isTransitionInsuranceRequestLoading}
      isDisabled={!isTransitionMilaRequestPossible}
    >
      {'Refresh Mila status'}
    </Button>
  )
}
