import { ShortcutRejectionReasonEnum, ShortcutStatesEnum } from '../../graphql/__generated__/graphql'

export type ShortcutDecisionOption = 'enableShortcut' | 'removeFromShortcut' | 'pauseInShortcut'

type ShortcutDecisionOptionsMap = {
  [key in ShortcutDecisionOption]: {
    title: string
    description: string
    actionLabel: string
    successMessage: string
    toState: ShortcutStatesEnum
    reason?: {
      title: string
      options: {
        label: string
        value: ShortcutRejectionReasonEnum
      }[]
    }
  }
}

export const SHORTCUT_DECISION_OPTIONS_MAP: ShortcutDecisionOptionsMap = {
  pauseInShortcut: {
    title: 'Pause shortcut',
    description: 'Are you sure you want to pause this home in Shortcut indefinitely?',
    actionLabel: 'Yes, pause in Shortcut',
    successMessage: 'Home paused in Shortcut',
    toState: ShortcutStatesEnum.Paused,
  },
  removeFromShortcut: {
    title: 'Remove from Shortcut',
    description:
      'Are you sure you want to remove this home from Shortcut and move it back into normal review process?',
    actionLabel: 'Yes, remove from Shortcut',
    successMessage: 'Home removed from Shortcut',
    toState: ShortcutStatesEnum.Aborted,
    reason: {
      title: 'Reason for removing from Shortcut',
      options: [
        {
          label: 'No response',
          value: ShortcutRejectionReasonEnum.NoResponse,
        },
        {
          label: 'Not interested, will publish themselves',
          value: ShortcutRejectionReasonEnum.NotInterestedWillPublishThemselves,
        },
        {
          label: 'Too high rent expectation',
          value: ShortcutRejectionReasonEnum.TooHighRentExpectation,
        },
        {
          label: 'Too short duration',
          value: ShortcutRejectionReasonEnum.TooShortDuration,
        },
        {
          label: 'Specific tenant requirements',
          value: ShortcutRejectionReasonEnum.SpecificTenantRequirements,
        },
        {
          label: 'Shared home',
          value: ShortcutRejectionReasonEnum.SharedHome,
        },
        {
          label: 'Used another service',
          value: ShortcutRejectionReasonEnum.UsedAnotherService,
        },
        {
          label: 'Other',
          value: ShortcutRejectionReasonEnum.Other,
        },
      ],
    },
  },
  enableShortcut: {
    title: 'Enable Shortcut',
    description: 'Have you checked the interest from the landlord?',
    actionLabel: 'Yes, enable Shortcut',
    successMessage: 'Shortcut enabled',
    toState: ShortcutStatesEnum.Ongoing,
  },
}
