import { graphql } from '../../../../graphql/__generated__'

export const UPDATE_CONTRACT_SCRIVE_ID = graphql(/* GraphQL */ `
  mutation UpdateContractScriveId($contractId: ID!, $scriveId: String!) {
    updateContractScriveId(id: $contractId, scriveId: $scriveId) {
      contract {
        id
        scriveId
      }
      errors {
        ...BaseError
      }
    }
  }
`)
