import { Heading, Stack, useBreakpointValue, Divider } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { Notes } from '../../../components/notes/notes'
import { useUserContext } from '../user-context'

import { UserCTAsFrance } from './user-cta-france'
import { UserData } from './user-data-france'

const UserSummaryWrapper = styled(Stack)({
  width: '100%',
})
const NotesAndActions = styled(Stack)(({ theme }) => ({
  flex: 4,
  [theme.mediaQueries.lgUp]: {
    flex: 7,
  },
}))
const NotesAndActionsColumn = styled(Stack)(({ theme }) => ({
  maxWidth: '100%',
  overflow: 'hidden',
  [theme.mediaQueries.lgUp]: {
    flex: 1,
  },
}))
const UserDataColumn = styled.div({ maxWidth: '100%', overflow: 'hidden', flex: 4 })

export function UserSummaryFrance() {
  const isLgUp = useBreakpointValue({ base: false, lg: true })
  const { user } = useUserContext()

  return (
    <UserSummaryWrapper gap="12x" direction="row">
      <UserDataColumn>
        <UserData />
      </UserDataColumn>
      <NotesAndActions gap="12x" direction={isLgUp ? 'row' : 'column'}>
        <Notes notes={user.adminNotes} />
        {!isLgUp && <Divider />}
        <NotesAndActionsColumn gap="4x">
          <Heading>User actions</Heading>
          <UserCTAsFrance />
        </NotesAndActionsColumn>
      </NotesAndActions>
    </UserSummaryWrapper>
  )
}
