import isEqual from 'lodash/isEqual'

import { notifyFailure } from '../../utils/notifications'

const validate = (predicate: boolean, message: string) => {
  if (!predicate) throw message
}

export const validateGeojson = (geojsonText: string) => {
  try {
    const parsedGeojson = JSON.parse(geojsonText)
    validate(parsedGeojson.type === 'MultiPolygon', 'geojson must be multipolygon')
    validate(
      parsedGeojson.coordinates[0][0][0].length > 0,
      'Multipolygon must have depth 4 and contain an array of coordinates',
    )
    const length = parsedGeojson.coordinates[0][0].length
    validate(
      isEqual(parsedGeojson.coordinates[0][0][0], parsedGeojson.coordinates[0][0][length - 1]),
      'The first and last positions of coordinates must be the same',
    )
    return parsedGeojson
  } catch (e) {
    notifyFailure(`Not possible to parse geojson, ${e}`)
    return null
  }
}
