import styled from '@emotion/styled'
import { Paragraph, Stack } from '@qasa/qds-ui'

import type { UsersQuery } from '../../graphql/__generated__/graphql'

const Table = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(5,1fr)',
  border: '1px solid',
  borderColor: theme.colors.border.default,
  lineHeight: '32px',
  borderRadius: theme.radii.md,
  overflowX: 'scroll',
}))
const TableRow = styled.div<{ childIndex: number }>(({ theme, childIndex }) => ({
  display: 'contents',
  cursor: 'pointer',
  backgroundColor: childIndex % 2 !== 0 ? theme.colors.core.gray30 : 'inherit',
  '&:hover': {
    backgroundColor: theme.colors.core.softPink,
  },
}))
const HeaderTableRow = styled.div(({ theme }) => ({
  display: 'contents',
  pointerEvents: 'none',
  backgroundColor: theme.colors.core.black,
  color: theme.colors.core.white,
}))
const TableCell = styled.span(({ theme }) => ({
  backgroundColor: 'inherit',
  padding: theme.spacing['4x'],
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))
const HeaderCell = styled(TableCell)({
  fontSize: 14,
})

const tableHeaders = ['Platform', 'First name', 'Family name', 'Contracts', 'Homes']

type UserSelectTableProps = {
  users: UsersQuery['users']['nodes']
  onUserSelect: (user: UsersQuery['users']['nodes'][number]) => void
}

export function UserSelectTable({ users, onUserSelect }: UserSelectTableProps) {
  return (
    <Stack gap="2x">
      <Paragraph>{`Multiple users found for the platforms you are admin to, select one:`}</Paragraph>
      <Table>
        <HeaderTableRow>
          {tableHeaders.map((header) => (
            <HeaderCell key={header}>{header}</HeaderCell>
          ))}
        </HeaderTableRow>
        {users.map((user, index) => (
          <TableRow onClick={() => onUserSelect(user)} key={index} childIndex={index}>
            <TableCell>{user.mainPlatform}</TableCell>
            <TableCell>{user.firstName}</TableCell>
            <TableCell>{user.private.familyName}</TableCell>
            <TableCell>{user.contractsCount}</TableCell>
            <TableCell>{user.homes.length}</TableCell>
          </TableRow>
        ))}
      </Table>
    </Stack>
  )
}
