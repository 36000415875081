import { graphql } from '../../graphql/__generated__'

export const CREATE_BLOB = graphql(`
  mutation CreateBlob($input: CreateBlobInput!) {
    createBlob(input: $input) {
      id
      headers
      signedUrl
    }
  }
`)
