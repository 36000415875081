import type { ChangeEvent, FormEvent } from 'react'
import { useState } from 'react'
import { Stack, Heading, Textarea, Button, TextField } from '@qasa/qds-ui'

import { useSupportFlag } from '../../queries/support-flag-queries'

type SupportFlagProps = {
  userId: string
}
export function SupportFlag({ userId }: SupportFlagProps) {
  const [userUid, setUserUid] = useState(userId)
  const [supportNote, setSupportNote] = useState('')
  const { mutate: supportFlag, isLoading: isSupportFlagLoading } = useSupportFlag()

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    supportFlag({ uid: userUid, supportNote })
    setSupportNote('')
  }

  const handleUidChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setUserUid(ev.target.value)
  }

  const handleNoteChange = (ev: ChangeEvent<HTMLTextAreaElement>) => {
    setSupportNote(ev.target.value)
  }
  return (
    <Stack gap="4x">
      <Heading as="h3">Flag suspicious behavior</Heading>
      <Stack gap="4x" as="form" onSubmit={handleSubmit}>
        <TextField label="User UID" placeholder="acbn6cfr" onChange={handleUidChange} value={userId} />
        <Textarea
          label="Reason for flagging"
          placeholder="The reason..."
          onChange={handleNoteChange}
          value={supportNote}
        />
        <Button variant="tertiary" type="submit" isLoading={isSupportFlagLoading}>
          Submit
        </Button>
      </Stack>
    </Stack>
  )
}
