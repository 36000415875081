import styled from '@emotion/styled'
import { IconButton, Label, Stack, ArrowLeftIcon, ArrowRightIcon } from '@qasa/qds-ui'

const PageNumber = styled('div')<{ isCurrentPage: boolean }>(({ isCurrentPage, theme }) => ({
  width: theme.sizes['10x'],
  height: theme.sizes['10x'],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.radii.full,
  position: 'relative',
  cursor: 'pointer',
  color: theme.colors.text.strong,
  ...theme.typography.label.md,
  ...(isCurrentPage && {
    backgroundColor: theme.colors.bg.brandPrimaryActive,
    color: theme.colors.text.onBrandPrimary,
    pointerEvents: 'none',
  }),
  ':hover': {
    background: theme.colors.core.gray20,
    color: theme.colors.text.strong,
  },
  /**
   * Make tap area larget without affecting actual DOM-size
   */
  ':before': {
    content: '""',
    position: 'absolute',
    inset: -4,
  },
}))

const Wrapper = Stack
const PagesList = Stack

type PaginationButtonsProps = {
  currentPage: number
  setCurrentPage: (page: number) => void
  totalPages: number
}
function PaginationButtons({ currentPage, setCurrentPage, totalPages }: PaginationButtonsProps) {
  const pages = []
  for (let i = 1; i <= totalPages; i++) {
    pages.push(
      <PageNumber key={i} onClick={() => setCurrentPage(i)} isCurrentPage={currentPage === i}>
        {i}
      </PageNumber>,
    )
  }
  return (
    <PagesList direction="row" gap="3x">
      {pages}
    </PagesList>
  )
}

type PaginationProps = {
  currentPage: number
  onPageChange: (page: number) => void
  totalPages: number
}
function Pagination({ currentPage, onPageChange, totalPages }: PaginationProps) {
  if (totalPages <= 1) return null

  return (
    <Wrapper gap="2x" alignItems="center">
      <Label>Page</Label>
      <PagesList alignItems="center" direction="row" gap="1x" justifyContent="center" wrap="wrap">
        <IconButton
          label=""
          icon={ArrowLeftIcon}
          variant={'ghost'}
          size={'sm'}
          onClick={() => {
            onPageChange(currentPage - 1)
          }}
          disabled={currentPage <= 1}
        />
        <PaginationButtons currentPage={currentPage} setCurrentPage={onPageChange} totalPages={totalPages} />
        <IconButton
          label=""
          icon={ArrowRightIcon}
          variant={'ghost'}
          size={'sm'}
          onClick={() => {
            onPageChange(currentPage + 1)
          }}
          disabled={currentPage >= totalPages}
        />
      </PagesList>
    </Wrapper>
  )
}

export { Pagination }
