import { graphql } from '../../../../graphql/__generated__'

export const DOCUMENT_REQUEST_QUERY = graphql(/* GraphQL */ `
  query DocumentRequests($contractId: ID!) {
    contract(id: $contractId) {
      rentalInsuranceMilaRequest {
        id
        currentState
        documentRequests {
          id
          status
          documentDescription
          documents {
            id
            filename
            url
          }
        }
      }
    }
  }
`)
