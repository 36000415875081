import styled from '@emotion/styled'
import { Stack, Paragraph } from '@qasa/qds-ui'

import { DataColumn } from '../../components/data-column'

import { UserHeader } from './user-header'
import { LoginAsUserButton } from './login-as-user-button'
import { CompanyHeader } from './company-header'
import { useUserContext } from './user-context'

const Wrapper = Stack
const DataRow = styled.div({ display: 'flex', justifyContent: 'space-between' })
const Label = styled(Paragraph)({ fontWeight: 'bold' })
const Data = Paragraph

const confirmOrDeny = (condition: boolean) => (condition ? 'Yes' : 'No')

export function UserData() {
  const { user } = useUserContext()
  const getCreditCheckStatus = () => {
    if (!user.certificate?.status) return 'UC not taken'

    const creditCheckStatus = user.certificate.status

    return creditCheckStatus === 'certified' ? 'Approved' : `Not Approved - ${creditCheckStatus}`
  }
  const activeAccount = user.private.activeBankAccount
  const accountVerifiedAt = activeAccount?.verifiedAt

  const mainPlatform = user.mainPlatform
  const uid = user.uid
  const schibstedAccountId = user.private.schibstedAccountId
  const hasSafeRental = user.safeRental ?? false
  const isTenant = user.tenant
  const isLandlord = user.landlord
  const isUserProfessional = user.professional
  const locale = user.locale
  const identityConfirmedAt = user.currentIdentification?.identityConfirmedAt
  const seenAt = user.seenAt ?? ''
  const createdAt = user.createdAt
  const removedAt = user.removedAt
  const suspended = user.suspended
  const reportCount = user.reportCount
  const invoiceAdsFrom = user.private.invoiceAdsFrom

  const userDataSection = [
    {
      label: 'Main platform',
      data: mainPlatform,
    },
    {
      label: 'UID:',
      data: uid,
    },
    {
      label: 'Schibsted Account Id:',
      data: schibstedAccountId ?? '-',
    },
    {
      label: 'Safe rental:',
      data: confirmOrDeny(hasSafeRental),
    },
    {
      label: 'Tenant:',
      data: confirmOrDeny(isTenant),
    },
    {
      label: 'Landlord:',
      data: confirmOrDeny(isLandlord),
    },
    {
      label: 'Professional:',
      data: confirmOrDeny(isUserProfessional),
    },
    {
      label: 'Locale:',
      data: locale,
    },
    {
      label: 'Bank ID Verification:',
      data: identityConfirmedAt ? new Date(identityConfirmedAt).toDateString() : 'Not verified',
    },
    {
      label: 'Credit check approved:',
      data: getCreditCheckStatus(),
    },
    {
      label: 'Active bank account:',
      data: confirmOrDeny(Boolean(activeAccount)),
    },
    {
      label: 'Bank account verification:',
      data: accountVerifiedAt ? new Date(accountVerifiedAt).toDateString() : ' Not verified',
    },
    {
      label: 'Seen at:',
      data: new Date(seenAt).toDateString(),
    },
    {
      label: 'Created at:',
      data: new Date(createdAt).toDateString(),
    },
    {
      label: 'Removed at:',
      data: removedAt ? new Date(removedAt).toDateString() : '-',
    },
    {
      label: 'Suspended:',
      data: confirmOrDeny(suspended),
    },
    {
      label: 'Report count:',
      data: reportCount || '-',
    },
    {
      label: 'Invoicing from:',
      data: invoiceAdsFrom ? new Date(invoiceAdsFrom).toDateString() : '-',
    },
  ]

  return (
    <Wrapper gap="8x">
      <Stack gap="4x">
        {isUserProfessional ? <CompanyHeader user={user} /> : <UserHeader user={user} />}
        <div>
          <LoginAsUserButton mainPlatform={mainPlatform} uid={uid} />
        </div>
      </Stack>
      <DataColumn>
        {userDataSection.map(({ label, data }) => (
          <DataRow key={label}>
            <Label size="sm">{label}</Label>
            <Data size="sm">{data}</Data>
          </DataRow>
        ))}
      </DataColumn>
    </Wrapper>
  )
}
