import { useMutation } from '@tanstack/react-query'

import { markSuspicionAsFalse, updateAdminCommentForSuspicion } from '../api/api-service'
import { displayApiErrors, notifySuccess } from '../utils'

export const useUpdateAdminCommentForSuspicion = () => {
  return useMutation(updateAdminCommentForSuspicion, {
    onSuccess: () => {
      notifySuccess('Comment updated')
    },
    onError: displayApiErrors,
  })
}

export const useMarkSuspicionAsFalse = () => {
  return useMutation(markSuspicionAsFalse, {
    onSuccess: () => {
      notifySuccess('Suspicion flagged as false')
    },
    onError: displayApiErrors,
  })
}
