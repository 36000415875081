export const AD_REVIEW_DECISION_OPTIONS = {
  editHome: {
    title: 'Why are you editing this ad (select all that apply)?',
    decision: 'EDIT_HOME',
    nextActionLabel: 'Continue to edit home',
    options: [
      {
        label: 'Email or phone number in description',
        value: 'EMAIL_OR_PHONE_NUMBER_IN_DESCRIPTION',
      },
      {
        label: 'Street address in description',
        value: 'STREET_ADDRESS_IN_DESCRIPTION',
      },
      {
        label: 'Inappropriate link in company',
        value: 'INAPPROPRIATE_LINK_IN_COMPANY',
      },
      {
        label: 'General company marketing',
        value: 'GENERAL_COMPANY_MARKETING',
      },
      {
        label: 'Unapproved pictures',
        value: 'UNAPPROVED_PICTURES',
      },
      {
        label: 'Other',
        value: 'OTHER',
      },
    ],
  },
  publish: {
    title: 'How good are the pictures in this ad?',
    nextActionLabel: 'Publish',
    decision: 'PUBLISH',
    options: [
      {
        label: 'Stunning',
        value: 'STUNNING',
      },
      {
        label: 'Good',
        value: 'GOOD',
      },
      {
        label: 'Acceptable',
        value: 'ACCEPTABLE',
      },
      {
        label: 'No pictures',
        value: 'NO_PICTURES',
      },
    ],
  },
  deny: {
    title: 'Why are you denying this ad (select all that apply)?',
    decision: 'DENY',
    nextActionLabel: 'Confirm denial',
    options: [
      {
        label: 'Duplicate',
        value: 'DUPLICATE',
      },
      {
        label: 'Incomplete address',
        value: 'INCOMPLETE_ADDRESS',
      },
      {
        label: 'Suspiciously low rent',
        value: 'SUSPICIOUSLY_LOW_RENT',
      },
      {
        label: 'Wrong category',
        value: 'WRONG_CATEGORY',
      },
      {
        label: 'Empty ad',
        value: 'EMPTY_AD',
      },
      {
        label: 'User has multiple listings',
        value: 'USER_HAS_MULTIPLE_LISTINGS',
      },
      {
        label: 'Should be company account',
        value: 'SHOULD_BE_COMPANY_ACCOUNT',
      },
      {
        label: 'Size is not representative of rent',
        value: 'SIZE_IS_NOT_REPRESENTATIVE_OF_RENT',
      },
      {
        label: 'Requesting service as rent',
        value: 'REQUESTING_SERVICE_AS_RENT',
      },
      {
        label: 'Short time rental',
        value: 'SHORT_TIME_RENTAL',
      },
      {
        label: 'Multiple properties in ad (private account)',
        value: 'MULTIPLE_PROPERTIES_IN_AD_PRIVATE_ACCOUNT',
      },
      {
        label: 'Offensive content',
        value: 'OFFENSIVE_CONTENT',
      },
      {
        label: 'User has another account active',
        value: 'USER_HAS_ANOTHER_ACCOUNT_ACTIVE',
      },
      {
        label: 'Other',
        value: 'OTHER',
      },
    ],
  },
  denyAndRemoveUser: {
    title: 'Why are you removing user (select all that apply)?',
    decision: 'DENY_AND_REMOVE_USER',
    nextActionLabel: 'Confirm denial and remove user',
    options: [
      {
        label: 'Offensive description',
        value: 'OFFENSIVE_DESCRIPTION',
      },
      {
        label: 'Offensive pictures',
        value: 'OFFENSIVE_PICTURES',
      },
      {
        label: 'Suspicious Landlord and/or home',
        value: 'SUSPICIOUS_LANLDORD',
      },
      {
        label: 'Other',
        value: 'OTHER',
      },
    ],
  },
}
