import { useState, useEffect } from 'react'

import { Api } from '../api'

type User = Record<string, any>

export const useGetCurrentUser = () => {
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    Api.userGetCurrent().then((res: { data: User }) => setUser(res.data))
  }, [])

  return user
}
