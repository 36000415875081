import { type EvictionProcess } from '../../../graphql/__generated__/graphql'
import { formatDate } from '../../../utils/date'

/*
note: the KFM PDF uses a different format for the svarande personnummer. 10 digits with the '-'.
The sokanden personnummer section is a text field so the full 12 digit personnummer works there
*/
const formatToTenDigitIdNumber = (idNumber: string) => {
  const tenDigitId = idNumber.substring(2, idNumber.length)
  const digitArray = tenDigitId.split('')
  digitArray.splice(6, 0, ' ')

  return digitArray.join('')
}

export const formatKfmPdfData = (evictionProcess: EvictionProcess) => {
  const landlord = evictionProcess.landlord
  const tenant = evictionProcess.user
  const contract = evictionProcess.contract

  const landlordName = `${landlord?.firstName} ${landlord?.private.familyName}`

  const dueDate = new Date(evictionProcess.invoice.dueAt)

  const landlordLocation = landlord.private.activeBankAccount?.location
  const homeLocation = contract.home?.location
  const home = contract.home
  const tenantAddress = `${homeLocation?.route} ${homeLocation?.streetNumber}`

  const companyInfo = evictionProcess.socInfo.qasaInfo

  const svarandePersonnummer = tenant?.idNumber ? formatToTenDigitIdNumber(tenant?.idNumber ?? '') : ''

  return {
    ombud: {
      namn1: 'Qasa AB',
      kronofogdenOmbudskod: 'QAS',
      namn2: 'Qasa AB',
      userEmail: 'info@qasa.se',
      adress: companyInfo.adress,
      phone: '08-512 583 40',
      invoiceNumber: evictionProcess.invoice.id,
      bankgiro: companyInfo.bankgiro,
    },
    sokanden: {
      namn: landlordName,
      epost: landlord?.private.email ?? '',
      telefon: landlord?.private.phoneNumber ?? '',
      personnummer: landlord?.idNumber ?? '',
      adress: `${landlordLocation?.route} ${landlordLocation?.streetNumber}, ${landlordLocation?.postalCode} ${landlordLocation?.locality}`,
    },
    svarande: {
      namn: `${tenant?.firstName} ${tenant?.private.familyName}`,
      epost: tenant?.private.email ?? '',
      telefonnummer: tenant?.private.phoneNumber ?? '',
      personnummer: svarandePersonnummer,
      adress: tenantAddress,
      postnummer: homeLocation?.postalCode?.replace(/\s/g, ''),
      postort: homeLocation?.locality ?? '',
    },
    kostnader: {
      ansokningsavgift: '300',
      ombudsArvode: '380',
      summaKostnader: '680',
    },
    avhysning: {
      adressRumYta: `Adress: ${homeLocation?.formattedAddress}. Antal rum: ${home?.roomCount}. Yta: ${home?.squareMeters} kvm.`,
      grundBostadstyp: `Försenad månadshyra för ${evictionProcess.socInfo.nextMonth.toLowerCase()} månad gällande andrahandsbostaden på ${tenantAddress}\n\nBostadstyp: ${home?.type ?? 'other'}`,
      forfallodatum: formatDate(dueDate) ?? '',
    },
  }
}
