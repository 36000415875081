import styled from '@emotion/styled'
import { Button } from '@qasa/qds-ui'

import { Api } from '../../../../../api'
import {
  updateProcessSuccess,
  updateProcessLoading,
  updateProcessFailed,
} from '../.././../overdue-payments-actions'
import { displayApiErrors } from '../../../../../utils'

import { STATES } from './eviction-process'

const SmallButton = styled(Button)({})
SmallButton.defaultProps = { variant: 'tertiary', size: 'xs' }

type TransitionButtonProps = {
  toState: string
  processId: number
  dispatch: any
  isButtonDisabled: boolean
}
export function TransitionButton({ toState, processId, dispatch, isButtonDisabled }: TransitionButtonProps) {
  const buttonProps = {
    onClick: () => {
      dispatch(updateProcessLoading())
      Api.processesTransitionTo(processId, toState)
        .then((res) => {
          dispatch(updateProcessSuccess(res.data.users))
        })
        .catch((e) => {
          dispatch(updateProcessFailed(e))
          displayApiErrors(e)
        })
    },
    disabled: isButtonDisabled,
  }

  switch (toState) {
    case STATES.report_to_socialtjansten:
      return <SmallButton {...buttonProps}>Send report to Socialtjänsten</SmallButton>

    case STATES.awaiting_reply_from_socialtjansten:
      return <SmallButton {...buttonProps}>I've sent the report</SmallButton>

    case STATES.report_to_kronofogden:
      return (
        <SmallButton {...buttonProps}>I've received a reply with a stamp from Socialtjänsten</SmallButton>
      )

    case STATES.awaiting_reply_from_kronofogden:
      return <SmallButton {...buttonProps}>I've sent the reports to Kronofogden</SmallButton>

    case STATES.delgivning_approved_awaiting_eviction_date:
      return <SmallButton {...buttonProps}>Delgivning was approved</SmallButton>

    case STATES.eviction_date_received:
      return <SmallButton {...buttonProps}>I've received the eviction date</SmallButton>

    case STATES.delgivning_denied:
      return <SmallButton {...buttonProps}>Delgivning was denied</SmallButton>

    case STATES.awaiting_reply_from_landlord_for_delgivning:
      return <SmallButton {...buttonProps}>I've contacted the landlord</SmallButton>

    case STATES.done:
      return <SmallButton {...buttonProps}>I've completed the process!</SmallButton>

    default:
      return null
  }
}
