import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Stack, Paragraph, XCircleIcon, Heading } from '@qasa/qds-ui'

const wiggle = keyframes({
  '0%': { transform: 'rotate(0deg) scale(0.90)', opacity: 0 },
  '20%': { transform: 'rotate(12deg)' },
  '40%': { transform: 'rotate(-12deg)' },
  '60%': { transform: 'rotate(6deg) ' },
  '80%': { transform: 'rotate(-6deg)' },
  '100%': { transform: 'rotate(0deg) scale(1)', opacity: 1 },
})
const ErrorIcon = styled(XCircleIcon)(({ theme }) => ({
  width: theme.sizes['20x'],
  height: theme.sizes['20x'],
  animation: `ease-out ${wiggle} 0.4s`,
  animationDelay: '0.15s',
  animationFillMode: 'backwards',
}))

type ErrorComponentProps = {
  title: string
  description?: string
}
export function ErrorComponent({ title, description }: ErrorComponentProps) {
  return (
    <Stack role="alert" gap="8x" alignItems="center">
      <ErrorIcon color="negative" />
      <Stack gap="4x">
        <Heading textAlign="center" size="lg">
          {title}
        </Heading>
        <Paragraph textAlign="center">{description}</Paragraph>
      </Stack>
    </Stack>
  )
}
