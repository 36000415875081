import { useMutation } from '@apollo/client'
import { Button } from '@qasa/qds-ui'

import { BookingEmailTemplateEnum } from '../../../../graphql/__generated__/graphql'
import { notifySuccess, notifyFailure } from '../../../../utils/notifications'

import { SEND_BOOKING_CONFIRMATION_EMAIL } from './send-booking-confirmation-email.gql'

type SendConfirmationEmailProps = {
  contract: any // Temporary until we have a setup for TS with immutable or if we start replacing our redux store with apollo/GQL and stop using immutable
  userEmail: string
}

export function SendConfirmationEmail({ contract, userEmail }: SendConfirmationEmailProps) {
  const bookingLandlordEmail = contract.landlord?.private.email
  const isLandlord = bookingLandlordEmail === userEmail

  const confirmAction = () => window.confirm('Are you sure?')

  const [sendBookingConfirmationEmail, { loading: isLoading }] = useMutation(
    SEND_BOOKING_CONFIRMATION_EMAIL,
    {
      variables: {
        bookingId: contract.booking.id,
        templateName: isLandlord
          ? BookingEmailTemplateEnum.LandlordBookingConfirmed
          : BookingEmailTemplateEnum.TenantBookingConfirmed,
      },
      onCompleted: () => {
        notifySuccess('Email sent')
      },
      onError: (error) => {
        notifyFailure(error.message)
      },
    },
  )

  const handleSend = () => {
    if (confirmAction()) {
      sendBookingConfirmationEmail()
    }
  }

  return (
    <Button isLoading={isLoading} variant="tertiary" onClick={handleSend}>
      Send confirmation email
    </Button>
  )
}
