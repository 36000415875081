import { graphql } from '../../graphql/__generated__'

export const KANYE_USER_QUERY = graphql(/* GraphQL */ `
  query KanyeUser($uid: ID!) {
    kanyeUser(uid: $uid) {
      uid
      idNumber
      mainPlatform
      professional
      removedAt
      reportCount
      adminNotes {
        author {
          firstName
        }
        createdAt
        id
        note
        updatedAt
      }
      private {
        id
        familyName
        email
        idNumber
        orgNumber
        schibstedAccountId
        phoneNumber
        invoiceAdsFrom
        stripeAccount {
          currentState
        }
        activeBankAccount {
          id
          verified
          verifiedAt
        }
      }
      certificate {
        id
        status
      }
      safeRental
      tenant
      landlord
      locale
      currentIdentification {
        identificationStatus
        identityConfirmedAt
        identityConfirmed
      }
      seenAt
      createdAt
      suspended
      companyName
      firstName
      profilePicture {
        id
        url
      }
    }
  }
`)
