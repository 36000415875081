import styled from '@emotion/styled'
import { Heading, Spacer } from '@qasa/qds-ui'
import { Fragment } from 'react'

import type { ContractFinancesQuery } from '../../../graphql/__generated__/graphql'
import { ProductEnum, PayoutStateEnum } from '../../../graphql/__generated__/graphql'
import { Table } from '../../../components/table/table'
import { TableHeader } from '../../../components/table/table-header'
import { TableBody } from '../../../components/table/table-body'
import '../../../components/table/styles.scss'
import { date } from '../../../utils/helpers'

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const headerTitles = ['ID', 'Type', 'Reference', 'Amount', 'Paid at', 'State']

const getCurrentState = (currentState: PayoutStateEnum) => {
  switch (currentState) {
    case PayoutStateEnum.Created:
      return 'Created'
    case PayoutStateEnum.Pending:
      return 'Panding'
    case PayoutStateEnum.Paid:
      return 'Paid'
    default:
      return 'No status'
  }
}

const getProductName = (name: ProductEnum) => {
  switch (name) {
    case ProductEnum.Deposit:
      return 'Deposit'
    case ProductEnum.MonthlyFee:
      return 'Monthly fee'
    case ProductEnum.InsuranceFee:
      return 'Insurance fee'
    case ProductEnum.RecoverableCharges:
      return 'Recoverable charges'
    case ProductEnum.MonthlyRent:
      return 'Monthly rent'
    default:
      return ''
  }
}

type PayoutsFinanceProps = {
  contract: ContractFinancesQuery['contract']
}

export function PayoutsFinance({ contract }: PayoutsFinanceProps) {
  const { financePayouts, financeRentInvoices } = contract
  return (
    <Wrapper>
      <Heading size="sm">{'Payouts'}</Heading>
      <Spacer size="2x" />
      <Table>
        <TableHeader headerTitles={headerTitles} />
        <TableBody>
          {financeRentInvoices?.map((invoice) => {
            const hasPeriods = Boolean(
              invoice.upcomingPayout?.periodStart && invoice.upcomingPayout?.periodEnd,
            )
            const periodText = hasPeriods
              ? `Period: ${date(invoice.upcomingPayout!.periodStart!)} - ${date(
                  invoice.upcomingPayout!.periodEnd!,
                )}`
              : '-'
            return (
              <Fragment key={invoice.id}>
                <tr>
                  <th className="Table__body-batch-header">{periodText}</th>
                </tr>
                <tr>
                  <td>{''}</td>
                  <td>{'Upcoming Payout'}</td>
                  <td>{''}</td>
                  <td>{invoice.upcomingPayout?.sumAmount.humanized}</td>
                  <td>
                    {invoice.paidAt ? (
                      <span className="Success">{date(invoice.paidAt)}</span>
                    ) : (
                      <span className="Danger">{'Unpaid'}</span>
                    )}
                  </td>
                  <td>{invoice.upcomingPayout?.dueDate}</td>
                </tr>
                {invoice.upcomingPayout?.charges?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{getProductName(item.productName)}</td>
                    <td>{''}</td>
                    <td>{item.amount.humanized}</td>
                    <td>{''}</td>
                    <td>{''}</td>
                  </tr>
                ))}
              </Fragment>
            )
          })}
          {financePayouts?.map((payout) => {
            const hasPeriods = Boolean(payout.periodStart && payout.periodEnd)
            const periodText = hasPeriods
              ? `Period: ${date(payout.periodStart!)} - ${date(payout.periodEnd!)}`
              : '-'
            return (
              <>
                <tr>
                  <th className="Table__body-batch-header">{periodText}</th>
                </tr>
                <tr key={payout.reference}>
                  <td>{payout.id}</td>
                  <td>{''}</td>
                  <td>{payout.reference}</td>
                  <td>{payout.amount.humanized}</td>
                  <td>
                    {payout.paidAt ? (
                      <span className="Success">{date(payout.paidAt)}</span>
                    ) : (
                      <span className="Danger">{'Unpaid'}</span>
                    )}
                  </td>
                  <td>{getCurrentState(payout.currentState)}</td>
                </tr>
                {payout.charges?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{getProductName(item.productName)}</td>
                    <td>{''}</td>
                    <td>{item.amount.humanized}</td>
                    <td>{''}</td>
                    <td>{''}</td>
                  </tr>
                ))}
              </>
            )
          })}
        </TableBody>
      </Table>
    </Wrapper>
  )
}
