import styled from '@emotion/styled'
import { Heading, Stack, useBreakpointValue } from '@qasa/qds-ui'

import { Notes } from '../../components/notes/notes'

import { UserCTAs } from './user-CTAs'
import { UserData } from './user-data'
import { useUserContext } from './user-context'

const UserSummaryWrapper = styled(Stack)({
  width: '100%',
})
const UserDataColumn = styled(Stack)({ flex: 1 })
const NotesContainer = styled(Stack)({ flex: 1, height: '100%' })
const UserActionsContainer = styled(Stack)({ flex: 1 })

export function UserSummary() {
  const isBelowLg = useBreakpointValue({ base: true, lg: false })
  const { user } = useUserContext()

  return (
    <UserSummaryWrapper gap="12x" direction="row">
      <UserDataColumn gap="4x">
        <UserData />
      </UserDataColumn>
      <Stack direction={isBelowLg ? 'column-reverse' : 'row'} style={{ flex: isBelowLg ? 1 : 2 }} gap="12x">
        <NotesContainer>
          <Notes notes={user.adminNotes} />
        </NotesContainer>
        <UserActionsContainer gap="4x">
          <Heading>{'User actions'}</Heading>
          <UserCTAs />
        </UserActionsContainer>
      </Stack>
    </UserSummaryWrapper>
  )
}
