import { Button } from '@qasa/qds-ui'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'

import { notifyFailure, notifySuccess } from '../../utils/notifications'
import { Dialog } from '../../components/_core/dialog'
import type { MarketSettingQuery, UpdateMarketSettingsInput } from '../../graphql/__generated__/graphql'
import { MarketNameTypeEnum } from '../../graphql/__generated__/graphql'

import { MARKET_SETTINGS_QUERY, UPDATE_MARKET_SETTINGS } from './shortcut.gql'
import { ShortcutSettingsForm } from './shortcut-settings-form'

export const FORM_ID = 'shortcut-settings-form'

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

type EditShortcutSettingsDialogProps = {
  onClose: VoidFunction
  isOpen: boolean
  marketSettings: MarketSettingQuery['marketSettings']
}

export function EditShortcutSettingsDialog({
  isOpen,
  onClose,
  marketSettings,
}: EditShortcutSettingsDialogProps) {
  const [updateMarketSettings, { loading: isMarketSettingsUpdateLoading }] = useMutation(
    UPDATE_MARKET_SETTINGS,
    {
      refetchQueries: [MARKET_SETTINGS_QUERY],
      onCompleted: (data) => {
        if (!data.updateMarketSettings?.errors) {
          notifySuccess('Market settings were updated')
          onClose()
        } else {
          notifyFailure('The market settings could not be updated')
        }
      },
      onError: () => notifyFailure('The market settings could not be updated'),
    },
  )

  const handleSubmit = (input: UpdateMarketSettingsInput) => {
    const fromDate = input.shortcutStartRange?.from
    const toDate = input.shortcutStartRange?.to

    const formattedInput = {
      ...input,
      shortcutStartRange: {
        from: fromDate || null,
        to: toDate || null,
      },
    }

    updateMarketSettings({
      variables: {
        updateMarketSettingsMarketName: MarketNameTypeEnum.Sweden,
        updateMarketSettingsSettingsInput: formattedInput,
      },
    })
  }

  const defaultValues = {
    shortcutEnlistNewHomes: marketSettings.shortcutEnlistNewHomes,
    shortcutMaxPendingListings: marketSettings.shortcutMaxPendingListings,
    shortcutStartRange: marketSettings.shortcutStartRange,
  } satisfies UpdateMarketSettingsInput

  return (
    <Dialog isOpen={isOpen} onOpenChange={onClose}>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>{'Edit shortcut settings'}</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <ShortcutSettingsForm defaultValues={defaultValues} onSubmit={handleSubmit} />
        </Dialog.Body>
        <Dialog.Footer>
          <ButtonWrapper>
            <Button disabled={isMarketSettingsUpdateLoading} form={FORM_ID} type="submit">
              {'Save changes'}
            </Button>
          </ButtonWrapper>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}
