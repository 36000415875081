import { useMutation } from '@tanstack/react-query'

import { assignRisk } from '../api/api-service'
import { notifySuccess } from '../utils/notifications'
import { displayApiErrors } from '../utils/error'

export const useAssignRisk = () => {
  return useMutation(assignRisk, {
    onSuccess: () => {
      notifySuccess('New risk level set successfully! 🌟')
    },
    onError: displayApiErrors,
  })
}
