import { useMutation } from '@tanstack/react-query'

import { notifySuccess } from '../utils/notifications'
import { displayApiErrors } from '../utils/error'
import { classifyAmlHit } from '../api/api-service'

export const useClassifyAmlHit = () => {
  return useMutation(classifyAmlHit, {
    onSuccess: () => {
      notifySuccess('Aml hit updated successfully!')
    },
    onError: displayApiErrors,
  })
}
