import { useMutation } from '@tanstack/react-query'

import { creditInvoice } from '../api/api-service'
import { notifySuccess } from '../utils/notifications'
import { displayApiErrors } from '../utils/error'
import type { EndpointInfo } from '../components/requires-clearance-level/use-user-endpoint-clearance'

export const useCreditInvoice = () => {
  return {
    ...useMutation(creditInvoice, {
      onSuccess: () => {
        notifySuccess('Invoice credited!')
      },
      onError: displayApiErrors,
    }),
    endpointInfo: {
      endpoint: '/invoices/:id/credit_invoice',
      endpointType: 'put',
    } as EndpointInfo,
  }
}
