import { useState } from 'react'
import { Stack } from '@qasa/qds-ui'

import { FILTER_METHODS } from '../../overdue-payments'
import type { StatesType } from '../processes/eviction-process/eviction-process'

import { Filters } from './filters'
import { SearchStatus } from './search-status'
import { SearchForm } from './search-form'

const Wrapper = Stack

type HeaderProps = {
  initialFilteredStates: StatesType[]
  initialShouldIncludeClosedProcesses: boolean
  initialSearchString: string
  onFilterChange: (
    filteredStates: string[] | (string | null)[],
    shouldIncludeClosedProcesses: boolean,
  ) => void
  onSearchChange: (searchString: string) => void
  filterMethod: string
  isLoading: boolean
}

export function Header({
  initialFilteredStates,
  initialShouldIncludeClosedProcesses,
  initialSearchString,
  onFilterChange,
  onSearchChange,
  filterMethod,
  isLoading,
}: HeaderProps) {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const shouldShowSearchForm = !isFilterMenuOpen
  return (
    <Wrapper direction="row" gap="8x">
      {filterMethod === FILTER_METHODS.filter && (
        <Filters
          initialFilteredStates={initialFilteredStates}
          initialShouldIncludeClosedProcesses={initialShouldIncludeClosedProcesses}
          onFilterChange={onFilterChange}
          isFilterMenuOpen={isFilterMenuOpen}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          isLoading={isLoading}
        />
      )}
      {filterMethod === FILTER_METHODS.search && (
        <SearchStatus
          searchString={initialSearchString}
          onFilterChange={onFilterChange}
          filteredStates={initialFilteredStates}
          shouldIncludeClosedProcesses={initialShouldIncludeClosedProcesses}
        />
      )}
      {shouldShowSearchForm && (
        <SearchForm
          initialSearchString={initialSearchString}
          onSearchChange={onSearchChange}
          isLoading={isLoading}
        />
      )}
    </Wrapper>
  )
}
