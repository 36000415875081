import styled from '@emotion/styled'
import { Heading } from '@qasa/qds-ui'

import { useAuthContext } from '../../context/auth-context'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

export function LandingPage() {
  const { authBody } = useAuthContext()
  const firstName = authBody && 'firstName' in authBody ? authBody.firstName : null
  return <Wrapper>{firstName ? <Heading size="lg">{`👋 Hej ${firstName}!`}</Heading> : null}</Wrapper>
}
