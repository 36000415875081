export const GET_PROCESSES_LOADING = 'GET_PROCESSES_LOADING'
export const GET_PROCESSES_SUCCESS = 'GET_PROCESSES_SUCCESS'
export const GET_PROCESSES_FAILED = 'GET_PROCESSES_FAILED'
export const UPDATE_PROCESS_SUCCESS = 'UPDATE_PROCESS_SUCCESS'
export const UPDATE_PROCESS_LOADING = 'UPDATE_PROCESS_LOADING'
export const UPDATE_PROCESS_FAILED = 'UPDATE_PROCESS_FAILED'
export const ADD_COMMENT_TO_PROCESS_LOADING = 'ADD_COMMENT_TO_PROCESS_LOADING'
export const ADD_COMMENT_TO_PROCESS_SUCCESS = 'ADD_COMMENT_TO_PROCESS_SUCCESS'
export const ADD_COMMENT_TO_PROCESS_FAILED = 'ADD_COMMENT_TO_PROCESS_FAILED'

import type { initState } from './overdue-payments-reducer'

type Users = typeof initState

export const getProcessesSuccess = (users: Users, totalPages: number) => ({
  type: GET_PROCESSES_SUCCESS,
  users,
  totalPages,
})

export const getProcessesFailed = (error: Error) => ({
  type: GET_PROCESSES_FAILED,
  error,
})

export const getProcessesLoading = () => ({
  type: GET_PROCESSES_LOADING,
})

export const updateProcessSuccess = (users: Users) => ({
  type: UPDATE_PROCESS_SUCCESS,
  users,
})

export const updateProcessFailed = (error: Error) => ({
  type: UPDATE_PROCESS_FAILED,
  error,
})

export const updateProcessLoading = () => ({
  type: UPDATE_PROCESS_LOADING,
})

export const addCommentToProcessSuccess = (users: Users) => ({
  type: ADD_COMMENT_TO_PROCESS_SUCCESS,
  users,
})

export const addCommentToProcessFailed = (error: Error) => ({
  type: ADD_COMMENT_TO_PROCESS_FAILED,
  error,
})

export const addCommentToProcessLoading = () => ({
  type: ADD_COMMENT_TO_PROCESS_LOADING,
})
