import { graphql } from '../../graphql/__generated__'

export const SUBMIT_SOC_PDF = graphql(/* GraphQL */ `
  mutation SendEvictionEmailToSoc($recipient: String!, $processId: ID) {
    sendEvictionEmailToSoc(recipient: $recipient, processId: $processId) {
      ... on Success {
        success
      }
      ...AuthorizationErrorFragment
      ...ValidationErrorFragment
      ...NotFoundErrorFragment
    }
  }
`)
