import { createLucideIcon } from '@qasa/qds-ui'
import { File, Copy, Pin, PinOff } from 'lucide-react'

export const FileInvoiceIcon = createLucideIcon(File)

export const CopyIcon = createLucideIcon(Copy)

export const PinIcon = createLucideIcon(Pin)

export const UnpinIcon = createLucideIcon(PinOff)
