import { Button, Stack, TextField } from '@qasa/qds-ui'
import type { ChangeEvent } from 'react'
import { useState } from 'react'

import { useChangeContractEndDate } from '../../../queries'

type ChangeContractEndDateProps = {
  contractId: string
}

export function ChangeContractEndDate({ contractId }: ChangeContractEndDateProps) {
  const [endDate, setEndDate] = useState('')
  const { mutate: contractChangeEndDate, isLoading: isChangeContractEndDateLoading } =
    useChangeContractEndDate()

  const handleEndDateChange = (ev: ChangeEvent<HTMLInputElement>) => setEndDate(ev.target.value)

  const handleEndDateSubmit = () => {
    if (Boolean(endDate)) {
      contractChangeEndDate({ id: contractId, endDate })
    }
    setEndDate('')
  }

  return (
    <Stack gap="3x">
      <TextField
        label="Change contract end date"
        type="date"
        value={endDate}
        onChange={handleEndDateChange}
      />
      <Button
        variant="tertiary"
        onClick={handleEndDateSubmit}
        isLoading={isChangeContractEndDateLoading}
        disabled={!endDate}
      >
        Send
      </Button>
    </Stack>
  )
}
