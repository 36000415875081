import { Stack, Label, Heading, TextField, Button } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'

import { useClassifyAmlHit } from '../../queries'
import type { ClassifyAmlHitParams } from '../../api/api-service.types'

const Wrapper = Stack
const ClassificationHeader = Heading
const FormWrapper = styled(Stack)(({ theme }) => ({
  width: theme.sizes[320],
}))
const CheckBoxLabel = styled(Label)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing['1x'],
}))

type PlianceClassifyProps = {
  userUid: string
}

export function PlianceClassify({ userUid }: PlianceClassifyProps) {
  const { mutate: classifyAmlHit, isLoading: isClassifyAmlHitLoading } = useClassifyAmlHit()

  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<ClassifyAmlHitParams>({
    defaultValues: {
      uid: userUid,
      classification: '',
      matchId: '',
      aliasId: '',
      bankAccountOwner: false,
    },
  })
  const handleSubmitClassifyAmlHit: SubmitHandler<ClassifyAmlHitParams> = (data) => {
    classifyAmlHit(data, {
      onSuccess: () => {
        reset()
      },
    })
  }

  return (
    <Wrapper gap="4x">
      <Heading as="h3">Classify Pliance hit</Heading>
      <FormWrapper>
        <Stack as="form" gap="3x" onSubmit={handleSubmit(handleSubmitClassifyAmlHit)}>
          <TextField
            {...register('uid', {
              required: 'User uid is required',
            })}
            label="User UID"
            placeholder="e.g. acbn6cvf"
          />
          <TextField
            {...register('matchId', {
              required: 'matchId is required',
            })}
            label="Match ID"
            placeholder="e.g. 25678dc"
          />
          <TextField
            {...register('aliasId', {
              required: 'aliasId is required',
            })}
            label="Alias ID"
            placeholder="e.g. 678654"
          />
          <CheckBoxLabel>
            <input type="checkbox" {...register('bankAccountOwner')} />
            Bank Account Owner
          </CheckBoxLabel>
          <ClassificationHeader as="h4">Classification</ClassificationHeader>
          <CheckBoxLabel>
            <input type="radio" {...register('classification')} value="match" />
            Match
          </CheckBoxLabel>
          <CheckBoxLabel>
            <input type="radio" {...register('classification')} value="falsePositive" />
            False positive
          </CheckBoxLabel>
          <Button variant="tertiary" type="submit" isLoading={isClassifyAmlHitLoading} isDisabled={!isValid}>
            Submit
          </Button>
        </Stack>
      </FormWrapper>
    </Wrapper>
  )
}
