import type { ReactNode } from 'react'
import { Link } from '@qasa/qds-ui'

import { isUserFinnish } from '../../utils'
import { config } from '../../config'
import { useGetCurrentUser } from '../../hooks'

type QasaLinkProps = {
  children: ReactNode
  to: string
}

export function QasaLink({ children, to }: QasaLinkProps) {
  const loggedInUser = useGetCurrentUser()
  const { mainPlatform } = loggedInUser || {}

  const baseUrl = isUserFinnish({ mainPlatform }) ? config.qasa_finland : config.host

  return (
    <Link isExternal href={baseUrl + to}>
      {children}
    </Link>
  )
}
