import { Stack, LoadingDots } from '@qasa/qds-ui'

import { ListCard } from '../components/list-card'
import { useAuthorisationContext } from '../../../context/authorisation-context'

export function GroupTabContent() {
  const { isLoadingGroups, groups, selectGroup, group } = useAuthorisationContext()

  return (
    <>
      {isLoadingGroups ? (
        <Stack alignItems="center">
          <LoadingDots />
        </Stack>
      ) : (
        groups?.length > 0 &&
        groups.map((element) => {
          const { id, name, employees } = element
          return (
            <ListCard
              key={id}
              name={name.charAt(0).toUpperCase() + name.slice(1)}
              email=""
              numberOfMembers={employees.nodes.length}
              onSelectCard={() => selectGroup(element)}
              isSelected={group?.id === element.id}
              groupId={id}
            />
          )
        })
      )}
    </>
  )
}
