import { format as dateFnsFormat } from 'date-fns'

type DateFormat = 'yyyy-MM-dd' | 'do'

//TODO: Add locale
export function formatDate(date: Date, format: DateFormat = 'yyyy-MM-dd') {
  return dateFnsFormat(date, format)
}

export const stringToDate = (dateString: string) => {
  const parsedDate = new Date(dateString)

  // Check if the parsed date is valid
  if (isNaN(parsedDate.getTime())) {
    return null
  }

  return parsedDate
}
