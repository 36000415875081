import type { CurrencyEnum } from '../graphql/__generated__/graphql'

export const date = (date: string) => (date !== null ? new Date(date).toLocaleDateString('sv-SE') : '-')

export const convertFactorToPercentage = ({ factor }: { factor: number }) => Math.round(factor * 100) + '%'

export const convertFactorToPercentageWithDecimals = ({ factor }: { factor: number }) => {
  const percentage = factor * 100
  const percentageToTwoDecimal = Math.round(percentage * 100) / 100

  return percentageToTwoDecimal + '%'
}

type FormatNumber = {
  amount: number
  maximumFractionDigits?: number
  minimumFractionDigits?: number
  currency?: CurrencyEnum
}

export const formatNumber = ({
  amount,
  currency,
  maximumFractionDigits = 0,
  minimumFractionDigits = 0,
}: FormatNumber) => {
  if (currency) {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency,
      minimumFractionDigits,
      maximumFractionDigits,
    }).format(amount)
  }
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(amount)
}
