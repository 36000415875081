/* eslint-disable import/no-unused-modules */
import snakeCase from 'lodash/snakeCase'
/* The object we construct has 32 parameters */
const MODEL_KEY_LENGTH = 32
/* The CSV template specifies that uploaded CSV should start on row 10 */
const ROW_TO_START_ON = 9

export const getHomeTemplates = (csvRowsAsStringArrays) => {
  csvRowsAsStringArrays.splice(0, ROW_TO_START_ON)
  const errors = []
  const warnings = []

  const rowValues = csvRowsAsStringArrays.map((row) => {
    row.length = MODEL_KEY_LENGTH
    return row
  })

  const homeTemplates = rowValues.map((row, index) => {
    const homeObject = mapToObject(row)

    const homeTemplate = getValidatedHome(homeObject)

    if (Boolean(homeTemplate.errors.length)) {
      errors.push(index + 1)
    } else if (Boolean(homeTemplate.warnings.length)) {
      warnings.push(index + 1)
    }

    return homeTemplate
  })

  return { homeTemplates, rowsWithAlerts: { errors, warnings } }
}

const mapToObject = (row) => {
  const model = {
    apartmentNumber: { label: 'Apartment Number', value: '', ifIsEmpty: 'error' },
    adress: { label: 'Adress', value: '', ifIsEmpty: 'error' },
    streetNumber: { label: 'Street number', value: '', ifIsEmpty: 'warning' },
    postalCode: { label: 'Postal Code', value: '', ifIsEmpty: 'warning' },
    locality: { label: 'Locality', value: '', ifIsEmpty: 'warning' },
    longitude: { label: 'Longitude', value: '' },
    latitude: { label: 'Latitude', value: '' },
    buildingName: { label: 'Building name', value: '', ifIsEmpty: 'error' },
    roomCount: { label: 'Room count', value: '', ifIsEmpty: 'error' },
    floor: { label: 'Floor', value: 0, ifIsEmpty: 'warning' },
    tenantCount: { label: 'Tenant count', value: '', ifIsEmpty: 'warning' },
    squareMeters: { label: 'Square meters', value: '', ifIsEmpty: 'error' },
    rent: { label: 'Rent', value: '', ifIsEmpty: 'error' },
    description: { label: 'Description', value: '', ifIsEmpty: 'warning' },
    dishWasher: { label: 'Dishwasher', value: false },
    furniture: { label: 'Furniture', value: false },
    wheelchairAccessible: { label: 'Wheelchair Accessible', value: false },
    internet: { label: 'Internet', value: false },
    pets: { label: 'Pets allowed', value: false },
    shower: { label: 'Shower', value: false },
    smoker: { label: 'Smoking allowed', value: false },
    washingMachine: { label: 'Washing machine', value: false },
    toilet: { label: 'Toilet', value: false },
    balcony: { label: 'Balcony', value: false },
    elevator: { label: 'Elevator', value: false },
    recycling: { label: 'Recycling', value: false },
    tumbleDryer: { label: 'Tumble dryer', value: false },
    sharedWashingArea: { label: 'Shared washing area', value: false },
    bathtub: { label: 'Bathtub', value: false },
    inhomeSauna: { label: 'Sauna', value: false },
    bikeRoom: { label: 'Bike room', value: false },
    storage: { label: 'Storage', value: false },
  }

  let index = 0

  for (let [key, defaultValue] of Object.entries(model)) {
    const correspondingArrayValue = row[index]
    if (
      ['floor', 'tenantCount', 'squareMeters', 'rent', 'roomCount', 'longitude', 'latitude'].includes(key)
    ) {
      if (correspondingArrayValue) {
        correspondingArrayValue.replaceAll(' ', '')

        model[key].value = parseInt(correspondingArrayValue, 10)
      }
    } else if (defaultValue.value === false) {
      model[key].value = correspondingArrayValue?.toLowerCase() === 'true'
    } else {
      model[key].value = correspondingArrayValue
    }

    index++
  }
  return model
}
export const getValidatedHome = (home) => {
  if (!home) return
  const errors = []
  const warnings = []
  const availableAmenities = []
  const amenityData = []
  const homeData = {}

  for (let [key, objectValue] of Object.entries(home)) {
    const { label, value, ifIsEmpty } = objectValue
    if (typeof value === 'boolean' && value) {
      amenityData.push(key)
      availableAmenities.push(label)
      /* Floor allows 0 */
    } else if (key === 'floor' && value === 0) {
      homeData[key] = value
    } else if (!value) {
      if (ifIsEmpty === 'warning') {
        warnings.push({ key, description: `${label} seems to be invalid or missing` })
      } else if (ifIsEmpty === 'error') {
        errors.push({ key, description: `${label} seems to be invalid or missing` })
      }
    }

    homeData[key] = value
  }

  return { homeData, amenityData, availableAmenities, warnings, errors }
}

export const formatForBackend = ({
  homeData: {
    apartmentNumber,
    adress,
    streetNumber,
    postalCode,
    locality,
    buildingName,
    roomCount,
    floor,
    tenantCount,
    squareMeters,
    rent,
    description,
  },
  amenityData,
}) => {
  return {
    building: {
      building_name: buildingName,
    },
    apartment_number: apartmentNumber,
    room_count: roomCount,
    square_meters: squareMeters,
    floor,
    rent,
    tenant_count: tenantCount,
    description,
    location: {
      route: adress,
      street_number: streetNumber,
      postal_code: postalCode,
      locality,
      country: 'Sweden',
      country_coude: 'SE',
    },
    traits: amenityData.map((item) => snakeCase(item)),
  }
}
