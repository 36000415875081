import styled from '@emotion/styled'
import { Button, TextField, Stack, Heading } from '@qasa/qds-ui'
import QasaLogo from 'src/assets/qasa.svg'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'

import { Alert } from '../../components/_core/alert'
import { Api } from '../../api'
import { axiosInstance, loginAdmin } from '../../api/api-service'
import { ContractsApi } from '../../api/contracts-api'
// import { ReactComponent as GoogleLogo } from '../../assets/google_logo.svg'
import { config } from '../../config'
import type { KanyeCurrentUser } from '../../context/auth-context'

const PageWrapper = styled(Stack)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  background: theme.colors.bg.default,
}))
const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing['2x'],
  width: theme.sizes[384],
}))
const StyledForm = styled.form({ width: '100%' })
const LogoImage = styled.img(({ theme }) => ({
  width: theme.sizes[128],
}))

const DEFAULT_VALUES = {
  email: '',
  password: '',
}

type Inputs = {
  email: string
  password: string
}

type LoginProps = {
  updateAccessToken: (token: string) => void
  loginPath: string
  currentEmployee: KanyeCurrentUser
}
export function Login({ updateAccessToken, loginPath, currentEmployee }: LoginProps) {
  const [hasAuthError, setHasAuthError] = useState(false)

  const handleInitApis = (token: string) => {
    updateAccessToken(token)
    Api.init(token)
    ContractsApi.init(token)
    axiosInstance.defaults.headers.common['access-token'] = token
  }

  const { mutate: login, isLoading } = useMutation({
    mutationFn: loginAdmin,
    onError: () => {
      setHasAuthError(true)
    },
    onSuccess: ({ data }) => {
      const token = data.accessToken
      handleInitApis(token)
    },
  })

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: DEFAULT_VALUES })

  const email = watch('email')
  const password = watch('password')

  const handleLogin = () => {
    login({ email, password })
  }

  const handleGLogin = () => {
    // const redirectUrl = process.env.NODE_ENV === 'development' ? config.google_login_redirect : config.qasa
    window.open(`${config.api}${loginPath}?redirect_to=http://localhost:3001`, '_self')
  }

  return (
    <PageWrapper gap="12x" alignItems="center" justifyContent="center">
      <LogoImage src={QasaLogo} alt="Logo" />
      <Wrapper gap="3x" justifyContent="center" alignItems="center">
        <StyledForm onSubmit={handleSubmit(handleLogin)}>
          <Stack gap="8x">
            <Heading size="lg">Login</Heading>
            <Stack gap="4x">
              <TextField
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Invalid email format',
                  },
                })}
                label="Email"
                placeholder="admin@qasa.se"
                type="email"
                isInvalid={Boolean(errors.email)}
                errorMessage={errors.email?.message}
              />
              <TextField
                {...register('password', {
                  required: 'Password is required',
                  minLength: {
                    value: 6,
                    message: 'Password should be at least 6 characters',
                  },
                })}
                label="Password"
                placeholder="********"
                type="password"
                isInvalid={Boolean(errors.password)}
                errorMessage={errors.password?.message}
              />
              <Button isLoading={isLoading} variant="primary" type="submit">
                Log in
              </Button>
              {/* {!currentEmployee && loginPath ? (
                <Button
                  iconLeft={GoogleLogo}
                  isDisabled={!loginPath}
                  variant="primary"
                  onClick={handleGLogin}
                >
                  Log in with Google
                </Button>
              ) : (
                <Button
                  iconLeft={GoogleLogo}
                  variant="primary"
                  onClick={() => window.open(`${config.api}/admin/sessions/logout`, '_self')}
                >
                  Logout from Google
                </Button>
              )} */}
            </Stack>
            {hasAuthError && (
              <Alert
                variant="error"
                title="Invalid  email or password"
                description={'Check that the email address and password are correct.'}
              />
            )}
          </Stack>
        </StyledForm>
      </Wrapper>
    </PageWrapper>
  )
}
