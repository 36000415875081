import { getStateName } from './eviction-process'
import { Task } from './tasks'
import { TransitionButton } from './transitions'

export const EvictionProcess = {
  name: 'Eviction',
  getStateName,
  Task,
  TransitionButton,
}
