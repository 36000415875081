import styled from '@emotion/styled'
import { Paragraph, Stack } from '@qasa/qds-ui'

const Wrapper = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing['6x'],
}))

type DataProps = {
  invoice: any
}
export function Data({ invoice }: DataProps) {
  const isInvoiceMissing = invoice.id === null

  if (isInvoiceMissing) {
    return null
  }

  const formatMoney = (amount: number) =>
    new Intl.NumberFormat('sv-SE', {
      style: 'currency',
      currency: invoice.currency,
    }).format(amount)
  const formatDate = (date: Date) =>
    new Intl.DateTimeFormat('sv-SE', {
      dateStyle: 'short',
      timeZone: 'Europe/Stockholm',
    }).format(date)

  return (
    <Wrapper>
      <Paragraph size="sm">
        {Boolean(invoice.unpaidAmount) ? (
          <>
            {formatMoney(invoice.amount)} ({formatMoney(invoice.unpaidAmount?.ValueForView)} unpaid)
          </>
        ) : Boolean(invoice.billectaId) ? (
          <>{formatMoney(invoice.amount)} (Couldn't get Billecta info)</>
        ) : (
          <>{formatMoney(invoice.amount)} (This invoice has no Billecta ID)</>
        )}
      </Paragraph>
      <Paragraph size="sm">
        Due {formatDate(new Date(invoice.dueDate))} ({invoice.daysLate} days late)
      </Paragraph>
    </Wrapper>
  )
}
