import { graphql } from '../../../graphql/__generated__'

export const UPDATE_WATER_FEE_AND_NUMBER_OF_TENANTS = graphql(`
  mutation UpdateContractWaterFee($id: ID!, $input: UpdateWaterFeeInput!) {
    updateContractWaterFee(id: $id, input: $input) {
      contract {
        id
        numberOfTenants
        tenantFees {
          waterFeePerTenant {
            cost {
              total
            }
          }
        }
      }
      errors {
        codes
        codesWithMessage {
          code
          message
        }
        field
      }
    }
  }
`)
