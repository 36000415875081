import styled from '@emotion/styled'

export const DataColumn = styled.div(({ theme }) => ({
  width: '100%',

  '> div': {
    padding: theme.spacing['3x'],
    borderRadius: theme.radii.md,
    ':nth-child(odd)': {
      background: theme.colors.core.gray20,
    },
  },
}))
