import styled from '@emotion/styled'
import { Button, Stack, TextField } from '@qasa/qds-ui'
import { useState } from 'react'
import { useMutation } from '@apollo/client'

import { Dialog } from '../_core/dialog'
import { notifyFailure, notifySuccess } from '../../utils'
import { containsProbableEmail } from '../../utils/validation'

import { SUBMIT_SOC_PDF } from './submit-soc-pdf.gql'

const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing['6x'],
}))

type SubmitSocPdfButtonProps = {
  evictionProcessId: string
}

export function SubmitSocPdfButton({ evictionProcessId }: SubmitSocPdfButtonProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [kommunEmail, setKommunEmail] = useState('')

  const [submitSocPdf, { loading: isSendingSubmitSocPdf }] = useMutation(SUBMIT_SOC_PDF, {
    variables: {
      processId: evictionProcessId,
      recipient: kommunEmail,
    },
    onCompleted: ({ sendEvictionEmailToSoc }) => {
      setIsDialogOpen(false)
      if (sendEvictionEmailToSoc.__typename === 'Success') {
        notifySuccess(`Email successfully sent to ${kommunEmail} with the attached SOC PDF`)
      } else {
        notifyFailure('Failed to send email to SOC. Please try again.')
      }
    },
    onError: () => {
      notifyFailure('Failed to send email to SOC. Please try again.')
    },
  })

  const handleSubmitSocEmail = () => {
    if (!containsProbableEmail(kommunEmail)) {
      notifyFailure('Please enter a valid email address')
      return
    }

    submitSocPdf()
    setKommunEmail('')
  }

  return (
    <Dialog
      isOpen={isDialogOpen}
      onOpenChange={(isOpen) => {
        if (!isDialogOpen) setKommunEmail('')
        return setIsDialogOpen(isOpen)
      }}
    >
      <Dialog.Trigger>
        <Button variant="tertiary" onClick={() => setIsDialogOpen(true)}>
          {'Send email to SOC'}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content size="small">
        <Dialog.Header>{'Submit SOC PDF'}</Dialog.Header>
        <Container gap="6x">
          <TextField
            onChange={(e) => setKommunEmail(e.target.value)}
            value={kommunEmail}
            type="text"
            placeholder="local authority email address"
            label="SOC Authority email address"
            errorMessage="error"
          />
          <Button
            variant="secondary"
            isLoading={isSendingSubmitSocPdf}
            isDisabled={!kommunEmail}
            onClick={handleSubmitSocEmail}
          >
            {'Submit'}
          </Button>
        </Container>
      </Dialog.Content>
    </Dialog>
  )
}
