import * as DialogPrimitive from '@radix-ui/react-dialog'
import styled from '@emotion/styled'
import type { ReactNode } from 'react'

import {
  DialogCloseButton,
  DialogFooter,
  DialogBody,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
  DialogTrigger,
} from './dialog.parts'

const DESKTOP_SIZES = {
  small: 448,
  medium: 576,
  large: 720,
}

type DialogRootProps = {
  children?: ReactNode
  isOpen?: boolean
  isDefaultOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
}

function DialogRoot({ children, isOpen, isDefaultOpen, onOpenChange }: DialogRootProps) {
  return (
    <DialogPrimitive.Root open={isOpen} defaultOpen={isDefaultOpen} onOpenChange={onOpenChange}>
      {children}
    </DialogPrimitive.Root>
  )
}
type DialogSize = 'small' | 'medium' | 'large'

const StyledContent = styled(DialogPrimitive.Content)<{ size: DialogSize; isScrollable: boolean }>(
  ({ theme, size, isScrollable }) => ({
    zIndex: theme.zIndices.modal,
    background: theme.colors.bg.default,
    borderRadius: theme.radii.md,
    boxShadow: theme.shadows.lg,
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    overflow: isScrollable ? 'scroll' : 'hidden',
    outline: 'none',
    transform: 'translate(-50%, -50%)',
    maxHeight: size === 'large' ? '90%' : '80%',
    width: DESKTOP_SIZES[size],
    top: '50%',
    left: '50%',
  }),
)

type DialogContentProps = {
  children?: ReactNode
  size?: DialogSize
  isCloseButtonVisible?: boolean
  isScrollable?: boolean
}
function DialogContent({
  children,
  size = 'medium',
  isCloseButtonVisible = true,
  isScrollable = false,
}: DialogContentProps) {
  return (
    <DialogPrimitive.Portal>
      <DialogOverlay />
      <StyledContent size={size} isScrollable={isScrollable}>
        {children}
        {isCloseButtonVisible && <DialogCloseButton />}
      </StyledContent>
    </DialogPrimitive.Portal>
  )
}

export const Dialog = Object.assign(DialogRoot, {
  /**
   * Used for rendering the button that triggers the dialog. Renders nothing by itself, but it
   * passes the required props and a ref to the child component. The child component therefore
   * needs to forward its ref.
   */
  Trigger: DialogTrigger,
  /**
   * The container for the dialog's content
   */
  Content: DialogContent,
  /**
   * The sticky header. Usually used in conjunction with the `Title`
   */
  Header: DialogHeader,
  /**
   * The title that labels the dialog
   */
  Title: DialogTitle,
  /**
   * Scrollable main content of dialog
   */
  Body: DialogBody,
  /**
   * The sticky footer that houses the dialog actions
   */
  Footer: DialogFooter,
})
