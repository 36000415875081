import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

export function Table({ children }) {
  return <table className="Table">{children}</table>
}

Table.propTypes = {
  children: PropTypes.node,
}
