export const getFormattedHomeAddress = (home) => {
  const formattedAddress = home.location.formattedAddress
  if (formattedAddress) {
    return formattedAddress
  } else {
    const route = home.location.route || ''
    const streetNumber = home.location.streetNumber || ''
    const postalCode = home.location.postalCode || ''
    const locality = home.location.locality || ''
    return `${route} ${streetNumber}, ${postalCode} ${locality}`
  }
}

export const outputDurationStart = (home) => {
  const duration = home.duration
  const startAsap = duration.start.asap
  const startOptimal = duration.start.optimal
  const start = startAsap ? 'Asap' : new Date(startOptimal).toLocaleDateString()
  return start
}
