import { graphql } from '../../../graphql/__generated__'

export const IDENTIFICATION = graphql(/* GraphQL */ `
  query Identification($uid: ID!) {
    user(uid: $uid) {
      adminFields {
        onfidoApplicantId
      }
      identityConfirmedAt
      private {
        identificationStatus
      }
    }
  }
`)

export const APPROVE_IDENTIFICATION = graphql(/* GraphQL */ `
  mutation ApproveIdentification($uid: ID!, $idNumberFields: IdNumberFieldsInput) {
    approveIdentification(uid: $uid, idNumberFields: $idNumberFields) {
      success
      errors {
        ...BaseError
      }
    }
  }
`)

export const REJECT_IDENTIFICATION = graphql(/* GraphQL */ `
  mutation RejectIdentification($uid: ID!) {
    rejectIdentification(uid: $uid) {
      success
    }
  }
`)

export const CREATE_ONFIDO_VERIFICATION_LINK = graphql(/* GraphQL */ `
  mutation CreateOnfidoVerificationLink($uid: ID!) {
    createOnfidoVerificationLink(uid: $uid) {
      errors {
        ...BaseError
      }
      url
    }
  }
`)
