import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Avatar, InputBase, Label, Link, Paragraph, Stack } from '@qasa/qds-ui'

import { Api } from '../../../api'
import {
  addCommentToProcessLoading,
  addCommentToProcessSuccess,
  addCommentToProcessFailed,
} from '../overdue-payments-actions'
import { displayApiErrors } from '../../../utils'

const Wrapper = styled(Stack)({ width: 342 })
const ExpandLink = styled(Link)({
  cursor: 'pointer',
})
const Comment = Stack
const Header = Stack
const Name = styled(Label)({ flexGrow: 1, whiteSpace: 'nowrap' })
const DateTime = Paragraph
const CommentBody = Paragraph

const NUMBER_OF_PERMANENTLY_VISIBLE_COMMENTS = 2

type Comment = {
  id: string
  user: {
    pictureUrl: string
    name: string
  }
  createdAt: string
  processState: string
  body: string
}

type CommentsSectionProps = {
  processComponent: any
  processId: number
  comments: any
  dispatch: any
  isAddCommentLoading?: boolean
  addCommentError?: any
}
export function CommentsSection({
  processComponent,
  processId,
  comments,
  dispatch,
  isAddCommentLoading,
  addCommentError,
}: CommentsSectionProps) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [comment, setComment] = useState('')

  const visibleComments = isExpanded ? comments : comments.slice(-NUMBER_OF_PERMANENTLY_VISIBLE_COMMENTS)

  const addCommentToProcess = () => {
    dispatch(addCommentToProcessLoading())
    Api.processesAddComment(processId, comment)
      .then((res) => {
        dispatch(addCommentToProcessSuccess(res.data.users))
      })
      .catch((e) => {
        dispatch(addCommentToProcessFailed(e))
        displayApiErrors(e)
      })
  }

  useEffect(() => {
    if (!isAddCommentLoading) {
      if (addCommentError === null) {
        setComment('')
      }
    }
  }, [isAddCommentLoading, addCommentError])

  return (
    <Wrapper gap="4x">
      {!isExpanded && comments.size > NUMBER_OF_PERMANENTLY_VISIBLE_COMMENTS && (
        <ExpandLink
          onClick={() => {
            setIsExpanded(true)
          }}
        >
          Show {comments.size - NUMBER_OF_PERMANENTLY_VISIBLE_COMMENTS} more comments
        </ExpandLink>
      )}

      {visibleComments.map((comment: Comment) => (
        <Comment key={comment.id} gap="2x">
          <Header direction="row" alignItems="center" gap="2x">
            <Avatar size={'sm'} src={comment.user.pictureUrl} />
            <Name size="sm">{comment.user.name}</Name>
            <DateTime size="xs" color="disabled" textAlign="right">
              {new Intl.DateTimeFormat('sv-SE', {
                dateStyle: 'short',
                timeStyle: 'short',
                timeZone: 'Europe/Stockholm',
              }).format(new Date(comment.createdAt))}
              <br />
              Step: {processComponent.getStateName(comment.processState)}
            </DateTime>
          </Header>
          <CommentBody size="sm">{comment.body}</CommentBody>
        </Comment>
      ))}
      <form
        onSubmit={(e) => {
          e.preventDefault()
          addCommentToProcess()
        }}
      >
        <InputBase
          placeholder={'Enter comment...'}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          isDisabled={isAddCommentLoading}
        />
      </form>
    </Wrapper>
  )
}
