import { graphql } from '../../../graphql/__generated__'

export const UPSERT_FRANCE_CONTRACT = graphql(/* GraphQL */ `
  mutation UpsertFranceContract($franceUpsertContractId: ID, $input: FranceUpsertContractInput!) {
    franceUpsertContract(id: $franceUpsertContractId, input: $input) {
      contract {
        id
        marketDetails {
          ... on FranceMarketDetails {
            depositMonths
          }
        }
      }
    }
  }
`)
