import { Button, Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { CollapsibleSection } from '../../components/_core/collapsible-section'
import { useSyncBBQNotifications } from '../../queries/bbq-notifications-queries'
import {
  useUserPermanentDelete,
  useResetCreditCheck,
  useSendResetLinkToEmail,
  useSafeRentalToggleMutation,
  useCreditCheck,
  useSyncUserToBillecta,
  useClearSchibstedAccountId,
  useAddInvoicingForUser,
  useRemoveInvoicingForUser,
  useVerifyAccount,
} from '../../queries'

import { InactivateUserAccountDialog, ReactivateUserAccountDialog } from './user-account-access'
import { IdentificationDialog } from './identification/identification-dialog'
import { UserEmailUpdate } from './user-email-update'
import { useUserContext } from './user-context'
import { ChangeMainPlatform } from './change-main-platform'

const Wrapper = Stack
const DeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.colors.core.red60,
}))

export function UserCTAs() {
  const { user } = useUserContext()
  const uid = user.uid
  const email = user.private.email
  const mainPlatform = user.mainPlatform
  const isBankAccountVerified = Boolean(user.private.activeBankAccount?.verified)
  const shouldShowBankAccountVerificationAction =
    Boolean(user.private.activeBankAccount) && isBankAccountVerified === false
  const isSafeRentalActive = user.safeRental ?? false
  const hasSchibstedAccountId = user.private.schibstedAccountId

  const { mutate: syncBBQNotifications, isLoading: isSyncBBQNotificationsLoading } = useSyncBBQNotifications()
  const { mutate: permanentDelete, isLoading: isPermanentDeleteLoading } = useUserPermanentDelete(uid)
  const { mutate: sendResetLinkToUserEmail, isLoading: isSendResetPasswordLoading } =
    useSendResetLinkToEmail()
  const { mutate: resetCreditCheck, isLoading: isResetCreditCheckLoading } = useResetCreditCheck(uid)
  const { mutate: creditCheck, isLoading: isCreditCheckLoading } = useCreditCheck(uid)

  const { mutate: handleUserUpdate, isLoading: isSafeRentalActionLoading } = useSafeRentalToggleMutation(
    isSafeRentalActive,
    uid,
  )
  const { mutate: verifyAccount, isLoading: isVerifyAccountLoading } = useVerifyAccount(
    user.private.activeBankAccount?.id ?? '',
  )
  const { mutate: clearSchibstedAccountId, isLoading: isClearSchibstedAccountIdLoading } =
    useClearSchibstedAccountId(uid)
  const { mutate: syncUserToBillecta, isLoading: isSyncUserToBillectaLoading } = useSyncUserToBillecta({
    uid,
  })
  const { mutate: removeInvoicingForUser, isLoading: isRemoveInvoicingForUserLoading } =
    useRemoveInvoicingForUser({ uid })
  const { mutate: addInvoicingUser, isLoading: isAddInvoicingUserLoading } = useAddInvoicingForUser({ uid })

  const confirmAction = (warning?: string) => window.confirm(warning || 'Are you sure?')

  const handlePermanentDelete = () => {
    if (confirmAction('This action is permanent! Are you sure you want to delete this user account?')) {
      permanentDelete()
    }
  }

  const handleClearSchibstedAccountID = () => {
    if (confirmAction()) {
      clearSchibstedAccountId()
    }
  }

  const handleCreditCheck = () => {
    if (confirmAction()) {
      creditCheck()
    }
  }

  const toggleSafeRental = () => {
    if (confirmAction()) {
      if (isSafeRentalActive) {
        handleUserUpdate()
      } else {
        handleUserUpdate()
      }
    }
  }

  const handleSyncBBQNotifications = () => {
    syncBBQNotifications(uid)
  }

  const handleResetCreditCheck = () => {
    if (confirmAction()) {
      resetCreditCheck()
    }
  }

  const handleVerifyAccount = () => verifyAccount()

  const handleSendResetPasswordEmail = () => sendResetLinkToUserEmail({ email, platform: mainPlatform })

  return (
    <Wrapper gap="4x">
      <CollapsibleSection title="User">
        {shouldShowBankAccountVerificationAction && (
          <Button variant="tertiary" onClick={handleVerifyAccount} isLoading={isVerifyAccountLoading}>
            Verify bank account details
          </Button>
        )}
        <Button
          variant="tertiary"
          onClick={handleClearSchibstedAccountID}
          isLoading={isClearSchibstedAccountIdLoading}
        >
          Clear Schibsted Account ID
        </Button>
        <Button variant="tertiary" onClick={handleCreditCheck} isLoading={isCreditCheckLoading}>
          Make credit check
        </Button>
        <Button variant="tertiary" onClick={handleResetCreditCheck} isLoading={isResetCreditCheckLoading}>
          Reset credit check
        </Button>
        <Button variant="tertiary" onClick={toggleSafeRental} isLoading={isSafeRentalActionLoading}>
          {isSafeRentalActive ? 'Remove' : 'Add'} safe rental
        </Button>
        <IdentificationDialog uid={uid} />
      </CollapsibleSection>
      <CollapsibleSection title="Account access">
        <InactivateUserAccountDialog uid={uid} />
        <ReactivateUserAccountDialog uid={uid} />
      </CollapsibleSection>
      <CollapsibleSection title="Account">
        {hasSchibstedAccountId && <UserEmailUpdate uid={uid} email={email} />}
        <Button
          variant="tertiary"
          onClick={handleSyncBBQNotifications}
          isLoading={isSyncBBQNotificationsLoading}
        >
          Sync BBQ notifications
        </Button>
        <Button
          variant="tertiary"
          onClick={handleSendResetPasswordEmail}
          isLoading={isSendResetPasswordLoading}
        >
          Send reset password email
        </Button>
        {user && <ChangeMainPlatform uid={uid} mainPlatform={mainPlatform} />}
      </CollapsibleSection>
      <CollapsibleSection title="Economy">
        <Button variant="tertiary" onClick={() => addInvoicingUser()} isLoading={isAddInvoicingUserLoading}>
          Add invoicing of ads
        </Button>
        <Button
          variant="tertiary"
          onClick={() => removeInvoicingForUser()}
          isLoading={isRemoveInvoicingForUserLoading}
        >
          Remove invoicing of ads
        </Button>
        <Button
          variant="tertiary"
          onClick={() => syncUserToBillecta()}
          isLoading={isSyncUserToBillectaLoading}
        >
          Sync user to Billecta
        </Button>
      </CollapsibleSection>
      <CollapsibleSection title="Other">
        <DeleteButton onClick={handlePermanentDelete} isLoading={isPermanentDeleteLoading}>
          Delete account
        </DeleteButton>
      </CollapsibleSection>
    </Wrapper>
  )
}
