import { useState } from 'react'
import { Button, Select, Stack, TextField } from '@qasa/qds-ui'
import { useForm } from 'react-hook-form'
import styled from '@emotion/styled'

import { IdNumberTypeEnum } from '../../../graphql/__generated__/graphql'
import { Alert } from '../../../components/_core/alert'

const SelectWrapper = styled.div({
  flexShrink: 0,
})

const ID_TYPES_OPTIONS = [
  {
    label: 'Sweden',
    value: IdNumberTypeEnum.SwedishPersonalNumber,
  },
  {
    label: 'Finland',
    value: IdNumberTypeEnum.FinnishPersonalNumber,
  },
  {
    label: 'Norway',
    value: IdNumberTypeEnum.NorwegianPersonalNumber,
  },
  { label: 'Other', value: IdNumberTypeEnum.Other },
]

export type IdNumberFormValues = {
  idNumber?: string
  idNumberType?: IdNumberTypeEnum
}
const DEFAULT_VALUES = {
  idNumber: undefined,
  idNumberType: undefined,
}

type AddIdNumberFormProps = {
  formId: string
  onSubmit: (formValues: IdNumberFormValues) => void
  error?: string
}
export function AddIdNumberForm({ formId, onSubmit, error }: AddIdNumberFormProps) {
  const {
    register,
    handleSubmit: reactHookFormSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IdNumberFormValues>({ defaultValues: DEFAULT_VALUES })
  const [isShown, setIsShown] = useState(false)

  const handleExpandIdNumber = () => {
    setIsShown(!isShown)
    reset()
  }
  const resolvedError = error || errors.idNumber?.message || errors.idNumberType?.message
  const idNumber = watch('idNumber')
  const idNumberType = watch('idNumberType')

  return (
    <Stack gap="4x" id={formId} as={'form'} onSubmit={reactHookFormSubmit(onSubmit)}>
      <Button variant="tertiary" onClick={handleExpandIdNumber}>
        {isShown ? 'Remove id number' : 'Add id number'}
      </Button>
      {isShown && (
        <Stack gap="4x">
          <Stack direction="row" gap="4x">
            <SelectWrapper>
              <Select
                {...register('idNumberType', {
                  required: {
                    value: Boolean(idNumber),
                    message: 'Missing id number to verify',
                  },
                })}
                label="Country"
                placeholder={'Select country'}
              >
                {ID_TYPES_OPTIONS.map(({ label, value }) => (
                  <Select.Option key={label} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </SelectWrapper>
            <TextField
              {...register('idNumber', {
                required: {
                  value: Boolean(idNumberType),
                  message: 'Missing country selection to verify',
                },
              })}
              label="Id number"
              placeholder="Enter id number"
            />
          </Stack>
          {resolvedError && <Alert variant="error" title="Invalid data" description={resolvedError} />}
        </Stack>
      )}
    </Stack>
  )
}
