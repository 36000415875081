import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { TextField } from '@qasa/qds-ui'

import { onEnter } from './search-bar.utils'

const Wrapper = styled.div(({ theme }) => ({
  gap: theme.spacing['2x'],
  background: theme.colors.white,
  position: 'relative',
  maxWidth: 350,
  '@media (max-width: 750px)': {
    maxWidth: '100%',
  },
}))
const StyledInput = styled(TextField)(({ theme }) => ({
  paddingLeft: theme.spacing['12x'],
}))
const IconWrapper = styled.span({
  position: 'absolute',
  top: 18,
  left: 8,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 30,
  fontSize: 20,
})

SearchBar.propTypes = {
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  submit: PropTypes.func,
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
}

export function SearchBar({ onChange, submit, value, placeholder, autoFocus = false, errorMessage }) {
  return (
    <Wrapper>
      <StyledInput
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => onEnter({ key: e.key, submit })}
        tabIndex={0}
        value={value}
        type="text"
        placeholder={placeholder}
        autoFocus={autoFocus}
        autoComplete="on"
        name="email"
        isInvalid={errorMessage}
        errorMessage={errorMessage}
      />
      <IconWrapper>🕵🏻‍♂️</IconWrapper>
    </Wrapper>
  )
}
