import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Link as RouterLink } from 'react-router-dom'
import { Button, Heading, LoadingDots, Paragraph, Stack, Link } from '@qasa/qds-ui'

import { QasaLink } from '../../../components/qasa-link'
import { SearchBar } from '../../../components/search-bar'
import { Table2 } from '../../../components/table/table2'
import { config } from '../../../config'
import { usePublishHome, useDenyHome } from '../../../queries'
import { getFormattedHomeAddress } from '../utils'
import { Api } from '../../../api'
import { displayApiErrors } from '../../../utils'
import type { HomesInReviewTabProps } from '../homes-in-review'

import type { Home } from './in-review/home-type'

const DeniedHomesWrapper = styled(Stack)({
  width: '100vw',
})

export function NeedsVerification({ highlightHome, selectedRows, setSelectedRows, resetSelections }: HomesInReviewTabProps) {
  const [page, setPage] = useState(1)
  const [email, setEmail] = useState('')
  const [selectedHomes, setSelectedHomes] = useState<Home[]>([])
  const [homes, setHomes] = useState<Home[]>([])
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const { mutate: publishHome, isLoading: isPublishHomeLoading } = usePublishHome()
  const { mutate: denyHome, isLoading: isDenyHomeLoading } = useDenyHome()

  const getNeedVerificationHomes = () => {
    setIsLoading(true)
    Api.homesGetNeedsVerification(page, email)
      .then((res) => {
        if (res.status === 200) {
          setHomes(res.data.adminReviewHomes)
          setCount(res.data.count)
        }
      })
      .catch(displayApiErrors)
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (homes.length === 0) {
      getNeedVerificationHomes()
    }
  }, [])

  const confirmAction = () => {
    return window.confirm('Are you sure? ' + selectedHomes.length + ' homes will be affected')
  }

  const handleEditHome = () => {
    if (selectedHomes.length < 2 || confirmAction()) {
      selectedHomes.forEach((home: Home) => {
        const win = window.open(`${config[home.mainPlatform]}/listings/${home.id}/edit`, '_blank')
        win?.focus()
      })
    }
  }

  const handlePublishHome = () => {
    if (selectedHomes.length < 2 || confirmAction()) {
      const homeIds = selectedHomes.map((home: Home) => home.id)
      homeIds.forEach((homeId) => {
        publishHome(homeId, {
          onSuccess: () => {
            getNeedVerificationHomes()
            resetSelections()
          },
        })
      })
    }
  }

  const handleDenyHome = () => {
    if (selectedHomes.length < 2 || confirmAction()) {
      const homeIds = selectedHomes.map((home: Home) => home.id)
      homeIds.forEach((homeId) => {
        denyHome(
          { id: homeId, archiveReason: 'review_denied' },
          {
            onSuccess: () => {
              getNeedVerificationHomes()
              resetSelections()
            },
          },
        )
      })
    }
  }

  const getHomes = (newPage: number) => {
    getNeedVerificationHomes()
    setPage(newPage)
  }

  const getFilteredHomes = () => {
    setHomes([])
    getHomes(1)
  }

  const onSelectionChanged = (selection: number[]) => {
    setSelectedHomes(selection.map((indexOfSelectedHome: number) => homes[indexOfSelectedHome]))
    const highlightedHome = selection.length === 1 ? homes[selection[0]] : null
    highlightHome(highlightedHome)
  }

  const actions = [
    {
      label: 'Edit home',
      action: handleEditHome,
      singleItem: true,
    },
    {
      label: 'Publish',
      action: handlePublishHome,
      singleItem: false,
      isLoading: isPublishHomeLoading,
    },
    {
      label: 'Deny',
      action: handleDenyHome,
      singleItem: false,
      isLoading: isDenyHomeLoading,
    },
  ]

  return (
    <DeniedHomesWrapper gap="2x">
      <SearchBar
        submit={getFilteredHomes}
        onChange={(email) => setEmail(email)}
        placeholder="example@email.com"
        value={email}
      />
      <Stack gap="2x" direction="row" justifyContent="flex-end">
        {actions.map(({ label, singleItem, action, isLoading }) => (
          <Button
            variant="tertiary"
            key={label}
            disabled={selectedHomes.length === 0 || (singleItem && selectedHomes.length > 1)}
            onClick={action}
            isLoading={isLoading}
          >
            {label}
          </Button>
        ))}
      </Stack>
      {isLoading && <LoadingDots />}
      {!isLoading && homes && (
        <>
          <Heading size="2xs">
            Homes that need verification: <Paragraph as="span">{count}</Paragraph>
          </Heading>
          <Table2
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            enableRowSelection
            onSelectionChanged={onSelectionChanged}
            rows={homes}
            getRowKey={(home) => home.id}
            columnsConfig={[
              {
                title: 'Rental type',
                render: (home: Home) => home.rentalType,
              },
              {
                title: 'Name',
                render: (home: Home) => (
                  <Link isExternal as={RouterLink} to={`/user?user=${encodeURIComponent(home.email)}`}>
                    {home.professional ? '🏢 ' + home.companyName : home.fullName}
                  </Link>
                ),
              },
              {
                title: 'E-mail',
                render: (home: Home) => home.email,
                getCellClassName: (home: Home) => (Boolean(home.emailConfirmedAt) ? 'Success' : 'Danger'),
              },
              {
                title: 'Bank ID',
                render: (home: Home) =>
                  home.identityConfirmedAt ? `Verified at: ${home.identityConfirmedAt}` : 'Not verified',
                getCellClassName: (home: Home) => (home.identityConfirmedAt ? 'Success' : 'Danger'),
              },
              {
                title: 'Address',
                render: getFormattedHomeAddress,
              },
              {
                title: 'Home',
                render: (home: Home) => (
                  <QasaLink to={`/home/${home.id}`}>
                    ${home.rent} | {home.squareMeters} m2 | {home.roomCount}
                  </QasaLink>
                ),
              },
              {
                title: 'Deny reason',
                render: (home: Home) => home.archiveReason,
              },
            ]}
          />
        </>
      )}
      <div>
        <Button
          isDisabled={homes.length === count}
          variant="tertiary"
          size="sm"
          onClick={() => getHomes(page + 1)}
        >
          Load more homes
        </Button>
      </div>
    </DeniedHomesWrapper>
  )
}
