import { VISIBLE_CONTRACT_STATUSES } from './constants'
import { type KanyeUserContractsType } from './contracts/contracts.gql'

type SortContractsType = {
  contracts: KanyeUserContractsType
}
export const sortContracts = ({ contracts }: SortContractsType) => {
  return contracts.sort((contractA, contractB) => {
    const statusIndexA = VISIBLE_CONTRACT_STATUSES.indexOf(contractA.status)
    const statusIndexB = VISIBLE_CONTRACT_STATUSES.indexOf(contractB.status)

    if (statusIndexA === statusIndexB) {
      return contractA.id < contractB.id ? 1 : -1
    }

    return statusIndexA < statusIndexB ? -1 : 1
  })
}
