import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useHistory } from 'react-router'
import { Stack } from '@qasa/qds-ui'

import { Tabs } from '../../components/tabs/tabs'

import { InReview, DeniedHomes, NeedsVerification, LatestPublished } from './tabs'
import { HomePreview } from './home-preview'
import type { Home } from './tabs/in-review/home-type'

const ContentWrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.bg.default,
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  overflow: 'scroll',
  paddingBottom: theme.spacing['4x'],
  alignSelf: 'flex-start',
}))
const HomePreviewSection = styled.div(({ theme }) => ({
  height: '100%',
  position: 'sticky',
  bottom: 0,
  marginBottom: theme.spacing['4x'],
}))

const TAB_COMPONENT = {
  InReview,
  DeniedHomes,
  NeedsVerification,
  LatestPublished,
}

const PATH_TAB_MAP = {
  '/homes-in-review': 'InReview',
  '/homes-in-review/denied-homes': 'DeniedHomes',
  '/homes-in-review/needs-verification': 'NeedsVerification',
  '/homes-in-review/latest-published': 'LatestPublished',
} as const

type TabMapKeys = keyof typeof PATH_TAB_MAP
type TabMapValues = (typeof PATH_TAB_MAP)[TabMapKeys]
export type HomesInReviewTabProps = {
  highlightHome: (home: Home | null) => void
  selectedRows: number[]
  setSelectedRows: Dispatch<SetStateAction<number[]>>
  resetSelections: () => void
}

export function HomesInReview() {
  const [activeTab, setActiveTab] = useState('')
  const [highlightedHome, setHighlightedHome] = useState<Home | null>(null)
  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const resetSelections = () => {
    setSelectedRows([])
    highlightHome(null)
  }

  const { pathname, search } = location
  const { push } = useHistory()

  const changeTab = ({ path }: { path: string }) => {
    setHighlightedHome(null)
    push(`/homes-in-review${path}${search}`)
  }

  useEffect(() => {
    setActiveTab(PATH_TAB_MAP[pathname as TabMapKeys])
  }, [pathname])

  if (!activeTab) return null

  const tabs = [
    {
      id: 'InReview',
      label: 'In review',
      onClick: () => changeTab({ path: '' }),
    },
    {
      id: 'DeniedHomes',
      label: 'Denied homes',
      onClick: () => changeTab({ path: '/denied-homes' }),
    },
    {
      id: 'NeedsVerification',
      label: 'Needs verification',
      onClick: () => changeTab({ path: '/needs-verification' }),
    },
    {
      id: 'LatestPublished',
      label: 'Latest published',
      onClick: () => changeTab({ path: '/latest-published' }),
    },
  ]

  const highlightHome = (home: Home | null) => setHighlightedHome(home)

  const ActiveTab = TAB_COMPONENT[activeTab as TabMapValues]

  return (
    <>
      <Tabs tabs={tabs} activeTab={activeTab} />
      <Stack direction="row" alignItems="flex-end">
        <ContentWrapper>
          <ActiveTab
            highlightHome={highlightHome}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            resetSelections={resetSelections}
          />
        </ContentWrapper>
        <HomePreviewSection>
          <HomePreview home={highlightedHome} />
        </HomePreviewSection>
      </Stack>
    </>
  )
}
