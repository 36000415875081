import { Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { TabsEnum, useAuthorisationContext } from '../../../context/authorisation-context'

const TabList = Stack
const Tab = styled(Stack)<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  flex: 1,
  backgroundColor: isSelected ? theme.colors.bg.brandSecondary : theme.colors.bg.brandTertiary,
  color: isSelected ? theme.colors.core.white : theme.colors.core.black,
  justifyContent: 'center',
  alignItems: 'center',
  borderTopLeftRadius: theme.radii.lg,
  borderTopRightRadius: theme.radii.lg,
  padding: theme.spacing['3x'],
  ...(!isSelected && {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.colors.bg.brandTertiaryHover,
    },
  }),
  userSelect: 'none',
}))

export function Tabs() {
  const { onChangeTab, selectedTab } = useAuthorisationContext()
  return (
    <TabList direction="row" gap="1x">
      <Tab
        isSelected={selectedTab === TabsEnum.Employees}
        onClick={() => onChangeTab({ tab: TabsEnum.Employees })}
      >
        Employees
      </Tab>
      <Tab isSelected={selectedTab === TabsEnum.Groups} onClick={() => onChangeTab({ tab: TabsEnum.Groups })}>
        Groups
      </Tab>
    </TabList>
  )
}
