import { useMutation } from '@apollo/client'
import { Button, Stack, TextField } from '@qasa/qds-ui'
import { useState } from 'react'

import { notifySuccess } from '../../../../utils'

import { UPDATE_CONTRACT_SCRIVE_ID } from './update-scrive-id.gql'

export function UpdateScriveId({ contractId }: { contractId: string }) {
  const [scriveId, setScriveId] = useState('')
  //NOTE: Currently this mutation only works on draft contracts
  const [updateScriveId, { error, loading: isLoading }] = useMutation(UPDATE_CONTRACT_SCRIVE_ID, {
    onCompleted: () => {
      notifySuccess('Scrive ID updated')
      setScriveId('')
    },
  })

  const handleUpdateScriveId = async () => {
    try {
      await updateScriveId({ variables: { contractId, scriveId } })
    } catch {
      // no-op
    }
  }

  const hasError = Boolean(error?.message)
  return (
    <Stack gap="3x">
      <TextField
        label="Modify scrive ID"
        type="number"
        value={scriveId}
        onChange={(ev) => setScriveId(ev.target.value)}
        name="new_scrive_id"
        errorMessage={error?.message}
        isInvalid={hasError}
        placeholder="Scrive ID"
      />
      <Button variant="tertiary" isLoading={isLoading} onClick={handleUpdateScriveId}>
        {'Send'}
      </Button>
    </Stack>
  )
}
