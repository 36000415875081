import type { ReactNode } from 'react'
import { useContext, createContext } from 'react'
import { useQuery } from '@apollo/client'
import { LoadingDots, Paragraph } from '@qasa/qds-ui'

import type { KanyeUserQuery } from '../../graphql/__generated__/graphql'

import { KANYE_USER_QUERY } from './kanye-user.gql'

export type KanyeUser = NonNullable<KanyeUserQuery['kanyeUser']>

export type UserContextValue = {
  user: KanyeUser
}

const UserContext = createContext<UserContextValue | undefined>(undefined)

type UserProviderProps = {
  children: ReactNode
  uid: string
}

function UserProvider({ children, uid }: UserProviderProps) {
  const {
    data: kanyeUserData,
    loading: isLoading,
    error,
  } = useQuery(KANYE_USER_QUERY, { variables: { uid } })

  const user = kanyeUserData?.kanyeUser

  if (isLoading) {
    return <LoadingDots />
  }

  if (error || !user) {
    return <Paragraph>{error?.message ?? 'Unexpected error, no user found.'}</Paragraph>
  }

  return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
}

const useUserContext = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider')
  }

  return context
}

export { UserProvider, useUserContext }
