import { useEffect, useReducer } from 'react'
import styled from '@emotion/styled'
import { Button, Divider, Heading, LoadingDots, Paragraph, Stack } from '@qasa/qds-ui'
import { useLocation } from 'react-router-dom'
import type { ParseOptions } from 'query-string'
import { parse as parseQueryString } from 'query-string'
import isEmpty from 'lodash/isEmpty'
import {
  useQueryParams,
  withDefault,
  ArrayParam,
  BooleanParam,
  NumberParam,
  StringParam,
} from 'use-query-params'

import { QUERY_PARAM_ENCODING_OPTIONS } from '../../app'
import { Api } from '../../api'
import { displayApiErrors } from '../../utils'

import { getProcessesFailed, getProcessesLoading, getProcessesSuccess } from './overdue-payments-actions'
import { overduePaymentsReducer, initState } from './overdue-payments-reducer'
import { User, Header, Pagination } from './components'
import type { StatesType } from './components/processes/eviction-process/eviction-process'
import { STATES } from './components/processes/eviction-process/eviction-process'

const Wrapper = styled.div(({ theme }) => ({
  width: '100%',
  background: theme.colors.core.white,
  padding: theme.spacing['8x'],
  paddingTop: theme.spacing['4x'],
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing['8x'],
}))
const Users = styled(Stack)(({ theme }) => ({
  gap: theme.spacing['16x'],
  marginBottom: theme.spacing['12x'],
}))

export const FILTER_METHODS = {
  filter: 'filter',
  search: 'search',
}
const DEFAULT_STATE = {
  filteredStates: [STATES.report_to_socialtjansten],
  shouldIncludeClosedProcesses: false,
  currentPage: 1,
  searchString: '',
}

export function OverduePayments() {
  const [users, dispatch] = useReducer(overduePaymentsReducer, initState)
  const [overduePaymentsState, setOverduePaymentsState] = useQueryParams({
    filteredStates: withDefault(ArrayParam, DEFAULT_STATE.filteredStates),
    shouldIncludeClosedProcesses: withDefault(BooleanParam, DEFAULT_STATE.shouldIncludeClosedProcesses),
    currentPage: withDefault(NumberParam, DEFAULT_STATE.currentPage),
    searchString: withDefault(StringParam, DEFAULT_STATE.searchString),
  })

  const location = useLocation()
  const processesPerPage = 10
  const filterMethod = isEmpty(overduePaymentsState.searchString)
    ? FILTER_METHODS.filter
    : FILTER_METHODS.search
  const applyFilter = (filteredStates: any, shouldIncludeClosedProcesses: any) => {
    const newState = {
      ...overduePaymentsState,
      filteredStates,
      shouldIncludeClosedProcesses,
      currentPage: 1,
      searchString: '',
    }
    setOverduePaymentsState(newState)
    fetch(newState)
  }
  const applySearch = (searchString: string) => {
    const newState = {
      ...overduePaymentsState,
      searchString,
      currentPage: 1,
    }
    setOverduePaymentsState(newState)
    searchProcesses(searchString)
  }
  const setCurrentPage = (page: number) => {
    const newState = { ...overduePaymentsState, currentPage: page }
    setOverduePaymentsState(newState)
    fetch(newState)
  }

  const getProcesses = (
    states: StatesType,
    shouldIncludeClosedProcesses: boolean,
    page: number,
    processesPerPage: number,
  ) => {
    dispatch(getProcessesLoading())
    Api.processesGet(states, shouldIncludeClosedProcesses, page, processesPerPage)
      .then((res) => {
        dispatch(getProcessesSuccess(res.data.users, res.data.totalPages))
      })
      .catch((e) => {
        dispatch(getProcessesFailed(e))
        displayApiErrors(e)
      })
  }

  const fetch = (state: any) => {
    getProcesses(
      state.filteredStates,
      state.shouldIncludeClosedProcesses,
      state.currentPage,
      processesPerPage,
    )
  }

  const searchProcesses = async (searchString: string) => {
    if (searchString) {
      dispatch(getProcessesLoading())
      await Api.processesSearch(searchString.trim())
        .then((res) => {
          if (res.status === 200) {
            dispatch(getProcessesSuccess(res.data.users, 1))
          }
        })
        .catch((e) => {
          dispatch(getProcessesFailed(e))
          displayApiErrors(e)
        })
    }
  }

  useEffect(() => {
    // overduePaymentsState gets updated later than location, so we need to fetch the state from the search string.
    const stateFromQueryParams = parseQueryString(
      location.search,
      QUERY_PARAM_ENCODING_OPTIONS as ParseOptions,
    )
    // If the search string is empty, we need to use the default state because (again) the overduePaymentsState defaults won't have been applied yet.
    const operationParameters = isEmpty(stateFromQueryParams) ? DEFAULT_STATE : stateFromQueryParams
    const filterMethod = isEmpty(operationParameters.searchString)
      ? FILTER_METHODS.filter
      : FILTER_METHODS.search
    switch (filterMethod) {
      case FILTER_METHODS.filter:
        fetch(operationParameters)
        break
      case FILTER_METHODS.search:
        searchProcesses(operationParameters.searchString as string)
        break
      default:
        throw new Error('Invalid filter method: ' + filterMethod)
    }
  }, [location])

  function Content() {
    if (users.usersWithOverduePayments?.isLoading) {
      return (
        <>
          <Header
            //@ts-expect-error ignore
            initialFilteredStates={overduePaymentsState.filteredStates}
            initialShouldIncludeClosedProcesses={overduePaymentsState.shouldIncludeClosedProcesses}
            initialSearchString={overduePaymentsState.searchString}
            onFilterChange={applyFilter}
            onSearchChange={applySearch}
            filterMethod={filterMethod}
            isLoading={true}
          />
          <LoadingDots />
        </>
      )
    }

    if (users.error) {
      const error = users.error
      return (
        <>
          <Paragraph>Error: {error.message}</Paragraph>
          <Button onClick={fetch}>Retry</Button>
        </>
      )
    }

    const usersData = users.usersWithOverduePayments?.data

    if (usersData === null) {
      return null
    }

    return (
      <>
        <Header
          //@ts-expect-error ignore
          initialFilteredStates={overduePaymentsState.filteredStates}
          initialShouldIncludeClosedProcesses={overduePaymentsState.shouldIncludeClosedProcesses}
          initialSearchString={overduePaymentsState.searchString}
          onFilterChange={applyFilter}
          onSearchChange={applySearch}
          filterMethod={filterMethod}
          isLoading={false}
        />
        {usersData?.length > 0 ? (
          <Users divider={<Divider />}>
            {usersData.map((user: any, index: number) => (
              <User
                key={user.id}
                user={user}
                index={index}
                dispatch={dispatch}
                addComment={users.addComment}
                updateProcess={users.updateProcess}
              />
            ))}
          </Users>
        ) : (
          <Heading size="sm">No matches found</Heading>
        )}
        {users.usersWithOverduePayments.totalPages && (
          <Pagination
            currentPage={overduePaymentsState.currentPage}
            onPageChange={setCurrentPage}
            totalPages={users.usersWithOverduePayments.totalPages}
          />
        )}
      </>
    )
  }

  return (
    <>
      <Stack gap="3x">
        <Heading>Overdue payments</Heading>
        <Wrapper>
          <Content />
        </Wrapper>
      </Stack>
    </>
  )
}
