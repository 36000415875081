import { graphql } from '../../../graphql/__generated__'

export const CREATE_TAKEOVER_LEASE = graphql(/* GraphQL */ `
  mutation CreateExternalContractTakeover($input: CreateExternalContractTakeoverInput!) {
    createExternalContractTakeover(input: $input) {
      contract {
        id
      }
      errors {
        codes
        field
        codesWithMessage {
          code
          message
        }
      }
    }
  }
`)
