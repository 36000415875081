/* eslint-disable */
/* This function is taken from https://stackoverflow.com/a/14991797 */

export function parseCsvStringToArrays(str) {
  const arr = []
  let quote = false // 'true' means we're inside a quoted field

  // Iterate over each character, keep track of current row and column (of the returned array)
  for (let row = 0, col = 0, c = 0; c < str.length; c++) {
    const currentCharacter = str[c]
    const nextCharacter = str[c + 1]

    arr[row] = arr[row] || [] // Create a new row if necessary
    arr[row][col] = arr[row][col] || '' // Create a new column (start with empty string) if necessary

    // If the current character is a quotation mark, and we're inside a
    // quoted field, and the next character is also a quotation mark,
    // add a quotation mark to the current column and skip the next character
    if (currentCharacter == '"' && quote && nextCharacter == '"') {
      arr[row][col] += currentCharacter
      ++c
      continue
    }

    // If it's just one quotation mark, begin/end quoted field
    if (currentCharacter == '"') {
      quote = !quote
      continue
    }

    // If it's a comma and we're not in a quoted field, move on to the next column
    if (currentCharacter == ',' && !quote) {
      ++col
      continue
    }

    // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
    // and move on to the next row and move to column 0 of that new row
    if (currentCharacter == '\r' && nextCharacter == '\n' && !quote) {
      ++row
      col = 0
      ++c
      continue
    }

    // If it's a newline (LF or CR) and we're not in a quoted field,
    // move on to the next row and move to column 0 of that new row
    if (currentCharacter == '\n' && !quote) {
      ++row
      col = 0
      continue
    }
    if (currentCharacter == '\r' && !quote) {
      ++row
      col = 0
      continue
    }

    // Otherwise, append the current character to the current column
    arr[row][col] += currentCharacter
  }

  return arr
}
