import { notifySuccess, displayApiErrors } from '../../utils'
import { Api } from '../../api'

export const inactivateUser = (uid: string, suspended: boolean | null) =>
  Api.userDelete(uid, suspended)
    .then(() => {
      if (suspended) notifySuccess('User inactivated and suspended')
      else notifySuccess('User inactivated')
    })
    .catch(displayApiErrors)

export const reactivateUser = (uid: string, suspended: boolean | null) =>
  Api.userReactivate(uid, suspended)
    .then(() => {
      if (suspended === null) notifySuccess('User reactivated')
      else notifySuccess('User reactivated and unsuspended')
    })
    .catch(displayApiErrors)
