import { Stack, Paragraph, Button, Heading } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { TabsEnum, useAuthorisationContext } from '../../context/authorisation-context'

import { SearchBar } from './search-bar'
import { Tabs } from './tabs/auth-tabs'
import { EmployeeTabContent } from './tabs/employee-tab-content'
import { GroupTabContent } from './tabs/group-tab-content'
import { AssignRolesDialog } from './assign-roles-dialog'
import { CreateGroupDialog } from './create-group-dialog'
import { AddEmployeeToGroupDialog } from './add-employee-to-group-dialog'
import { ListCard } from './components/list-card'

const PageWrapper = styled(Stack)(({ theme }) => ({
  overflow: 'hidden',
  height: '100%',
  paddingBottom: theme.spacing['6x'],
}))
const RightColumn = styled(Stack)(({ theme }) => ({
  borderRadius: theme.radii.lg,
  padding: theme.spacing['6x'],
  flex: 2,
  backgroundColor: theme.colors.core.offWhiteDark,
}))
const ListItemContainer = styled(Stack)(({ theme }) => ({
  overflowY: 'auto',
  borderRadius: theme.radii.lg,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  padding: theme.spacing['3x'],
  paddingBottom: theme.spacing['6x'],
  backgroundColor: theme.colors.bg.brandTertiary,
  minWidth: 300,
  height: '100%',
  '::-webkit-scrollbar': {
    width: 0 /* Remove scrollbar space */,
  },
}))
const ContentSectionWrapper = styled(Stack)(({ theme }) => ({
  borderRadius: theme.radii.lg,
  padding: theme.spacing['6x'],
  backgroundColor: theme.colors.core.white,
  flex: 1,
}))
const ContentSectionItemContainer = styled(Stack)(({ theme }) => ({
  borderRadius: theme.radii.xs,
  paddingInline: theme.spacing['3x'],
  paddingBlock: theme.spacing['1x'],
  backgroundColor: theme.colors.core.blue30,
  width: 'fit-content',
}))

export const BulletList = styled.ul(({ theme }) => ({
  listStyle: 'initial',
  paddingLeft: theme.spacing['4x'],
}))

const TABS = {
  [TabsEnum.Employees]: EmployeeTabContent,
  [TabsEnum.Groups]: GroupTabContent,
}

function LeftSection() {
  const { selectedTab } = useAuthorisationContext()
  const Tab = TABS[selectedTab]

  return (
    <Stack>
      <Tabs />
      <SearchBar />
      <ListItemContainer gap="2x">
        <Tab />
      </ListItemContainer>
    </Stack>
  )
}

function RightSection() {
  const {
    group,
    selectedTab,
    dialogMethods: { rolesDialog, createGroupDialog, assignEmployeeToGroupDialog },
    employee,
  } = useAuthorisationContext()

  const selectedEmployeeRoles = employee?.roles?.nodes || []
  const selectedGroupRoles = group?.roles?.nodes || []
  const selectedEmployeeGroups = employee?.groups?.edges || []
  const selectedGroupEmployees = group?.employees?.nodes || []

  const rolesToShow = selectedTab === TabsEnum.Employees ? selectedEmployeeRoles : selectedGroupRoles

  const shouldShowContent = employee || group

  return (
    <RightColumn>
      {shouldShowContent ? (
        <Stack gap="6x">
          <Stack gap="6x" direction="row">
            <ContentSectionWrapper gap="6x" justifyContent="space-between">
              <Stack gap="2x">
                <Heading size="sm">Roles</Heading>
                {rolesToShow.length > 0 ? (
                  <Stack gap="1x" wrap="wrap" direction="row">
                    {rolesToShow.map((role) => (
                      <ContentSectionItemContainer key={role.id}>
                        <Paragraph size="sm">{role.name}</Paragraph>
                      </ContentSectionItemContainer>
                    ))}
                  </Stack>
                ) : (
                  <Paragraph>{'No roles'}</Paragraph>
                )}
              </Stack>
              <div>
                <Button size="sm" variant="secondary" onClick={rolesDialog.open}>
                  Assign role
                </Button>
              </div>
            </ContentSectionWrapper>
            {selectedTab === TabsEnum.Employees ? (
              <ContentSectionWrapper gap="6x" justifyContent="space-between">
                <Stack gap="2x">
                  <Heading size="sm">Groups</Heading>
                  {selectedEmployeeGroups.length > 0 ? (
                    <Stack gap="1x" wrap="wrap" direction="row">
                      {selectedEmployeeGroups.map((group) => (
                        <ContentSectionItemContainer key={group.node.id}>
                          <Paragraph size="sm">{group.node.name}</Paragraph>
                        </ContentSectionItemContainer>
                      ))}
                    </Stack>
                  ) : (
                    <Paragraph>{'No groups'}</Paragraph>
                  )}
                </Stack>
                <div>
                  <Button variant="secondary" size="sm" onClick={assignEmployeeToGroupDialog.open}>
                    Assign group
                  </Button>
                </div>
              </ContentSectionWrapper>
            ) : (
              <ContentSectionWrapper gap="6x" justifyContent="space-between">
                <Stack gap="2x">
                  <Heading size="sm">Employees</Heading>
                  {selectedGroupEmployees.length > 0 ? (
                    <>
                      {selectedGroupEmployees.map(({ id, name, email, profilePictureUrl }) => (
                        <ListCard
                          key={id}
                          name={name}
                          email={email}
                          profilePictureUrl={profilePictureUrl}
                          isSelectable={false}
                        />
                      ))}
                    </>
                  ) : (
                    <Paragraph>{'No members'}</Paragraph>
                  )}
                </Stack>
              </ContentSectionWrapper>
            )}
          </Stack>
        </Stack>
      ) : selectedTab === TabsEnum.Groups ? (
        <div>
          <Button variant="secondary" onClick={createGroupDialog.open}>
            Create new group
          </Button>
        </div>
      ) : null}
    </RightColumn>
  )
}

export function Authorisation() {
  const { group, selectedTab, employee } = useAuthorisationContext()

  const shouldShowContent = employee || group

  return (
    <PageWrapper direction="row" gap="6x">
      <LeftSection />
      <RightSection />
      <CreateGroupDialog />
      {shouldShowContent && (
        <>
          <AssignRolesDialog />
          {selectedTab === TabsEnum.Employees && <AddEmployeeToGroupDialog />}
        </>
      )}
    </PageWrapper>
  )
}
