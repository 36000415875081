import { Label, Stack } from '@qasa/qds-ui'
import { type FetchResult, useMutation } from '@apollo/client'

import { GenerateSocPdfButton } from '../../../components/pdf-generators/generate-Soc-pdf-button'
import { GenerateKfmPdfButton } from '../../../components/pdf-generators/generate-KfmPdf-button'
import { PreviewPdfButton } from '../../../components/pdf-generators/preview-pdf-button'
import { SubmitSocPdfButton } from '../../../components/submit-soc-pdf/submit-soc-pdf-button'
import { ResetGeneratedPdfButton } from '../../../components/reset-generated-pdf-button'
import { UploadCustomPdfButton } from '../../../components/upload-custom-pdf/upload-custom-pdf-button'
import { notifyFailure } from '../../../utils'
import type {
  UpdateEvictionProcessMutationVariables,
  UpdateEvictionProcessMutation,
} from '../../../graphql/__generated__/graphql'

import { UPDATE_EVICTION_PROCESS } from './eviction-process.gql'

const Wrapper = Stack
const Section = Stack

export type EvictionProcess = {
  id: string
  messageToSocPdf?:
    | {
        id: string
        filename: string
        url?: string | null | undefined
      }
    | null
    | undefined
  contract: {
    id: string
  }
}

export type UpdateEvictionProcess = (input: {
  variables: UpdateEvictionProcessMutationVariables
}) => Promise<FetchResult<UpdateEvictionProcessMutation>>

type EvictionSectionProps = {
  evictionProcess: EvictionProcess
}
export function EvictionSection({ evictionProcess }: EvictionSectionProps) {
  const [updateEvictionProcess, { error: evictionProcessUpdateError }] = useMutation(UPDATE_EVICTION_PROCESS)

  const evictionProcessId = evictionProcess.id

  if (evictionProcessUpdateError) {
    notifyFailure('An error occurred while attempting to update the eviction process')
  }

  return (
    <Wrapper gap="4x">
      <Section gap="2x">
        <Label>{'Generate & send PDF ** NEW **'}</Label>
        <PreviewPdfButton evictionProcess={evictionProcess} />
        <ResetGeneratedPdfButton
          evictionProcessId={evictionProcessId}
          updateEvictionProcess={updateEvictionProcess}
        />
        <UploadCustomPdfButton
          evictionProcessId={evictionProcessId}
          updateEvictionProcess={updateEvictionProcess}
        />
        <SubmitSocPdfButton evictionProcessId={evictionProcessId} />
      </Section>
      <Section gap="2x">
        <Label>{'Generate PDF manually'}</Label>
        <GenerateSocPdfButton evictionProcess={evictionProcess} />
        <GenerateKfmPdfButton evictionProcess={evictionProcess} />
      </Section>
    </Wrapper>
  )
}
