import { useMemo, forwardRef } from 'react'
import { Heading, Paragraph, IconButton, Stack, ShareIcon } from '@qasa/qds-ui'
import upperFirst from 'lodash/upperFirst'

// Destroy all batches temporarily disabled - @magnuslh 2022-10-10
// import { destroyAllowedInvoiceBatches } from '../../actions/contracts-actions'

import { getContractNames } from '../user-contract-meta.utils'
import { ContentAccordian } from '../../../components/content-accordian'
import { notifyDefault } from '../../../utils/notifications'
import { SigningProcessEnum, PricingModelNameEnum } from '../../../graphql/__generated__/graphql'
import { CopyIcon, PinIcon, UnpinIcon } from '../../../components/icons'
import { splitSnakeCase } from '../../../utils/strings'

import { ContractStatus } from './components/contract-status'
import { ExpandedContract } from './expanded-contract'
import { ContractTag } from './components/contract-tag'
import type { TagVariant } from './components/contract-tag.utils'
import type { KanyeUserContractsType } from './contracts.gql'

const Description = Paragraph

type ContractProps = {
  contract: KanyeUserContractsType[number]
  isDefaultExpanded?: boolean
  onPinContract: () => void
  isPinned?: boolean
}
export const Contract = forwardRef<any, ContractProps>(
  ({ contract, isDefaultExpanded = false, onPinContract, isPinned = false }, scrollRef) => {
    const { tenantName, landlordName } = getContractNames({ contract })

    const cosigneesString = contract.cosigningTenants?.length ? 'with cosignees' : ''
    const location = contract.home?.location
    const address = `${location?.route ?? ''} ${location?.streetNumber ?? ''}, ${
      location?.postalCode ?? ''
    } ${location?.locality ?? ''}`

    const contractTags = useMemo(() => {
      const pricingModel = contract.pricingModel
      const isShortcutContract = pricingModel?.modelName === PricingModelNameEnum.ShortcutLandlordMonthlyFee
      const isInstantSignContract = contract.signingProcess === SigningProcessEnum.Instant
      const tags: TagVariant[] = []
      if (isShortcutContract) {
        tags.push('shortcut')
      }
      if (isInstantSignContract) {
        tags.push('instantSign')
      }
      return tags
    }, [contract])

    const handleCopyAddress = () => {
      navigator.clipboard.writeText(address)
      notifyDefault(`Copied address to clipboard!`)
    }

    const formattedContractStatus = upperFirst(splitSnakeCase(contract.status))

    const handleShareContract = () => {
      navigator.clipboard.writeText(`${window.location.href}&contractId=${contract.id}`)
      notifyDefault(`Shareable url with contract id ${contract.id} copied to clipboard!`)
    }

    const headerComponent = (
      <Stack gap="2x" ref={scrollRef}>
        <Stack gap="2x" direction="row" alignItems="center">
          <Heading size="sm">{`#${contract.id} - ${landlordName} & ${tenantName} ${cosigneesString}`}</Heading>
          {contractTags.map((tag) => (
            <ContractTag variant={tag} key={tag} />
          ))}
        </Stack>
        <Stack gap="2x" direction="row" alignItems="center">
          <Description>{address}</Description>
          <IconButton
            label="Copy address to clipboard"
            onClick={(ev) => {
              ev.stopPropagation()
              handleCopyAddress()
            }}
            size="xs"
            icon={CopyIcon}
          />
          <IconButton
            label={isPinned ? 'Unpin contract from top of list' : 'Pin contract to top of list'}
            onClick={(ev) => {
              ev.stopPropagation()
              onPinContract()
            }}
            size="xs"
            icon={isPinned ? UnpinIcon : PinIcon}
          />
          <IconButton
            label="Share contract"
            onClick={(ev) => {
              ev.stopPropagation()
              handleShareContract()
            }}
            size="xs"
            icon={ShareIcon}
          />
        </Stack>
        <ContractStatus status={contract.status} label={formattedContractStatus} />
      </Stack>
    )

    return (
      <ContentAccordian
        HeaderComponent={headerComponent}
        isDefaultExpanded={isDefaultExpanded}
        isPinned={isPinned}
      >
        <ExpandedContract contract={contract} showBatchesDefault={isDefaultExpanded} />
      </ContentAccordian>
    )
  },
)
