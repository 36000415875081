import styled from '@emotion/styled'
import { Label, createStyleVariants } from '@qasa/qds-ui'
import { useMemo } from 'react'

import type { TagVariant } from './contract-tag.utils'

const getTagVariantStyles = createStyleVariants((theme) => ({
  shortcut: {
    backgroundColor: theme.colors.core.softPink,
  },
  instantSign: {
    backgroundColor: theme.colors.core.softYellow,
  },
  leaseTakeover: {
    backgroundColor: theme.colors.core.red30,
  },
}))
const TagWrapper = styled.div<{ variant: TagVariant }>(({ theme, variant }) => ({
  paddingInline: theme.spacing['3x'],
  paddingBlock: theme.spacing['1x'],
  borderRadius: theme.radii.full,
  ...getTagVariantStyles(theme)[variant],
}))

type ContractTagProps = {
  variant: TagVariant
}

const getTagLabel = (variant: TagVariant) => {
  switch (variant) {
    case 'shortcut':
      return 'Shortcut'
    case 'instantSign':
      return 'Instant sign'
    case 'leaseTakeover':
      return 'Lease takeover'
    default:
      return null
  }
}
export function ContractTag({ variant }: ContractTagProps) {
  const variantName = useMemo(() => getTagLabel(variant), [variant])

  return (
    <TagWrapper variant={variant}>
      <Label size="sm">{variantName}</Label>
    </TagWrapper>
  )
}
