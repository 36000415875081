import { Button, Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import './styles.scss'

const TableTopSection = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing['4x'],
}))
const ButtonContainer = styled.div(({ isHidden }) => ({
  visibility: isHidden ? 'hidden' : 'visiable',
}))

export function Table2({
  columnsConfig,
  rows,
  getRowKey,
  enableRowSelection,
  onSelectionChanged,
  selectedRows,
  setSelectedRows,
}) {
  const toggleRowSelection = (rowIndex) => {
    const selection = selectedRows.includes(rowIndex)
      ? selectedRows.filter((index) => index !== rowIndex)
      : [...selectedRows, rowIndex]
    setSelectedRows(selection)
    onSelectionChanged(selection)
  }

  const clearSelection = () => {
    setSelectedRows([])
    onSelectionChanged([])
  }

  return (
    <div>
      <TableTopSection gap="4x" direction="row" alignItems="center">
        <span>{selectedRows.length} rows selected</span>
        <ButtonContainer isHidden={selectedRows.length === 0}>
          <Button size="xs" onClick={clearSelection} variant="tertiary">
            {'Clear selection'}
          </Button>
        </ButtonContainer>
      </TableTopSection>
      <div>
        <table className="Table">
          <thead className="Table__header">
            <tr>
              {enableRowSelection && <th style={{ width: '25px' }} />}
              {columnsConfig.map((config, index) => (
                <th key={index}>{config.title}</th>
              ))}
            </tr>
          </thead>
          <tbody className="Table__body">
            {rows.map((rowItem, rowIndex) => (
              <tr
                key={getRowKey(rowItem)}
                className={classnames({
                  selected: selectedRows.includes(rowIndex),
                  selectable: enableRowSelection,
                })}
                onClick={enableRowSelection ? () => toggleRowSelection(rowIndex) : null}
              >
                {enableRowSelection && (
                  <td
                    valign="middle"
                    style={{
                      textAlign: 'center',
                      verticalAlign: 'bottom',
                    }}
                  >
                    <input
                      style={{ cursor: 'pointer' }}
                      type="checkbox"
                      onChange={() => {}}
                      checked={selectedRows.includes(rowIndex)}
                    />
                  </td>
                )}
                {columnsConfig.map((config) => (
                  <td
                    className={config.getCellClassName ? config.getCellClassName(rowItem) : ''}
                    key={config.title}
                  >
                    {config.render(rowItem, rowIndex)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
Table2.propTypes = {
  columnsConfig: PropTypes.array,
  enableRowSelection: PropTypes.bool,
  getRowKey: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  rows: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectedRows: PropTypes.array,
  setSelectedRows: PropTypes.func,
}
