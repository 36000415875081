import { useState } from 'react'
import styled from '@emotion/styled'
import { Select, TextField, Textarea, Button, Stack } from '@qasa/qds-ui'

import { Dialog } from '../../components/_core/dialog'

import { validateGeojson } from './search-areas.utils'

const StyledButton = styled(Button)({ flex: 1 })

const COUNTRY_CODES = [
  { label: 'Sweden', value: 'se' },
  { label: 'Finland', value: 'fi' },
]

const DEFAULT_COUNTRY = COUNTRY_CODES[0]

const DEFAULT_GEOJSON = `{
  "type": "MultiPolygon",
  "coordinates": [
    [
      [
        [X, Y],
        [X, Y],
        [X, Y],
        [X, Y]
      ]
    ]
  ]
}`

type CreateSearchAreaDialogProps = {
  createArea: (data: any) => void
  isOpen: boolean
  onClose: () => void
}

export function CreateSearchAreaDialog({ createArea, isOpen, onClose }: CreateSearchAreaDialogProps) {
  const [countryCode, setCountryCode] = useState(DEFAULT_COUNTRY.value)
  const [name, setName] = useState('')
  const [city, setCity] = useState('')
  const [geojson, setGeojson] = useState(DEFAULT_GEOJSON)

  const handleCreate = () => {
    const parsedGeojson = validateGeojson(geojson)
    if (parsedGeojson) {
      createArea({ name, city, country: countryCode, geojson: parsedGeojson })
    }
  }
  const handleReset = () => {
    setCountryCode(DEFAULT_COUNTRY.value)
    setName('')
    setCity('')
    setGeojson(DEFAULT_GEOJSON)
  }

  const isResetDisabled = !name && !city && !geojson
  const isSubmitDisabled = !name || !city || !geojson

  return (
    <Dialog isOpen={isOpen} onOpenChange={onClose}>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>{'Create new search area'}</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <Stack gap="2x" direction="row">
            <Select
              label=""
              value={countryCode}
              onChange={(e) => {
                setCountryCode(e.target.value)
              }}
            >
              {COUNTRY_CODES.map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
            <TextField label="" placeholder="name" value={name} onChange={(e) => setName(e.target.value)} />
            <TextField label="" placeholder="city" value={city} onChange={(e) => setCity(e.target.value)} />
          </Stack>
          <Textarea label="" onChange={(e) => setGeojson(e.target.value)} value={geojson} minRows={13} />
        </Dialog.Body>
        <Dialog.Footer>
          <Stack gap="2x" direction="row">
            <StyledButton variant="tertiary" onClick={handleReset} disabled={isResetDisabled}>
              Reset
            </StyledButton>
            <StyledButton variant="primary" onClick={handleCreate} disabled={isSubmitDisabled}>
              Create
            </StyledButton>
          </Stack>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}
