import { Button, Paragraph } from '@qasa/qds-ui'
import { useMutation } from '@apollo/client'

import { notifyFailure, notifySuccess } from '../../../../utils'
import { Dialog } from '../../../../components/_core/dialog'

import { COMPENSATION_CLAIM, CREATE_COMPENSATION_CLAIM } from './compensation-claim.gql'

type Props = {
  contractId: string
}

export function NoClaimContent({ contractId }: Props) {
  const [createCompensationClaim, { loading: isLoadingCreateCompensationClaim }] = useMutation(
    CREATE_COMPENSATION_CLAIM,
    {
      variables: {
        contractId,
      },
      onCompleted: () => {
        notifySuccess('Compensation claim created')
      },
      onError: (error) => notifyFailure(error.message),
      refetchQueries: [COMPENSATION_CLAIM],
    },
  )

  return (
    <>
      <Dialog.Header> Split deposit </Dialog.Header>
      <Dialog.Body>
        <Paragraph>No compensation claim exists for this contract.</Paragraph>
        <Button
          variant={'tertiary'}
          onClick={() => createCompensationClaim()}
          isLoading={isLoadingCreateCompensationClaim}
        >
          Create compensation claim
        </Button>
      </Dialog.Body>
    </>
  )
}
