import React, { useState } from 'react'
import styled from '@emotion/styled'
import {
  Heading,
  Stack,
  Paragraph,
  Textarea,
  Button,
  IconButton,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@qasa/qds-ui'

import { useUpdateAdminCommentForSuspicion, useMarkSuspicionAsFalse } from '../../queries'

import { formatText } from './suspicious-users.utils'
import { SuspiciousEvent } from './suspicious-event'

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing['4x'],
  backgroundColor: theme.colors.bg.default,
}))
const Column = styled(Stack)({
  flex: 1,
})

type SuspicionProps = {
  suspicion: any
  refetch: () => void
}
export function Suspicion({ suspicion, refetch }: SuspicionProps) {
  const type = suspicion.type
  const createdAt = suspicion.createdAt
  const updatedAt = suspicion.updatedAt
  const id = suspicion.id
  const activeSuspiciousEvents: any[] = suspicion.activeSuspiciousEvents

  const [adminComment, setAdminComment] = useState(suspicion.adminComment)
  const [isOpen, setIsOpen] = useState(false)
  const { mutate: updateAdminCommentForSuspicion, isLoading: isUpdateAdminCommentLoading } =
    useUpdateAdminCommentForSuspicion()
  const { mutate: markSuspicionAsFalse, isLoading: isMarkSuspicionAsFalseLoading } = useMarkSuspicionAsFalse()

  const handleMarkSuspicionAsFalse = () => {
    markSuspicionAsFalse(
      { id },
      {
        onSuccess: () => refetch(),
      },
    )
  }

  const handleUpdateComment = () => {
    updateAdminCommentForSuspicion(
      { id, adminComment },
      {
        onSuccess: () => refetch(),
      },
    )
  }

  return (
    <Wrapper gap="2x">
      <Heading>{formatText(type)}</Heading>
      <Stack direction="row" gap="2x">
        <Column gap="3x" alignItems="flex-start">
          <div>
            <Paragraph size="sm" color="subtle">
              Created at: {new Date(createdAt).toLocaleString()}
            </Paragraph>
            <Paragraph size="sm" color="subtle">
              Updated at: {new Date(updatedAt).toLocaleString()}
            </Paragraph>
          </div>
          <Button
            variant="tertiary"
            isLoading={isMarkSuspicionAsFalseLoading}
            onClick={handleMarkSuspicionAsFalse}
          >
            Flag suspicion as false
          </Button>
        </Column>
        <Column gap="2x" alignItems="flex-end">
          <Textarea
            label="Comment"
            value={adminComment}
            onChange={(e) => setAdminComment(e.target.value)}
            minRows={2}
          />
          <Button
            isDisabled={!adminComment}
            isLoading={isUpdateAdminCommentLoading}
            onClick={handleUpdateComment}
          >
            Save
          </Button>
        </Column>
      </Stack>
      <Stack direction="row" alignItems="center">
        <IconButton
          icon={isOpen ? ChevronUpIcon : ChevronDownIcon}
          label="Show more"
          onClick={() => setIsOpen(!isOpen)}
        />
        <Paragraph>{activeSuspiciousEvents ? activeSuspiciousEvents.length : 0} event(s)</Paragraph>
      </Stack>
      {isOpen &&
        activeSuspiciousEvents.map((event: any, index) => <SuspiciousEvent key={index} event={event} />)}
    </Wrapper>
  )
}
