import { Button } from '@qasa/qds-ui'

import { RequiresClearanceLevel } from '../../../components/requires-clearance-level'
import { useCreditInvoice } from '../../../queries/invoice-queries'

export function CreditInvoiceButton({ id }: { id: string }) {
  const {
    mutate: creditInvoice,
    isLoading,
    endpointInfo: creditInvoiceEndpointInfo,
    isSuccess,
  } = useCreditInvoice()

  return (
    <RequiresClearanceLevel endpoints={[creditInvoiceEndpointInfo]}>
      <Button
        variant="tertiary"
        size="sm"
        isLoading={isLoading}
        isDisabled={isSuccess}
        onClick={() => creditInvoice({ id })}
      >
        Credit invoice
      </Button>
    </RequiresClearanceLevel>
  )
}
