import * as Sentry from '@sentry/react'

const NO_INTERNET_CONNECTION_ERRORS = [
  'Network request failed',
  'Failed to fetch',
  'NetworkError',
  'Network Error',
  'Misslyckades med fetch',
]

const isDev = process.env.NODE_ENV === 'development'
export const initializeSentry = () => {
  return Sentry.init({
    dsn: 'https://9ae82dd3abd68c0d0da05613d6b2a5ea@o518680.ingest.us.sentry.io/4507548778627072',
    debug: isDev,
    enabled: !isDev,
    integrations: [
      Sentry.browserTracingIntegration(),
      // show text and images in session replay instead of replacing them with asterisks
      Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    ignoreErrors: NO_INTERNET_CONNECTION_ERRORS, // Ignore network errors
    replaysSessionSampleRate: 0, // The sample rate for replays that begin recording immediately and last the entirety of the user's session.
    replaysOnErrorSampleRate: 1.0, // The sample rate for replays that are recorded when an error happens. This type of replay will record up to a minute of events prior to the error and continue recording until the session ends.
  })
}

export function identirySentryUser({
  email,
  id,
  firstName,
}: {
  email: string
  id: string
  firstName: string
}) {
  Sentry.setUser({
    id,
    email,
    username: firstName,
    ip_address: '{{auto}}',
  })
}

export function clearSentryUser() {
  Sentry.setUser(null)
}
