import { graphql } from '../../graphql/__generated__'

export const MARK_INVOICE_AS_PAID = graphql(`
  mutation MarkInvoiceAsPaid($id: ID!) {
    markInvoiceAsPaid(id: $id) {
      ... on Invoice {
        paidAt
      }
      ... on ValidationError {
        message
        attributeErrors {
          attribute
          message
          type
        }
      }
      ... on Error {
        message
      }
    }
  }
`)
