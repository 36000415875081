import styled from '@emotion/styled'
import { Button, Label, Paragraph, Stack } from '@qasa/qds-ui'

import type { StatesType } from '../processes/eviction-process/eviction-process'
import { STATE_NAMES } from '../processes/eviction-process/eviction-process'

const Settings = styled(Stack)(({ theme }) => ({
  background: theme.colors.bg.inset,
  padding: theme.spacing['4x'],
  borderRadius: theme.radii.md,
}))
const Options = styled(Stack)(({ theme }) => ({ flexWrap: 'wrap', rowGap: theme.spacing['2x'] }))
const Checkbox = Stack

type FilterSettingsProps = {
  filteredStates: StatesType[]
  setFilteredStates: (input: any) => void
  shouldIncludeClosedProcesses: boolean
  setShouldIncludeClosedProcesses: (input: boolean) => void
  setIsFilterVisible: (input: boolean) => void
  onFilterChange: (filteredStates: StatesType[] | string[], shouldIncludeClosedProcesses: boolean) => void
  isLoading: boolean
}

export function FilterSettings({
  filteredStates,
  setFilteredStates,
  shouldIncludeClosedProcesses,
  setShouldIncludeClosedProcesses,
  onFilterChange,
  setIsFilterVisible,
  isLoading,
}: FilterSettingsProps) {
  const applySettingsAndClose = (
    filteredStates: StatesType[] | string[],
    shouldIncludeClosedProcesses: boolean,
  ) => {
    onFilterChange(filteredStates, shouldIncludeClosedProcesses)
    setIsFilterVisible(false)
  }

  return (
    <Settings gap="2x" alignItems="flex-start">
      <Label>General</Label>
      <Options direction="row" gap="4x" alignItems="center">
        <label>
          <Checkbox direction="row" gap="2x" alignItems="center">
            <input
              type="checkbox"
              checked={shouldIncludeClosedProcesses}
              onChange={() => {
                setShouldIncludeClosedProcesses(!shouldIncludeClosedProcesses)
              }}
            />
            <Paragraph size={'sm'}>Include closed processes</Paragraph>
          </Checkbox>
        </label>
      </Options>
      <Label>Eviction process</Label>
      <Options>
        <Button variant={'tertiary'} size={'xs'} onClick={() => setFilteredStates([])}>
          Clear
        </Button>
        {Object.keys(STATE_NAMES).map((key) => (
          <Button
            key={key}
            variant={'tertiary'}
            size={'xs'}
            onClick={() => {
              const newFilteredStates = [key]
              setFilteredStates(newFilteredStates)
              applySettingsAndClose(newFilteredStates, shouldIncludeClosedProcesses)
            }}
          >
            {STATE_NAMES[key as StatesType]}
          </Button>
        ))}
      </Options>
      <Options>
        {Object.keys(STATE_NAMES).map((key) => {
          return (
            <label key={key}>
              <Checkbox key={key}>
                <input
                  type="checkbox"
                  checked={filteredStates.includes(key as StatesType)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilteredStates([...filteredStates, key])
                    } else {
                      setFilteredStates(filteredStates.filter((state) => state !== key))
                    }
                  }}
                />
                <Paragraph size={'sm'}>{STATE_NAMES[key as StatesType]}</Paragraph>
              </Checkbox>
            </label>
          )
        })}
      </Options>
      <Button
        variant={'secondary'}
        size={'sm'}
        onClick={() => {
          applySettingsAndClose(filteredStates, shouldIncludeClosedProcesses)
        }}
        disabled={isLoading}
      >
        Apply
      </Button>
    </Settings>
  )
}
