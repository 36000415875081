import { useMutation } from '@apollo/client'
import { Button, Stack } from '@qasa/qds-ui'
import { useState } from 'react'

import type { UserPrivate } from '../../../graphql/__generated__/graphql'
import { IdentificationStatusEnum, IdNumberTypeEnum } from '../../../graphql/__generated__/graphql'
import { notifySuccess, notifyFailure } from '../../../utils/notifications'

import type { IdNumberFormValues } from './add-id-number-form'
import { AddIdNumberForm } from './add-id-number-form'
import { APPROVE_IDENTIFICATION, IDENTIFICATION, REJECT_IDENTIFICATION } from './identification.gql'
import { ReverifyOnfido } from './reverify-onfido'

const UPSERT_FORM_ID = 'ID_NUMBER'
type ActionsVariant = 'default' | 'onfido'

type IdentificationActionsProps = Pick<UserPrivate, 'identificationStatus'> & {
  handleReset?: VoidFunction
  closeDialog: VoidFunction
  uid: string
  variant?: ActionsVariant
  onfidoId?: string | null
}
export function IdentificationActions({
  identificationStatus,
  handleReset,
  closeDialog,
  uid,
  variant = 'default',
  onfidoId,
}: IdentificationActionsProps) {
  const [approveError, setApproveError] = useState('')
  const [approveIdentification, { error: mutationApproveError, loading: isApproveLoading }] = useMutation(
    APPROVE_IDENTIFICATION,
    {
      refetchQueries: [IDENTIFICATION],
      onCompleted: (data) => {
        if (data.approveIdentification?.success) {
          notifySuccess('User was verified')
          closeDialog()
        } else {
          // Formatting issues is the only error
          setApproveError('Id number format is invalid')
        }
      },
    },
  )
  const [rejectIdentification, { loading: isRejectLoading }] = useMutation(REJECT_IDENTIFICATION, {
    refetchQueries: [IDENTIFICATION],
    onCompleted: () => {
      notifySuccess('User was rejected')
    },
    onError: () => {
      notifyFailure('Failed to reject user')
    },
  })

  const handleVerifySubmit = async (data: IdNumberFormValues) => {
    const { idNumber, idNumberType } = data

    await approveIdentification({
      variables: {
        uid,
        idNumberFields: {
          idNumber: idNumber ?? '',
          idNumberType: idNumberType ?? IdNumberTypeEnum.Other,
        },
      },
    })
  }

  const handleReject = async () => {
    await rejectIdentification({ variables: { uid } }).finally(() => {
      closeDialog()
    })
  }

  switch (identificationStatus) {
    case IdentificationStatusEnum.Accepted:
      if (variant === 'onfido') {
        return (
          <Button isLoading={isRejectLoading} variant="tertiary" onClick={handleReject} isFullWidth>
            {'Reject'}
          </Button>
        )
      }
      return (
        <Button variant="tertiary" isFullWidth onClick={handleReset}>
          {'Reset'}
        </Button>
      )
    case IdentificationStatusEnum.Pending:
    case IdentificationStatusEnum.Rejected:
    case IdentificationStatusEnum.RejectedMinor:
    case IdentificationStatusEnum.Invalidated:
    default:
      return (
        <Stack gap={'12x'}>
          <AddIdNumberForm
            error={approveError || mutationApproveError?.message}
            formId={UPSERT_FORM_ID}
            onSubmit={handleVerifySubmit}
          />
          <Stack gap={'4x'}>
            <Button
              isLoading={isApproveLoading}
              type={'submit'}
              variant="tertiary"
              form={UPSERT_FORM_ID}
              isFullWidth
            >
              {'Verify'}
            </Button>
            {/* Only display if they have an onfido id */}
            {onfidoId && <ReverifyOnfido uid={uid} />}
          </Stack>
        </Stack>
      )
  }
}
