import type { TextFieldProps } from '@qasa/qds-ui'
import { TextField } from '@qasa/qds-ui'
import type { FieldValues, Path, UseControllerProps } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { formatDate } from '../../../utils/date'

type FormDateFieldProps<TValues extends FieldValues, TPath extends Path<TValues>> = UseControllerProps<
  TValues,
  TPath
> &
  Pick<TextFieldProps, 'label' | 'isInvalid' | 'errorMessage' | 'helperText'> & {
    min?: Date
    max?: Date
  }

export function FormDateField<TValues extends FieldValues, TPath extends Path<TValues>>(
  props: FormDateFieldProps<TValues, TPath>,
) {
  const { label, isInvalid, errorMessage, helperText, min: minProp, max: maxProp, ...restProps } = props
  const { field } = useController<TValues, TPath>(restProps)
  const { value, ...fieldProps } = field

  const min = minProp ? formatDate(minProp) : undefined
  const max = maxProp ? formatDate(maxProp) : undefined

  return (
    <TextField
      label={label}
      type="date"
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      helperText={helperText}
      value={value ? formatDate(new Date(value)) : undefined}
      min={min}
      max={max}
      {...fieldProps}
    />
  )
}
