import React, { useState } from 'react'
import { Button, Label, Stack, TextField } from '@qasa/qds-ui'

import { Dialog } from '../../../components/_core/dialog'
import { RadioInput } from '../../../components/input-components/radio-input'
import { useCreateInvoice } from '../../../queries'

const invoiceTypes = {
  SELF_INVOICE_DEPOSIT: 'selfinvoice_deposit',
  SELF_INVOICE_DEPOSIT_COMPENSATION: 'selfinvoice_deposit_compensation',
  INVOICE_DEPOSIT_COMPENSATION: 'invoice_deposit_compensation',
}

type CreateInvoiceProps = {
  contractId: string
}

export function CreateInvoice({ contractId }: CreateInvoiceProps) {
  const [invoiceAmount, setInvoiceAmount] = useState('')
  const [selectedInvoiceType, setSelectedInvoiceType] = useState(invoiceTypes.SELF_INVOICE_DEPOSIT)
  const [isDialogShowing, setIsDialogShowing] = useState(false)
  const { mutate: createInvoice } = useCreateInvoice()

  const invoicingOptions = [
    {
      label: 'Self invoice deposit',
      value: invoiceTypes.SELF_INVOICE_DEPOSIT,
      action: () => {
        if (invoiceAmount) {
          setInvoiceAmount('')
        }
        setSelectedInvoiceType('selfinvoice_deposit')
      },
    },
    {
      label: 'Self invoice deposit compensation',
      value: invoiceTypes.SELF_INVOICE_DEPOSIT_COMPENSATION,
      action: () => setSelectedInvoiceType('selfinvoice_deposit_compensation'),
    },
    {
      label: 'Invoice deposit compensation',
      value: invoiceTypes.INVOICE_DEPOSIT_COMPENSATION,
      action: () => setSelectedInvoiceType('invoice_deposit_compensation'),
    },
  ]
  const handleShowDialog = () => setIsDialogShowing(true)

  const handleCloseDialog = () => setIsDialogShowing(false)

  const isInvoiceTypeSelfInvoiceDeposit = selectedInvoiceType === invoiceTypes.SELF_INVOICE_DEPOSIT

  const handleSubmitCreateInvoice = () => {
    createInvoice({ contractId, amount: invoiceAmount, invoiceType: selectedInvoiceType })
    setInvoiceAmount('')
    handleCloseDialog()
  }

  return (
    <>
      <Button variant="tertiary" onClick={handleShowDialog}>
        Create invoice
      </Button>
      <Dialog isOpen={isDialogShowing} onOpenChange={handleCloseDialog}>
        <Dialog.Content>
          <Dialog.Header />
          <Dialog.Body>
            <Stack gap="8x">
              <div>
                <Label>{'Invoice type'}</Label>
                {invoicingOptions.map((option) => (
                  <div key={option.value}>
                    <RadioInput
                      label={option.label}
                      value={option.value}
                      currentValue={selectedInvoiceType}
                      onClick={() => setSelectedInvoiceType(option.value)}
                    />
                  </div>
                ))}
              </div>
              {!isInvoiceTypeSelfInvoiceDeposit && (
                <TextField
                  label="Invoice amount"
                  value={invoiceAmount || ''}
                  onChange={(ev) => setInvoiceAmount(ev.target.value)}
                  name="invoice_amount"
                  placeholder="Invoice amount"
                />
              )}
            </Stack>
          </Dialog.Body>
          <Dialog.Footer>
            <Button
              isFullWidth
              onClick={handleSubmitCreateInvoice}
              isDisabled={!isInvoiceTypeSelfInvoiceDeposit && !invoiceAmount}
            >
              Submit
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    </>
  )
}
