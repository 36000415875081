import type { ReactNode } from 'react'

import type { EndpointInfo } from './use-user-endpoint-clearance'
import { useUserEndpointClearance } from './use-user-endpoint-clearance'

type RequiresClearanceLevelProps = {
  endpoints: EndpointInfo[]
  children: ReactNode
}

export function RequiresClearanceLevel({ children, endpoints }: RequiresClearanceLevelProps) {
  const { isUserCleared } = useUserEndpointClearance(endpoints)

  if (!isUserCleared) return null

  return <>{children}</>
}
