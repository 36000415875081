import { graphql } from '../graphql/__generated__'

export const AUTH_BODY = graphql(`
  query AuthBody {
    me {
      uid
      firstName
      profilePicture {
        id
        url
      }
      private {
        email
      }
    }
  }
`)
