import { useState } from 'react'
import { Button, Paragraph, Stack, TextField } from '@qasa/qds-ui'
import { validate as validateEmail } from 'email-validator'

import { Dialog } from '../../components/_core/dialog'
import { useUpdateEmail } from '../../queries'
import { Checkbox } from '../../components/_core/checkbox'

const Acknowledgement = 'div'

type UserEmailUpdateProps = {
  uid: string
  email: string
}
export function UserEmailUpdate({ uid, email }: UserEmailUpdateProps) {
  const [newMailAddress, setNewMailAddress] = useState('')
  const [isLegalAcknowledged, setIsLegalAcknowledged] = useState(false)
  const { mutate: updateEmail, isLoading: isUpdateEmilLoading } = useUpdateEmail()

  const handleUpdateEmail = () => {
    updateEmail({ uid, email: newMailAddress })
    setIsLegalAcknowledged(false)
    setNewMailAddress('')
  }

  const handleCancelEmailUpdate = () => {
    setNewMailAddress('')
    setIsLegalAcknowledged(false)
  }

  return (
    <>
      <Dialog>
        <Dialog.Trigger>
          <Button variant="tertiary">{' Update user email address'}</Button>
        </Dialog.Trigger>
        <Dialog.Content>
          <Dialog.Header>
            <Dialog.Title>Update user email address</Dialog.Title>
          </Dialog.Header>
          <Dialog.Body>
            <Stack gap="8x">
              <Acknowledgement>
                <Checkbox
                  label="I acknowledge that the user has updated their Schibsted Account"
                  onChange={() => {
                    setIsLegalAcknowledged(!isLegalAcknowledged)
                  }}
                  isChecked={isLegalAcknowledged}
                />
              </Acknowledgement>
              {isLegalAcknowledged && (
                <Stack gap="8x" justifyContent="center">
                  <TextField
                    label="Update email address"
                    value={newMailAddress || ''}
                    onChange={(ev) => setNewMailAddress(ev.target.value)}
                    name="Update email address"
                    placeholder="sara@qasa.se"
                  />
                  {validateEmail(newMailAddress) && (
                    <Paragraph>
                      Update email for user <b>{email}</b> to <b>{newMailAddress}</b>
                    </Paragraph>
                  )}
                </Stack>
              )}
            </Stack>
          </Dialog.Body>
          {isLegalAcknowledged && (
            <Dialog.Footer hasBorder={false}>
              <Stack direction="row" justifyContent="space-between">
                <Button variant="tertiary" onClick={handleCancelEmailUpdate}>
                  {'Cancel'}
                </Button>
                <Button
                  onClick={handleUpdateEmail}
                  disabled={!validateEmail(newMailAddress) || !newMailAddress}
                  isLoading={isUpdateEmilLoading}
                >
                  {'Update'}
                </Button>
              </Stack>
            </Dialog.Footer>
          )}
        </Dialog.Content>
      </Dialog>
    </>
  )
}
