import { useMutation } from '@tanstack/react-query'

import { supportFlag } from '../api/api-service'
import { notifySuccess } from '../utils/notifications'
import { displayApiErrors } from '../utils/error'

export const useSupportFlag = () => {
  return useMutation(supportFlag, {
    onSuccess: () => {
      notifySuccess('User has been flagged')
    },
    onError: displayApiErrors,
  })
}
