import { graphql } from '../../../../graphql/__generated__'

export const COMPENSATION_CLAIM = graphql(`
  query CompensationClaim($contractId: ID!) {
    compensationClaim(contractId: $contractId) {
      id
      reservations {
        id
        amount
        status
        payoutTo {
          uid
        }
      }
      coverages {
        id
        amount
        status
      }
      balance
      cap
    }
  }
`)

export const CREATE_COMPENSATION_CLAIM = graphql(`
  mutation CreateCompensationClaim($contractId: ID!) {
    createCompensationClaim(contractId: $contractId) {
      compensationClaim {
        id
        reservations {
          id
          amount
          status
          payoutTo {
            uid
          }
        }
        coverages {
          id
          amount
          status
        }
        balance
        cap
      }
    }
  }
`)

export const CREATE_RESERVATIONS = graphql(`
  mutation createReservations($reservations: [CompensationReservationInput!]!, $contractId: ID!) {
    createCompensationReservations(reservations: $reservations, contractId: $contractId) {
      compensationClaim {
        balance
        cap
        id
        reservations {
          status
          amount
        }
      }
    }
  }
`)

export const PAYOUT_COMPENSATION_CLAIM_RESERVATION = graphql(`
  mutation PayoutCompensationClaimReservation($payoutToUid: ID!, $reservationId: ID!) {
    payoutCompensationClaimReservation(payoutToUid: $payoutToUid, reservationId: $reservationId) {
      reservation {
        id
        status
        amount
        payoutTo {
          uid
        }
      }
    }
  }
`)
