import { PricingModelNameEnum, SigningProcessEnum } from '../../../../graphql/__generated__/graphql'
import { type KanyeUserContractType } from '../contracts.gql'

export type TagVariant = 'shortcut' | 'instantSign' | 'leaseTakeover'

type GetContractTags = {
  modelName?: PricingModelNameEnum
  signingProcess?: SigningProcessEnum
  externalContractTakeover?: KanyeUserContractType['externalContractTakeover']
}
export const getContractTags = ({ externalContractTakeover, modelName, signingProcess }: GetContractTags) => {
  const isShortcutContract = modelName === PricingModelNameEnum.ShortcutLandlordMonthlyFee
  const isInstantSignContract = signingProcess === SigningProcessEnum.Instant
  const isLeaseTakeover = Boolean(externalContractTakeover?.currentState)
  const tags: TagVariant[] = []

  if (isShortcutContract) {
    tags.push('shortcut')
  }
  if (isInstantSignContract) {
    tags.push('instantSign')
  }
  if (isLeaseTakeover) {
    tags.push('leaseTakeover')
  }
  return tags
}
