import styled from '@emotion/styled'
import { ChevronDownIcon, ChevronUpIcon, Heading, Stack } from '@qasa/qds-ui'
import { useState, type ReactNode } from 'react'

const CollapsibleWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing['4x'],
}))
const CollapsibleHeader = styled.button(({ theme }) => ({
  background: theme.colors.core.gray20,
  padding: theme.spacing['3x'],
  borderRadius: theme.radii.md,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

type CollapsibleSectionProps = {
  title: string
  children: ReactNode
}

export function CollapsibleSection({ title, children }: CollapsibleSectionProps) {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleIsExpanded = () => {
    setIsExpanded((prevIsExpanded) => !prevIsExpanded)
  }

  return (
    <>
      <CollapsibleHeader onClick={toggleIsExpanded}>
        <Heading size="xs">{title}</Heading>
        {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </CollapsibleHeader>
      {isExpanded && <CollapsibleWrapper gap="4x">{children}</CollapsibleWrapper>}
    </>
  )
}
