import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Button } from '@qasa/qds-ui'

import { notifySuccess, notifyFailure } from '../../utils/notifications'
import { Input } from '../../components/input-components/input-component'
import { Api } from '../../api'
import { displayApiErrors } from '../../utils/error'

import { validateGeojson } from './search-areas.utils'
import { CreateSearchAreaDialog } from './create-search-area-dialog'
import { SearchAreaMetadata } from './search-area-metadata'

const Wrapper = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '1fr 2fr',
  gap: 16,
  [theme.mediaQueries.lgUp]: {
    gridTemplateColumns: '2fr 3fr',
  },
}))
const LeftColumn = 'div'
const Title = 'h3'
const InputWrapper = styled.div({ display: 'grid', gridTemplateColumns: '5fr 1fr', columnGap: 8 })
const StyledInput = styled(Input)({ padding: 10 })
const RightColumn = 'div'
const TopSection = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing['2x'],
}))
const ButtonWrapper = styled.div({ display: 'flex', gap: 8 })
const StyledTextarea = styled.textarea(({ theme }) => ({
  padding: theme.spacing['3x'],
  height: '40vh',
  width: '100%',

  [theme.mediaQueries.lgUp]: {
    height: '80vh',
  },
}))

type Geojson = {
  type: string
  coordinates?: (((number[] | null)[] | null)[] | null)[] | null
} | null
type CreatedBy = {
  uid?: null
  email?: null
}
export type SearchArea = {
  geojson: Geojson
  name: string
  city: string
  origin?: null | string
  identifier: string
  createdAt: string
  fallback: boolean
  timesShown: number
  customAreaAddedAt?: null
  createdBy: CreatedBy
  searchAreaKeywords?: { keyword: string; id: number }[] | null
}

// se/stockholm/vasastaden

export function SearchAreas() {
  const [identifier, setIdentifier] = useState('')
  const [geojsonText, setGeojsonText] = useState('')
  const [geojson, setGeojson] = useState(null)
  const [isValid, setIsValid] = useState(false)
  const [isDialogShowing, setIsDialogShowing] = useState(false)
  const [searchArea, setSearchArea] = useState<SearchArea>({
    name: '',
    city: '',
    createdBy: { uid: null, email: null },
    identifier: '',
    createdAt: '',
    geojson: null,
    fallback: false,
    timesShown: 0,
    origin: null,
    searchAreaKeywords: [],
  })

  const getSearchArea = (identifier: string) => {
    Api.getSearchArea(identifier)
      .then(({ data }) => {
        setSearchArea(data)
      })
      .catch((e) => {
        displayApiErrors(e)
      })
  }

  const fetchSearchArea = () => {
    if (identifier.length > 0) {
      getSearchArea(identifier)
      setIsValid(false)
    } else {
      notifyFailure('Area identifier must be provided!')
    }
  }

  useEffect(() => {
    const newGeojson = searchArea?.geojson
    if (newGeojson) {
      const newGeojsonText = JSON.stringify(newGeojson, null, 2)
      setGeojsonText(newGeojsonText)
    }
  }, [searchArea])

  const copyToClipboard = () => {
    navigator.clipboard.writeText(geojsonText)
    notifySuccess('Copied to clipboard')
  }

  const updateGeojson = () => {
    const id = searchArea.identifier
    if (id) {
      Api.updateSearchAreaGeojson(identifier, geojson)
        .then(() => {
          notifySuccess('Successfully updated search area geojson')
          return getSearchArea(identifier)
        })
        .catch(displayApiErrors)
    }
  }

  const createArea = (data: SearchArea) => {
    Api.createSearchArea(data)
      .then(({ data }) => {
        notifySuccess('Successfully created search area')
        return getSearchArea(data.identifier)
      })
      .catch(displayApiErrors)
  }

  const deleteKeyword = ({ id, identifier }: { id: string; identifier: string }) => {
    Api.deleteSearchAreaKeyword(id)
      .then(() => {
        notifySuccess('Successfully deleted keyword')
        return getSearchArea(identifier)
      })
      .catch(displayApiErrors)
  }

  const createKeyword = ({
    searchAreaIdentifier,
    keyword,
  }: {
    searchAreaIdentifier: string
    keyword: string
  }) => {
    Api.createSearchAreaKeyword({ searchAreaIdentifier, keyword })
      .then(() => {
        notifySuccess('Successfully created keyword')
        return getSearchArea(searchAreaIdentifier)
      })
      .catch(displayApiErrors)
  }

  const updateKeyword = ({ id, keyword }: { id: string; keyword: string }) => {
    Api.updateSearchAreaKeyword(id, keyword)
      .then(() => notifySuccess('Successfully updated keyword'))
      .catch(displayApiErrors)
  }

  const handleValidate = () => {
    const parsedGeojson = validateGeojson(geojsonText)
    if (parsedGeojson) {
      setGeojson(parsedGeojson)
      setIsValid(true)
      notifySuccess('Geojson is valid')
    }
  }

  const onIdentifierChange = (value: string) => setIdentifier(value)

  const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setGeojsonText(e.target.value)
    setIsValid(false)
  }

  return (
    <Wrapper>
      <LeftColumn>
        <Button variant="tertiary" onClick={() => setIsDialogShowing(true)}>
          {'Create new searcharea'}
        </Button>
        <Title>{'Search for area'}</Title>
        <InputWrapper>
          <StyledInput
            onChange={onIdentifierChange}
            onEnter={fetchSearchArea}
            placeholder={'se/lysekil/gråskären'}
          />
          <Button variant="tertiary" onClick={fetchSearchArea}>
            {'Find'}
          </Button>
        </InputWrapper>
        <SearchAreaMetadata
          searchArea={searchArea}
          deleteKeyword={deleteKeyword}
          createKeyword={createKeyword}
          updateKeyword={updateKeyword}
        />
        <CreateSearchAreaDialog
          createArea={createArea}
          isOpen={isDialogShowing}
          onClose={() => setIsDialogShowing(false)}
        />
      </LeftColumn>
      <RightColumn>
        <TopSection>
          <Title>{"Area's geojson"}</Title>
          <ButtonWrapper>
            {isValid ? (
              <Button variant="tertiary" onClick={updateGeojson}>
                {'Update geojson'}
              </Button>
            ) : (
              <Button variant="tertiary" onClick={handleValidate} disabled={!geojsonText.length}>
                {'Validate geojson'}
              </Button>
            )}
            <Button variant="tertiary" onClick={copyToClipboard}>
              {'Copy to clipboard!'}
            </Button>
          </ButtonWrapper>
        </TopSection>
        <StyledTextarea onChange={onTextChange} value={geojsonText} />
      </RightColumn>
    </Wrapper>
  )
}
