import { useState } from 'react'
import { Stack, Select, Textarea, Heading, Button } from '@qasa/qds-ui'

import { useAssignRisk } from '../../queries/assign-risk-queries'

import { useUserContext } from './user-context'

enum RiskOption {
  Normal = 'normal',
  HighAcceptable = 'high_acceptable',
  HighPossible = 'high_possible',
  HighNonAcceptable = 'high_non_acceptable',
}

export function AssignRisk() {
  const [risk, setRisk] = useState<RiskOption>()
  const [riskAssessmentNote, setRiskAssessmentNote] = useState('')
  const { mutate: assignRisk, isLoading: isAssignRiskLoading } = useAssignRisk()
  const { user } = useUserContext()
  const { uid } = user

  const handleAssignRisk = () => {
    if (!risk) {
      return
    }

    assignRisk({
      uid,
      overrideRisk: risk,
      riskAssessmentNote,
    })
    setRisk(undefined)
    setRiskAssessmentNote('')
  }

  return (
    <Stack gap="4x">
      <Heading as="h3">Set new risk level</Heading>
      <Select
        label="Risk level"
        placeholder="Select value..."
        value={risk}
        onChange={(e) => {
          setRisk(e.target.value as RiskOption)
        }}
      >
        <Select.Option value={RiskOption.Normal}>Normal</Select.Option>
        <Select.Option value={RiskOption.HighAcceptable}>High acceptable</Select.Option>
        <Select.Option value={RiskOption.HighPossible}>High possible</Select.Option>
        <Select.Option value={RiskOption.HighNonAcceptable}>High non acceptable</Select.Option>
      </Select>
      <Textarea
        label="Comment"
        minRows={3}
        placeholder="The reason..."
        value={riskAssessmentNote}
        onChange={(e) => setRiskAssessmentNote(e.target.value)}
      />
      <Button variant="tertiary" onClick={handleAssignRisk} isLoading={isAssignRiskLoading}>
        Assign new risk
      </Button>
    </Stack>
  )
}
