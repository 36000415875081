import axios from 'axios'
import { config } from 'src/config'

class ContractsApiClass {
  init(accessToken) {
    this.authHeaders = {
      'access-token': accessToken,
    }
  }

  contractsRestartSigning(contractId) {
    return axios.put(`${config.api}/v1/admin/contracts/${contractId}/restart_signing`, {
      headers: this.authHeaders,
    })
  }
}

export const ContractsApi = new ContractsApiClass()
