export const STATE_NAMES = {
  not_started: 'Not started',
  report_to_socialtjansten: 'Report to socialtjänsten',
  awaiting_reply_from_socialtjansten: 'Awaiting reply from Socialtjänsten',
  report_to_kronofogden: 'Report to Kronofogden',
  awaiting_reply_from_kronofogden: 'Awaiting reply from Kronofogden',
  delgivning_approved_awaiting_eviction_date: 'Delgivning approved, awaiting eviction date',
  eviction_date_received: 'Eviction date received',
  delgivning_denied: 'Delgivning denied',
  awaiting_reply_from_landlord_for_delgivning: 'Awaiting reply from landlord',
  done: 'Done',
}

export const STATES = {
  not_started: 'not_started',
  report_to_socialtjansten: 'report_to_socialtjansten',
  awaiting_reply_from_socialtjansten: 'awaiting_reply_from_socialtjansten',
  report_to_kronofogden: 'report_to_kronofogden',
  awaiting_reply_from_kronofogden: 'awaiting_reply_from_kronofogden',
  delgivning_approved_awaiting_eviction_date: 'delgivning_approved_awaiting_eviction_date',
  eviction_date_received: 'eviction_date_received',
  delgivning_denied: 'delgivning_denied',
  awaiting_reply_from_landlord_for_delgivning: 'awaiting_reply_from_landlord_for_delgivning',
  done: 'done',
}

export type StatesType = keyof typeof STATES

export const getStateName = (state: StatesType) => {
  return STATE_NAMES[state]
}
