import { graphql } from '../../../graphql/__generated__'

export const CONTRACT_FINANCES = graphql(/* GraphQL */ `
  query ContractFinances($contractId: ID!) {
    contract(id: $contractId) {
      financeRentInvoices {
        id
        periodStart
        periodEnd
        paidAt
        dueDate
        reference
        currentState
        overdue
        sumAmount {
          humanized
        }
        items {
          id
          productName
          amount {
            humanized
          }
        }
        upcomingPayout {
          dueDate
          periodEnd
          periodStart
          sumAmount {
            humanized
          }
          charges {
            id
            productName
            amount {
              humanized
            }
            vat {
              fractional
              humanized
            }
            vatPercent
          }
        }
      }
      financeDepositInvoices {
        id
        overdue
        dueDate
        currentState
        reference
        paidAt
        sumAmount {
          humanized
        }
      }
      financePayouts {
        periodStart
        periodEnd
        paidAt
        amount {
          humanized
        }
        id
        reference
        currentState
        charges {
          amount {
            humanized
          }
          productName
          id
        }
      }
    }
  }
`)
