import { useMutation } from '@apollo/client'
import { Button, Stack } from '@qasa/qds-ui'
import { useRef, type ChangeEvent } from 'react'
import { ArrayBuffer } from 'spark-md5'
import axios from 'axios'

import { notifyDefault } from '../../utils'
import { notifyFailure, notifySuccess } from '../../utils/notifications'
import type { UpdateEvictionProcess } from '../../screens/user/contracts/eviction-section'

import { CREATE_BLOB } from './upload-custom-pdf.gql'

const generateChecksum = async (file: File) => {
  const arrayBuffer = await file.arrayBuffer()
  const sparkBinaryDigest = ArrayBuffer.hash(arrayBuffer, true)
  const checksum = window.btoa(sparkBinaryDigest)
  return checksum
}

type UploadCustomPdfButtonProps = {
  evictionProcessId: string
  updateEvictionProcess: UpdateEvictionProcess
}
export function UploadCustomPdfButton({
  evictionProcessId,
  updateEvictionProcess,
}: UploadCustomPdfButtonProps) {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [createBlob, { loading: isCreateBlobLoading, error: createBlobError }] = useMutation(CREATE_BLOB)

  if (createBlobError) {
    notifyDefault('An error occurred while uploading the file')
  }

  const onFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = e?.target?.files
    const chosenFile = uploadedFiles?.length && uploadedFiles[0]

    if (chosenFile) {
      const { size, type, name } = chosenFile

      if (!type.includes('pdf')) {
        notifyFailure('Only PDF files are valid for upload')
        return
      }

      const checksum = await generateChecksum(chosenFile)
      const { data } = await createBlob({
        variables: {
          input: {
            byteSize: size,
            checksum,
            contentType: type,
            filename: name,
          },
        },
      })

      const createdBlob = data?.createBlob

      if (createdBlob) {
        const { id, signedUrl, headers } = createdBlob

        await axios.put(signedUrl, chosenFile, {
          headers,
        })
        const { data } = await updateEvictionProcess({
          variables: {
            attributes: {
              messageToSocPdf: id,
            },
            processId: evictionProcessId,
          },
        })

        const updatedEvictionProcessResult = data?.updateEvictionProcess

        if (
          updatedEvictionProcessResult?.__typename === 'EvictionProcess' &&
          updatedEvictionProcessResult?.id
        ) {
          notifySuccess(
            `Successfully uploaded the file: ${updatedEvictionProcessResult.messageToSocPdf?.filename}`,
          )
        }

        // todo: handle other error types with a utility function that outputs the correct message

        if (
          ['AuthorizationError', 'NotFoundError', 'ValidationError'].includes(
            updatedEvictionProcessResult?.__typename ?? '',
          )
        ) {
          notifyDefault('An error occurred while uploading the file')
        }
      }
    }
  }

  return (
    <Stack gap="3x">
      <Button
        variant="tertiary"
        isLoading={isCreateBlobLoading}
        onClick={() => fileInputRef?.current?.click()}
      >
        {'Upload custom SOC PDF'}
      </Button>
      <input onChange={onFileUpload} multiple={false} ref={fileInputRef} type="file" hidden />
    </Stack>
  )
}
