import axios from 'axios'
import { config } from 'src/config'

class ApiClass {
  init(accessToken) {
    this.authHeaders = { 'access-token': accessToken }
  }

  getEndpointClearanceLevels = () =>
    axios.get(`${config.api}/v1/admin/endpoint_clearance_levels`, { headers: this.authHeaders })

  homesGetInReview = () =>
    axios.get(`${config.api}/v1/admin/homes/with_status/in_review`, {
      headers: this.authHeaders,
    })

  homesGetDenied = (page, email) =>
    axios.get(`${config.api}/v1/admin/homes/review/denied`, {
      headers: this.authHeaders,
      params: {
        page,
        perPage: 40,
        email,
      },
    })

  homesGetNeedsVerification = (page, email) => {
    return axios.get(`${config.api}/v1/admin/homes/review/needs_verification`, {
      headers: this.authHeaders,
      params: {
        page,
        perPage: 200,
        email,
      },
    })
  }

  homesGetLatestPublished = (page, email) => {
    return axios.get(`${config.api}/v1/admin/homes/review/latest_published`, {
      headers: this.authHeaders,
      params: {
        page,
        perPage: 40,
        email,
      },
    })
  }

  shortcutGetShortcutCandidates = () =>
    axios.get(`${config.api}/v1/admin/homes/with_status/pending_shortcut`, {
      headers: this.authHeaders,
    })

  userGetCurrent = () => axios.get(`${config.api}/v1/user`, { headers: this.authHeaders })

  userReactivate = (uid, suspended) => {
    let data = {}
    if (suspended !== null) data = { suspended }
    return axios.post(`${config.api}/v1/admin/users/${uid}/reactivate`, data, { headers: this.authHeaders })
  }

  userDelete = (uid, suspended) => {
    let data = {}
    if (suspended !== null) data = { suspended }
    return axios.delete(`${config.api}/v1/users/${uid}`, {
      headers: this.authHeaders,
      data,
    })
  }

  userGetByEmail = (email) =>
    axios.get(`${config.api}/v1/admin/users/email/${email}`, {
      headers: this.authHeaders,
    })

  userGetAllByPhoneNumber = (phone_number) => {
    return axios.get(`${config.api}/v1/admin/users`, {
      headers: this.authHeaders,
      params: {
        phone_number,
      },
    })
  }

  userGetBySchibstedAccountId = (schibsted_account_id) => {
    return axios.get(`${config.api}/v1/admin/users`, {
      headers: this.authHeaders,
      params: {
        schibsted_account_id,
      },
    })
  }

  userGetAllByEmail = (email) => {
    return axios.get(`${config.api}/v1/admin/users`, {
      headers: this.authHeaders,
      params: {
        email,
      },
    })
  }

  userGetByUid = (uid) => {
    return axios.get(`${config.api}/v1/admin/users/uid/${uid}`, {
      headers: this.authHeaders,
    })
  }

  contractsGetIdsByEmail = (email) =>
    axios.get(`${config.api}/v1/admin/contracts/signed_by_email?email=${encodeURIComponent(email)}`, {
      headers: this.authHeaders,
    })

  contractsGetById = (id) =>
    axios.get(`${config.api}/v1/admin/contracts/${id}/with_invoices`, {
      headers: this.authHeaders,
    })

  contractSendInvoice = (id) =>
    axios.post(`${config.api}/v1/admin/invoices/${id}/send`, {}, { headers: this.authHeaders })

  contractApproveDepositPayout = (id) =>
    axios.post(`${config.api}/v1/contracts/${id}/approve_deposit_payout`, {}, { headers: this.authHeaders })

  contractDenyDepositPayout = (id) =>
    axios.post(`${config.api}/v1/contracts/${id}/deny_deposit_payout`, {}, { headers: this.authHeaders })

  reconfirmDeniedBooking = (payload) =>
    axios.put(
      `${config.api}/v1/admin/contracts/${payload.id}/reconfirm_denied_booking`,
      {},
      { headers: this.authHeaders },
    )

  contractDestroyAllowedInvoiceBatches = (id) =>
    axios.delete(`${config.api}/v1/admin/invoices/contract/${id}/allowed_batches`, {
      headers: this.authHeaders,
    })

  contractMarkAsCredited = (id) =>
    axios.put(`${config.api}/v1/admin/invoices/${id}/mark_as_credited`, {}, { headers: this.authHeaders })

  contractResetInvoice = (id) =>
    axios.put(`${config.api}/v1/admin/invoices/${id}/reset`, {}, { headers: this.authHeaders })

  contractDestroyInvoice = (id) =>
    axios.delete(`${config.api}/v1/admin/invoices/${id}`, { headers: this.authHeaders })

  contractUpdateInvoiceEntries = (id, payload) =>
    axios.put(`${config.api}/v1/admin/invoices/${id}/update_entries`, payload, { headers: this.authHeaders })

  getRiskClassifications = (uid) =>
    axios.get(`${config.api}/v1/admin/risk_classifications`, {
      headers: this.authHeaders,
      params: {
        uid,
      },
    })

  getAmlHits = (uid, type) =>
    axios.get(`${config.api}/v1/admin/risk_classifications/aml_hits`, {
      headers: this.authHeaders,
      params: {
        uid,
        type,
      },
    })

  createSearchArea = (data) =>
    axios.post(`${config.api}/v1/admin/search/areas`, data, {
      headers: this.authHeaders,
    })

  getSearchArea = (identifier) =>
    axios.get(`${config.api}/v1/admin/search/areas`, {
      headers: this.authHeaders,
      params: {
        identifier,
      },
    })

  createSearchAreaKeyword = (data) =>
    axios.post(`${config.api}/v1/admin/search/areas/keyword`, data, {
      headers: this.authHeaders,
    })

  deleteSearchAreaKeyword = (id) =>
    axios.delete(`${config.api}/v1/admin/search/areas/keyword`, {
      headers: this.authHeaders,
      params: {
        id,
      },
    })

  updateSearchAreaKeyword = (id, keyword) =>
    axios.put(`${config.api}/v1/admin/search/areas/keyword`, { id, keyword }, { headers: this.authHeaders })

  updateSearchAreaGeojson = (identifier, geojson) => {
    const payload = { geojson }
    return axios.put(`${config.api}/v1/admin/search/areas`, payload, {
      headers: this.authHeaders,
      params: {
        identifier,
      },
    })
  }

  getSubUsersByUser = (uid) =>
    axios.get(`${config.api}/v1/admin/sub_users/show_by_user`, {
      headers: this.authHeaders,
      params: {
        uid,
      },
    })

  cancelSelfInvoice = (id) =>
    axios.put(`${config.api}/v1/admin/invoices/${id}/cancel_selfinvoice`, {}, { headers: this.authHeaders })

  checkInvoicePayment = (id) =>
    axios.put(`${config.api}/v1/admin/invoices/${id}/check_payment`, {}, { headers: this.authHeaders })

  invoiceRemoveCreditedAmount = (id) =>
    axios.put(
      `${config.api}/v1/admin/invoices/${id}/remove_credited_amount`,
      {},
      { headers: this.authHeaders },
    )

  invoiceEnableReminders = (id) =>
    axios.put(`${config.api}/v1/admin/invoices/${id}/enable_reminders`, {}, { headers: this.authHeaders })

  invoiceDisableReminders = (id) =>
    axios.put(`${config.api}/v1/admin/invoices/${id}/disable_reminders`, {}, { headers: this.authHeaders })

  postHomeTemplates = (templateObject) =>
    axios.post(
      `${config.api}/v1/admin/home_templates_multiple`,
      { ...templateObject },
      { headers: this.authHeaders },
    )

  getSuspiciousUsers = () =>
    axios.get(`${config.api}/v1/admin/users/suspicious`, {
      headers: this.authHeaders,
    })

  processesGet = (states, includeClosed, page, processesPerPage) =>
    axios.get(`${config.api}/v1/admin/overdue_payments`, {
      headers: this.authHeaders,
      params: { states, includeClosed, page, processesPerPage },
    })

  processesTransitionTo = (id, state) =>
    axios.post(
      `${config.api}/v1/admin/overdue_payments/transition_to`,
      { id, state },
      {
        headers: this.authHeaders,
      },
    )

  processesSetClosed = (id) =>
    axios.post(
      `${config.api}/v1/admin/overdue_payments/close`,
      { id },
      {
        headers: this.authHeaders,
      },
    )

  processesSetOpen = (id) =>
    axios.post(
      `${config.api}/v1/admin/overdue_payments/open`,
      { id },
      {
        headers: this.authHeaders,
      },
    )

  processesAddComment = (id, body) =>
    axios.post(
      `${config.api}/v1/admin/overdue_payments/add_comment`,
      { id, body },
      {
        headers: this.authHeaders,
      },
    )

  processesSearch = (searchString) =>
    axios.post(
      `${config.api}/v1/admin/overdue_payments/search`,
      { searchString },
      {
        headers: this.authHeaders,
      },
    )
}

export const Api = new ApiClass()
