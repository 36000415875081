import { useState } from 'react'
import { Button, Heading, LoadingDots, Stack, Link, Paragraph } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { Api } from '../../../api'
import { useUserContext } from '../user-context'

import { HomeCard } from './home-card'
import { formatForBackend, getHomeTemplates } from './import-homes.utils'
import { InformationSection } from './information-section'
import { parseCsvStringToArrays } from './parse-csv-string-to-arrays'

const StyledContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  gap: theme.spacing['2x'],
}))
const HomeListContainer = Stack
const HomeList = styled.ul(({ theme }) => ({
  overflow: 'scroll',
  width: theme.sizes[896],
  height: theme.sizes[672],
  display: 'flex',
  flexDirection: 'column',
}))
const SpanWrapper = styled(Paragraph)(({ theme }) => ({
  backgroundColor: theme.colors.bg.brandTertiary,
  padding: theme.spacing['2x'],
}))

export function UserImportHomes() {
  const { user } = useUserContext()
  const uid = user.uid
  const [homes, setHomes] = useState([])
  const [rowsWithAlerts, setRowsWithAlerts] = useState({ warnings: [], errors: [] })

  const [isLoading, setIsLoading] = useState(false)
  const [hasUploadFailed, setHasUploadFailed] = useState(null)
  const hasHomes = Boolean(homes.length)

  const handleFile = (e) => {
    setIsLoading(true)
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.onload = (evt) => {
      const arrayOfRowStrings = parseCsvStringToArrays(evt.target.result)

      const { homeTemplates, rowsWithAlerts } = getHomeTemplates(arrayOfRowStrings)
      if (!Boolean(homeTemplates.length)) {
        setIsLoading(false)
        return setHasUploadFailed(true)
      }
      setHomes(homeTemplates)
      setRowsWithAlerts(rowsWithAlerts)
      setIsLoading(false)
    }
    reader.readAsText(file)
  }

  const sendTemplates = async () => {
    setIsLoading(true)
    const formattedHometemplate = homes.map((home) => formatForBackend(home))
    const response = await Api.postHomeTemplates({ home_templates: formattedHometemplate, uid })

    const { status } = response
    if (status === 201) {
      setHasUploadFailed(false)
    } else {
      setHasUploadFailed(true)
    }
    setIsLoading(false)
  }

  if (isLoading) {
    return <LoadingDots />
  }

  if (hasUploadFailed === false) {
    return (
      <div>
        <Heading>CSV uploaded!</Heading>
        <span>
          {
            'While the file was succesfully sent to server, homes may still fail. Processing the file can take several minutes. Please visit the '
          }
          <Link isExternal href={'https://qasa.slack.com/archives/C04381P35QC'}>
            {' slack channel'}
          </Link>{' '}
          {'for details.'}
        </span>
      </div>
    )
  }

  if (hasUploadFailed) {
    return (
      <div>
        <span>
          Your uploaded CSV could not be handled, please make sure it perfectly corresponds to the CSV
          template.
        </span>
        <Button
          onClick={() => {
            setHomes([])
            setHasUploadFailed(null)
          }}
        >
          Upload new CSV
        </Button>
      </div>
    )
  }
  return (
    <StyledContainer>
      {!hasHomes ? (
        <Stack as="form" gap="2x">
          <Heading>Upload CSV</Heading>
          <input type="file" accept=".CSV" onChange={(e) => handleFile(e)} />
        </Stack>
      ) : (
        <>
          <InformationSection
            rowsWithAlerts={rowsWithAlerts}
            shouldDisableSend={isLoading}
            onSend={sendTemplates}
            onUploadNewCsv={() => {
              setHomes([])
              setHasUploadFailed(null)
            }}
            amountOfHomes={homes.length}
          />
          <HomeListContainer>
            <SpanWrapper>
              <span>{'Click template to expand'}</span>
            </SpanWrapper>
            <HomeList>
              {homes.map((home, i) => (
                <HomeCard key={i} {...home} index={i} />
              ))}
            </HomeList>
          </HomeListContainer>
        </>
      )}
    </StyledContainer>
  )
}
