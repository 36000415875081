import React from 'react'
import PropTypes from 'prop-types'
import { InputBase } from '@qasa/qds-ui'

const onKeyDown = (key, onEnter) => {
  if (onEnter && key === 'Enter') {
    onEnter()
  }
}

export function Input({ inputRef, onChange, type, value, name, placeholder, onEnter, readonly }) {
  return (
    <InputBase
      readOnly={readonly}
      onChange={(e) => onChange(e.target.value)}
      type={type || 'text'}
      value={value}
      name={name}
      onKeyDown={(e) => onKeyDown(e.key, onEnter)}
      placeholder={placeholder}
      ref={inputRef}
    />
  )
}

Input.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.any,
}
