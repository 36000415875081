import styled from '@emotion/styled'
import { Stack } from '@qasa/qds-ui'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { Tabs } from '../../../components/tabs/tabs'

import { UserContractsFrance } from './user-contracts-france'
import { UserSummaryFrance } from './user-summary-france'

const Wrapper = 'div'
const ContentWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing['5x'],
  backgroundColor: theme.colors.core.white,
  position: 'relative',
}))

const TAB_COMPONENT = {
  UserSummaryFrance,
  UserContractsFrance,
}

const PATH_TAB_MAP = {
  '/user': 'UserSummaryFrance',
  '/user/contracts': 'UserContractsFrance',
} as const

type TabMapKeys = keyof typeof PATH_TAB_MAP
type TabMapValues = (typeof PATH_TAB_MAP)[TabMapKeys]

export function UserContentFrance() {
  const [activeTab, setActiveTab] = useState<'' | TabMapValues>('')
  const { push } = useHistory()
  const { pathname, search } = location

  const changeTab = ({ path }: { path: string }) => push(`/user${path}${search}`)

  useEffect(() => {
    const pageNotSupported = !Object.keys(PATH_TAB_MAP).includes(pathname)
    if (pageNotSupported) {
      setActiveTab(PATH_TAB_MAP['/user'])
    } else {
      setActiveTab(PATH_TAB_MAP[pathname as TabMapKeys])
    }
  }, [pathname])

  if (!activeTab) {
    return null
  }

  const tabs = [
    {
      id: 'UserSummaryFrance',
      label: 'User',
      onClick: () => changeTab({ path: '' }),
    },
    {
      id: 'UserContractsFrance',
      label: 'Long-term Contracts',
      onClick: () => changeTab({ path: '/contracts' }),
    },
  ]

  const ActiveTab = TAB_COMPONENT[activeTab]

  return (
    <Wrapper>
      <Tabs tabs={tabs} activeTab={activeTab} />
      <ContentWrapper>
        <ActiveTab />
      </ContentWrapper>
    </Wrapper>
  )
}
