import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'
TableHeader.propTypes = {
  headerTitles: PropTypes.arrayOf(PropTypes.string),
}

export function TableHeader({ headerTitles }) {
  return (
    <thead className="Table__header">
      <tr>
        {headerTitles.map((title, i) => (
          <th key={i} style={{ width: title ? 'initial' : '25px' }}>
            {title}
          </th>
        ))}
      </tr>
    </thead>
  )
}
