import styled from '@emotion/styled'
import { IconButton, XIcon, theme } from '@qasa/qds-ui'
import type { ToastOptions } from 'react-toastify'
import { toast, ToastContainer as ToastContainerPrimitive } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

const StyledToastContainer = styled(ToastContainerPrimitive)({
  '.Toastify__toast': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colors.core.brown,
    borderRadius: theme.radii.lg,
    color: theme.colors.core.offWhite,
  },
  '.Toastify__toast-body': {
    padding: theme.spacing['2x'],
  },
  '.Toastify__toast-icon': {
    width: theme.sizes['6x'],
  },
  '.Toastify__toast--success .Toastify__toast-icon path': {
    fill: theme.colors.icon.positive,
  },
  '.Toastify__toast--error .Toastify__toast-icon path': {
    fill: theme.colors.icon.negative,
  },
  '.Toastify__toast--info .Toastify__toast-icon path': {
    fill: theme.colors.core.blue50,
  },
})

export function ToastContainer() {
  return (
    <StyledToastContainer
      closeButton={() => <IconButton label="Close toast" icon={XIcon} size="xs" variant="secondary" />}
    />
  )
}

const defaultToastOptions: ToastOptions = {
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: 3000,
  hideProgressBar: true,
}

const errorToastOptions: ToastOptions = {
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: false,
  hideProgressBar: true,
}

export const notifySuccess = (msg: string) => toast.success(msg, defaultToastOptions)

export const notifyFailure = (msg: string) => toast.error(msg, errorToastOptions)

export const notifyDefault = (msg: string) => toast.info(msg, defaultToastOptions)
