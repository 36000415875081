import type { AxiosError } from 'axios'

import { notifyFailure } from './notifications'

// NOTE: Exported for testing purposes
export const FALLBACK_ERROR_MESSAGE = 'An error occurred.'

type ResponseDataError = {
  message?: string
  code?: string
}

export const displayApiErrors = (error: AxiosError) => {
  const responseData = error.response?.data
  const errors = responseData?.errors
  const fallbackErrorMessage = error.message || FALLBACK_ERROR_MESSAGE

  if (Array.isArray(errors) && errors.length) {
    errors.forEach((errorMessage: ResponseDataError) => {
      notifyFailure(errorMessage.message || errorMessage.code || fallbackErrorMessage)
    })
  } else {
    notifyFailure(fallbackErrorMessage)
  }
}
