/* eslint-disable no-console */
export const config = {
  api: process.env.REACT_APP_API_URL,
  host: process.env.REACT_APP_HOST_URL,
  qasa: process.env.REACT_APP_QASA_URL,
  blocket: process.env.REACT_APP_BLOCKET_URL,
  tori: process.env.REACT_APP_TORI_URL,
  qasa_finland: process.env.REACT_APP_QASA_FINLAND_URL,
  qasa_france: process.env.REACT_APP_QASA_FRANCE_URL,
  finn: process.env.REACT_APP_FINN_URL,
  homeq: '',
  dotcom: process.env.REACT_APP_QASA_URL,
}

console.log('----------------------')
console.log('Building with env: ' + process.env.NODE_ENV)
console.log('----------------------')
