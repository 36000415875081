import { useState, useRef, useEffect } from 'react'
import { Textarea, Stack, Button, Heading, ArrowUpIcon, IconButton, theme } from '@qasa/qds-ui'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import { AdminNoteRecordTypeEnum } from '../../graphql/__generated__/graphql'
import { useUserContext } from '../../screens/user/user-context'
import { KANYE_USER_QUERY } from '../../screens/user/kanye-user.gql'
import { notifyFailure } from '../../utils'
import { CONTRACTS } from '../../screens/user/contracts/contracts.gql'

import { Note, type AdminNoteType } from './note'
import { CREATE_ADMIN_NOTE, DESTROY_ADMIN_NOTE } from './notes.gql'

const Wrapper = styled(Stack)(({ theme }) => ({
  height: '100%',
  borderRadius: theme.radii.lg,
  padding: theme.spacing['6x'],
  backgroundColor: theme.colors.core.blue30,
}))
const ScrollToButtonWrapper = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'sticky',
  top: theme.sizes['4x'],
}))
const NotesList = styled(Stack)({
  overflowY: 'auto',
  position: 'relative',
  flex: 1,
})

function ScrollToBottomIconButton({
  hasScrolledInList,
  onClick,
}: {
  hasScrolledInList: boolean
  onClick: () => void
}) {
  const bgVariants = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  }

  return (
    <ScrollToButtonWrapper
      variants={bgVariants}
      initial="hidden"
      animate={hasScrolledInList ? 'visible' : 'hidden'}
      transition={{ duration: 0.3 }}
    >
      <IconButton
        onClick={onClick}
        label="scroll to top of notes list"
        size="xs"
        icon={ArrowUpIcon}
        variant="secondary"
      />
    </ScrollToButtonWrapper>
  )
}

type Note = {
  author?: {
    firstName?: string | null
  } | null
} & AdminNoteType

type NotesProps = {
  notes: Note[]
  type?: AdminNoteRecordTypeEnum.User | AdminNoteRecordTypeEnum.Contract
  contractId?: string
}

export function Notes({ notes, type = AdminNoteRecordTypeEnum.User, contractId }: NotesProps) {
  const { user } = useUserContext()
  const [notesInputValue, setNotesInputValue] = useState('')
  const [hasScrolledInList, setHasScrolledInList] = useState(false)
  const [noteInputHeight, setNoteInputHeight] = useState(0)
  const noteSectionRef = useRef<HTMLDivElement>(null)
  const notesListRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (noteSectionRef?.current) {
      setNoteInputHeight(noteSectionRef.current.clientHeight)
    }
  }, [])

  const isUserNote = type === AdminNoteRecordTypeEnum.User

  const refetchQueries = isUserNote ? [KANYE_USER_QUERY] : [CONTRACTS]

  const failureNote = 'Failed to save note'
  const [createAdminNote, { loading: isCreateAdminNoteLoading }] = useMutation(CREATE_ADMIN_NOTE, {
    onCompleted: (data) => {
      const result = data.createAdminNote
      if (!result?.errors) {
        setNotesInputValue('')
      } else {
        notifyFailure(failureNote)
      }
    },
    onError: () => notifyFailure(failureNote),
    // TODO: try to use an optimistic update here instead of refetching the whole query
    refetchQueries,
  })

  const handleCreateAdminNote = () => {
    createAdminNote({
      variables: {
        input: {
          note: notesInputValue,
          recordType: type,
          ...(isUserNote
            ? {
                uid: user.uid,
              }
            : {
                recordId: contractId,
              }),
        },
      },
    })
  }

  const [destroyAdminNote] = useMutation(DESTROY_ADMIN_NOTE, {
    onError: (error) => notifyFailure(`Failed to remove note: ${error.message}`),
    // TODO: try to use an optimistic update here instead of refetching the whole query
    refetchQueries,
  })

  const handleRemoveNote = (id: string) => {
    destroyAdminNote({ variables: { destroyAdminNoteId: id } })
  }

  let sortedNotes = [...notes].sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))

  if (!sortedNotes || sortedNotes.length === 0) {
    sortedNotes = [
      {
        id: 'no_notes_to_show',
        note: isUserNote
          ? 'Use the field above ot create a note on this user 😊'
          : 'Use the field to the left to create a note on this contract. 😊',
        createdAt: new Date().toDateString(),
      },
    ]
  }

  const title = isUserNote ? 'User notes' : 'Contract notes'

  return (
    <Wrapper gap="2x" direction={isUserNote ? 'column' : 'row'}>
      <Stack ref={noteSectionRef}>
        <Heading>{title}</Heading>
        <Stack gap="3x">
          <Textarea
            label=""
            value={notesInputValue}
            onChange={(event) => setNotesInputValue(event.target.value)}
            minRows={3}
          />
          <Button
            isLoading={isCreateAdminNoteLoading}
            onClick={handleCreateAdminNote}
            variant="tertiary"
            isDisabled={!notesInputValue}
          >
            {'Save note'}
          </Button>
        </Stack>
      </Stack>
      <NotesList
        style={{ maxHeight: isUserNote ? theme.sizes[768] : noteInputHeight }}
        gap="3x"
        ref={notesListRef}
        onScroll={(e) => setHasScrolledInList((e.target as HTMLElement).scrollTop > 50)}
      >
        <ScrollToBottomIconButton
          hasScrolledInList={hasScrolledInList}
          onClick={() => notesListRef.current?.scrollTo(0, 0)}
        />
        {sortedNotes.map(({ id, note, createdAt, author }) => (
          <Note
            key={id}
            id={id}
            handleRemoveNote={() => handleRemoveNote(id)}
            note={note}
            createdAt={createdAt}
            authorName={author?.firstName ?? 'System'}
          />
        ))}
      </NotesList>
    </Wrapper>
  )
}
