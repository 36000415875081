import { Avatar, IconButton, Paragraph, Stack, TrashIcon } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useMutation } from '@apollo/client'

import { DELETE_EMPLOYEE_GROUP, GROUPS } from '../authorisation.gql'

const ListCardWrapper = styled(Stack)<{ isSelected: boolean; isSelectable: boolean }>(
  ({ theme, isSelected, isSelectable }) => ({
    padding: theme.spacing['2x'],
    backgroundColor: isSelectable ? theme.colors.core.white : theme.colors.core.gray30,
    borderRadius: theme.radii.md,
    boxShadow: theme.shadows.md,
    borderColor: !isSelectable
      ? theme.colors.core.gray30
      : isSelected
        ? theme.colors.border.defaultSelected
        : theme.colors.core.offWhiteLight,
    borderWidth: 1.5,
    ...(isSelectable && {
      ':hover': {
        cursor: 'pointer',
        borderColor: isSelected ? theme.colors.border.defaultSelected : theme.colors.border.defaultHover,
        '.delete': {
          visibility: 'visible',
          pointerEvents: 'auto',
        },
      },
    }),
  }),
)
const StyledIconButton = styled(IconButton)({
  visibility: 'hidden',
  pointerEvents: 'none',
})

type ListCardInfo = {
  name: string
  email?: string
  profilePictureUrl?: string | undefined | null
  onSelectCard?: () => void
  isSelected?: boolean
  isSelectable?: boolean
  numberOfMembers?: number
  groupId?: string
}
export function ListCard({
  name,
  email,
  profilePictureUrl,
  onSelectCard,
  isSelected = false,
  isSelectable = true,
  numberOfMembers,
  groupId = '',
}: ListCardInfo) {
  const [deleteGroup, { loading: isDeletingGroup }] = useMutation(DELETE_EMPLOYEE_GROUP, {
    variables: {
      destroyEmployeeGroupId: groupId,
    },
    refetchQueries: [GROUPS],
  })

  return (
    <ListCardWrapper
      direction="row"
      gap="2x"
      onClick={onSelectCard}
      isSelected={isSelected}
      isSelectable={isSelectable}
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack direction="row" gap="3x">
        <Avatar src={profilePictureUrl || ''} />
        <Stack justifyContent="center" alignItems="flex-start">
          <Paragraph size="sm">{name}</Paragraph>
          {email && (
            <Paragraph color="subtle" size="sm">
              {email}
            </Paragraph>
          )}
          {numberOfMembers !== undefined && <Paragraph size="sm">{`Members: ${numberOfMembers}`}</Paragraph>}
        </Stack>
      </Stack>
      {groupId && (
        <StyledIconButton
          className="delete"
          label="Copy address to clipboard"
          isDisabled={isDeletingGroup}
          onClick={(ev) => {
            ev.stopPropagation()
            deleteGroup()
          }}
          size="xs"
          icon={TrashIcon}
        />
      )}
    </ListCardWrapper>
  )
}
