import { graphql } from '../../../graphql/__generated__'
import type { ContractsQuery } from '../../../graphql/__generated__/graphql'

export const CONTRACTS = graphql(/* GraphQL */ `
  query Contracts($uid: ID!, $status: [ContractStatusEnum!], $rentalType: [ContractRentalTypeEnum!]) {
    kanyeUser(uid: $uid) {
      contracts(status: $status, rentalType: $rentalType) {
        id
        booking {
          id
          status
        }
        rent
        status
        signedAt
        scriveId
        depositPayoutApprovedAt
        depositPayoutDeniedAt
        invoicedThrough
        signingProcess
        currency
        insurance
        qasaGuaranteeMonths
        depositFree
        qasaGuarantee
        insuranceCost
        insuranceRecord {
          omocomId
          status
          claims
        }
        adminNotes {
          author {
            firstName
          }
          createdAt
          id
          note
        }
        qasaGuaranteeCost
        fee
        tenantBaseFee
        depositFreeCost
        invoices {
          sentAt
          billectaId
          sendAt
          amount
          type
          creditedAmount
          invoiceEntries {
            entryType
            amount
            costType
          }
        }
        pendingTerminationEndDate
        rentalInsuranceMilaRequest {
          id
          currentState
          milaId
          documentRequests {
            id
            documents {
              id
              filename
              url
            }
          }
        }
        cosigningTenants {
          uid
          firstName
          private {
            id
            familyName
            email
          }
        }
        landlord {
          firstName
          uid
          companyName
          mainPlatform
          private {
            id
            familyName
            email
          }
        }
        tenant {
          firstName
          companyName
          uid
          mainPlatform
          private {
            id
            familyName
            email
          }
        }
        tenantFees {
          waterFeePerTenant {
            cost {
              total
            }
          }
        }
        numberOfTenants
        duration {
          startOptimal
          endUfn
          startAsap
          endOptimal
        }
        externalContractTakeover {
          currentState
        }
        signingProcess
        pricingModel {
          modelName
          insuranceCostFactor
          qasaGuaranteeCostFactor
          tenantBaseFeeFactor
          depositFreeCostFactor
          landlordBaseFeeFactor
        }
        home {
          id
          location {
            route
            streetNumber
            postalCode
            locality
            country
          }
        }
        rentalType
        marketDetails {
          ... on FranceMarketDetails {
            depositMonths
          }
        }
      }
    }
  }
`)

export type KanyeUserContractsType = ContractsQuery['kanyeUser']['contracts']
export type KanyeUserContractType = ContractsQuery['kanyeUser']['contracts'][number]
