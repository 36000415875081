import { ContractStatusEnum, BookingStatusEnum } from '../../graphql/__generated__/graphql'

export const BOOLEAN_RISK_REASONS = [
  {
    label: 'PEP screen hit:',
    valueKey: 'pepScreenHit',
  },
  {
    label: 'RCA screen hit:',
    valueKey: 'rcaScreenHit',
  },
  {
    label: 'Sanction screen hit:',
    valueKey: 'sanctionScreenHit',
  },
  {
    label: 'PEP user response:',
    valueKey: 'pepUserResponse',
  },
  {
    label: 'RCA user response:',
    valueKey: 'rcaUserResponse',
  },
  {
    label: 'Professional:',
    valueKey: 'professional',
  },
  {
    label: 'Sign without bankid:',
    valueKey: 'signWithoutBankid',
  },
  {
    label: 'Multiple ongoing leases:',
    valueKey: 'ongoingLeases',
  },
  {
    label: 'Rent increased significantly:',
    valueKey: 'rentIncreased',
  },
  {
    label: 'High vacation rent:',
    valueKey: 'highVacationRent',
  },
  {
    label: 'Long vacation rental period:',
    valueKey: 'longVacationRentalPeriod',
  },
  {
    label: 'ID number:',
    valueKey: 'idNumber',
  },
  {
    label: 'Bank account owner ID number:',
    valueKey: 'idNumberMismatch',
  },
  {
    label: 'ID number mismatch:',
    valueKey: 'idNumberMismatch',
  },
  {
    label: 'Mismatch bank account owner PEP:',
    valueKey: 'mismatchBankAccountOwnerPep',
  },
  {
    label: 'Mismatch bank account owner RCA:',
    valueKey: 'mismatchBankAccountOwnerRca',
  },
  {
    label: 'Mismatch bank account owner Sanction:',
    valueKey: 'mismatchBankAccountOwnerSanction',
  },
  {
    label: 'Mismatch bank account owner Relation:',
    valueKey: 'mismatchBankAccountOwnerRelationType',
  },
  {
    label: 'Flagged by support:',
    valueKey: 'flaggedBySupport',
  },
]

export const clearanceLevels = {
  observing_admin: 1,
  admin: 2,
  grandadmin: 3,
  superadmin: 4,
}

export const evictionSectionEndpoints = [
  "get '/contracts/:id/soc_eviction_data",
  "get '/contracts/:id/kronofogden_eviction_data",
]

// The visible statuses should be in the order that we want to sort them in in `UserContracts.js` - Sahin Aslan 2021-11-25
export const VISIBLE_CONTRACT_STATUSES = [
  ContractStatusEnum.Signed,
  ContractStatusEnum.Extended,
  ContractStatusEnum.Pending,
  ContractStatusEnum.PendingTenantCreditCheck,
  ContractStatusEnum.PendingAprilaApplication,
  ContractStatusEnum.PendingAprilaRentalAgreement,
  ContractStatusEnum.Ended,
  ContractStatusEnum.Canceled,
]

export const VISIBLE_BOOKING_STATUSES = [
  BookingStatusEnum.Accepted,
  BookingStatusEnum.Confirmed,
  BookingStatusEnum.Revoked,
  BookingStatusEnum.Declined,
  BookingStatusEnum.Canceled,
  BookingStatusEnum.AwaitingPayment,
]

export const VISIBLE_CONTRACT_STATUSES_FRANCE = [
  ContractStatusEnum.Signed,
  ContractStatusEnum.AwaitingInsuranceApproval,
  ContractStatusEnum.Pending,
  ContractStatusEnum.Draft,
  ContractStatusEnum.FinalDraft,
  ContractStatusEnum.Canceled,
  ContractStatusEnum.Ended,
]
