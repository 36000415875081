import { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { Stack } from '@qasa/qds-ui'

import { Input } from '../../components/input-components/input-component'

const StyledTextWrapper = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  height: theme.sizes['8x'],
  width: theme.sizes[160],
}))

type EditableInputProps = {
  inputText: string
  updateText: (keyword: string) => void
}

export function EditableInput({ inputText, updateText }: EditableInputProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isInputVisible, setIsInputVisible] = useState(false)
  const [text, setText] = useState(inputText)

  const onClickOutSide = (evt: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(evt.target as Node)) {
      setIsInputVisible(false)
      setText(inputText)
    }
  }

  useEffect(() => {
    if (isInputVisible) {
      document.addEventListener('mousedown', onClickOutSide)
    }
    return () => {
      document.removeEventListener('mousedown', onClickOutSide)
    }
  })

  const onEnter = () => {
    setIsInputVisible(false)
    updateText(text)
  }
  const onTextChange = (value: string) => setText(value)

  return isInputVisible ? (
    <Input inputRef={inputRef} value={text} onChange={onTextChange} onEnter={onEnter} />
  ) : (
    <StyledTextWrapper alignItems="center" onClick={() => setIsInputVisible(true)}>
      {text}
    </StyledTextWrapper>
  )
}
