import { Button } from '@qasa/qds-ui'

import { notifySuccess } from '../../utils'
import type { UpdateEvictionProcess } from '../../screens/user/contracts/eviction-section'

type ResetGeneratedPdfButtonProps = {
  evictionProcessId: string
  updateEvictionProcess: UpdateEvictionProcess
}
export function ResetGeneratedPdfButton({
  evictionProcessId,
  updateEvictionProcess,
}: ResetGeneratedPdfButtonProps) {
  const resetPdf = () => {
    updateEvictionProcess({
      variables: {
        attributes: {
          messageToSocPdf: null,
        },
        regeneratePdf: true,
        processId: evictionProcessId,
      },
    }).then(() => notifySuccess('PDF reset successfully'))
  }

  return (
    <Button variant="tertiary" onClick={resetPdf}>
      {'Reset PDF'}
    </Button>
  )
}
