import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { Button, LoadingDots, Stack, IconButton, Paragraph } from '@qasa/qds-ui'
import { useState } from 'react'

import { notifyFailure, notifySuccess } from '../../../utils/notifications'
import { CopyIcon } from '../../../components/icons'

import { CREATE_ONFIDO_VERIFICATION_LINK } from './identification.gql'

const LinkWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.colors.core.gray20,
  padding: theme.spacing['4x'],
  borderRadius: theme.radii.md,
}))

export function ReverifyOnfido({ uid }: { uid: string }) {
  const [link, setLink] = useState<string | null>(null)
  const [createOnfidoVerificationLink, { loading: isLinkLoading }] = useMutation(
    CREATE_ONFIDO_VERIFICATION_LINK,
  )

  const handleCreateLink = () => {
    createOnfidoVerificationLink({
      variables: { uid },
      onCompleted: (data) => {
        if (data.createOnfidoVerificationLink && data.createOnfidoVerificationLink.url) {
          setLink(data.createOnfidoVerificationLink.url)
        } else {
          notifyFailure('Could not generate onfido link')
        }
      },
      onError: () => {
        notifyFailure('Could not generate onfido link')
      },
    })
  }

  const copyToClipboard = () => {
    if (link) {
      navigator.clipboard.writeText(link)
      notifySuccess('Copied to clipboard')
    }
  }

  switch (true) {
    case isLinkLoading:
      return <LoadingDots />
    case Boolean(link):
      return (
        <Stack direction="row" gap="2x">
          <LinkWrapper gap="2x">
            <Paragraph color="strong">{'Onfido reverification link:'}</Paragraph>
            <Paragraph>{link}</Paragraph>
          </LinkWrapper>
          <IconButton
            onClick={(ev) => {
              ev.stopPropagation()
              copyToClipboard()
            }}
            icon={CopyIcon}
            label="copy"
          />
        </Stack>
      )
    default:
      return <Button onClick={handleCreateLink}>{'Generate reverification link'}</Button>
  }
}
