import { graphql } from '../../../graphql/__generated__'

export const SEARCH_FOR_USER = graphql(/* GraphQL */ `
  query Users($input: UsersSearchInput!) {
    users(input: $input) {
      nodes {
        uid
        contractsCount
        mainPlatform
        firstName
        private {
          familyName
        }
        homes {
          id
        }
      }
    }
  }
`)
