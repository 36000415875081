import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { getEndpointClearanceLevels } from '../../api/api-service'
import { useGetCurrentUser } from '../../hooks'

const CLEARANCE_LEVELS = {
  observing_admin: 1,
  admin: 2,
  grandadmin: 3,
  superadmin: 4,
}
const emptyEndpointClearanceLevels: EndpointsClearanceLevels = {
  get: {},
  delete: {},
  put: {},
  post: {},
}

type EndpointsClearanceLevels = {
  get: Record<string, number>
  delete: Record<string, number>
  put: Record<string, number>
  post: Record<string, number>
}

type EndpointType = keyof EndpointsClearanceLevels

export type EndpointInfo = {
  endpoint: string
  endpointType: EndpointType
}

type ClearanceLevel = keyof typeof CLEARANCE_LEVELS

const parseEndpointClearanceLevels = (endpointClearanceLevels?: Record<string, string>) => {
  const parsedEndpointClearanceLevels = emptyEndpointClearanceLevels
  if (endpointClearanceLevels) {
    Object.keys(endpointClearanceLevels).forEach((key) => {
      const [endpointType, endpoint] = key.split(' ')
      parsedEndpointClearanceLevels[endpointType as EndpointType][endpoint.replaceAll("'", '')] =
        CLEARANCE_LEVELS[endpointClearanceLevels[key] as ClearanceLevel]
    })
  }
  return parsedEndpointClearanceLevels
}

export const useUserEndpointClearance = (endpoints: EndpointInfo[]) => {
  const user = useGetCurrentUser()
  const { data } = useQuery({
    queryKey: ['endpointClearanceLevels'],
    queryFn: getEndpointClearanceLevels,
  })

  const isUserCleared = useMemo(
    () =>
      endpoints.every(({ endpoint, endpointType }) => {
        const clearance = parseEndpointClearanceLevels(data?.requiredClearanceLevels)[endpointType][endpoint]
        const userClearance = user?.clearance ? CLEARANCE_LEVELS[user.clearance as ClearanceLevel] : null
        if (!clearance || !userClearance) {
          // NOTE: default to true until we handle the user data being available at all times.
          return true
        }
        return userClearance >= clearance
      }),
    [endpoints, data, user],
  )

  return { isUserCleared }
}
