import isBoolean from 'lodash/isBoolean'
import styled from '@emotion/styled'
import { Paragraph, Textarea } from '@qasa/qds-ui'

import { DataColumn } from '../../components/data-column'

import { BOOLEAN_RISK_REASONS } from './constants'
function stringifyRiskReason(value?: boolean) {
  if (value) {
    if (isBoolean(value)) {
      return 'Yes'
    } else {
      return value
    }
  } else if (!value) {
    return 'No'
  } else {
    return 'Not set'
  }
}

const Data = 'span'
const Label = styled(Paragraph)({ fontWeight: 'bold' })
const DataRow = styled.div<{ isElevatingRisk?: boolean; isDiff?: boolean }>(
  ({ isElevatingRisk, isDiff, theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: isElevatingRisk ? 'bold' : 'normal',
    ...(isDiff && {
      color: isElevatingRisk ? theme.colors.text.negative : theme.colors.text.positive,
    }),
  }),
)

type RiskClassificationDataProps = {
  riskClassification: any
  riskClassificationDiffKeys: string[]
}
export function RiskClassificationData({
  riskClassification,
  riskClassificationDiffKeys,
}: RiskClassificationDataProps) {
  if (!riskClassification) {
    return <Paragraph>No risk classification to display</Paragraph>
  }

  return (
    <DataColumn>
      <DataRow>
        <Label>Created at:</Label>
        <Data>{riskClassification.createdAt}</Data>
      </DataRow>
      <DataRow>
        <Label>Risk:</Label>
        <Data>{riskClassification.risk}</Data>
      </DataRow>
      {BOOLEAN_RISK_REASONS.map(({ label, valueKey }, index) => {
        const value = riskClassification[valueKey]
        return (
          <DataRow
            key={valueKey.concat(String(index))}
            isElevatingRisk={Boolean(value)}
            isDiff={riskClassificationDiffKeys.includes(valueKey)}
          >
            <Label size="sm">{label}</Label>
            <Data>{stringifyRiskReason(value)}</Data>
          </DataRow>
        )
      })}
      <DataRow>
        <Label>Created by:</Label>
        <Data>{riskClassification.createdBy}</Data>
      </DataRow>
      <Textarea
        label="Support note:"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        readOnly
        value={riskClassification.supportNote || ''}
      />
    </DataColumn>
  )
}
