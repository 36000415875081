import { graphql } from '../../../graphql/__generated__'

export const APPROVE_DEPOSIT_PAYOUT = graphql(`
  mutation ApproveDepositPayout($id: ID!) {
    approveDepositPayout(id: $id) {
      contract {
        id
        depositPayoutApprovedAt
      }
      errors {
        field
        codes
      }
    }
  }
`)

export const DENY_DEPOSIT_PAYOUT = graphql(`
  mutation DenyDepositPayout($id: ID!) {
    denyDepositPayout(id: $id) {
      contract {
        id
        depositPayoutDeniedAt
      }
      errors {
        field
        codes
      }
    }
  }
`)

export const SET_DEPOSIT_FREE = graphql(`
  mutation SetDepositFree($id: ID!) {
    setDepositFree(id: $id) {
      contract {
        id
        depositFree
      }
      errors {
        ...BaseError
      }
    }
  }
`)
