import { Heading, LoadingDots, Paragraph, Stack, useBreakpointValue, TextField } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { createClient } from 'contentful'

type PatchImage = {
  fields: {
    file: {
      url: string
    }
  }
}

type QasaPatchesItemType = {
  title: string
  description: string
  patchImage: PatchImage
  achievedBy: string[]
}

type QasaPatchesData = {
  fields: {
    title: string
    description: string
    qasaPatchesItem: {
      fields: QasaPatchesItemType
    }[]
  }
}

const SPACE_ID = 'quny917x1d1b'
const ACCESS_TOKEN = 'Px1dZA2mZ376HYrHLYkebeZqT1gniN41A3b3d1jlcrs'
const CONTENT_ID = '4GZTKUgWvq4UdiNf2qbyqK'

const client = createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN,
  host: 'preview.contentful.com',
})

const fetchContentfulData = async () => {
  try {
    const response = await client.getEntry(CONTENT_ID)
    return response
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log({ error })
  }
}

const Wrapper = styled(Stack)(({ theme }) => ({
  // NOTE: Hack solution to override the `PageContent` padding in Routes.
  margin: `-${theme.spacing['6x']}`,
  padding: theme.spacing['6x'],
  backgroundColor: theme.colors.bg.brandTertiary,
  height: '100vh',
  overflowY: 'scroll',
}))
const CenteredPageContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
const Image = styled.img(({ theme }) => ({
  objectFit: 'contain',
  aspectRatio: '4/1',
  borderRadius: theme.radii.lg,
  width: '100%',
  flexShrink: 0,
  [theme.mediaQueries.mdUp]: {
    width: theme.sizes['176'],
    aspectRatio: '1/1',
  },
}))
const Searchbar = styled(TextField)(({ theme }) => ({
  borderRadius: 999,
  borderColor: theme.colors.core.black,

  ':hover': {
    borderColor: theme.colors.core.black,
  },

  [theme.mediaQueries.mdUp]: {
    width: theme.sizes['448'],
  },
}))

function QasaPatchesItem({ title, description, patchImage }: QasaPatchesItemType) {
  const { direction } = useBreakpointValue({
    base: { direction: 'column' },
    md: { direction: 'row' },
  } as const)

  return (
    <Stack gap="6x" direction={direction}>
      <Image src={patchImage.fields.file.url ?? ''} />
      <Stack gap="2x" style={{ paddingTop: 16 }}>
        <Heading size="md">{title}</Heading>
        <Paragraph>{description}</Paragraph>
      </Stack>
    </Stack>
  )
}

export function QasaPatches() {
  const [memberSearch, setMemberSearch] = useState('')
  const { data: contentfulData, error, isLoading } = useQuery(['contentfulData'], fetchContentfulData)

  if (isLoading) {
    return (
      <CenteredPageContent>
        <LoadingDots />
      </CenteredPageContent>
    )
  }

  if (error || !contentfulData) {
    return <div>Something went wrong</div>
  }

  const data = contentfulData as unknown as QasaPatchesData
  const items = data.fields.qasaPatchesItem

  const filteredItems = items.filter((item) => {
    const memberNames = item.fields.achievedBy?.join(' ') ?? ''
    return memberNames.toLowerCase().includes(memberSearch.toLowerCase())
  })

  return (
    <Wrapper gap="8x">
      <Stack gap="3x">
        <Heading size="lg">{data.fields.title}</Heading>
        <Paragraph>{data.fields.description}</Paragraph>
      </Stack>
      <Searchbar
        label="Search for member"
        value={memberSearch}
        onChange={(e) => setMemberSearch(e.target.value)}
        placeholder="Ex: Ludvig"
      />
      <Stack gap="12x" style={{ maxWidth: 788 }}>
        {filteredItems.length ? (
          <>
            {filteredItems.map((item) => (
              <QasaPatchesItem key={item.fields.title} {...item.fields} />
            ))}
          </>
        ) : (
          <Paragraph>No matches</Paragraph>
        )}
      </Stack>
    </Wrapper>
  )
}
