import { useState } from 'react'
import { LoadingDots, Paragraph, Stack } from '@qasa/qds-ui'
import { useQuery } from '@apollo/client'

import { SearchContracts } from '../search-contracts'
import { sortContracts } from '../contracts.utils'
import { ContractRentalTypeEnum } from '../../../graphql/__generated__/graphql'
import { CONTRACTS, type KanyeUserContractsType } from '../contracts/contracts.gql'
import { VISIBLE_CONTRACT_STATUSES } from '../constants'
import { useUserContext } from '../user-context'
import { useScrollToContractListItem } from '../../../hooks'

import { BookingContract } from './booking-contract'

export function UserBookings() {
  const { user } = useUserContext()

  const [searchResultContracts, setSearchResultContracts] = useState<KanyeUserContractsType>([])
  const [hasResults, setHasResults] = useState(true)

  const userUid = user.uid

  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(CONTRACTS, {
    variables: {
      uid: userUid,
      rentalType: ContractRentalTypeEnum.Vacation,
      status: VISIBLE_CONTRACT_STATUSES,
    },
  })

  const contracts = data?.kanyeUser.contracts ?? []

  const shouldDisplayAllContracts =
    searchResultContracts.length === 0 || searchResultContracts.length === contracts.length

  const contractsToDisplay = shouldDisplayAllContracts ? contracts : searchResultContracts

  const sortedContracts = sortContracts({
    contracts: [...contractsToDisplay],
  })

  const { refsArray, targetScrollIndex } = useScrollToContractListItem({ dataArray: sortedContracts })

  if (isLoading) {
    return <LoadingDots />
  }
  if (!user || !contracts || error) {
    return null
  }
  if (!contracts.length) {
    return <Paragraph>{'No bookings to display'}</Paragraph>
  }

  return (
    <Stack gap="6x">
      <SearchContracts
        setSearchResultContracts={setSearchResultContracts}
        contracts={contracts}
        setHasResults={setHasResults}
        rentalType={ContractRentalTypeEnum.Vacation}
      />
      {hasResults ? (
        <Stack gap="4x">
          {sortedContracts.map((contract, index) => (
            <BookingContract
              ref={(el) => (refsArray.current[index] = el)}
              key={contract.id}
              contract={contract}
              user={user}
              isDefaultExpanded={index === targetScrollIndex}
            />
          ))}
        </Stack>
      ) : (
        <Paragraph>{'No results'}</Paragraph>
      )}
    </Stack>
  )
}
