import { useState } from 'react'
import styled from '@emotion/styled'
import { Link } from '@qasa/qds-ui'

import { formatText } from './suspicious-users.utils'

const Metadata = styled.pre({
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
})

type SuspiciousEventProps = {
  event: any
}
export function SuspiciousEvent({ event }: SuspiciousEventProps) {
  const [isOpen, setIsOpen] = useState(false)
  const metadata = event.metadata
  const type = event.type
  const createdAt = event.createdAt

  const formattedMetadata = JSON.stringify(JSON.parse(metadata), undefined, 4)

  return (
    <div>
      <Link as="button" onClick={() => setIsOpen(!isOpen)}>
        {`${formatText(type)}: ${new Date(createdAt).toLocaleString()}`}
      </Link>
      {isOpen && <Metadata>{formattedMetadata}</Metadata>}
    </div>
  )
}
