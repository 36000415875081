import React, { useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Button, Label, Paragraph, Stack } from '@qasa/qds-ui'

import { isUserFinnish } from '../../utils'
import { useGetCurrentUser } from '../../hooks'

import { hideFinnishIdNumber } from './toggleable-id-number.utils'

const RowTitle = Label
const IdNumberContainer = styled(Paragraph)(({ idConfirmed, theme }) => ({
  color: idConfirmed ? theme.colors.text.positive : theme.colors.text.negative,
}))
const ToggleButton = styled(Button)(({ isDisabled }) => ({
  display: isDisabled ? 'none' : 'block',
  pointerEvents: isDisabled ? 'none' : 'auto',
}))

ToggleableIdNumber.propTypes = {
  idConfirmed: PropTypes.bool,
  idNumber: PropTypes.string,
  mainPlatform: PropTypes.string,
  showRowTitle: PropTypes.bool,
  // array of 2 strings for the on/off toggle text eg. ['show', 'hide']
  toggleOptions: PropTypes.arrayOf(PropTypes.string),
}

export function ToggleableIdNumber({
  toggleOptions = ['show', 'hide'],
  mainPlatform,
  idNumber,
  idConfirmed,
  showRowTitle,
}) {
  const [shouldCensorIdNumber, setShouldCensorIdNumber] = useState(true)
  const loggedInUser = useGetCurrentUser()

  const [optionOne, optionTwo] = toggleOptions

  const hasUserAccessToUncensoredIdNumber = ['admin', 'grandadmin', 'superadmin'].includes(
    loggedInUser?.clearance,
  )

  const canUserToggleCensorIdNumber = hasUserAccessToUncensoredIdNumber && isUserFinnish({ mainPlatform })

  const toggleCensorIdNumber = () => setShouldCensorIdNumber(!shouldCensorIdNumber)

  const userIdNumberToRender =
    shouldCensorIdNumber && isUserFinnish({ mainPlatform }) ? hideFinnishIdNumber({ idNumber }) : idNumber

  return (
    <Stack gap="3x" wrap="wrap">
      {showRowTitle && <RowTitle>{'ID Number: '}</RowTitle>}
      <Stack direction="row" gap="3x">
        <IdNumberContainer as="span" idConfirmed={idConfirmed}>
          {userIdNumberToRender}
        </IdNumberContainer>
        <ToggleButton
          variant="tertiary"
          size="xs"
          isDisabled={!canUserToggleCensorIdNumber}
          onClick={toggleCensorIdNumber}
        >
          {shouldCensorIdNumber ? optionOne : optionTwo}
        </ToggleButton>
      </Stack>
    </Stack>
  )
}
