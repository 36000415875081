import { useState } from 'react'
import styled from '@emotion/styled'
import { Stack, Paragraph, Button, Heading, LoadingDots, TextField, Label } from '@qasa/qds-ui'
import { useMutation, useQuery } from '@apollo/client'

import { Dialog } from '../../components/_core/dialog'
import { notifyFailure, notifySuccess } from '../../utils'
import { useAuthorisationContext } from '../../context/authorisation-context'

import { EMPLOYEE_ROLES, GROUPS, UPSERT_EMPLOYEE_GROUP } from './authorisation.gql'

export const GroupContainer = styled(Stack)<{ hasRole: boolean }>(({ theme, hasRole }) => ({
  backgroundColor: hasRole ? theme.colors.core.black : theme.colors.core.offWhiteDark,
  color: hasRole ? theme.colors.core.white : theme.colors.core.black,
  paddingInline: theme.spacing['6x'],
  paddingBlock: theme.spacing['3x'],
  borderRadius: theme.radii.xl,
  ':hover': {
    cursor: 'pointer',
    backgroundColor: theme.colors.core.yellow60,
  },
}))

export function CreateGroupDialog() {
  const {
    dialogMethods: {
      createGroupDialog: { status: isOpen, close: onClose },
    },
  } = useAuthorisationContext()
  const { data, loading: isLoadingEmployees } = useQuery(EMPLOYEE_ROLES)
  const [roleIdArray, setRoleIdArray] = useState<string[]>([])
  const [groupName, setGroupName] = useState<string>('')

  const employeeRoles =
    data?.employeeRoles.__typename === 'EmployeeRoleConnection' ? data?.employeeRoles.nodes : []

  const [createGroup, { loading: isCreatingGroup }] = useMutation(UPSERT_EMPLOYEE_GROUP, {
    variables: {
      employeeIds: [],
      name: groupName,
      roleIds: roleIdArray,
    },
    onCompleted: () => {
      // TODO: error handling
      notifySuccess(`Group: ${groupName} created`)
      onClose()
    },
    onError: (error) => notifyFailure(error.message),
    refetchQueries: [GROUPS],
  })

  const handleRoleChange = (roleId: string) => {
    if (roleIdArray.includes(roleId)) {
      setRoleIdArray((prev) => prev.filter((role) => role !== roleId))
    } else {
      setRoleIdArray((prev) => [...prev, roleId])
    }
  }

  return (
    <Dialog isOpen={isOpen} onOpenChange={onClose}>
      <Dialog.Content>
        <Dialog.Header>
          <Heading size="sm">Create group</Heading>
        </Dialog.Header>
        <Dialog.Body>
          <Stack gap="6x">
            <Stack gap="3x">
              <TextField
                label="Add new group"
                type="string"
                value={groupName}
                onChange={(ev) => setGroupName(ev.target.value)}
                placeholder="Group name"
              />
            </Stack>
            <Stack gap="3x">
              <Label>Add roles to group</Label>
              <Stack gap="3x" wrap="wrap" direction="row" alignItems="center">
                {isLoadingEmployees && <LoadingDots />}
                {employeeRoles.map((role: any) => {
                  const hasRole = roleIdArray?.includes(role.id)
                  return (
                    <GroupContainer
                      key={role.id}
                      gap="3x"
                      hasRole={hasRole}
                      onClick={() => handleRoleChange(role.id)}
                    >
                      <Paragraph color={hasRole ? 'onBrandSecondary' : 'onBrandTertiary'}>
                        {role.name}
                      </Paragraph>
                    </GroupContainer>
                  )
                })}
              </Stack>
            </Stack>
          </Stack>
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            variant="tertiary"
            isFullWidth
            isLoading={isCreatingGroup}
            disabled={isCreatingGroup || !groupName || roleIdArray.length === 0}
            onClick={() => createGroup()}
          >
            Save group
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}
