import { useState } from 'react'
import styled from '@emotion/styled'
import { IconButton, Label, Paragraph, SettingsIcon, Stack } from '@qasa/qds-ui'

import type { StatesType } from '../processes/eviction-process/eviction-process'
import { STATE_NAMES } from '../processes/eviction-process/eviction-process'

import { FilterSettings } from './filter-settings'

const Wrapper = Stack
const SettingsButtonAndContent = Stack
const ActiveFilters = Stack

const Options = styled(Stack)(({ theme }) => ({ flexWrap: 'wrap', rowGap: theme.spacing['2x'] }))

type FiltersProps = {
  initialFilteredStates: StatesType[]
  initialShouldIncludeClosedProcesses: boolean
  onFilterChange: (filteredStates: string[], shouldIncludeClosedProcesses: boolean) => void
  isFilterMenuOpen: boolean
  setIsFilterMenuOpen: (isFilterMenuOpen: boolean) => void
  isLoading: boolean
}

export function Filters({
  initialFilteredStates,
  initialShouldIncludeClosedProcesses,
  onFilterChange,
  isFilterMenuOpen,
  setIsFilterMenuOpen,
  isLoading,
}: FiltersProps) {
  const [filteredStates, setFilteredStates] = useState(initialFilteredStates)
  const [shouldIncludeClosedProcesses, setShouldIncludeClosedProcesses] = useState(
    initialShouldIncludeClosedProcesses,
  )

  return (
    <Wrapper direction="row" gap="8x">
      <SettingsButtonAndContent direction="row" gap="4x">
        <IconButton
          icon={SettingsIcon}
          label={'Filters'}
          onClick={() => {
            setIsFilterMenuOpen(!isFilterMenuOpen)
          }}
        />
        {isFilterMenuOpen ? (
          <FilterSettings
            filteredStates={filteredStates}
            setFilteredStates={setFilteredStates}
            shouldIncludeClosedProcesses={shouldIncludeClosedProcesses}
            setShouldIncludeClosedProcesses={setShouldIncludeClosedProcesses}
            setIsFilterVisible={setIsFilterMenuOpen}
            onFilterChange={onFilterChange}
            isLoading={isLoading}
          />
        ) : (
          <ActiveFilters direction="row" gap="8x" alignItems="center">
            <Label>Active filter:</Label>
            <Options direction="row" gap="4x" alignItems="center">
              {initialFilteredStates.map((state) => (
                <Paragraph key={state} size="sm">
                  {STATE_NAMES[state]}
                </Paragraph>
              ))}
              {filteredStates.length === 0 && <Paragraph size="sm">None</Paragraph>}
            </Options>
            {shouldIncludeClosedProcesses && (
              <Options>
                <Paragraph size="sm">Include closed</Paragraph>
              </Options>
            )}
          </ActiveFilters>
        )}
      </SettingsButtonAndContent>
    </Wrapper>
  )
}
