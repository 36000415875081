import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Paragraph, Stack, Heading } from '@qasa/qds-ui'

const MinimizedWrapper = styled(Stack)(({ theme, shouldHaveDarkColor }) => ({
  overflow: 'none',
  backgroundColor: shouldHaveDarkColor ? theme.colors.core.offWhiteDark : theme.colors.core.offWhiteLight,
  cursor: 'pointer',
  gap: theme.spacing['1x'],
  padding: theme.spacing['2x'],
}))
const ExpandedWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing['2x'],
  borderBottom: `1px solid ${theme.colors.border.strong}`,
}))
const DetailsWrapper = styled(Stack)(({ theme }) => ({
  overflow: 'hidden',
  flex: 2,
  backgroundColor: theme.colors.core.offWhiteLight,
  borderRadius: theme.radii.md,
}))
const DetailsRow = styled(Stack)(({ theme, shouldHaveErrorColor, shouldHaveWarningColor }) => ({
  alignItems: 'center',
  padding: theme.spacing['2x'],
  borderBottom: `1px solid ${theme.colors.border.strong}`,
  justifyContent: 'space-between',
  ...(shouldHaveErrorColor && { backgroundColor: theme.colors.bg.negative }),
  ...(shouldHaveWarningColor && { backgroundColor: theme.colors.bg.warning }),
}))
const ColumnWrapper = styled(Stack)(({ theme, shouldHaveWarningColor }) => ({
  padding: theme.spacing['2x'],
  flex: 1,
  borderRadius: theme.radii.md,
  backgroundColor: shouldHaveWarningColor ? theme.colors.bg.warning : theme.colors.core.offWhiteLight,
}))

HomeCard.propTypes = {
  availableAmenities: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.array,
  warnings: PropTypes.array,
  homeData: PropTypes.object,
  index: PropTypes.number,
}

export function HomeCard({
  availableAmenities,
  errors,
  homeData: {
    adress,
    apartmentNumber,
    buildingName,
    description,
    floor,
    locality,
    postalCode,
    rent,
    roomCount,
    squareMeters,
    streetNumber,
    tenantCount,
  },
  warnings,
  index,
}) {
  const [isExpanded, setIsExpanded] = useState(false)
  const hasErrors = Boolean(errors.length)
  const hasWarnings = Boolean(warnings.length)
  const details = [
    { key: 'adress', heading: 'Adress', data: adress },
    { key: 'streetNumber', heading: 'Street number', data: streetNumber },
    { key: 'apartmentNumber', heading: 'Apt no', data: apartmentNumber },
    { key: 'rent', heading: 'Rent', data: rent },
    { key: 'locality', heading: 'Locality', data: locality },
    { key: 'postalCode', heading: 'Postal code', data: postalCode },
    { key: 'buildingName', heading: 'Building name', data: buildingName },
    { key: 'floor', heading: 'Floor', data: floor },
    { key: 'roomCount', heading: 'Rooms', data: roomCount },
    { key: 'squareMeters', heading: 'Square meters', data: squareMeters },
    { key: 'tenantCount', heading: 'Max tenants', data: tenantCount },
  ]

  const handleClick = () => setIsExpanded((prev) => !prev)
  const getSuffix = (length) => (length > 1 ? 's' : '')

  if (!isExpanded) {
    return (
      <MinimizedWrapper shouldHaveDarkColor={index % 2 === 0} onClick={handleClick}>
        <Heading size="2xs">{`${adress} ${streetNumber}`}</Heading>
        <Paragraph size="sm" color="onBrandTertiary">{`Apartment No ${apartmentNumber}`}</Paragraph>
        <Stack direction="row" gap="2x">
          {hasWarnings && (
            <Paragraph color="warning" size="xs">{`${warnings.length} Warning${getSuffix(
              warnings.length,
            )}`}</Paragraph>
          )}
          {hasErrors && (
            <Paragraph color="negative" size="xs">{`${errors.length} Error${getSuffix(
              errors.length,
            )}`}</Paragraph>
          )}
        </Stack>
      </MinimizedWrapper>
    )
  }
  return (
    <ExpandedWrapper gap="2x" onClick={handleClick}>
      <Stack direction="row" gap="2x">
        <Heading size="2xs">{`${adress} ${streetNumber}`}</Heading>
      </Stack>
      <Stack direction="row" gap="2x">
        <DetailsWrapper>
          {details.map(({ heading, data, key }, index) => {
            const hasWarning = warnings.some(({ key: warningKey }) => warningKey === key)
            const hasError = errors.some(({ key: errorKey }) => errorKey === key)
            return (
              <DetailsRow
                gap="2x"
                direction="row"
                key={index}
                shouldHaveErrorColor={hasError}
                shouldHaveWarningColor={hasWarning}
              >
                <Heading size="2xs">{`${heading}:`}</Heading>
                <Paragraph>{hasWarning || hasError ? 'Missing/invalid' : data}</Paragraph>
              </DetailsRow>
            )
          })}
        </DetailsWrapper>
        <ColumnWrapper>
          <Heading size="2xs">{'Amenities'} </Heading>
          {availableAmenities.length === 0 && <Paragraph size="">{'None'}</Paragraph>}
          {availableAmenities.map((amenity, index) => (
            <Paragraph key={index}>{amenity}</Paragraph>
          ))}
        </ColumnWrapper>
        <ColumnWrapper shouldHaveWarningColor={!description}>
          <Heading size="2xs">{'Description'}</Heading>
          <Paragraph>{description || 'Missing/invalid'}</Paragraph>
        </ColumnWrapper>
      </Stack>
    </ExpandedWrapper>
  )
}
