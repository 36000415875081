import { useState, forwardRef } from 'react'
import { Button, Heading, IconButton, Stack, Paragraph, ShareIcon } from '@qasa/qds-ui'
import upperFirst from 'lodash/upperFirst'

import { Table } from '../../../components/table/table'
import { TableHeader } from '../../../components/table/table-header'
import { UserContractMeta } from '../contracts/user-contract-meta'
import { getContractNames } from '../user-contract-meta.utils'
import { ContentAccordian } from '../../../components/content-accordian'
import { notifyDefault } from '../../../utils/notifications'
import { Batches } from '../batches'
import { CopyIcon } from '../../../components/icons'
import { splitSnakeCase } from '../../../utils/strings'
import { ContractStatus } from '../contracts/components/contract-status'
import type { KanyeUser } from '../user-context'
import type { KanyeUserContractsType } from '../contracts/contracts.gql'

import { BookingActionsSection } from './booking-actions-section'

const Wrapper = Stack
const Description = Paragraph
const HeaderWrapper = Stack
const LeftColumnContainer = 'div'

enum ExternalIdName {
  aprila = 'Aprila ID',
  billecta = 'Billecta ID',
}

type HeaderTitlesProps = {
  externalIdName: ExternalIdName
}
const headerTitles = ({ externalIdName }: HeaderTitlesProps) => [
  'ID',
  'Invoice',
  externalIdName,
  'Type',
  'Amount',
  'Send at',
  'Sent at',
  'Paid at',
  'Due',
  'Credit',
]

type ContractProps = {
  contract: KanyeUserContractsType[number]
  user: KanyeUser
}

type ExpandedContractProps = ContractProps & {
  showBatchesDefault: boolean
}

function ExpandedContract({ contract, user, showBatchesDefault = false }: ExpandedContractProps) {
  const externalIdName = user.mainPlatform === 'finn' ? ExternalIdName.aprila : ExternalIdName.billecta
  const [showBatches, setShowBatches] = useState(showBatchesDefault)

  const toggleBatches = () => setShowBatches(!showBatches)
  return (
    <Wrapper gap="3x">
      <HeaderWrapper direction="row">
        <LeftColumnContainer>
          <UserContractMeta contract={contract} />
        </LeftColumnContainer>
        <Stack>
          <BookingActionsSection contract={contract} user={user} />
        </Stack>
      </HeaderWrapper>
      <Stack alignItems="flex-end">
        <Button variant="tertiary" onClick={toggleBatches}>
          {showBatches ? 'Hide batches' : 'Show batches'}
        </Button>
      </Stack>
      {showBatches && (
        <Table>
          <TableHeader headerTitles={headerTitles({ externalIdName })} />
          <Batches contractId={contract.id} />
        </Table>
      )}
    </Wrapper>
  )
}

type BookingContractProps = ContractProps & {
  isDefaultExpanded: boolean
}

export const BookingContract = forwardRef<any, BookingContractProps>(
  ({ contract, user, isDefaultExpanded }, scrollRef) => {
    const { tenantName, landlordName } = getContractNames({ contract })
    const { route = '', streetNumber = '', postalCode = '', locality = '' } = contract.home?.location || {}
    const address = `${route} ${streetNumber}, ${postalCode} ${locality}`
    const booking = contract.booking

    const handleCopyAddress = () => {
      navigator.clipboard.writeText(address)
      notifyDefault(`Copied address to clipboard!`)
    }

    const bookingStatus = booking?.status ?? ''
    const formattedBookingStatus = upperFirst(splitSnakeCase(bookingStatus))

    const handleShareContract = () => {
      navigator.clipboard.writeText(`${window.location.href}&contractId=${contract.id}`)
      notifyDefault(`Shareable url with contract id ${contract.id} copied to clipboard!`)
    }

    const headerComponent = (
      <Stack gap="2x" ref={scrollRef}>
        <Heading size="sm">{`#${contract.id} - ${landlordName} & ${tenantName}`}</Heading>
        <Stack gap="2x" direction="row" alignItems="center">
          <Description>{address}</Description>
          <IconButton
            label="Copy data field icon button"
            size="xs"
            icon={CopyIcon}
            onClick={(ev) => {
              ev.stopPropagation()
              handleCopyAddress()
            }}
          />
          <IconButton
            label="Share contract"
            onClick={(ev) => {
              ev.stopPropagation()
              handleShareContract()
            }}
            size="xs"
            icon={ShareIcon}
          />
        </Stack>
        {bookingStatus && <ContractStatus isBooking status={bookingStatus} label={formattedBookingStatus} />}
      </Stack>
    )

    return (
      <ContentAccordian HeaderComponent={headerComponent} isDefaultExpanded={isDefaultExpanded}>
        <ExpandedContract contract={contract} user={user} showBatchesDefault={isDefaultExpanded} />
      </ContentAccordian>
    )
  },
)
