import { useEffect, useState } from 'react'
import { Button, Heading, Paragraph, Stack, TextField } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { useCreateSubUser, useRemoveSubUser, useReinviteSubUser } from '../../queries'
import { Api } from '../../api'
import { displayApiErrors } from '../../utils/error'

import { useUserContext } from './user-context'

const Wrapper = Stack
const Row = styled(Stack)(({ theme }) => ({
  padding: theme.spacing['2x'],
  borderRadius: theme.radii.sm,
  '&:nth-child(odd)': {
    backgroundColor: theme.colors.bg.inset,
  },
}))

const useGetSubUsers = (uid: string): any[] => {
  const [subUsers, setSubUsers] = useState([])

  useEffect(() => {
    Api.getSubUsersByUser(uid)
      .then((res) => setSubUsers(res.data['subUsers']))
      .catch(displayApiErrors)
  }, [uid])
  return [subUsers, setSubUsers, uid]
}

export function UserSubUsers() {
  const { user } = useUserContext()
  const uid = user.uid
  const [subUsers, setSubUsers] = useGetSubUsers(uid)
  const [subUserEmail, setSubUserEmail] = useState('')
  const { mutate: createSubUser, isLoading: isCreateSubUserLoading } = useCreateSubUser()
  const { mutate: removeSubUser, isLoading: isRemoveSubUserLoading } = useRemoveSubUser()
  const { mutate: reinviteSubUser, isLoading: isReinviteSubUserLoading } = useReinviteSubUser()

  const handleCreateSubUser = () => {
    createSubUser(
      { uid, subUserEmail },
      {
        onSuccess: () => {
          setSubUsers([...subUsers, { email: subUserEmail }])
          setSubUserEmail('')
        },
      },
    )
  }

  return (
    <Wrapper gap="4x">
      <Heading>Sub users for {user.private.email}</Heading>
      <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" gap="2x">
        <div>
          <TextField label="Email" value={subUserEmail} onChange={(e) => setSubUserEmail(e.target.value)} />
        </div>
        <Button isDisabled={!subUserEmail} onClick={handleCreateSubUser} isLoading={isCreateSubUserLoading}>
          {'Invite'}
        </Button>
      </Stack>
      {subUsers.map((subUser: any, index: number) => (
        <Row direction="row" alignItems="center" justifyContent="space-between" key={index}>
          <Paragraph>{subUser.email}</Paragraph>
          {subUser.removedAt ? (
            <Paragraph size="sm" color="negative">
              {'removed at: ' + new Date(subUser.removedAt).toDateString()}
            </Paragraph>
          ) : (
            <Stack gap="1x" direction="row">
              <Button
                variant="tertiary"
                size="sm"
                onClick={() => reinviteSubUser({ email: subUser.email })}
                isLoading={isReinviteSubUserLoading}
              >
                {'Re-send invite'}
              </Button>
              <Button
                variant="tertiary"
                size="sm"
                onClick={() => removeSubUser({ email: subUser.email })}
                isLoading={isRemoveSubUserLoading}
              >
                {'Remove'}
              </Button>
            </Stack>
          )}
        </Row>
      ))}
    </Wrapper>
  )
}
