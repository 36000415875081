import type { HTMLProps } from 'react'
import { forwardRef } from 'react'
import styled from '@emotion/styled'
import { Paragraph, CheckIcon } from '@qasa/qds-ui'

import { VisuallyHidden } from './visually-hidden'

const CheckMarkContainer = styled.div<{ isChecked: boolean }>(({ theme, isChecked }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 16,
  height: 16,
  flexShrink: 0,
  border: '1px solid',
  borderColor: isChecked ? theme.colors.core.black : theme.colors.core.gray80,
  borderRadius: theme.radii.sm,

  ':before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 32,
    height: 32,
    transform: 'translate(-50%, -50%)',
  },
}))

type CheckboxIndicatorProps = {
  isChecked: boolean
}
function CheckboxIndicator({ isChecked }: CheckboxIndicatorProps) {
  return <CheckMarkContainer isChecked={isChecked}>{isChecked && <CheckIcon />}</CheckMarkContainer>
}

const Wrapper = styled.label({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  cursor: 'pointer',
  userSelect: 'none',
  position: 'relative',
})

type CheckboxProps = {
  label: string
  isChecked: boolean
} & HTMLProps<HTMLInputElement>

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, isChecked, ...restProps }, ref) => {
    return (
      <Wrapper>
        <VisuallyHidden>
          <input type="checkbox" ref={ref} {...restProps} />
        </VisuallyHidden>
        <CheckboxIndicator isChecked={isChecked} />
        <Paragraph as="span" role="none">
          {label}
        </Paragraph>
      </Wrapper>
    )
  },
)
