import { Button } from '@qasa/qds-ui'

import kronofogdenPdfTemplate from '../../assets/pdf/kronofogden.pdf'

import { openPdfHandler, fillInKronofogdenPdfTextFields, formatKfmPdfData } from './utils'

/*
  This component downloads a PDF and fills it in. "Kfm" is "Kronofogdemyndigheten", the Swedish
  "Enforcement Authority", so this is only relevant for Sweden. Admins will send this PDF to the
  authorities when tenants don't pay their rent.
*/

export function GenerateKfmPdfButton({ evictionProcess }: { evictionProcess: any }) {
  const openPdf = () =>
    openPdfHandler({
      pdfDocument: kronofogdenPdfTemplate,
      mapDataToFieldsFunc: fillInKronofogdenPdfTextFields,
      pdfData: formatKfmPdfData(evictionProcess),
    })

  return (
    <Button variant="tertiary" onClick={openPdf}>
      Generate Kronofogden PDF
    </Button>
  )
}
