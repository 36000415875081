import styled from '@emotion/styled'
import { Paragraph } from '@qasa/qds-ui'

const Wrapper = styled.div(({ theme }) => ({
  padding: `${theme.spacing['3x']} ${theme.spacing['2x']}`,
  backgroundColor: theme.colors.bg.default,
  borderColor: theme.colors.border.default,
  borderWidth: 1,
  borderRadius: theme.radii.md,
}))
const EmptyState = styled(Paragraph)(({ theme }) => ({
  whiteSpace: 'nowrap',
  padding: `${theme.spacing['1x']} ${theme.spacing['4x']}`,
}))
const TableCell = styled.td(({ theme }) => ({
  whiteSpace: 'nowrap',
  padding: `${theme.spacing['1x']} ${theme.spacing['4x']}`,
}))

type StepHistory = {
  stateHistory: any[]
  CurrentProcess: any
}
function StepHistory({ stateHistory, CurrentProcess }: StepHistory) {
  if (stateHistory === undefined) {
    return null
  }

  if (stateHistory.length === 0) {
    return (
      <Wrapper>
        <EmptyState size="sm">This is a new process, so there's no history yet.</EmptyState>
      </Wrapper>
    )
  }

  const swedishDateTimeFormat = new Intl.DateTimeFormat('sv-SE', {
    dateStyle: 'short',
    timeStyle: 'short',
    timeZone: 'Europe/Stockholm',
  })

  return (
    <Wrapper>
      <table>
        {stateHistory.map(({ toState, at }, index) => {
          return (
            <tr key={index}>
              <TableCell>
                <Paragraph size="sm">{CurrentProcess.getStateName(toState)}</Paragraph>
              </TableCell>
              <TableCell>
                <Paragraph size="sm">{swedishDateTimeFormat.format(new Date(at))}</Paragraph>
              </TableCell>
            </tr>
          )
        })}
      </table>
    </Wrapper>
  )
}

export { StepHistory }
