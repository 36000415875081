import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Link as RouterLink } from 'react-router-dom'
import { Stack, Button, Heading, Paragraph, LoadingDots, Link } from '@qasa/qds-ui'

import { Table2 } from '../../../components/table/table2'
import { QasaLink } from '../../../components/qasa-link'
import { SearchBar } from '../../../components/search-bar'
import { useGetCurrentUser } from '../../../hooks'
import { getFormattedHomeAddress } from '../utils'
import { displayApiErrors, isUserFinnish } from '../../../utils'
import { useHomePublishedOnBlocket, useArchiveHome } from '../../../queries'
import { Api } from '../../../api'
import type { HomesInReviewTabProps } from '../homes-in-review'

import type { Home } from './in-review/home-type'

const LatestPublishedWrapper = styled(Stack)({
  width: '100vw',
})

export function LatestPublished({
  highlightHome,
  selectedRows,
  setSelectedRows,
  resetSelections,
}: HomesInReviewTabProps) {
  const { mutate: archiveHome, isLoading: isArchiveHomeLoading } = useArchiveHome()
  const { mutate: publishHomeOnBlocket, isLoading: isPublishHomeOnBlocketLoading } =
    useHomePublishedOnBlocket()
  const [currentPage, setCurrentPage] = useState(1)
  const [email, setEmail] = useState('')
  const [selectedHomes, setSelectedHomes] = useState<Home[]>([])
  const [homes, setHomes] = useState<Home[]>([])
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const loadLatestPublishedHomes = (newPage: number) => {
    setIsLoading(true)
    Api.homesGetLatestPublished(newPage, email)
      .then((res) => {
        if (res.status === 200) {
          setHomes(res.data.adminReviewHomes)
          setCount(res.data.count)
        }
      })
      .catch(() => displayApiErrors)
      .finally(() => setIsLoading(false))
    setCurrentPage(newPage)
  }

  useEffect(() => {
    loadLatestPublishedHomes(currentPage)
  }, [])

  const hasMoreHomesToLoad = homes.length !== count

  const loggedInUser = useGetCurrentUser()
  const { mainPlatform } = loggedInUser || {}

  const incrementToNextPage = () => {
    loadLatestPublishedHomes(currentPage + 1)
    setCurrentPage((prevState) => prevState + 1)
  }

  const loadFiltered = () => {
    if (currentPage === 1) {
      setHomes([])
    }
    loadLatestPublishedHomes(1)
  }

  const handleSubmitSearch = () => {
    loadFiltered()
    setCurrentPage(1)
  }

  const confirmAction = () =>
    window.confirm('Are you sure? ' + selectedHomes.length + ' homes will be affected')

  const onArchiveHome = () => {
    if (selectedHomes.length < 2 || confirmAction()) {
      selectedHomes.forEach((home: Home) => {
        archiveHome(
          { id: home.id, archiveReason: 'review_denied' },
          {
            onSuccess: () => {
              loadLatestPublishedHomes(currentPage)
              resetSelections()
            },
          },
        )
      })
    }
  }

  const onBlocketPublish = () => {
    if (selectedHomes.length < 2 || confirmAction()) {
      selectedHomes.forEach((home: Home) => {
        publishHomeOnBlocket(
          { id: String(home.id) },
          {
            onSuccess: () => {
              loadLatestPublishedHomes(currentPage)
              resetSelections()
            },
          },
        )
      })
    }
  }

  const onSelectionChanged = (selection: number[]) => {
    setSelectedHomes(selection.map((indexOfSelectedHome: number) => homes[indexOfSelectedHome]))
    const highlightedHome = selection.length === 1 ? homes[selection[0]] : null
    highlightHome(highlightedHome)
  }

  const actions = [
    {
      label: 'Archive',
      action: onArchiveHome,
      singleItem: false,
      isLoading: isArchiveHomeLoading,
    },
    {
      label: 'Publish on Blocket',
      action: onBlocketPublish,
      singleItem: false,
      isLoading: isPublishHomeOnBlocketLoading,
    },
  ]

  return (
    <LatestPublishedWrapper gap="2x">
      <SearchBar
        submit={handleSubmitSearch}
        onChange={(email: string) => setEmail(email)}
        placeholder="example@email.com"
        value={email}
      />
      <Stack gap="2x" direction="row" justifyContent="flex-end">
        {actions.map(({ label, action, singleItem, isLoading }) => (
          <Button
            variant="tertiary"
            key={label}
            disabled={selectedHomes.length === 0 || (singleItem && selectedHomes.length > 1)}
            onClick={action}
            isLoading={isLoading}
          >
            {label}
          </Button>
        ))}
      </Stack>
      {isLoading ? (
        <LoadingDots />
      ) : (
        <>
          <Heading size="2xs">
            Latest published homes: <Paragraph as="span"> {count}</Paragraph>
          </Heading>

          <>
            {homes && (
              <Table2
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                enableRowSelection
                onSelectionChanged={onSelectionChanged}
                rows={homes}
                getRowKey={(home) => home.id}
                columnsConfig={[
                  {
                    title: 'Name',
                    render: (home: Home) => {
                      const email = home.email
                      const professional = home.professional
                      const companyName = home.companyName
                      const fullName = home.fullName
                      return (
                        <Link isExternal as={RouterLink} to={`/user?user=${encodeURIComponent(email)}`}>
                          {professional ? '🏢 ' + companyName : fullName}
                        </Link>
                      )
                    },
                  },
                  {
                    title: 'E-mail',
                    render: (home: Home) => home.email,
                    getCellClassName: (home: Home) => (Boolean(home.emailConfirmedAt) ? 'Success' : 'Danger'),
                  },
                  {
                    title: 'Bank ID',
                    render: (home: Home) => {
                      const identityConfirmedAt = home.identityConfirmedAt
                      return identityConfirmedAt ? `Verified at: ${identityConfirmedAt}` : 'Not verified'
                    },
                    getCellClassName: (home: Home) => {
                      const identityConfirmedAt = home.identityConfirmedAt
                      return identityConfirmedAt ? 'Success' : 'Danger'
                    },
                  },
                  {
                    title: 'Address',
                    render: getFormattedHomeAddress,
                  },
                  {
                    title: 'Home',
                    render: (home: Home) => {
                      const id = home.id
                      const rent = home.rent
                      const squareMeters = home.squareMeters
                      const roomCount = home.roomCount
                      return (
                        <QasaLink to={`/home/${id}`}>
                          ${rent} | {squareMeters} m2 | {roomCount}
                        </QasaLink>
                      )
                    },
                  },
                  ...(!isUserFinnish({ mainPlatform })
                    ? [
                        {
                          title: 'Blocket Ad',
                          render: (home: Home) => {
                            const externalAdId = home.externalAdId
                            return <Link href={'http://blocket.se/vi/' + externalAdId}>{externalAdId}</Link>
                          },
                        },
                      ]
                    : []),
                ]}
              />
            )}
            <div>
              {hasMoreHomesToLoad && (
                <Button
                  isDisabled={homes.length === count}
                  variant="tertiary"
                  size="sm"
                  onClick={incrementToNextPage}
                >
                  Load more homes
                </Button>
              )}
            </div>
          </>
        </>
      )}
    </LatestPublishedWrapper>
  )
}
