import { Stack, LoadingDots } from '@qasa/qds-ui'

import { ListCard } from '../components/list-card'
import { type EmployeesQuery } from '../../../graphql/__generated__/graphql'
import { useAuthorisationContext } from '../../../context/authorisation-context'

export type Employee = EmployeesQuery['employees']['nodes'][number]

export function EmployeeTabContent() {
  const { employees, isLoadingEmployees, selectEmployee, employee } = useAuthorisationContext()

  return (
    <>
      {isLoadingEmployees ? (
        <Stack alignItems="center">
          <LoadingDots />
        </Stack>
      ) : (
        employees?.length > 0 &&
        employees?.map((element) => {
          const { name, email, profilePictureUrl, id } = element
          return (
            <ListCard
              key={id}
              name={name}
              email={email}
              profilePictureUrl={profilePictureUrl}
              onSelectCard={() => selectEmployee(element)}
              isSelected={employee?.id === id}
            />
          )
        })
      )}
    </>
  )
}
