import axios from 'axios'
import Cookies from 'js-cookie'

import { config } from '../config'

import type {
  DenyHomeParams,
  TerminateContractParams,
  SupportFlagParams,
  AssignRiskParams,
  CreateInvoiceParams,
  AdReviewDecisionParams,
  UpdateUserMainPlatformParams,
  SubUsersParams,
  CreateSubUserParams,
  ClassifyAmlHitParams,
  UpdateAdminCommentParams,
  LoginAdminResponse,
  LoginAdminParams,
} from './api-service.types'

export const axiosInstance = axios.create({
  baseURL: config.api,
})
// NOTE: This is a workaround for being able to override the default headers in the auth-action aswell.
// Doesn't seem to be possible to override the default headers in the auth-action when declaring it in the axios.create() function.
const accessToken = Cookies.get('Access-Token')
if (accessToken) {
  axiosInstance.defaults.headers.common['access-token'] = accessToken
}

export const syncBBQNotifications = (userUid: string) =>
  axiosInstance.post(`/v1/admin/users/${userUid}/sync_blocket_notifications`)

export const userPermanentDelete = (uid: string) => axiosInstance.post(`/v1/admin/users/${uid}/forget`)

export const sendResetLinkToUserEmail = ({ email, platform }: { email: string; platform: string }) =>
  axiosInstance.post(`/v1/users/password`, { user: { email, platform } })

export const terminateContract = ({ contractId, terminationDate }: TerminateContractParams) =>
  axiosInstance.post(`/v1/contracts/${contractId}/terminate`, {
    endDate: terminationDate,
  })

export const getEndpointClearanceLevels = () =>
  axiosInstance.get('/v1/admin/endpoint_clearance_levels').then((res) => res.data)

// TODO: update the publishHomeV2 mutation to handle kanye usage
export const publishHome = (id: number) => axiosInstance.post(`/v1/admin/homes/review/${id}/approve`)

// TODO: needs a mutation - denyHomeMutation
export const denyHome = ({ id, archiveReason, denyReasons }: DenyHomeParams) =>
  axiosInstance.post(`/v1/admin/homes/review/${id}/deny`, { archiveReason, denyReasons })

export const supportFlag = ({ uid, supportNote }: SupportFlagParams) =>
  axiosInstance.post(`/v1/admin/risk_classifications/support_flagging`, { uid, supportNote })

export const resetCreditCheck = (uid: string) =>
  axiosInstance.post(`/v1/admin/users/${uid}/reset_credit_check`)

export const assignRisk = ({ uid, overrideRisk, riskAssessmentNote }: AssignRiskParams) =>
  axiosInstance.post('/v1/admin/risk_classifications/override_risk', {
    uid,
    override_risk: overrideRisk,
    riskAssessmentNote,
  })

export const createInvoice = ({ contractId, amount, invoiceType }: CreateInvoiceParams) =>
  axiosInstance.post(`/v1/admin/invoices/contract/${contractId}/create_invoice`, {
    amount,
    invoice_type: invoiceType,
  })

export const creditInvoice = ({ id }: { id: string }) =>
  axiosInstance.put(`/v1/admin/invoices/${id}/credit_invoice`)

export const adReviewDecision = ({ id, decision, reasons, reviewerUid }: AdReviewDecisionParams) =>
  axiosInstance.post(`/v1/admin/homes/review_decision`, { id, decision, reasons, reviewer_uid: reviewerUid })

export const changeSignedPdf = ({ id, scriveId }: { id: string; scriveId: string }) =>
  axiosInstance.put(`/v1/admin/contracts/${id}/change_signed_pdf`, { scrive_id: scriveId })

export const scheduleInvoices = ({ id }: { id: string }) =>
  axiosInstance.post(`/v1/admin/invoices/contract/${id}/schedule`)

export const resetBankIDVerification = (uid: string) =>
  axiosInstance.post(`/v1/admin/identifications/reset`, { uid })

export const changeContractEndDate = ({ id, endDate }: { id: string; endDate: string }) =>
  axiosInstance.put(`/v1/admin/contracts/${id}/change_end_date`, { endDate })

export const updateEmail = ({ uid, email }: { uid: string; email: string }) =>
  axiosInstance.put(`/v1/users`, { user: { uid, email } })

export const verifyAccount = ({ isUsersAccountVerified }: { isUsersAccountVerified: string }) =>
  axiosInstance.put(`/v1/admin/accounts/verify`, { account: { id: isUsersAccountVerified } })

export const creditCheck = (uid: string) => axiosInstance.post(`/v1/admin/users/${uid}/credit_check`)

export const updateUserSafeRentalStatus = ({ uid, safeRental }: { uid: string; safeRental: boolean }) =>
  axiosInstance.put(`/v1/users`, { user: { uid, safeRental } })

export const enableSafeRentalUser = async ({ uid }: { uid: string }) => {
  await updateUserSafeRentalStatus({ uid, safeRental: true })
}

export const disableSafeRentalUser = async ({ uid }: { uid: string }) => {
  await updateUserSafeRentalStatus({ uid, safeRental: false })
}

export const clearSchibstedAccountId = ({ uid }: { uid: string }) =>
  axiosInstance.patch(`/v1/admin/users/clear_schibsted_id/${uid}`, { uid })

export const updateContractRent = ({ id, newRent }: { id: string; newRent: number }) =>
  axiosInstance.put(`/v1/admin/contracts/${id}/change_rent`, { new_rent: newRent })

export const syncInvoiceBatch = ({ contractId, batch }: { contractId: string; batch: number }) =>
  axiosInstance.put(`/v1/admin/invoices/contract/${contractId}/batch/${batch}`, { contractId })

export const deleteInvoiceBatch = ({ contractId, batch }: { contractId: string; batch: number }) =>
  axiosInstance.delete(`/v1/admin/invoices/contract/${contractId}/batch/${batch}`)

export const publishHomeOnBlocket = ({ id }: { id: string }) =>
  axiosInstance.post(`/v1/admin/homes/blocket_publish`, { id })

export const archiveHome = ({ id, archiveReason }: { id: number; archiveReason: string }) =>
  axiosInstance.delete(`/v1/homes/${id}`, { data: { archiveReason } })

export const sendTerminationPDF = ({ id, endDate }: { id: string; endDate: string }) =>
  axiosInstance.post(`/v1/admin/contracts/${id}/send_termination_pdf`, { endDate })

export const updateUserMainPlatform = ({ uid, mainPlatform }: UpdateUserMainPlatformParams) =>
  axiosInstance.put(`/v1/users`, {
    user: { uid, mainPlatform },
  })

export const createSubUser = ({ uid, subUserEmail }: CreateSubUserParams) =>
  axiosInstance.post(`/v1/admin/sub_users/create`, { uid, subUserEmail })

export const reinviteSubUser = ({ email }: SubUsersParams) =>
  axiosInstance.post(`/v1/admin/sub_users/send_reset_password_email`, { email })

export const removeSubUser = ({ email }: SubUsersParams) =>
  axiosInstance.post(`/v1/admin/sub_users/remove`, { email })

export const syncUserToBillecta = ({ uid }: { uid: string }) =>
  axiosInstance.post(`/v1/admin/users/${uid}/sync_user_to_billecta`)

export const classifyAmlHit = (params: ClassifyAmlHitParams) =>
  axiosInstance.post(`v1/admin/risk_classifications/aml_hits/classify`, params, {})

export const addInvoicingForUser = ({ uid }: { uid: string }) =>
  axiosInstance.post(`/v1/admin/users/${uid}/add_invoicing`)

export const removeInvoicingForUser = ({ uid }: { uid: string }) =>
  axiosInstance.post(`/v1/admin/users/${uid}/remove_invoicing`)

export const updateAdminCommentForSuspicion = ({ id, adminComment }: UpdateAdminCommentParams) =>
  axiosInstance.patch(`/v1/admin/suspicion/${id}`, { adminComment })

export const cancelContractAfterSigning = ({ id }: { id: string }) =>
  axiosInstance.put(`/v1/admin/contracts/${id}/cancel_after_sign`)

export const markSuspicionAsFalse = ({ id }: { id: string }) =>
  axiosInstance.patch(`/v1/admin/suspicion/${id}/flag_as_false`)

export const loginAdmin = ({ email, password }: LoginAdminParams) => {
  return axiosInstance.post<LoginAdminResponse>('/v1/admin/users/token/agnostic', { email, password })
}
