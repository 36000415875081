import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Stack, TextField } from '@qasa/qds-ui'

import { RequiresClearanceLevel } from '../../../components/requires-clearance-level'
import { useUpdateContractRent } from '../../../queries/contract-queries'
import type { KanyeUserContractType } from '../contracts/contracts.gql'

UpdateRent.propTypes = {
  contract: PropTypes.object,
}

const ACTIVE_CONTRACT_STATUSES = ['draft', 'pending', 'signed', 'extended']

type UpdateRentProps = {
  contract: KanyeUserContractType
}
export function UpdateRent({ contract }: UpdateRentProps) {
  const isActiveContract = ACTIVE_CONTRACT_STATUSES.includes(contract.status)
  const [rent, setRent] = useState('')
  const {
    mutate: updateContractRent,
    isLoading: isUpdateRentLoading,
    endpointInfo: updateContractRentEndpointInfo,
    isSuccess,
  } = useUpdateContractRent()

  if (!isActiveContract) return null

  useEffect(() => {
    if (isSuccess) {
      setRent('')
    }
  }, [isSuccess])

  const handleUpdateRent = () => {
    updateContractRent({ id: contract.id, newRent: Number(rent) })
  }

  return (
    <RequiresClearanceLevel endpoints={[updateContractRentEndpointInfo]}>
      <Stack gap="3x">
        <TextField
          label="Change rent"
          type="number"
          value={rent}
          onChange={(ev) => setRent(ev.target.value)}
          placeholder="Rent"
        />
        <Button
          variant="tertiary"
          disabled={!Boolean(rent)}
          isLoading={isUpdateRentLoading}
          onClick={handleUpdateRent}
        >
          Update
        </Button>
      </Stack>
    </RequiresClearanceLevel>
  )
}
