import styled from '@emotion/styled'
import { IconButton, LoadingDots, Paragraph, Stack, createStyleVariants, PenIcon } from '@qasa/qds-ui'
import { useState } from 'react'
import { useQuery } from '@apollo/client'

import { MarketNameTypeEnum } from '../../graphql/__generated__/graphql'
import { formatDate } from '../../utils/date'

import { EditShortcutSettingsDialog } from './edit-shortcut-settings-dialog'
import { MARKET_SETTINGS_QUERY } from './shortcut.gql'

const Wrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing['6x'],
  borderRadius: theme.radii.lg,
  border: '1px solid',
  borderColor: theme.colors.border.default,
}))
const BoldSpan = styled.span({
  fontWeight: 'bold',
})

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing['2x'],
  right: theme.spacing['2x'],
}))

const getIndicatorColor = createStyleVariants((theme) => ({
  enabled: {
    backgroundColor: theme.colors.core.green50,
  },
  disabled: {
    backgroundColor: theme.colors.core.red50,
  },
}))
const IndicatorCircle = styled.span<{ variant: 'enabled' | 'disabled' }>(({ theme, variant }) => ({
  display: 'inline-block',
  width: theme.sizes['2x'],
  height: theme.sizes['2x'],
  borderRadius: theme.radii.full,

  ...getIndicatorColor(theme)[variant],
}))

export function SettingsCard() {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { data, loading: isLoading } = useQuery(MARKET_SETTINGS_QUERY, {
    variables: {
      marketName: MarketNameTypeEnum.Sweden,
    },
  })

  if (isLoading) {
    return (
      <Wrapper>
        <LoadingDots />
      </Wrapper>
    )
  }

  const marketSettings = data?.marketSettings
  const {
    shortcutEnlistNewHomes: isShortcutEnabled,
    shortcutMaxPendingListings,
    shortcutStartRange,
  } = marketSettings ?? {}
  const { from, to } = shortcutStartRange ?? {}
  const formattedMoveInFrom = from ? formatDate(new Date(from)) : 'no date set'
  const formattedMoveInTo = to ? formatDate(new Date(to)) : 'no date set'

  return (
    <>
      <Wrapper gap="1x">
        <StyledIconButton
          icon={PenIcon}
          variant="tertiary"
          size="xs"
          label="Edit shortcut settings"
          style={{ justifySelf: 'flex-end' }}
          onClick={() => setIsDialogOpen(true)}
          disabled={!marketSettings}
        />
        <Stack direction="row" gap="2x" alignItems="center">
          <IndicatorCircle variant={isShortcutEnabled ? 'enabled' : 'disabled'} />
          <Paragraph>{isShortcutEnabled ? 'Enabled' : 'Disabled'}</Paragraph>
        </Stack>
        <Paragraph>
          <BoldSpan>Number of allowed pending homes: </BoldSpan>
          {shortcutMaxPendingListings ?? 0}
        </Paragraph>
        <Paragraph>
          <BoldSpan>First allowed move in date: </BoldSpan>
          {formattedMoveInFrom}
        </Paragraph>
        <Paragraph>
          <BoldSpan>Last allowed move in date: </BoldSpan>
          {formattedMoveInTo}
        </Paragraph>
      </Wrapper>
      {marketSettings && (
        <EditShortcutSettingsDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          marketSettings={marketSettings}
        />
      )}
    </>
  )
}
