import PropTypes from 'prop-types'
import React, { createContext, useContext } from 'react'
import { Label, Stack } from '@qasa/qds-ui'

import { useRadioGroup } from './use-radio-group'
import { RadioItem } from './radio-item'

const RadioGroupContext = createContext(undefined)

RadioGroupRoot.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}
export function RadioGroupRoot({ label, children, name, ...restProps }) {
  const groupContextValue = useRadioGroup({ name, ...restProps })
  const ariaLabelId = ['radio-group-label', name].join('-')

  return (
    <RadioGroupContext.Provider value={groupContextValue}>
      <Stack gap="2x" role="radiogroup" aria-labelledby={ariaLabelId}>
        <Label id={ariaLabelId}>{label}</Label>
        {children}
      </Stack>
    </RadioGroupContext.Provider>
  )
}

export const useRadioGroupContext = () => {
  const context = useContext(RadioGroupContext)

  if (context === undefined) {
    throw Error('Radio components must be used within a RadioGroup')
  }

  return context
}

export const RadioGroup = Object.assign(RadioGroupRoot, { Item: RadioItem })
