import { useState, useEffect } from 'react'
import { LoadingDots, Paragraph, Stack } from '@qasa/qds-ui'
import { useQuery } from '@apollo/client'

import { ContractFrance } from '../contracts/contract-france'
import { useScrollToContractListItem } from '../../../hooks'
import type { KanyeUserContractsType } from '../contracts/contracts.gql'
import { CONTRACTS } from '../contracts/contracts.gql'
import { useUserContext } from '../user-context'
import { sortContracts } from '../contracts.utils'
import { VISIBLE_CONTRACT_STATUSES_FRANCE } from '../constants'
import { notifyDefault } from '../../../utils/notifications'
import { useLocalStorage } from '../../../hooks/use-local-storage'
import { ContractRentalTypeEnum } from '../../../graphql/__generated__/graphql'

import { SearchContractsFrance } from './search-contracts-france'

export function UserContractsFrance() {
  const { user } = useUserContext()
  const [storedPinnedContracts, setPinnedContractsToLocalStorage] = useLocalStorage<string[]>(
    'pinnedContracts',
    [],
  )
  const [pinnedContracts, setPinnedContracts] = useState<string[]>(storedPinnedContracts || [])
  const [searchResultContracts, setSearchResultContracts] = useState<KanyeUserContractsType>([])
  const [isUserSearching, setIsUserSearching] = useState(false)
  const [hasResults, setHasResults] = useState(true)
  const userUid = user.uid

  useEffect(() => {
    setPinnedContractsToLocalStorage(pinnedContracts)
  }, [pinnedContracts.length])

  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(CONTRACTS, {
    variables: {
      uid: userUid,
      rentalType: ContractRentalTypeEnum.LongTerm,
      status: VISIBLE_CONTRACT_STATUSES_FRANCE,
    },
  })

  const contracts = data?.kanyeUser.contracts ?? []

  const pinnedContractsArray = contracts
    .filter(({ id }) => pinnedContracts.includes(id))
    .sort((a, b) => pinnedContracts.indexOf(a.id) - pinnedContracts.indexOf(b.id))
  const unpinnedContractsArray = contracts.filter(({ id }) => !pinnedContracts.includes(id))

  const contractsToDisplay = isUserSearching ? searchResultContracts : unpinnedContractsArray

  const sortedUnpinnedContracts = sortContracts({
    contracts: [...contractsToDisplay],
  })

  const renderContracts = [...pinnedContractsArray, ...sortedUnpinnedContracts]

  const { refsArray, targetScrollIndex } = useScrollToContractListItem({
    dataArray: renderContracts,
  })

  const onPinContract = ({ contractId }: { contractId: string }) => {
    const isContractAlreadyPinned = pinnedContracts.includes(contractId)
    const action = isContractAlreadyPinned ? 'unpinned' : 'pinned'

    setPinnedContracts((pinnedContracts) => {
      if (isContractAlreadyPinned) {
        return pinnedContracts.filter((id) => id !== contractId)
      }
      return [contractId, ...pinnedContracts]
    })

    notifyDefault(`Contract #${contractId} ${action}!`)
  }

  if (isLoading) {
    return <LoadingDots />
  }
  if (!user || !contracts || error) {
    return (
      <Paragraph color="warning">{error?.message ?? 'Unexpected error, contracts object empty'}</Paragraph>
    )
  }
  if (!contracts.length) {
    return <Paragraph>{'No contracts to display'}</Paragraph>
  }

  const handleSearchInUse = (input: string) => setIsUserSearching(Boolean(input))

  return (
    <Stack gap="6x">
      <SearchContractsFrance
        setSearchResultContracts={setSearchResultContracts}
        contracts={contracts}
        setHasResults={setHasResults}
        handleSearchInUse={handleSearchInUse}
      />
      {hasResults ? (
        <Stack gap="4x">
          {renderContracts.map((contract, index) => (
            <ContractFrance
              ref={(el) => (refsArray.current[index] = el)}
              key={contract.id}
              contract={contract}
              isDefaultExpanded={index === targetScrollIndex}
              onPinContract={() => onPinContract({ contractId: contract.id })}
              isPinned={pinnedContracts.includes(contract.id)}
            />
          ))}
        </Stack>
      ) : (
        <Paragraph>{'No contracts found'}</Paragraph>
      )}
    </Stack>
  )
}
