import { Paragraph, Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { BookingStatusEnum, ContractStatusEnum } from '../../../../graphql/__generated__/graphql'

const StatusBase = styled(Paragraph)(({ theme }) => ({
  paddingBlock: theme.spacing['1x'],
  paddingInline: theme.spacing['2x'],
  borderRadius: theme.radii.sm,
  color: theme.colors.core.offWhite,
}))

const LongTermContractStatus = styled(StatusBase)<{ status: ContractStatusEnum }>(({ theme, status }) => {
  const getLabelStyles = (status: ContractStatusEnum) => {
    switch (status) {
      case ContractStatusEnum.Signed:
        return { backgroundColor: theme.colors.bg.positive }
      case ContractStatusEnum.Extended:
        return {
          backgroundColor: theme.colors.core.green40,
          color: theme.colors.text.default,
        }
      case ContractStatusEnum.Ended:
      case ContractStatusEnum.Draft:
      case ContractStatusEnum.FinalDraft:
        return { backgroundColor: theme.colors.core.gray50 }
      case ContractStatusEnum.Canceled:
      case ContractStatusEnum.Rejected:
        return { backgroundColor: theme.colors.bg.negative }
      case ContractStatusEnum.Pending:
      case ContractStatusEnum.PendingTenantCreditCheck:
      case ContractStatusEnum.PendingAprilaApplication:
      case ContractStatusEnum.PendingAprilaRentalAgreement:
      case ContractStatusEnum.AwaitingInsuranceApproval:
        return { backgroundColor: theme.colors.bg.warning }
      default:
        return { backgroundColor: 'transparent' }
    }
  }
  return {
    ...getLabelStyles(status),
  }
})

const BookingStatus = styled(StatusBase)<{ status: BookingStatusEnum }>(({ theme, status }) => {
  const getLabelStyles = (status: BookingStatusEnum) => {
    switch (status) {
      case BookingStatusEnum.Accepted:
      case BookingStatusEnum.Ongoing:
        return {
          backgroundColor: theme.colors.core.green30,
          color: theme.colors.text.default,
        }
      case BookingStatusEnum.Confirmed:
        return {
          backgroundColor: theme.colors.core.green40,
          color: theme.colors.text.default,
        }
      case BookingStatusEnum.Completed:
        return { backgroundColor: theme.colors.bg.positive }
      case BookingStatusEnum.Declined:
      case BookingStatusEnum.DeclinedDueToLatePayment:
      case BookingStatusEnum.DeclinedDueToTenantInactivity:
        return {
          backgroundColor: theme.colors.core.red30,
          color: theme.colors.text.default,
        }
      case BookingStatusEnum.Revoked:
        return {
          backgroundColor: theme.colors.core.red40,
          color: theme.colors.text.default,
        }
      case BookingStatusEnum.Canceled:
        return { backgroundColor: theme.colors.bg.negative }
      case BookingStatusEnum.AwaitingPayment:
        return { backgroundColor: theme.colors.bg.warning }
      default:
        return { backgroundColor: 'transparent' }
    }
  }
  return {
    ...getLabelStyles(status),
  }
})

type ContractStatusProps = {
  isBooking?: boolean
  label: string
  status: BookingStatusEnum | ContractStatusEnum
}
export function ContractStatus({ status, label, isBooking = false }: ContractStatusProps) {
  return (
    <Stack direction="row" alignItems="center">
      {isBooking ? (
        <BookingStatus status={status as BookingStatusEnum}>{label}</BookingStatus>
      ) : (
        <LongTermContractStatus status={status as ContractStatusEnum}>{label}</LongTermContractStatus>
      )}
    </Stack>
  )
}
