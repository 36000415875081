import styled from '@emotion/styled'
import { Heading, IconButton, Stack, Link } from '@qasa/qds-ui'

import { notifyDefault } from '../../../../utils/notifications'
import { CopyIcon } from '../../../../components/icons'

const ItemsWrapper = styled(Stack)(({ theme }) => ({
  flex: 1,
  minWidth: theme.sizes['320'],
  borderRadius: theme.radii.lg,
  padding: theme.spacing['6x'],
  backgroundColor: theme.colors.core.softPink,
  ':nth-child(2)': { backgroundColor: theme.colors.core.softYellow },
  ':nth-child(3)': { backgroundColor: theme.colors.core.blue20 },
}))

const SectionHeader = styled.div({ display: 'flex', justifyContent: 'space-between', alignItems: 'center' })

const ItemWrapper = styled.div({ display: 'flex', justifyContent: 'space-between' })
const Label = styled.span({ fontWeight: 'bolder' })
const Value = styled.span({ textAlign: 'right' })

type LabeledValue = {
  label: string
  value?: string | number | null
  url?: string
}

function Item({ label, value, url }: LabeledValue) {
  return (
    <ItemWrapper>
      <Label>{label}</Label>
      {url ? (
        <Link isExternal href={url}>
          {value}
        </Link>
      ) : (
        <Value>{value}</Value>
      )}
    </ItemWrapper>
  )
}

type ContractMetaSectionProps = {
  header: string
  sectionData: LabeledValue[]
}
export function ContractMetaSection({ header, sectionData }: ContractMetaSectionProps) {
  const copySectionDataToClipboard = () => {
    let text = ''
    sectionData.forEach(({ label, value }) => {
      text += `${label}: ${value}\n`
    })
    navigator.clipboard.writeText(text)
    notifyDefault(`Copied section to clipboard!`)
  }

  return (
    <ItemsWrapper gap="3x">
      <SectionHeader>
        <Heading style={{ color: 'inherit' }} size="xs">
          {header}
        </Heading>
        <IconButton
          label="Copy section to clipboard"
          size="xs"
          icon={CopyIcon}
          onClick={copySectionDataToClipboard}
        />
      </SectionHeader>
      <Stack gap="1x">
        {sectionData.map(({ label, value, url }) => (
          <Item key={label} label={label} value={value} url={url} />
        ))}
      </Stack>
    </ItemsWrapper>
  )
}
