import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Button, Paragraph, Stack, Label } from '@qasa/qds-ui'

import { Checkbox } from '../../components/_core/checkbox'
import { Dialog } from '../../components/_core/dialog'
import { RadioGroup } from '../../components/_core/radio-group/radio-group'
import { useUpdateMainPlatform } from '../../queries'
import { PlatformEnum } from '../../graphql/__generated__/graphql'

const legalUpdateMainPlatformTextSwedish = (from: PlatformEnum, to: PlatformEnum): string => {
  return `Vill du att vi plockar ut dina användaruppgifter från ${from} och skickar de till 
  ${to} och sedan tar bort uppgifterna från 
  ${from}? I praktiken för dig blir det att du inte kan logga in på 
  ${from} längre och hela ditt konto flyttas till ${to}`
}

const InfoBox = styled.div(({ theme }) => ({
  padding: theme.spacing['4x'],
  borderRadius: theme.radii.md,
  background: theme.colors.core.gray20,
}))
const Acknowledgement = 'div'

type MainPlatformInputProps = {
  onChange: (value: PlatformEnum) => void
  currentMainPlatform: PlatformEnum
  initialPlatform: PlatformEnum
}

function MainPlatformInput({ initialPlatform, onChange, currentMainPlatform }: MainPlatformInputProps) {
  return (
    <div>
      <RadioGroup name="Reee" value={currentMainPlatform} label={'Main platform'} onChange={onChange}>
        <Stack gap="2x">
          {Object.values(PlatformEnum).map((type, index) => (
            <RadioGroup.Item
              key={index}
              value={type}
              label={initialPlatform === type ? `${type} (initial platform)` : type}
            />
          ))}
        </Stack>
      </RadioGroup>
    </div>
  )
}

type ChangeMainPlatformProps = {
  uid: string
  mainPlatform: PlatformEnum
}
export function ChangeMainPlatform({ uid, mainPlatform }: ChangeMainPlatformProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedMainPlatform, setSelectedMainPlatform] = useState(mainPlatform)
  const [isLegalAcknowledged, setIsLegalAcknowledged] = useState(false)
  const { mutate: updateUserMainPlatform, isLoading: isUpdateUserMainPlatformLoading } =
    useUpdateMainPlatform()

  const updateMainPlatform = () => {
    updateUserMainPlatform(
      { uid, mainPlatform: selectedMainPlatform },
      {
        onSuccess: () => {
          setIsOpen(false)
        },
      },
    )
  }

  return (
    <Dialog isOpen={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger>
        <Button onClick={() => setIsOpen(true)} variant="tertiary">
          Change main platform
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>Change users main platform</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <Stack gap="8x">
            <MainPlatformInput
              initialPlatform={mainPlatform}
              currentMainPlatform={selectedMainPlatform}
              onChange={(mainPlatform) => setSelectedMainPlatform(mainPlatform)}
            />
            <Stack gap="2x">
              <div>
                <Label size="sm" as="h4">
                  Legal requirements
                </Label>
                <Paragraph>Receive following acknowledgement from the user before proceeding</Paragraph>
              </div>
              <InfoBox>
                <Paragraph>
                  {legalUpdateMainPlatformTextSwedish(mainPlatform, selectedMainPlatform)}
                </Paragraph>
              </InfoBox>
            </Stack>
          </Stack>
        </Dialog.Body>
        <Dialog.Footer>
          <Stack gap="2x">
            <Acknowledgement>
              <Checkbox
                label="I have checked with the user"
                onChange={() => setIsLegalAcknowledged(!isLegalAcknowledged)}
                isChecked={isLegalAcknowledged}
              />
            </Acknowledgement>
            <Button
              onClick={updateMainPlatform}
              isDisabled={!isLegalAcknowledged}
              isLoading={isUpdateUserMainPlatformLoading}
              variant="primary"
              isFullWidth
            >
              Change platform
            </Button>
          </Stack>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}
