import { Button, Stack, RadioGroup } from '@qasa/qds-ui'
import { useState } from 'react'
import { useMutation } from '@apollo/client'

import { inactivateUser } from '../user-utils'
import { Dialog } from '../../../components/_core/dialog'
import { notifyFailure } from '../../../utils'
import { CREATE_ADMIN_NOTE } from '../../../components/notes/notes.gql'
import { KANYE_USER_QUERY } from '../kanye-user.gql'

const reasoningOptions = {
  adminDecision: 'Admin decision',
  userInactivaity: 'User inactivaity',
}
const levelOptions = {
  inactivate: 'Inactivate only',
  inactivateAndSuspend: 'Inactivate & suspend',
}

export function InactivateUserAccountDialog({ uid }: { uid: string }) {
  const [isInactivateDialogOpen, setIsInactivateDialogOpen] = useState(false)
  const [selectedLevel, setSelectedLevel] = useState(levelOptions.inactivate)
  const [selectedReasoning, setSelectedReasoning] = useState(reasoningOptions.adminDecision)

  const [createUserSuspensionNote] = useMutation(CREATE_ADMIN_NOTE, {
    onCompleted: () => setIsInactivateDialogOpen(false),
    onError: () => notifyFailure('Failed to save user account access note'),
    refetchQueries: [{ query: KANYE_USER_QUERY, variables: { uid } }],
  })

  const onInactivate = () => {
    const suspended = selectedLevel === levelOptions.inactivateAndSuspend ? true : false
    inactivateUser(uid, suspended)

    let note = 'Account inactivated'

    if (suspended) {
      note += ' & suspended'
    }

    if (selectedReasoning) {
      note += ` - ${selectedReasoning}`
    }

    createUserSuspensionNote({
      variables: {
        input: {
          uid,
          note,
        },
      },
    })
  }

  const onLevelChange = (level: string) => setSelectedLevel(level)
  const onReasoningChange = (reason: string) => setSelectedReasoning(reason)

  return (
    <Dialog isOpen={isInactivateDialogOpen} onOpenChange={setIsInactivateDialogOpen}>
      <Dialog.Trigger>
        <Button variant="tertiary" onClick={() => setIsInactivateDialogOpen(true)}>
          {'Inactivate'}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>{'Inactivate user account'}</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <Stack gap="8x">
            <RadioGroup
              defaultValue={selectedLevel}
              name="inactivate_level_radio_group"
              onValueChange={onLevelChange}
            >
              <RadioGroup.Label>{'Level of inactivation'}</RadioGroup.Label>
              <Stack gap="2x">
                <RadioGroup.Card label={levelOptions.inactivate} value={levelOptions.inactivate} />
                <RadioGroup.Card
                  label={levelOptions.inactivateAndSuspend}
                  value={levelOptions.inactivateAndSuspend}
                />
              </Stack>
            </RadioGroup>
            <RadioGroup
              defaultValue={selectedReasoning}
              name="reasoning_radio_group"
              onValueChange={onReasoningChange}
            >
              <RadioGroup.Label>{'Reason for inactivation'}</RadioGroup.Label>
              <Stack gap="2x">
                <RadioGroup.Card
                  label={reasoningOptions.adminDecision}
                  value={reasoningOptions.adminDecision}
                />
                <RadioGroup.Card
                  label={reasoningOptions.userInactivaity}
                  value={reasoningOptions.userInactivaity}
                />
              </Stack>
            </RadioGroup>
          </Stack>
        </Dialog.Body>
        <Dialog.Footer>
          <Button variant="secondary" isFullWidth onClick={onInactivate}>
            {'Done'}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}
