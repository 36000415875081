import { Button, Stack, TextField } from '@qasa/qds-ui'
import { type ChangeEvent, useState } from 'react'
import { useMutation } from '@apollo/client'

import { notifyFailure, notifySuccess } from '../../../utils/notifications'
import { type KanyeUserContractType } from '../contracts/contracts.gql'

import { UPDATE_WATER_FEE_AND_NUMBER_OF_TENANTS } from './update-water-fee-and-number-of-tenants.gql'

type UpdateContractWaterFeeProps = {
  contract: KanyeUserContractType
}
export function UpdateNumberOfTenants({ contract }: UpdateContractWaterFeeProps) {
  const [amountOfTenants, setAmountOfTenants] = useState('')

  const [updateAmountOfTenants, { loading: isUpdatingAmountOfTenants }] = useMutation(
    UPDATE_WATER_FEE_AND_NUMBER_OF_TENANTS,
    {
      variables: {
        id: contract.id,
        input: {
          numberOfTenants: Number(amountOfTenants || contract.numberOfTenants),
          waterFeePerTenant: contract?.tenantFees.waterFeePerTenant.cost.total,
        },
      },
      refetchQueries: ['Contracts'],
      onCompleted: (data) => {
        const result = data.updateContractWaterFee
        if (!result?.errors) {
          notifySuccess(`num of tenants: ${result?.contract?.numberOfTenants}`)
          setAmountOfTenants('')
        } else {
          notifyFailure(`num of tenants on contract #${contract.id} could not be updated`)
        }
      },
      onError: () => notifyFailure(`num of tenants on contract #${contract.id} could not be updated`),
    },
  )

  return (
    <Stack gap="3x">
      <TextField
        label="Update amount of tenants"
        type="number"
        placeholder="num of tenants"
        value={amountOfTenants}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setAmountOfTenants(e.target.value)}
      />
      <Button
        variant="tertiary"
        disabled={!Boolean(amountOfTenants)}
        isLoading={isUpdatingAmountOfTenants}
        onClick={() => updateAmountOfTenants()}
      >
        Update
      </Button>
    </Stack>
  )
}
