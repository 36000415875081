import { BrowserRouter } from 'react-router-dom'
import { QueryParamProvider, transformSearchStringJsonSafe } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import { parse, stringify } from 'query-string'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { AuthorisationProvider } from './context/authorisation-context'
import { Routes } from './routes'
import { AuthProvider } from './context/auth-context'

export const QUERY_PARAM_ENCODING_OPTIONS = {
  arrayFormat: 'comma',
  skipNull: true,
  skipEmptyString: true,
  transformSearchString: transformSearchStringJsonSafe,
}

const queryClient = new QueryClient()

export function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <QueryParamProvider
          adapter={ReactRouter5Adapter}
          options={{
            searchStringToObject: (queryParamString) => parse(queryParamString, QUERY_PARAM_ENCODING_OPTIONS),
            objectToSearchString: (queryParamObject) =>
              stringify(queryParamObject, QUERY_PARAM_ENCODING_OPTIONS),
          }}
        >
          <AuthProvider>
            <AuthorisationProvider>
              <Routes />
            </AuthorisationProvider>
          </AuthProvider>
        </QueryParamProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}
