import { ChevronDownIcon, ChevronUpIcon, Heading, Paragraph, Stack } from '@qasa/qds-ui'
import { Link } from 'react-router-dom'
import includes from 'lodash/includes'

import { FileInvoiceIcon } from '../../../../components/icons'

import { getBillectaUrl } from './utils'

const TopBar = Stack
const LeftColumn = Stack
const RightColumn = Stack
const IconAndTitle = Stack

const INVOICE_TYPES = {
  invoice_deposit: 'Deposit',
  invoice_rent: 'Rent',
  verification_fee: 'Qasa fee',
  invoice_deposit_compensation: 'Deposit split',
}

type InvoiceLinkProps = {
  billectaId: string
  invoiceType: string
  user: any
  children: React.ReactNode
}

function InvoiceLink({ billectaId, invoiceType, user, children }: InvoiceLinkProps) {
  if (user.mainPlatform === 'finn') {
    return null
  }
  let type
  if (!billectaId || !invoiceType) {
    return children
  } else if (includes(invoiceType, 'selfinvoice')) {
    type = 'sfi'
  } else {
    type = 'in'
  }

  const url = getBillectaUrl({ type, id: billectaId })
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

type HeaderProps = {
  invoice: any
  user: any
  isExpanded: boolean
  setIsExpanded: (isExpanded: boolean) => void
}
export function Header({ invoice, user, isExpanded, setIsExpanded }: HeaderProps) {
  const isInvoiceMissing = invoice.id === null

  const content = () => {
    if (isInvoiceMissing) {
      return (
        <IconAndTitle direction="row" alignItems="center" gap="2x">
          <FileInvoiceIcon />
          <Heading size="2xs">Deleted invoice</Heading>
        </IconAndTitle>
      )
    }

    return (
      <>
        <IconAndTitle direction="row" alignItems="center" gap="2x">
          <FileInvoiceIcon />
          <Heading size="2xs">
            <InvoiceLink billectaId={invoice.billectaId} invoiceType={invoice.type} user={user}>
              {INVOICE_TYPES[invoice.type as keyof typeof INVOICE_TYPES]} invoice {invoice.invoiceNumber}
            </InvoiceLink>
          </Heading>
        </IconAndTitle>
        <Paragraph
          as={Link}
          size="sm"
          style={{ textDecoration: 'none' }}
          target={'_blank'}
          to={{
            pathname: '/user/contracts',
            search: `user=${user.uid}&contractId=${invoice.contract.id}`,
          }}
        >
          {invoice.contract?.address}
        </Paragraph>
      </>
    )
  }

  return (
    <TopBar gap="4x" direction="row" justifyContent="space-between" alignItems="center">
      <LeftColumn gap="4x" direction="row">
        {content()}
      </LeftColumn>
      <button
        onClick={() => {
          setIsExpanded(!isExpanded)
        }}
      >
        <RightColumn direction="row" alignItems="center" gap="2x">
          {invoice.archived ? <span>Archived</span> : <span>Active</span>}
          {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </RightColumn>
      </button>
    </TopBar>
  )
}
