import { useState } from 'react'
import { Button, InputBase, Stack } from '@qasa/qds-ui'
import isEmpty from 'lodash/isEmpty'

type SearchFormProps = {
  initialSearchString: string
  onSearchChange: (searchString: string) => void
  isLoading: boolean
}

export function SearchForm({ initialSearchString, onSearchChange, isLoading }: SearchFormProps) {
  const [searchString, setSearchString] = useState(initialSearchString)

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        if (isEmpty(searchString)) return
        onSearchChange(searchString)
      }}
    >
      <Stack direction={'row'} gap={'2x'} alignItems={'center'}>
        <InputBase
          placeholder={'Email, name, invoice, id, comments...'}
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          title={
            'You can search by e-mail (both tenant and landlord), name, invoice number, personal number, or comments'
          }
        />
        <Button variant="tertiary" type={'submit'} disabled={isLoading}>
          Search
        </Button>
      </Stack>
    </form>
  )
}
