import styled from '@emotion/styled'
import { Stack, Paragraph } from '@qasa/qds-ui'

import { DataColumn } from '../../../components/data-column'
import { UserHeader } from '../user-header'
import { LoginAsUserButton } from '../login-as-user-button'
import { CompanyHeader } from '../company-header'
import { AccountResourceStateEnum } from '../../../graphql/__generated__/graphql'
import { useUserContext } from '../user-context'

const Wrapper = Stack
const DataRow = styled.div({ display: 'flex', justifyContent: 'space-between' })
const Label = styled(Paragraph)({ fontWeight: 'bold' })
const Data = Paragraph

const confirmOrDeny = (condition: boolean) => (condition ? 'Yes' : 'No')

export function UserData() {
  const { user } = useUserContext()

  const idConfirmedAt = user.currentIdentification?.identityConfirmedAt

  const hasActiveBankAccount = Boolean(user?.private.stripeAccount)
  const isStripePayoutsEnabled =
    user?.private.stripeAccount?.currentState === AccountResourceStateEnum.PayoutsEnabled

  const mainPlatform = user.mainPlatform
  const isUserProfessional = user.professional
  const locale = user.locale
  const seenAt = user.seenAt
  const createdAt = user.createdAt
  const removedAt = user.removedAt
  const isSuspended = user.suspended

  const userDataSection = [
    {
      label: 'Main platform',
      data: mainPlatform,
    },
    {
      label: 'UID:',
      data: user.uid,
    },
    {
      label: 'Professional:',
      data: confirmOrDeny(isUserProfessional),
    },
    {
      label: 'Locale:',
      data: locale,
    },
    {
      label: 'Onfido Verification:',
      data: idConfirmedAt ? new Date(idConfirmedAt).toDateString() : 'Not verified',
    },
    {
      label: 'Active bank account:',
      data: confirmOrDeny(Boolean(hasActiveBankAccount)),
    },
    {
      label: 'Stripe payouts possible:',
      data: confirmOrDeny(Boolean(isStripePayoutsEnabled)),
    },
    {
      label: 'Seen at:',
      data: new Date(seenAt ?? '').toDateString(),
    },
    {
      label: 'Created at:',
      data: new Date(createdAt).toDateString(),
    },
    {
      label: 'Removed at:',
      data: removedAt ? new Date(removedAt).toDateString() : '-',
    },
    {
      label: 'Suspended:',
      data: confirmOrDeny(isSuspended),
    },
  ]

  return (
    <Wrapper gap="4x">
      {isUserProfessional ? <CompanyHeader user={user} /> : <UserHeader user={user} />}
      <div>
        <LoginAsUserButton mainPlatform={mainPlatform} uid={user.uid} />
      </div>
      <DataColumn>
        {userDataSection.map(({ label, data }) => (
          <DataRow key={label}>
            <Label size="sm">{label}</Label>
            <Data size="sm">{data}</Data>
          </DataRow>
        ))}
      </DataColumn>
    </Wrapper>
  )
}
