import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { TextField, Select, Stack } from '@qasa/qds-ui'
import upperFirst from 'lodash/upperFirst'

import { ContractRentalTypeEnum } from '../../graphql/__generated__/graphql'
import { splitSnakeCase } from '../../utils/strings'

import { getContractNames } from './user-contract-meta.utils'
import { VISIBLE_CONTRACT_STATUSES, VISIBLE_BOOKING_STATUSES } from './constants'
import { type KanyeUserContractsType } from './contracts/contracts.gql'

const InputContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  [theme.mediaQueries.lgUp]: {
    width: '50%',
  },
}))
const SearchInput = TextField
const ContractStatusSelect = Select

const STATUS_OPTIONS = {
  [ContractRentalTypeEnum.LongTerm]: ['all', ...VISIBLE_CONTRACT_STATUSES],
  [ContractRentalTypeEnum.Vacation]: ['all', ...VISIBLE_BOOKING_STATUSES],
}

type SearchContractsProps = {
  setSearchResultContracts: (results: KanyeUserContractsType) => void
  contracts: KanyeUserContractsType
  setHasResults: React.Dispatch<React.SetStateAction<boolean>>
  rentalType: ContractRentalTypeEnum
  handleSearchInUse?: (value: string) => void
}
export function SearchContracts({
  setSearchResultContracts,
  contracts,
  setHasResults,
  rentalType,
  handleSearchInUse,
}: SearchContractsProps) {
  const [contractStatus, setContractStatus] = useState('all')
  const [searchQuery, setSearchQuery] = useState('')

  const getContractsByStatus = () => {
    if (contractStatus === 'all') {
      return [...contracts]
    }

    const filteredContracts = [...contracts].filter((contract) => {
      return rentalType === ContractRentalTypeEnum.LongTerm
        ? contract.status.includes(contractStatus)
        : contract.booking?.status.includes(contractStatus)
    })

    return filteredContracts
  }

  const handleSearch = (value: string) => {
    const allResults: KanyeUserContractsType = []
    value.trim()

    const contractsByStatus = getContractsByStatus()

    contractsByStatus.forEach((contract) => {
      const location = contract.home?.location
      const { route = '', streetNumber = '', postalCode = '', locality = '' } = location || {}
      const address = `${route} ${streetNumber}, ${postalCode} ${locality}`
      const { landlordName, tenantName } = getContractNames({ contract })
      const dataToQuery = [
        contract.landlord?.private.email,
        landlordName,
        tenantName,
        contract.tenant?.private.email,
        contract.id,
        address,
      ]

      const dataToQueryIncludesValue = dataToQuery.filter((dataToQuery) =>
        dataToQuery?.toLowerCase().includes(value),
      )

      if (dataToQueryIncludesValue.length > 0) {
        allResults.push(contract)
      }
    })

    if (!allResults.length) {
      setHasResults(false)
    }

    setSearchResultContracts(allResults)
  }

  useEffect(() => {
    setHasResults(true)

    if (searchQuery) {
      handleSearch(searchQuery)
    } else {
      const contractsByStatus = getContractsByStatus()

      if (!contractsByStatus.length) {
        setHasResults(false)
      }

      setSearchResultContracts(contractsByStatus)
    }
  }, [contractStatus, searchQuery])

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase())
    handleSearchInUse?.(e.target.value)
  }

  return (
    <InputContainer gap="1x" direction="row">
      <SearchInput
        label="Search for a contract"
        helperText="Search for email, name, address, or contract ID"
        placeholder="🔍"
        onChange={handleOnChange}
      />
      <ContractStatusSelect label="Contract status" onChange={(e) => setContractStatus(e.target.value)}>
        {STATUS_OPTIONS[rentalType].map((value, i) => {
          const optionText = upperFirst(splitSnakeCase(value))
          return (
            <ContractStatusSelect.Option key={i} value={value}>
              {optionText}
            </ContractStatusSelect.Option>
          )
        })}
      </ContractStatusSelect>
    </InputContainer>
  )
}
