import { useMemo } from 'react'
import groupBy from 'lodash/groupBy'
import styled from '@emotion/styled'
import { Stack, Button, LoadingDots, Paragraph } from '@qasa/qds-ui'
import { useQuery } from '@apollo/client'

import { useSyncInvoiceBatch, useDeleteInvoiceBatch } from '../../queries'
import { TableBody } from '../../components/table/table-body'
import { InvoiceTypeEnum } from '../../graphql/__generated__/graphql'

import { Invoice } from './invoice'
import { BATCHES } from './batches.gql'

const StyledTableHeader = styled.th(({ theme }) => ({
  padding: `${theme.spacing['1x']} 0`,
  textAlign: 'left',
  fontWeight: '400',
  whiteSpace: 'nowrap',
}))

const sortingArray = [
  InvoiceTypeEnum.InvoiceDeposit, // Deposit invoice: Tenant to pay us
  InvoiceTypeEnum.InvoiceRent, // Rental invoice: Tenant to pay us
  InvoiceTypeEnum.SelfinvoiceDeposit, // Refund of deposit: We are to pay tenant
  InvoiceTypeEnum.SelfinvoiceRent, // Payment of rent: We are to pay landlord
  InvoiceTypeEnum.VerificationFee, // Qasa fee: Payment to us
  InvoiceTypeEnum.SelfinvoiceDepositCompensation, // Compensation invoice: From us to landlord when the deposit is to be split
  InvoiceTypeEnum.InvoiceDepositCompensation, // Compensation invoice: Tenant to pay us when deposit free and deposit is to be split
]

type BatchesProps = {
  contractId: string
}
export function Batches({ contractId }: BatchesProps) {
  const { data, loading: isLoading, error } = useQuery(BATCHES, { variables: { contractId } })
  const { mutate: syncInvoiceBatch, isLoading: isSyncInvoiceBatchLoading } = useSyncInvoiceBatch()
  const { mutate: deleteInvoiceBatch, isLoading: isDeleteInvoiceBatchLoading } = useDeleteInvoiceBatch()
  const invoices = data?.contract.invoices
  const batches = groupBy(invoices, (i) => Math.floor(i.batch))

  const sortedBatches = useMemo(
    () =>
      Object.values(batches).map((batch) =>
        batch.sort((a, b) => sortingArray.indexOf(a.type) - sortingArray.indexOf(b.type)),
      ),
    [invoices],
  )

  const handleSyncBatch = (batch: number, contractId: string) => {
    const confirm = window.confirm('Are you sure you want to sync this batch?')
    if (confirm) {
      syncInvoiceBatch({ batch, contractId })
    }
  }

  const handleDestroyBatch = (batch: number, contractId: string) => {
    const confirm = window.confirm('Are you sure you want to delete this invoice batch?')
    if (confirm) {
      deleteInvoiceBatch({ batch, contractId })
    }
  }

  if (isLoading) {
    return <LoadingDots />
  }

  if (error) {
    return <Paragraph>{error.message}</Paragraph>
  }

  return (
    <>
      {sortedBatches.map((batch) => (
        <TableBody key={batch[0].id}>
          <tr>
            <StyledTableHeader colSpan={10}>{`Batch #${batch[0].batch}`}</StyledTableHeader>
          </tr>
          {batch.map((invoice) => (
            <Invoice key={invoice.id} invoice={invoice} />
          ))}
          <tr>
            <StyledTableHeader colSpan={10}>
              <Stack gap="4x" direction="row" justifyContent="flex-end">
                <Button
                  size="xs"
                  variant="tertiary"
                  onClick={() => handleSyncBatch(batch[0].batch, contractId)}
                  isLoading={isSyncInvoiceBatchLoading}
                >
                  {'Sync batch'}
                </Button>
                <Button
                  size="xs"
                  variant="tertiary"
                  onClick={() => handleDestroyBatch(batch[0].batch, contractId)}
                  isLoading={isDeleteInvoiceBatchLoading}
                >
                  {'Destroy batch'}
                </Button>
              </Stack>
            </StyledTableHeader>
          </tr>
        </TableBody>
      ))}
    </>
  )
}
