import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Link as RouterLink } from 'react-router-dom'
import {
  Button,
  Heading,
  Link,
  LoadingDots,
  Paragraph,
  Stack,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@qasa/qds-ui'

import { SearchBar } from '../../../components/search-bar'
import { QasaLink } from '../../../components/qasa-link'
import { Table2 } from '../../../components/table/table2'
import { getFormattedHomeAddress } from '../utils'
import { usePublishHome } from '../../../queries'
import { Api } from '../../../api'
import { displayApiErrors } from '../../../utils'
import type { HomesInReviewTabProps } from '../homes-in-review'

import type { Home } from './in-review/home-type'

const DeniedHomesWrapper = styled(Stack)({
  width: '100vw',
})

export function DeniedHomes({ highlightHome, selectedRows, setSelectedRows, resetSelections }: HomesInReviewTabProps) {
  const [pageTracker, setPageTracker] = useState({ currentPage: 1, totalPages: 1 })
  const [count, setCount] = useState(0)
  const [email, setEmail] = useState('')
  const [selectedHomes, setSelectedHomes] = useState<Home[]>([])
  const [homes, setHomes] = useState<Home[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [loadBtnPressed, setLoadBtnPressed] = useState<null | 'less' | 'more'>(null)
  const { mutate: publishHome, isLoading: isPublishHomeLoading } = usePublishHome()

  const getDeniedHomes = (page: number, email: string) => {
    setIsLoading(true)
    Api.homesGetDenied(page, email)
      .then((res) => {
        setCount(res.data.count)
        setHomes(res.data.adminReviewHomes)
        setPageTracker({ currentPage: res.data.currentPage, totalPages: res.data.totalPages })
      })
      .catch(displayApiErrors)
      .finally(() => {
        setIsLoading(false)
        setLoadBtnPressed(null)
      })
  }

  useEffect(() => {
    if (homes.length === 0) {
      getDeniedHomes(pageTracker.currentPage, email)
    }
  }, [])

  const confirmAction = () => {
    return window.confirm('Are you sure? ' + selectedHomes.length + ' homes will be affected')
  }

  const loadHomes = (newPage: number) => getDeniedHomes(newPage, email)

  const handlePublishHome = () => {
    if (selectedHomes.length < 2 || confirmAction()) {
      const homeIds = selectedHomes.map((home: Home) => home.id)
      homeIds.forEach((homeId) => {
        publishHome(homeId, {
          onSuccess: () => {
            loadHomes(pageTracker.currentPage)
            resetSelections()
          },
        })
      })
    }
  }

  const onSelectionChanged = (selection: number[]) => {
    setSelectedHomes(selection.map((indexOfSelectedHome: number) => homes[indexOfSelectedHome]))
    const highlightedHome = selection.length === 1 ? homes[selection[0]] : null
    highlightHome(highlightedHome)
  }

  return (
    <DeniedHomesWrapper gap="2x">
      <SearchBar
        submit={() => loadHomes(1)}
        onChange={(email) => setEmail(email)}
        placeholder="example@email.com"
        value={email}
      />
      <Stack direction="row" justifyContent="flex-end">
        <Button
          variant="tertiary"
          disabled={selectedHomes.length === 0}
          onClick={handlePublishHome}
          isLoading={isPublishHomeLoading}
        >
          Publish
        </Button>
      </Stack>
      {isLoading && <LoadingDots />}
      {homes && (
        <>
          <Heading size="3xs">
            Denied homes: <Paragraph as="span">{count}</Paragraph>
          </Heading>
          <Table2
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            enableRowSelection
            onSelectionChanged={onSelectionChanged}
            rows={homes}
            getRowKey={(home) => home.id}
            columnsConfig={[
              {
                title: 'Rental type ',
                render: (home: Home) => home.rentalType,
              },
              {
                title: 'Name',
                render: (home: Home) => (
                  <Link isExternal as={RouterLink} to={`/user?user=${encodeURIComponent(home.email)}`}>
                    {home.professional ? '🏢 ' + home.companyName : home.fullName}
                  </Link>
                ),
              },
              {
                title: 'E-mail',
                render: (home: Home) => home.email,
                getCellClassName: (home: Home) => (Boolean(home.emailConfirmedAt) ? 'Success' : 'Danger'),
              },
              {
                title: 'Bank ID',
                render: (home: Home) =>
                  home.identityConfirmedAt ? `Verified at: ${home.identityConfirmedAt}` : 'Not verified',
                getCellClassName: (home: Home) => (home.identityConfirmedAt ? 'Success' : 'Danger'),
              },
              {
                title: 'Address',
                render: getFormattedHomeAddress,
              },
              {
                title: 'Home',
                render: (home: Home) => (
                  <QasaLink to={`/home/${home.id}`}>
                    ${home.rent} | {home.squareMeters} m2 | {home.roomCount}
                  </QasaLink>
                ),
              },
              {
                title: 'Deny reason',
                render: (home: Home) => home.archiveReason,
              },
            ]}
          />
        </>
      )}
      <Stack direction="row" gap="3x" style={{ visibility: homes.length === 0 ? 'hidden' : 'visible' }}>
        <Button
          isDisabled={pageTracker.currentPage === 1 || isLoading}
          variant="tertiary"
          size="sm"
          onClick={() => {
            loadHomes(pageTracker.currentPage - 1)
            setLoadBtnPressed('less')
          }}
          iconLeft={ChevronLeftIcon}
          isLoading={isLoading && loadBtnPressed === 'less'}
        >
          {`${pageTracker.currentPage === 1 ? 1 : pageTracker.currentPage - 1}`}
        </Button>
        <Button
          isDisabled={pageTracker.currentPage === pageTracker.totalPages || isLoading}
          variant="tertiary"
          size="sm"
          onClick={() => {
            loadHomes(pageTracker.currentPage + 1)
            setLoadBtnPressed('more')
          }}
          iconRight={ChevronRightIcon}
          isLoading={isLoading && loadBtnPressed === 'more'}
        >
          {`${pageTracker.currentPage === pageTracker.totalPages ? pageTracker.totalPages : pageTracker.currentPage + 1}`}
        </Button>
      </Stack>
    </DeniedHomesWrapper>
  )
}
