import { Button } from '@qasa/qds-ui'
import { useState } from 'react'
import styled from '@emotion/styled'
import { useMutation } from '@apollo/client'

import { Dialog } from '../../../components/_core/dialog'
import type { CreateExternalContractTakeoverInput } from '../../../graphql/__generated__/graphql'
import { FranceRecoverableChargesPaymentPlanEnum } from '../../../graphql/__generated__/graphql'
import { notifyFailure, notifySuccess } from '../../../utils'
import type { KanyeUser } from '../user-context'

import { CREATE_TAKEOVER_LEASE } from './create-takeover-lease.gql'
import { LeaseTakeoverForm } from './lease-takeover-form'

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

export const FORM_ID = 'takeover-form'

const DEFAULT_VALUES = {
  landlordUid: '',
  contract: {
    furniture: false,
    recoverableCharges: {
      monthlyCost: null,
      paymentPlan: FranceRecoverableChargesPaymentPlanEnum.Included,
    },
    scriveId: '',
  },
  duration: {
    shortenedEndDate: '',
    startDate: '',
  },
  location: {
    locality: '',
    postalCode: '',
    route: '',
    streetNumber: '',
  },
  tenant: {
    email: '',
    familyName: '',
    firstName: '',
    phoneNumber: null,
  },
  milaId: '',
} satisfies CreateExternalContractTakeoverInput

type Props = {
  user: KanyeUser
}

export function LeaseTakeoverDialog({ user }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const [createTakeOverLease, { loading: isLoading, error }] = useMutation(CREATE_TAKEOVER_LEASE)

  const handleSubmit = async (formValues: CreateExternalContractTakeoverInput) => {
    try {
      const { data, errors } = await createTakeOverLease({
        variables: {
          input: formValues,
        },
      })

      const validationMessage =
        data?.createExternalContractTakeover?.errors?.[0].codesWithMessage?.[0].message
      const errorMessage = errors?.[0].message ?? validationMessage
      if (data?.createExternalContractTakeover?.contract?.id) {
        notifySuccess('Successfully created contract takeover')
        setIsOpen(false)
      } else {
        notifyFailure(errorMessage ?? 'Unexepected error')
      }
    } catch (e) {
      notifyFailure('Unexepected error:' + e)
    }
  }

  if (error) {
    notifyFailure(error.message)
  }

  const defaultValues = {
    ...DEFAULT_VALUES,
    landlordUid: user.uid,
  }

  return (
    <Dialog isOpen={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger>
        <Button variant="tertiary">{'Create lease take-over'}</Button>
      </Dialog.Trigger>
      <Dialog.Content size="large">
        <Dialog.Header>
          <Dialog.Title>{'Lease take-over'}</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <LeaseTakeoverForm defaultValues={defaultValues} onSubmit={handleSubmit} />
        </Dialog.Body>
        <Dialog.Footer>
          <ButtonWrapper>
            <Button isLoading={isLoading} type="submit" form={FORM_ID} variant="primary">
              {'Save'}
            </Button>
          </ButtonWrapper>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}
