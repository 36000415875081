import { type KanyeUserContractType } from '../../contracts/contracts.gql'

export const omocomReservationAmount = 2500

type GetReservationsToCreate = {
  landlordCompensationAmount: number
  contract: KanyeUserContractType
  shouldReserveForOmocom: boolean
  shouldPayBackTenant: boolean
  balance: number
}

export const getReservationsToCreate = ({
  landlordCompensationAmount,
  contract,
  shouldReserveForOmocom,
  shouldPayBackTenant,
  balance,
}: GetReservationsToCreate) => {
  const compensationReservations = []
  if (landlordCompensationAmount > 0) {
    compensationReservations.push({
      amount: landlordCompensationAmount,
      payoutToUid: contract.landlord.uid,
    })
  }
  if (shouldReserveForOmocom) {
    compensationReservations.push({
      amount: omocomReservationAmount,
    })
  }

  if (shouldPayBackTenant) {
    compensationReservations.push({
      amount: balance,
      payoutToUid: contract.tenant?.uid,
    })
  }

  return compensationReservations
}
