import { Button, Stack, RadioGroup } from '@qasa/qds-ui'
import { useState } from 'react'
import { useMutation } from '@apollo/client'

import { Dialog } from '../../../components/_core/dialog'
import { CREATE_ADMIN_NOTE } from '../../../components/notes/notes.gql'
import { reactivateUser } from '../user-utils'
import { notifyFailure } from '../../../utils'
import { KANYE_USER_QUERY } from '../kanye-user.gql'

const levelOptions = {
  reactivate: 'Reactivate only',
  reactivateAndSuspend: 'Reactivate & unsuspend',
}

export function ReactivateUserAccountDialog({ uid }: { uid: string }) {
  const [isReactivateDialogOpen, setIsReactivateDialogOpen] = useState(false)
  const [selectedLevel, setSelectedLevel] = useState(levelOptions.reactivate)

  const [createUserSuspensionNote] = useMutation(CREATE_ADMIN_NOTE, {
    onCompleted: () => setIsReactivateDialogOpen(false),
    onError: () => notifyFailure('Failed to save user account access note'),
    refetchQueries: [{ query: KANYE_USER_QUERY, variables: { uid } }],
  })

  const onReactivate = () => {
    const suspended = selectedLevel === levelOptions.reactivateAndSuspend ? false : null
    reactivateUser(uid, suspended)

    let note = 'Account reactivated'

    // note: passing false to reactivateUser === reactivate & unsuspend
    if (suspended === false) {
      note += ' & unsuspended'
    }

    // TODO: uncomment when GQL user object is in place & can be refetched after suspension status change
    // note: passing null to reactivateUser === reactivate only
    // else if (suspended === null && isSuspended) {
    //   note += ' - user is still suspended'
    // }

    createUserSuspensionNote({
      variables: {
        input: {
          uid,
          note,
        },
      },
    })
  }

  const onLevelChange = (level: string) => setSelectedLevel(level)

  return (
    <Dialog isOpen={isReactivateDialogOpen} onOpenChange={setIsReactivateDialogOpen}>
      <Dialog.Trigger>
        <Button variant="tertiary" onClick={() => setIsReactivateDialogOpen(true)}>
          {'Reactivate'}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>{'Reactivate user account'}</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <Stack gap="8x">
            <RadioGroup
              defaultValue={selectedLevel}
              name="reactivate_user_radio_group"
              onValueChange={onLevelChange}
            >
              <Stack gap="2x">
                <RadioGroup.Card label={levelOptions.reactivate} value={levelOptions.reactivate} />
                <RadioGroup.Card
                  label={levelOptions.reactivateAndSuspend}
                  value={levelOptions.reactivateAndSuspend}
                />
              </Stack>
            </RadioGroup>
          </Stack>
        </Dialog.Body>
        <Dialog.Footer>
          <Button variant="secondary" isFullWidth onClick={onReactivate}>
            {'Done'}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}
