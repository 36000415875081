import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Link as RouterLink } from 'react-router-dom'
import { Heading, Paragraph, Stack, Button, LoadingDots, Link } from '@qasa/qds-ui'

import { config } from '../../../../config'
import { getFormattedHomeAddress } from '../../utils'
import { usePublishHome, useDenyHome } from '../../../../queries'
import { QasaLink } from '../../../../components/qasa-link'
import { Table2 } from '../../../../components/table/table2'
import { Api } from '../../../../api'
import { displayApiErrors } from '../../../../utils'
import { useGetCurrentUser } from '../../../../hooks'
import type { HomesInReviewTabProps } from '../../homes-in-review'

import { AD_REVIEW_DECISION_OPTIONS } from './in-review.data'
import { AdReviewDecisionDialog } from './ad-review-decision-dialog'
import { RiskScore } from './risk-score'
import type { Home } from './home-type'

const InReviewWrapper = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing['8x'],
  width: '100vw',
}))

export function InReview({ highlightHome, selectedRows, setSelectedRows, resetSelections }: HomesInReviewTabProps) {
  const [homes, setHomes] = useState<Home[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedHomes, setSelectedHomes] = useState<Home[]>([])

  const getInReviewHomes = () => {
    Api.homesGetInReview()
      .then((res) => setHomes(res.data.adminReviewHomes))
      .catch(displayApiErrors)
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (homes.length === 0) {
      getInReviewHomes()
    }
  }, [])

  const adminUid = useGetCurrentUser()?.uid
  const [adReviewDecisionState, setAdReviewDecisionState] = useState<any>({})
  const [adReviewDecisionSelectedOptions, setAdReviewDecisionSelectedOptions] = useState([])
  const isDialogShowing = Boolean(adReviewDecisionState.options)
  const { mutate: publishHome } = usePublishHome()
  const { mutate: denyHome } = useDenyHome()

  const confirmAction = () =>
    window.confirm('Are you sure? ' + selectedHomes.length + ' homes will be affected')

  const onOpenDialog = (state: any) => setAdReviewDecisionState(state)

  const onCloseDialog = () => {
    setAdReviewDecisionState({})
    setAdReviewDecisionSelectedOptions([])
  }

  const handlePublishHome = () => {
    if (selectedHomes.length < 2 || confirmAction()) {
      const homeIds = selectedHomes.map((home) => home.id)
      homeIds.forEach((homeId) => {
        publishHome(homeId, {
          onSuccess: () => {
            getInReviewHomes()
            resetSelections()
          },
        })
      })
    }
  }

  const onEditHome = () => {
    if (selectedHomes.length < 2 || confirmAction()) {
      selectedHomes.forEach((home) => {
        const uid = home.uid
        const homeId = home.id
        const hostUrl = config[home.mainPlatform]
        const url = `${hostUrl}/admin-login-as-user?uid=${uid}&pathname=/edit-listing/${homeId}/`

        const win = window.open(url, '_blank')
        win?.focus()
      })
    }
  }

  const onSelectionChanged = (selection: number[]) => {
    setSelectedHomes(selection.map((indexOfSelectedHome: number) => homes[indexOfSelectedHome]))
    const highlightedHome = selection.length === 1 ? homes[selection[0]] : null
    highlightHome(highlightedHome)
  }

  const handleDenyAction = ({ archiveReason, denyReasons }: { archiveReason: any; denyReasons: any }) => {
    if (confirmAction()) {
      selectedHomes.forEach((home) => {
        denyHome(
          { id: home.id, archiveReason, denyReasons },
          {
            onSuccess: async () => {
              getInReviewHomes()
              resetSelections()
            },
          },
        )
      })
    }
  }

  const actionOptions = [
    {
      label: 'Edit home',
      action: () =>
        onOpenDialog({
          ...AD_REVIEW_DECISION_OPTIONS.editHome,
          nextAction: onEditHome,
        }),
      singleItem: true,
    },
    {
      label: 'Publish',
      action: () =>
        onOpenDialog({
          ...AD_REVIEW_DECISION_OPTIONS.publish,
          nextAction: handlePublishHome,
          isSingleChoice: true,
        }),
    },
    {
      label: 'Deny',
      action: () =>
        onOpenDialog({
          ...AD_REVIEW_DECISION_OPTIONS.deny,
          nextAction: ({ selectedOptions }: { selectedOptions: any }) =>
            handleDenyAction({
              archiveReason: 'review_denied',
              denyReasons: selectedOptions,
            }),
        }),
    },

    {
      label: 'Deny & Verify',
      action: () =>
        handleDenyAction({
          archiveReason: 'needs_verification',
          denyReasons: null,
        }),
    },
    {
      label: 'Deny & Remove user',
      action: () =>
        onOpenDialog({
          ...AD_REVIEW_DECISION_OPTIONS.denyAndRemoveUser,
          nextAction: ({ selectedOptions }: { selectedOptions: any }) =>
            handleDenyAction({
              archiveReason: 'suspected_scam',
              denyReasons: selectedOptions,
            }),
        }),
      singleItem: true,
    },
  ]

  return (
    <InReviewWrapper gap="2x">
      {isLoading ? (
        <LoadingDots />
      ) : (
        <>
          <Stack gap="2x" direction="row" alignItems="center" justifyContent="space-between">
            <Heading size="2xs">{`Homes in review: ${homes.length}`}</Heading>
            <Stack gap="2x" direction="row">
              {actionOptions.map(({ action, label, singleItem }) => (
                <Button
                  size="sm"
                  variant="tertiary"
                  key={label}
                  disabled={selectedHomes.length === 0 || (singleItem && selectedHomes.length > 1)}
                  onClick={action}
                >
                  {label}
                </Button>
              ))}
            </Stack>
            {isDialogShowing && (
              <AdReviewDecisionDialog
                adReviewDecisionState={adReviewDecisionState}
                setSelectedOptions={setAdReviewDecisionSelectedOptions}
                selectedOptions={adReviewDecisionSelectedOptions}
                onClose={onCloseDialog}
                selectedHomes={selectedHomes}
                adminUid={adminUid}
              />
            )}
          </Stack>

          <>
            {homes ? (
              <Table2
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                enableRowSelection
                onSelectionChanged={onSelectionChanged}
                rows={homes}
                getRowKey={(home) => home.id}
                columnsConfig={[
                  {
                    title: 'Rental type',
                    render: (home: Home) => home.rentalType,
                  },
                  {
                    title: 'Risk score',
                    render: (home: Home) => <RiskScore riskScoreData={home.adReviewModelOutput} />,
                  },

                  {
                    title: 'Main Platform',
                    render: (home: Home) => <Paragraph as="span"> {home.mainPlatform}</Paragraph>,
                  },

                  {
                    title: 'Name',
                    render: (home: Home) => (
                      <Link isExternal as={RouterLink} to={`/user?user=${encodeURIComponent(home.email)}`}>
                        {home.professional ? '🏢 ' + home.companyName : home.fullName}
                      </Link>
                    ),
                  },
                  {
                    title: 'E-mail',
                    render: (home: Home) => home.email,
                    getCellClassName: (home: Home) => (Boolean(home.emailConfirmedAt) ? 'Success' : 'Danger'),
                  },
                  {
                    title: 'Bank ID',
                    render: (home: Home) =>
                      home.identityConfirmedAt ? `Verified at: ${home.identityConfirmedAt}` : 'Not verified',
                    getCellClassName: (home: Home) => (home.identityConfirmedAt ? 'Success' : 'Danger'),
                  },
                  {
                    title: 'Address',
                    render: getFormattedHomeAddress,
                  },
                  {
                    title: 'Home',
                    render: (home: Home) => (
                      <QasaLink to={`/home/${home.id}`}>
                        ${home.rent} | {home.squareMeters} m2 | {home.roomCount}
                      </QasaLink>
                    ),
                  },
                ]}
              />
            ) : (
              <Paragraph>There are no homes to review right now 🤷‍♀️</Paragraph>
            )}
          </>
        </>
      )}
    </InReviewWrapper>
  )
}
