import { useState } from 'react'
import { Stack, Button, Select, Link } from '@qasa/qds-ui'

type DocumentRequestSelectorProps = {
  documentRequests:
    | {
        __typename?: 'FranceDocumentRequestType' | undefined
        id: string
        documents: {
          __typename?: 'Blob' | undefined
          id: string
          filename: string
          url?: string | null | undefined
        }[]
      }[]
    | null
    | undefined
}

export function DocumentRequestSelector({ documentRequests }: DocumentRequestSelectorProps) {
  const [selectedDocument, setSelectedDocument] = useState('')

  if (!documentRequests || documentRequests?.length === 0) {
    return null
  }

  return (
    <Stack gap="2x">
      <Select
        label="Uploaded documents"
        placeholder="Choose document"
        onChange={(e) => setSelectedDocument(e.target.value)}
      >
        {documentRequests?.map(({ documents }) => {
          return documents.map(({ id, filename, url }) => {
            if (url) {
              return (
                <Select.Option value={url} key={id}>
                  {filename}
                </Select.Option>
              )
            }

            return null
          })
        })}
      </Select>
      <Button as={Link} href={selectedDocument} target="_blank" variant="tertiary">
        {'View document'}
      </Button>
    </Stack>
  )
}
