/* eslint-disable no-console */
import { ApolloClient, InMemoryCache, createHttpLink, from, ApolloLink } from '@apollo/client'
import type { OperationDefinitionNode } from 'graphql'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import Cookies from 'js-cookie'

const authLink = setContext((_, { headers }) => {
  const accessToken = Cookies.get('Access-Token')
  return {
    headers: {
      ...headers,
      ...(accessToken
        ? {
            'Access-Token': accessToken,
          }
        : {}),
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation, response }) => {
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`, { operation, response })
  }

  // For some reason `graphQlErrors` can also be an empty string.
  // So having this check is important not to crash the app
  // due to `forEach` being called on an empty string.
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      // Currently all auth errors report `unauthorized`.
      // We can't differentiate between auth errors such as
      // invalid token, expired token, etc. So we just assume that
      // all errors are critical and remove the token.
      //
      // In the future we'd at least want to differentiate between
      // authentication vs authorization errors.
      if (['You are not authorized to access the admin platform', 'unauthorized'].includes(message)) {
        Cookies.remove('Access-Token')
      }

      // eslint-disable-next-line no-console
      console.log(`[GraphQL error]: ${message}`, { operation, response })
    })
  }
})

const httpLink = createHttpLink({
  uri: new URL('graphql', process.env.REACT_APP_API_URL).href,
  credentials: 'include',
})

// Enables automatic retries for network or server errors
const retryLink = new RetryLink()

const responseLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const operationName = operation.operationName
    const operationResponse = response?.data && Object.values(response?.data)[0]

    const responseTypename = operationResponse?.__typename

    const ErrorUnionTypenames = ['AuthorizationError', 'NotFoundError', 'ValidationError']
    const isUnionTypedError =
      typeof responseTypename === 'string' && ErrorUnionTypenames.includes(responseTypename)

    const definitionsArray = operation.query.definitions as OperationDefinitionNode[]
    const operationType = definitionsArray.find((el) => el.kind === 'OperationDefinition')?.operation

    if (isUnionTypedError) {
      console.log(
        `%cError:\n${operationName} ${operationType}`,
        'color:white; background:red; font-size: 1em',
      )
      console.log(
        `%cVariables used in ${operationType}: `,
        'color:lime; background:black; font-size: 1em',
        operation.variables,
      )
    }
    return response
  })
})

export const apolloClient = new ApolloClient({
  link: from([
    authLink,
    errorLink,
    retryLink,
    responseLink,
    // httpLink has to be last since it is the terminating link - sends each GraphQL operation to the server
    // See https://www.apollographql.com/docs/react/api/link/introduction/#the-terminating-link
    httpLink,
  ]),
  cache: new InMemoryCache({
    addTypename: true,
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})
