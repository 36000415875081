export function openPdfInNewTab({ pdfAsBytes }) {
  var blob = new Blob([pdfAsBytes], { type: 'application/pdf' })
  const href = URL.createObjectURL(blob)

  // open the document in a new tab - the user can then double check, save, and flatten the doc themselves
  window.open(href)

  /*

    we will use this section later on to auto download the file, initially it will just open in a separate tab for inspection

    let alink = document.createElement('a')

    alink.href = href

    // title of the downloaded that is shown to the user
    alink.download = pdfTemplateName

    // downloads the document automatically
    alink.click()

    */
}
