import { Button, Stack, TextField } from '@qasa/qds-ui'
import { type ChangeEvent, useState } from 'react'
import { useMutation } from '@apollo/client'

import { notifyFailure, notifySuccess } from '../../../utils/notifications'
import type { KanyeUserContractType } from '../contracts/contracts.gql'

import { UPDATE_WATER_FEE_AND_NUMBER_OF_TENANTS } from './update-water-fee-and-number-of-tenants.gql'

type UpdateContractWaterFeeProps = {
  contract: KanyeUserContractType
}
export function UpdateContractWaterFeeSection({ contract }: UpdateContractWaterFeeProps) {
  const [waterFee, setWaterFee] = useState('')

  const [updateWaterFee, { loading: isUpdatingWaterFee }] = useMutation(
    UPDATE_WATER_FEE_AND_NUMBER_OF_TENANTS,
    {
      variables: {
        id: contract.id,
        input: {
          numberOfTenants: contract.numberOfTenants || 1,
          waterFeePerTenant: Number(waterFee),
        },
      },
      refetchQueries: ['Contracts'],
      onCompleted: (data) => {
        const result = data.updateContractWaterFee
        if (!result?.errors) {
          notifySuccess(
            `water fee: ${result?.contract?.tenantFees.waterFeePerTenant.cost.total}€
          🚨 Destroy and renew any unsent invoices with the new water fee! 🚨`,
          )
          setWaterFee('')
        } else {
          notifyFailure(`Water fee on contract #${contract.id} could not be updated`)
        }
      },
      onError: () => notifyFailure(`Water fee on contract #${contract.id} could not be updated`),
    },
  )

  return (
    <Stack gap="4x">
      <Stack gap="3x">
        <TextField
          label="Update water fee per tenant"
          type="number"
          placeholder="Water fee per tenant"
          value={waterFee}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setWaterFee(e.target.value)}
        />
        <Button
          variant="tertiary"
          disabled={!Boolean(waterFee)}
          isLoading={isUpdatingWaterFee}
          onClick={() => updateWaterFee()}
        >
          Update
        </Button>
      </Stack>
    </Stack>
  )
}
