import { Button, Stack } from '@qasa/qds-ui'

import { PlatformEnum } from '../../graphql/__generated__/graphql'
import { config } from '../../config'
import { useAuthContext } from '../../context/auth-context'

const platformAdminPaths = {
  [PlatformEnum.Qasa]: '/admin-login-as-user',
  [PlatformEnum.Blocket]: '/admin-login-as-user',
  [PlatformEnum.QasaFinland]: '/admin-login-as-user',
  [PlatformEnum.Finn]: '/admin',
  [PlatformEnum.QasaFrance]: '/admin-login-as-user',
  [PlatformEnum.Homeq]: '',
  [PlatformEnum.Dotcom]: '/admin-login-as-user',
}

type LoginAsUserButtonProps = {
  mainPlatform: PlatformEnum
  uid: string
}
export function LoginAsUserButton({ mainPlatform, uid }: LoginAsUserButtonProps) {
  const { currentEmployee } = useAuthContext()
  const host = config[mainPlatform] || config.host
  const path = platformAdminPaths[mainPlatform] || platformAdminPaths.qasa
  const params = `?uid=${encodeURIComponent(uid)}${currentEmployee ? `&isEmployee=${encodeURIComponent(true)}` : ''}`

  const loginAsUserUrl = `${host}${path}${params}`

  const buttonText = currentEmployee ? 'Employee login as user' : 'User login as user'

  return (
    <Stack gap="3x">
      <Button variant="primary" as="a" href={loginAsUserUrl} target="_blank" rel="noopener noreferrer">
        {buttonText}
      </Button>
    </Stack>
  )
}
