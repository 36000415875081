import React from 'react'
import styled from '@emotion/styled'
import { Stack, Paragraph } from '@qasa/qds-ui'

import { VisuallyHidden } from '../visually-hidden'

import { useRadio } from './use-radio'

const InputCardWrapper = styled.label<{ isChecked: boolean }>(({ theme, isChecked }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 16,
  paddingRight: 16,
  minHeight: 56,
  paddingTop: 15,
  paddingBottom: 15,
  background: theme.colors.bg.default,
  border: '1px solid',
  borderColor: isChecked ? theme.colors.core.black : theme.colors.border.default,
  borderRadius: theme.radii.md,
  cursor: 'pointer',
  transitionProperty: 'border-color, box-shadow, background-color',
  transitionDuration: '120ms',
  transitionTimingFunction: 'ease-in-out',

  ':hover': {
    background: theme.colors.core.gray10,
  },
  ':active': {
    background: theme.colors.core.gray20,
  },
  boxShadow: isChecked ? `inset 0 0 0 1px ${theme.colors.core.black}` : 'none',
}))

const RadioIndicator = styled.div<{ isChecked: boolean; size?: string }>(({ theme, isChecked, size }) => {
  const checkedBorderWidth = size === 'small' ? 5 : 7
  return {
    flexShrink: 0,
    background: 'white',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.colors.core.gray80,
    borderRadius: '50%',
    height: 20,
    width: 20,
    transitionProperty: 'border, outline-offset',
    transitionDuration: '150ms',
    transitionTimingFunction: 'ease-in-out',
    ...(isChecked && {
      borderWidth: checkedBorderWidth,
      borderColor: theme.colors.core.black,
    }),
    outlineWidth: 2,
  }
})

type RadioItemProps = {
  label: string
  value: string
  onClick?: () => void
}
export function RadioItem({ label, onClick, ...rest }: RadioItemProps) {
  const { getInputProps, isChecked } = useRadio({ ...rest })

  return (
    <InputCardWrapper {...{ isChecked, onClick }}>
      <VisuallyHidden>
        <input {...getInputProps()} />
      </VisuallyHidden>
      <Stack gap="3x" direction="row">
        <RadioIndicator {...{ isChecked }} />
        <Paragraph as="span" role="none">
          {label}
        </Paragraph>
      </Stack>
    </InputCardWrapper>
  )
}
