import { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { Button, LoadingDots, Paragraph, Stack } from '@qasa/qds-ui'
import { Document, Page, pdfjs } from 'react-pdf'

import { ErrorComponent } from '../_core/error-component'
import { Dialog } from '../_core/dialog'
import type { EvictionProcess } from '../../screens/user/contracts/eviction-section'

/* https://github.com/wojtekmaj/react-pdf/issues/991 */
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const StyledPage = styled(Page)({
  boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',
  '> canvas': {
    width: '100% !important',
    height: '100% !important',
  },
})
const PageNumber = styled(Paragraph)(({ theme }) => ({
  paddingBlock: theme.spacing['2x'],
}))
const LoadingContainer = styled(Stack)(({ theme }) => ({
  paddingBlock: theme.spacing['12x'],
}))

type PreviewPdfButtonProps = {
  evictionProcess: EvictionProcess
}

export function PreviewPdfButton({ evictionProcess }: PreviewPdfButtonProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [numPages, setNumPages] = useState<number>(0)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => setNumPages(numPages)

  const pdfUrl = evictionProcess?.messageToSocPdf?.url

  const pdfPageNumbers = useMemo(() => Array.from(new Array(numPages)), [numPages])

  return (
    <Dialog isOpen={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Dialog.Trigger>
        <Button variant="tertiary" onClick={() => setIsDialogOpen(true)}>
          {'Preview SOC PDF'}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content size="large" isScrollable>
        {pdfUrl && (
          <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <LoadingContainer justifyContent="center" alignItems="center">
                <LoadingDots />
              </LoadingContainer>
            }
            error={<ErrorComponent title="The pdf could not load" />}
          >
            {pdfPageNumbers.map((_, index) => (
              <>
                <StyledPage
                  key={`page-${index}`}
                  pageIndex={index}
                  loading={<LoadingDots />}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  error={
                    <ErrorComponent
                      title="Page could not load"
                      description="Try to reload the page, if the problem persists, please write to tech support"
                    />
                  }
                />
                <PageNumber>{`Page ${index + 1} of ${numPages}`}</PageNumber>
              </>
            ))}
          </Document>
        )}
      </Dialog.Content>
    </Dialog>
  )
}
