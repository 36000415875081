import type { ChangeEvent } from 'react'
import { useState } from 'react'
import { Heading, Stack, Label, Textarea, Link } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { Api } from '../../api'

const CheckboxLabel = styled(Label)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing['2x'],
}))

export function PlianceData({ userUid }: { userUid: string }) {
  const [type, setType] = useState<string | null>(null)
  const [plianceData, setPlianceData] = useState('')

  const getAmlHits = (uid: string) => {
    Api.getAmlHits(uid, type).then((res) => {
      setPlianceData(JSON.stringify(res.data, null, 2))
    })
  }
  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target
    setType(checked ? value : null)
  }

  return (
    <Stack gap="4x">
      <Heading as="h3">Pliance data</Heading>
      <CheckboxLabel>
        Company data
        <input type="checkbox" value="company" onChange={handleTypeChange} checked={type === 'company'} />
      </CheckboxLabel>
      <CheckboxLabel>
        Bank Account Owner data
        <input
          type="checkbox"
          value="bank_account_owner"
          onChange={handleTypeChange}
          checked={type === 'bank_account_owner'}
        />
      </CheckboxLabel>
      <Link onClick={() => getAmlHits(userUid)}>Retrieve pliance data</Link>
      <Textarea
        style={{
          display: plianceData ? 'inline' : 'none',
          minWidth: '600px',
          minHeight: '400px',
        }}
        value={plianceData}
        label=""
      />
    </Stack>
  )
}
