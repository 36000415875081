import { PDFDocument } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'

import customFontRelativeUrl from '../../../assets/Ubuntu-R.ttf'

export async function createPdfTemplate({ pdfDocument, mapDataToFieldsFunc, pdfData }) {
  const pdfAsBytes = await fetch(pdfDocument).then((res) => res.arrayBuffer())

  const pdfAsDocument = await PDFDocument.load(pdfAsBytes)
  const form = pdfAsDocument.getForm()

  pdfAsDocument.registerFontkit(fontkit)
  const fontBytes = await fetch(customFontRelativeUrl).then((res) => res.arrayBuffer())
  const customFont = await pdfAsDocument.embedFont(fontBytes, { subset: true })

  mapDataToFieldsFunc({ form, pdfData })

  form.updateFieldAppearances(customFont)

  // if we do not flatten the pdf then the user will need to download it and flatten it themselves in adobe
  // form.flatten()

  return pdfAsDocument.save()
}
