import { LoadingDots, Stack } from '@qasa/qds-ui'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'

import { PayoutsFinance } from '../invoices/payouts-finance'
import { InvoicesFinance } from '../invoices/invoices-finance'
import { CONTRACT_FINANCES } from '../invoices/invoices.gql'
import { notifyFailure } from '../../../utils/notifications'
import { AdminNoteRecordTypeEnum } from '../../../graphql/__generated__/graphql'
import { getBaseInfo, getOverviewInfo } from '../user-contract-meta.utils'
import { UserContractCTAsFrance } from '../user-contract-CTAs-france'
import { Notes } from '../../../components/notes/notes'

import { ContractMetaSection } from './components/contract-meta-section'
import type { KanyeUserContractsType } from './contracts.gql'

const LeftColumn = Stack
const RightColumn = styled(Stack)({ flex: 1 })

type ExpandedContractFranceProps = {
  contract: KanyeUserContractsType[number]
}

export function ExpandedContractFrance({ contract }: ExpandedContractFranceProps) {
  const { data, loading: isLoading } = useQuery(CONTRACT_FINANCES, {
    variables: {
      contractId: contract.id,
    },
    onError: () => {
      notifyFailure('Could not load contract finances')
    },
  })
  const financeContract = data?.contract
  if (isLoading) {
    return <LoadingDots />
  }
  if (!financeContract) {
    return null
  }

  const overviewInfo = getOverviewInfo({ contract })
  const baseInfo = getBaseInfo({ contract })

  return (
    <Stack gap="8x">
      <Stack gap="8x" direction="row" wrap="wrap">
        <LeftColumn gap="4x">
          <ContractMetaSection header="Overview" sectionData={overviewInfo} />
          <ContractMetaSection header="Base info" sectionData={baseInfo} />
        </LeftColumn>
        <RightColumn gap="8x">
          <Notes
            notes={contract.adminNotes}
            type={AdminNoteRecordTypeEnum.Contract}
            contractId={contract.id}
          />
          <Stack gap="8x" direction="row">
            <UserContractCTAsFrance contract={contract} />
          </Stack>
        </RightColumn>
      </Stack>
      <InvoicesFinance contract={financeContract} />
      <PayoutsFinance contract={financeContract} />
    </Stack>
  )
}
