import { notifyFailure } from 'app/utils/notifications'

const missingDataAlert = (field) => notifyFailure(`Missing data for field: ${field}`)

const fillInSocPdfTextFields = ({ form, pdfData }) => {
  const nullableValues = {
    HyresvardTelefonnummer: pdfData.hyresvard.telefonnummer,
    HyresvardPersonnummer: pdfData.hyresvard.personnummer,
    HyresvardAdress: pdfData.hyresvard.adress,
    HyresvardPostnrOrt: pdfData.hyresvard.postnrOrt,
    HyresgastTelefonnummer: pdfData.hyresgast.telefonnummer,
    HyresgastPersonnummer: pdfData.hyresgast.personnummer,
  }

  const {
    HyresvardTelefonnummer,
    HyresvardPersonnummer,
    HyresvardAdress,
    HyresvardPostnrOrt,
    HyresgastTelefonnummer,
    HyresgastPersonnummer,
  } = nullableValues

  // Ombud
  form.getTextField('QasaFirma').setText(pdfData.qasa.firma)
  form.getTextField('QasaOrgnummer').setText(pdfData.qasa.orgnummer)
  form.getTextField('QasaAdress').setText(pdfData.qasa.adress)
  form.getTextField('QasaPostnummer').setText(pdfData.qasa.postnummer)
  form.getTextField('QasaOrt').setText(pdfData.qasa.ort)
  form.getTextField('QasaBankgiro').setText(pdfData.qasa.bankgiro)

  // Hyresvard
  form.getTextField('HyresvardNamn').setText(pdfData.hyresvard.namn)
  form.getTextField('HyresvardEpost').setText(pdfData.hyresvard.epost)
  HyresvardTelefonnummer
    ? form.getTextField('HyresvardTelefonnummer').setText(HyresvardTelefonnummer)
    : missingDataAlert('HyresvardTelefonnummer')
  HyresvardPersonnummer
    ? form.getTextField('HyresvardPersonnummer').setText(HyresvardPersonnummer)
    : missingDataAlert('HyresvardPersonnummer')
  HyresvardAdress
    ? form.getTextField('HyresvardAdress').setText(HyresvardAdress)
    : missingDataAlert('HyresvardAdress')
  HyresvardPostnrOrt
    ? form.getTextField('HyresvardPostnrOrt').setText(HyresvardPostnrOrt)
    : missingDataAlert('HyresvardPostnrOrt')

  // Hyresgast
  form.getTextField('HyresgastNamn').setText(pdfData.hyresgast.namn)
  form.getTextField('HyresgastEpost').setText(pdfData.hyresgast.epost)
  HyresgastTelefonnummer
    ? form.getTextField('HyresgastTelefonnummer').setText(HyresgastTelefonnummer)
    : missingDataAlert('HyresgastTelefonnummer')
  HyresgastPersonnummer
    ? form.getTextField('HyresgastPersonnummer').setText(HyresgastPersonnummer)
    : missingDataAlert('HyresgastPersonnummer')
  form.getTextField('HyresgastAdress').setText(pdfData.hyresgast.adress)
  form.getTextField('HyresgastPostnrOrt').setText(pdfData.hyresgast.postnrOrt)

  // hyra
  form.getTextField('HyraManad').setText(pdfData.hyra.manad)
  form.getTextField('HyraForfallodatum').setText(pdfData.hyra.forfallodatum)
  form.getTextField('HyraObetaltBelopp').setText(pdfData.hyra.obetaltBelopp)

  // hem
  form.getTextField('HemAdress').setText(pdfData.hem.adress)
  form.getTextField('HemAntalRum').setText(pdfData.hem.antalRum)
  form.getTextField('HemYtaKvm').setText(pdfData.hem.ytaKvm)

  // underskrift
  form.getTextField('QasaSignaturOrt').setText(pdfData.underskrift.ort)
  form.getTextField('QasaSignaturDatum').setText(pdfData.underskrift.datum)
  form.getTextField('QasaSignaturHyresvardNamn').setText(pdfData.underskrift.hyresvardNamn)
}

const fillInKronofogdenPdfTextFields = ({ form, pdfData }) => {
  const nullableValues = {
    SokandenAdress: pdfData.sokanden.adress,
    SokandenTelefon: pdfData.sokanden.telefon,
    SokandenPersonnummer: pdfData.sokanden.personnummer,
    SvarandePersonnummer: pdfData.svarande.personnummer,
    SvarandeTelefonnummer: pdfData.svarande.telefonnummer,
  }

  const {
    SokandenAdress,
    SokandenTelefon,
    SokandenPersonnummer,
    SvarandePersonnummer,
    SvarandeTelefonnummer,
  } = nullableValues

  // Sokanden
  form.getTextField('SokandenNamn').setText(pdfData.sokanden.namn)
  SokandenAdress
    ? form.getTextField('SokandenAdress').setText(SokandenAdress)
    : missingDataAlert('SokandenAdress')
  form.getTextField('SokandenEpost').setText(pdfData.sokanden.epost)
  SokandenTelefon
    ? form.getTextField('SokandenTelefon').setText(SokandenTelefon)
    : missingDataAlert('SokandenTelefon')
  // this personnummer field has no predefined "-" before the last 4 characters so we add it in here
  SokandenPersonnummer
    ? form.getTextField('SokandenPersonnummer').setText(SokandenPersonnummer)
    : missingDataAlert('SokandenPersonnummer')

  // Ombud
  form.getTextField('KronofogdenOmbudskod').setText(pdfData.ombud.kronofogdenOmbudskod)
  form.getTextField('QasaNamn1').setText(pdfData.ombud.namn1)
  form.getTextField('QasaNamn2').setText(pdfData.ombud.namn2)
  form.getTextField('QasaUserEmail').setText(pdfData.ombud.userEmail)
  form.getTextField('QasaAdress').setText(pdfData.ombud.adress)
  form.getTextField('QasaTelefon').setText(pdfData.ombud.phone)
  form.getTextField('QasaFakturanummer').setText(pdfData.ombud.invoiceNumber)
  form.getTextField('QasaBankgiro').setText(pdfData.ombud.bankgiro)

  // Svarande
  form.getTextField('SvarandeNamn').setText(pdfData.svarande.namn)
  SvarandePersonnummer
    ? form.getTextField('SvarandePersonnummer').setText(SvarandePersonnummer)
    : missingDataAlert('SvarandePersonnummer')
  form.getTextField('SvarandePostnummer').setText(pdfData.svarande.postnummer)
  SvarandeTelefonnummer
    ? form.getTextField('SvarandeTelefonnummer').setText(SvarandeTelefonnummer)
    : missingDataAlert('SvarandePostnummer')
  form.getTextField('SvarandeEpost').setText(pdfData.svarande.epost)
  form.getTextField('SvarandeAdress').setText(pdfData.svarande.adress)
  form.getTextField('SvarandePostort').setText(pdfData.svarande.postort)

  // Kostnader
  form.getTextField('Ansokningsavgift').setText(pdfData.kostnader.ansokningsavgift)
  form.getTextField('OmbudsArvode').setText(pdfData.kostnader.ombudsArvode)
  form.getTextField('SummaKostnader').setText(pdfData.kostnader.summaKostnader)

  // Avhysning
  form.getTextField('AvhysningAdressRumYta').setText(pdfData.avhysning.adressRumYta)
  form.getTextField('AvhysningGrundBostadstyp').setText(pdfData.avhysning.grundBostadstyp)
  form.getTextField('AvhysningForfallodatum').setText(pdfData.avhysning.forfallodatum)
}

export { fillInKronofogdenPdfTextFields, fillInSocPdfTextFields }
