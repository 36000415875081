import styled from '@emotion/styled'
import { Heading, IconButton, Paragraph, Stack, ShareIcon } from '@qasa/qds-ui'
import upperFirst from 'lodash/upperFirst'
import { forwardRef } from 'react'

import { ContentAccordian } from '../../../components/content-accordian'
import { notifyDefault } from '../../../utils/notifications'
import { getContractNames } from '../user-contract-meta.utils'
import { CopyIcon, PinIcon, UnpinIcon } from '../../../components/icons'
import { splitSnakeCase } from '../../../utils/strings'

import { ExpandedContractFrance } from './expanded-contract-france'
import { ContractTag } from './components/contract-tag'
import { getContractTags } from './components/contract-tag.utils'
import { ContractStatus } from './components/contract-status'
import type { KanyeUserContractsType } from './contracts.gql'

export const Wrapper = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: theme.spacing['2x'],
  paddingBottom: theme.spacing['2x'],
}))
export const Title = styled(Heading)({ marginBottom: 4 })
export const Description = Paragraph

type ContractFranceProps = {
  contract: KanyeUserContractsType[number]
  isDefaultExpanded?: boolean
  onPinContract: () => void
  isPinned?: boolean
}

export const ContractFrance = forwardRef<any, ContractFranceProps>(
  ({ contract, isDefaultExpanded = false, onPinContract, isPinned = false }, scrollRef) => {
    const { tenantName, landlordName } = getContractNames({ contract })

    const cosigneesString = contract.cosigningTenants?.length ? 'with cosignees' : ''
    const location = contract.home?.location
    const address = `${location?.route ?? ''} ${location?.streetNumber ?? ''}, ${
      location?.postalCode ?? ''
    } ${location?.locality ?? ''}`

    const handleCopyAddress = () => {
      navigator.clipboard.writeText(address)
      notifyDefault(`Copied address to clipboard!`)
    }
    const contractTags = getContractTags({
      externalContractTakeover: contract.externalContractTakeover,
      modelName: contract.pricingModel?.modelName,
      signingProcess: contract.signingProcess,
    })

    const formattedContractStatus = upperFirst(splitSnakeCase(contract.status))

    const handleShareContract = () => {
      navigator.clipboard.writeText(`${window.location.href}&contractId=${contract.id}`)
      notifyDefault(`Shareable url with contract id ${contract.id} copied to clipboard!`)
    }

    //NOTE: Currently only france tenants is invited, therefore sometimes they are not set
    const tenantNameString = contract.tenant ? tenantName : 'No tenant'
    const headerComponent = (
      <Stack gap="2x" ref={scrollRef}>
        <Stack gap="2x" direction="row" alignItems="center">
          <Heading size="sm">{`#${contract.id} - ${landlordName} & ${tenantNameString} ${cosigneesString}`}</Heading>
          {contractTags.map((tag) => (
            <ContractTag variant={tag} key={tag} />
          ))}
        </Stack>
        <Stack gap="2x" direction="row" alignItems="center">
          <Description>{address}</Description>
          <IconButton
            label="Copy address to clipboard"
            onClick={(ev) => {
              ev.stopPropagation()
              handleCopyAddress()
            }}
            size="xs"
            icon={CopyIcon}
          />
          <IconButton
            label={isPinned ? 'Unpin contract from top of list' : 'Pin contract to top of list'}
            onClick={(ev) => {
              ev.stopPropagation()
              onPinContract()
            }}
            size="xs"
            icon={isPinned ? UnpinIcon : PinIcon}
          />
          <IconButton
            label="Share contract"
            onClick={(ev) => {
              ev.stopPropagation()
              handleShareContract()
            }}
            size="xs"
            icon={ShareIcon}
          />
        </Stack>
        <ContractStatus status={contract.status} label={formattedContractStatus} />
      </Stack>
    )

    return (
      <ContentAccordian
        HeaderComponent={headerComponent}
        isDefaultExpanded={isDefaultExpanded}
        isPinned={isPinned}
      >
        <ExpandedContractFrance contract={contract} />
      </ContentAccordian>
    )
  },
)
