import { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Heading, LoadingDots, Stack, Link } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { Table2 } from '../../components/table/table2'
import { QasaLink } from '../../components/qasa-link'
import { getFormattedHomeAddress } from '../homes-in-review/utils'
import { HomePreview } from '../homes-in-review/home-preview'
import type { Home } from '../homes-in-review/tabs/in-review/home-type'
import { Api } from '../../api'
import { displayApiErrors } from '../../utils'

import { SettingsCard } from './settings-card'
import { ShortcutTransitionActions } from './shortcut-transition-actions'

const Wrapper = styled(Stack)({
  width: '100%',
})
const TopSection = Stack
const SettingsWrapper = styled(Stack)({
  maxWidth: 400,
})

export const updatedOutputTimeSinceShortcutCreation = (home: Home) => {
  const createdAt = home.shortcut.createdAt || ''
  return new Date(createdAt).toLocaleString()
}

export function Shortcut() {
  const [highlightedHome, hightlightHome] = useState<Home | null>(null)
  const [selectedHomes, setSelectedHomes] = useState<Home[]>([])
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [homes, setHomes] = useState<Home[]>([])
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const getShortcutCandidates = () => {
    setIsLoading(true)
    Api.shortcutGetShortcutCandidates()
      .then((res) => {
        if (res.status === 200) {
          setHomes(res.data.adminReviewHomes)
          setCount(res.data.count)
        }
      })
      .catch(displayApiErrors)
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    getShortcutCandidates()
  }, [])

  const onSelectionChanged = (selection: number[]) => {
    setSelectedHomes(selection.map((indexOfSelectedHome: number) => homes[indexOfSelectedHome]))
    const highlightedHome = selection.length === 1 ? homes[selection[0]] : null
    hightlightHome(highlightedHome)
  }

  return (
    <Wrapper gap={'6x'}>
      <Heading>Shortcut</Heading>
      <SettingsWrapper>
        <SettingsCard />
      </SettingsWrapper>
      <TopSection direction="row" justifyContent="space-between">
        <Heading size="xs">Current shortcut candidates ({count || '...'})</Heading>
        <ShortcutTransitionActions selectedHomes={selectedHomes} onComplete={getShortcutCandidates} />
      </TopSection>
      {isLoading ? (
        <LoadingDots />
      ) : (
        <>
          {homes.length === 0 ? (
            <Heading size="xs">There are no Shortcut candidates right now 🤷‍♀️</Heading>
          ) : (
            <Table2
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              enableRowSelection
              onSelectionChanged={onSelectionChanged}
              rows={homes}
              getRowKey={(home) => home.id}
              columnsConfig={[
                {
                  title: 'Name',
                  render: (home: Home) => (
                    <Link isExternal as={RouterLink} to={`/user?user=${encodeURIComponent(home.email)}`}>
                      {home.professional ? '🏢 ' + home.companyName : home.fullName}
                    </Link>
                  ),
                },
                {
                  title: 'E-mail',
                  render: (home: Home) => home.email,
                  getCellClassName: (home: Home) => (Boolean(home.emailConfirmedAt) ? 'Success' : 'Danger'),
                },
                {
                  title: 'Paused in Shortcut',
                  render: (home: Home) => (home.shortcut.currentState === 'paused' ? 'Yes' : '-'),
                },
                {
                  title: 'Bank ID',
                  render: (home: Home) =>
                    home.identityConfirmedAt ? `Verified at: ${home.identityConfirmedAt}` : 'Not verified',
                  getCellClassName: (home: Home) => (home.identityConfirmedAt ? 'Success' : 'Danger'),
                },
                {
                  title: 'Home',
                  render: (home: Home) => (
                    <QasaLink to={`/home/${home.id}`}>
                      ${home.rent} | {home.squareMeters} m2 | {home.roomCount}
                    </QasaLink>
                  ),
                },
                {
                  title: 'Candidate created at',
                  render: updatedOutputTimeSinceShortcutCreation,
                },
                {
                  title: 'Address',
                  render: getFormattedHomeAddress,
                },
              ]}
            />
          )}
        </>
      )}
      <HomePreview home={highlightedHome} />
    </Wrapper>
  )
}
