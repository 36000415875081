import { notifyFailure } from '../../../utils'

import { openPdfInNewTab } from './open-pdf-in-new-tab'
import { createPdfTemplate } from './create-pdf-template'

export async function openPdfHandler({ pdfDocument, mapDataToFieldsFunc, pdfData }) {
  try {
    const pdfAsBytes = await createPdfTemplate({ pdfDocument, mapDataToFieldsFunc, pdfData })
    openPdfInNewTab({ pdfAsBytes })
  } catch (error) {
    reportError(error)
    notifyFailure(`Could not open PDF`)
  }
}
