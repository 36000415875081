import socialtjanstenPdfTemplate from 'src/assets/pdf/qasa-soc.pdf'
import { Button } from '@qasa/qds-ui'

import { formatSocPdfData } from './utils/format-soc-pdf-data'
import { openPdfHandler, fillInSocPdfTextFields } from './utils'

// NOTE: This component downloads a PDF and fills it in. "Soc" is "Socialtjänsten", the Swedish
// Social Services, so this is only relevant for Sweden. Admins will send this PDF to the
// authorities when tenants don't pay their rent.

export function GenerateSocPdfButton({ evictionProcess }: { evictionProcess?: any }) {
  const openPdf = () =>
    openPdfHandler({
      pdfDocument: socialtjanstenPdfTemplate,
      mapDataToFieldsFunc: fillInSocPdfTextFields,
      pdfData: formatSocPdfData(evictionProcess),
    })

  return (
    <Button variant="tertiary" onClick={openPdf}>
      Generate Socialtjänsten PDF
    </Button>
  )
}
