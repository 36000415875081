import { useMutation } from '@tanstack/react-query'

import {
  publishHome,
  denyHome,
  adReviewDecision,
  publishHomeOnBlocket,
  archiveHome,
} from '../api/api-service'
import { notifySuccess } from '../utils/notifications'
import { displayApiErrors } from '../utils/error'

export const usePublishHome = () => {
  return useMutation(publishHome, {
    onSuccess: () => {
      notifySuccess('Home published!')
    },
    onError: displayApiErrors,
  })
}

export const useDenyHome = () => {
  return useMutation(denyHome, {
    onSuccess: () => {
      notifySuccess('Home denied')
    },
    onError: displayApiErrors,
  })
}

export const useAdReviewDecision = () => {
  return useMutation(adReviewDecision, {
    onError: displayApiErrors,
  })
}

export const useHomePublishedOnBlocket = () => {
  return useMutation(publishHomeOnBlocket, {
    onSuccess: () => notifySuccess('Home published on blocket!'),
    onError: displayApiErrors,
  })
}

export const useArchiveHome = () => {
  return useMutation(archiveHome, {
    onSuccess: () => {
      notifySuccess('Home archived!')
    },
    onError: displayApiErrors,
  })
}
