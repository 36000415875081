import styled from '@emotion/styled'
import { Heading, Spacer } from '@qasa/qds-ui'
import { Fragment } from 'react'

import { date } from '../../../utils/helpers'
import type { ContractFinancesQuery } from '../../../graphql/__generated__/graphql'
import { InvoiceStateEnum, ProductEnum } from '../../../graphql/__generated__/graphql'
import { TableHeader } from '../../../components/table/table-header'
import { TableBody } from '../../../components/table/table-body'
import { Table } from '../../../components/table/table'

import '../../../components/table/styles.scss'

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const headerTitles = ['ID', 'Type', 'Reference', 'Amount', 'Paid at', 'Due', 'Overdue', 'State']

const getCurrentState = (currentState: InvoiceStateEnum) => {
  switch (currentState) {
    case InvoiceStateEnum.Created:
      return 'Created'
    case InvoiceStateEnum.Pending:
      return 'Invoice sent'
    case InvoiceStateEnum.Paid:
      return 'Paid'
    case InvoiceStateEnum.Credited:
      return 'Credited'
    case InvoiceStateEnum.Cancelled:
      return 'Cancelled'
    default:
      return 'No status'
  }
}

const getProductName = (name: ProductEnum) => {
  switch (name) {
    case ProductEnum.Deposit:
      return 'Deposit'
    case ProductEnum.MonthlyFee:
      return 'Monthly fee'
    case ProductEnum.InsuranceFee:
      return 'Insurance fee'
    case ProductEnum.RecoverableCharges:
      return 'Recoverable charges'
    case ProductEnum.MonthlyRent:
      return 'Monthly rent'
    default:
      return ''
  }
}

type InvoicesFinanceProps = {
  contract: ContractFinancesQuery['contract']
}

export function InvoicesFinance({ contract }: InvoicesFinanceProps) {
  const { financeDepositInvoices, financeRentInvoices } = contract

  return (
    <Wrapper>
      <Heading size="sm">{'Invoices'}</Heading>
      <Spacer size="2x" />
      <Table>
        <TableHeader headerTitles={headerTitles} />
        <TableBody>
          {financeDepositInvoices?.map((deposit) => (
            <Fragment key={deposit.id}>
              <tr>
                <th className="Table__body-batch-header">{'Deposit'}</th>
              </tr>
              <tr key={deposit.reference}>
                <td>{''}</td>
                <td>{''}</td>
                <td>{deposit.reference}</td>
                <td>{deposit.sumAmount.humanized}</td>
                <td>
                  {deposit.paidAt ? (
                    <span className="Success">{date(deposit.paidAt)}</span>
                  ) : (
                    <span className="Danger">{'Unpaid'}</span>
                  )}
                </td>
                <td>{deposit.dueDate}</td>
                <td> {deposit.overdue ? <span className="Danger">{'Yes'}</span> : <span>{'No'}</span>}</td>
                <td>{getCurrentState(deposit.currentState)}</td>
              </tr>
            </Fragment>
          ))}
          {financeRentInvoices?.map((invoice) => (
            <Fragment key={invoice.id}>
              <tr>
                <th className="Table__body-batch-header">{`Rent invoice: ${invoice.periodStart} - ${invoice.periodEnd}`}</th>
              </tr>
              <tr key={invoice.reference}>
                <td>{''}</td>
                <td>{''}</td>
                <td>{invoice.reference}</td>
                <td>{invoice.sumAmount.humanized}</td>
                <td>
                  {invoice.paidAt ? (
                    <span className="Success">{date(invoice.paidAt)}</span>
                  ) : (
                    <span className="Danger">{'Unpaid'}</span>
                  )}
                </td>
                <td>{invoice.dueDate}</td>
                <td> {invoice.overdue ? <span className="Danger">{'Yes'}</span> : <span>{'No'}</span>}</td>
                <td>{getCurrentState(invoice.currentState)}</td>
              </tr>
              {invoice.items?.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{getProductName(item.productName)}</td>
                  <td>{''}</td>
                  <td>{item.amount.humanized}</td>
                  <td>{''}</td>
                  <td>{''}</td>
                  <td>{''}</td>
                  <td>{''}</td>
                </tr>
              ))}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </Wrapper>
  )
}
