import React from 'react'
import PropTypes from 'prop-types'

TableBody.propTypes = {
  children: PropTypes.node,
}

export function TableBody({ children }) {
  return <tbody className="Table__body">{children}</tbody>
}
