import React from 'react'
import styled from '@emotion/styled'
import { Button, Heading, Paragraph, Stack, useBreakpointValue } from '@qasa/qds-ui'

import { updateProcessLoading, updateProcessSuccess, updateProcessFailed } from '../overdue-payments-actions'
import { Api } from '../../../api'
import { displayApiErrors } from '../../../utils'

import { EvictionProcess } from './processes'
import { CommentsSection } from './comments-section'
import { StepHistory } from './step-history'

const Wrapper = Stack
const ProcessHeader = styled(Stack)(({ theme }) => ({
  padding: '0 0 4px',
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: theme.colors.border.default,
}))
const Content = Stack
const ProcessContent = styled(Stack)({ width: 400 })
const Actions = styled(Stack)({ flexWrap: 'wrap', rowGap: 4 })
const StepHistoryContainer = styled(Paragraph)({ position: 'relative', cursor: 'pointer' })
const StepHistoryPopupContainer = styled.div({ position: 'absolute', top: '100%', left: '0' })

export const getProcess = (processName: string) => {
  switch (processName) {
    case 'Eviction':
    default:
      return EvictionProcess
  }
}

type ProcessProps = {
  userUid: string
  process: any
  invoice: any
  dispatch: any
  addComment: {
    isLoading: boolean
    error: any
  }
  updateProcess: {
    isLoading: boolean
    error: any
  }
}
export function Process({ process, invoice, dispatch, addComment, updateProcess, userUid }: ProcessProps) {
  const [isStepHistoryVisible, setIsStepHistoryVisible] = React.useState(false)
  const contentLayoutDirection = useBreakpointValue({ base: 'column', md: 'row' } as const)
  const CurrentProcess = getProcess(process.name)

  const setProcessOpen = () => {
    dispatch(updateProcessLoading())
    Api.processesSetOpen(process.id)
      .then((res) => {
        dispatch(updateProcessSuccess(res.data.users))
      })
      .catch((e) => {
        dispatch(updateProcessFailed(e))
        displayApiErrors(e)
      })
  }

  const setProcessClosed = () => {
    dispatch(updateProcessLoading())
    Api.processesSetClosed(process.id)
      .then((res) => {
        dispatch(updateProcessSuccess(res.data.users))
      })
      .catch((e) => {
        dispatch(updateProcessFailed(e))
        displayApiErrors(e)
      })
  }

  const buttonText = process.closed ? 'Reopen' : 'Close'
  const clickhandler = process.closed ? setProcessOpen : setProcessClosed

  return (
    <Wrapper gap="2x">
      <ProcessHeader direction="row" justifyContent="space-between" gap="1x">
        <Heading size="2xs">{CurrentProcess.name}</Heading>
        <button onClick={() => setIsStepHistoryVisible(!isStepHistoryVisible)}>
          <StepHistoryContainer size="sm">
            {process.closed ? (
              <>Closed</>
            ) : (
              <>
                Current step: {CurrentProcess.getStateName(process.currentState)}
                {process.stateHistory && <> ({process.stateHistory.size})</>}
              </>
            )}
            {isStepHistoryVisible && (
              <StepHistoryPopupContainer>
                <StepHistory stateHistory={process.stateHistory} CurrentProcess={CurrentProcess} />
              </StepHistoryPopupContainer>
            )}
          </StepHistoryContainer>
        </button>
      </ProcessHeader>
      <Content direction={contentLayoutDirection} gap="8x">
        <ProcessContent gap="2x">
          {process.closed ? (
            <Paragraph size={'sm'}>
              Last step: {CurrentProcess.getStateName(process.currentState)}
              {/* TODO: Add button here (variant ghost) to see comments */}
            </Paragraph>
          ) : (
            <CurrentProcess.Task process={process} userUid={userUid} />
          )}

          <Actions gap="4x" direction="row">
            {!process.closed && (
              <>
                {process.allowedTransitions.map((toState: any) => (
                  <CurrentProcess.TransitionButton
                    key={toState}
                    toState={toState}
                    processId={process.id}
                    dispatch={dispatch}
                    isButtonDisabled={updateProcess?.isLoading}
                  />
                ))}
              </>
            )}
            <Button onClick={() => clickhandler()} variant="tertiary" size="xs">
              {buttonText}
            </Button>
          </Actions>
        </ProcessContent>
        <CommentsSection
          processComponent={CurrentProcess}
          processId={process.id}
          comments={process.comments}
          dispatch={dispatch}
          isAddCommentLoading={addComment.isLoading}
          addCommentError={addComment.error}
        />
      </Content>
    </Wrapper>
  )
}
