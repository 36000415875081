import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Stack } from '@qasa/qds-ui'
import { useHistory } from 'react-router'

import { Tabs } from '../../components/tabs'

import { UserContracts } from './contracts/user-contracts'
import { UserBookings } from './bookings/user-bookings'
import { UserSummary } from './user-summary'
import { UserRiskClassification } from './user-risk-classification'
import { UserSubUsers } from './user-sub-users'
import { UserImportHomes } from './import-homes/import-homes'
import { useUserContext } from './user-context'

const Wrapper = 'div'
const ContentWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing['5x'],
  backgroundColor: theme.colors.core.white,
  position: 'relative',
}))

const TAB_COMPONENT = {
  UserSummary,
  UserContracts,
  UserBookings,
  UserRiskClassification,
  UserSubUsers,
  UserImportHomes,
}

const PATH_TAB_MAP = {
  '/user': 'UserSummary',
  '/user/contracts': 'UserContracts',
  '/user/bookings': 'UserBookings',
  '/user/risk_classification': 'UserRiskClassification',
  '/user/sub_users': 'UserSubUsers',
  '/user/import_homes': 'UserImportHomes',
} as const

type TabMapKeys = keyof typeof PATH_TAB_MAP
type TabMapValues = (typeof PATH_TAB_MAP)[TabMapKeys]

export function UserContent() {
  const [activeTab, setActiveTab] = useState('')
  const { user } = useUserContext()
  const userMainPlatform = user.mainPlatform
  const userIsPro = user.professional
  const { pathname, search } = location
  const { push } = useHistory()

  useEffect(() => {
    setActiveTab(PATH_TAB_MAP[pathname as TabMapKeys])
  }, [pathname])

  if (!activeTab) return null

  const changeTab = ({ path }: { path: string }) => push(`/user${path}${search}`)

  const tabs = [
    {
      id: 'UserSummary',
      label: 'User',
      onClick: () => changeTab({ path: '' }),
    },
    {
      id: 'UserContracts',
      label: 'Long-term Contracts',
      onClick: () => changeTab({ path: '/contracts' }),
    },
    ...(userMainPlatform !== 'finn'
      ? [
          {
            id: 'UserBookings',
            label: 'Vacation Contracts',
            onClick: () => changeTab({ path: '/bookings' }),
          },
          {
            id: 'UserRiskClassification',
            label: 'Risk Classification',
            onClick: () => changeTab({ path: '/risk_classification' }),
          },
          {
            id: 'UserSubUsers',
            label: 'Sub users',
            onClick: () => changeTab({ path: '/sub_users' }),
          },
        ]
      : []),
    ...(userIsPro
      ? [
          {
            id: 'UserImportHomes',
            label: 'Import Homes',
            onClick: () => changeTab({ path: '/import_homes' }),
          },
        ]
      : []),
  ]

  const ActiveTab = TAB_COMPONENT[activeTab as TabMapValues]

  return (
    <Wrapper>
      <Tabs tabs={tabs} activeTab={activeTab} />
      <ContentWrapper>
        <ActiveTab />
      </ContentWrapper>
    </Wrapper>
  )
}
