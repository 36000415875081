import styled from '@emotion/styled'
import { Stack } from '@qasa/qds-ui'

import { convertFactorToPercentage } from '../../../../utils/helpers'

function getColor(value: number) {
  const hue = ((1 - value) * 120).toString(10)
  return ['hsl(', hue, ',100%,50%)'].join('')
}
const Wrapper = Stack
const ColorIndicator = styled.div<{ percentage: number }>(({ theme, percentage }) => ({
  width: theme.sizes['2x'],
  height: theme.sizes['2x'],
  background: getColor(percentage),
  border: `0.5px solid ${theme.colors.border.default}`,
  borderRadius: theme.radii.full,
}))

type RiskScoreData = any

export function RiskScore({ riskScoreData }: { riskScoreData: RiskScoreData }) {
  const { riskScore } = riskScoreData
  if (typeof riskScore !== 'number') {
    return null
  }

  const percentage = convertFactorToPercentage({ factor: riskScore })
  return (
    <Wrapper direction="row" alignItems="center" gap="2x">
      <ColorIndicator percentage={riskScore} />
      {percentage}
    </Wrapper>
  )
}
