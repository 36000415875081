import { useQuery } from '@apollo/client'
import { useEffect, useMemo, useState, type ReactNode } from 'react'
import { createContext, useContext } from 'react'
import debounce from 'lodash/debounce'

import { EMPLOYEES, GROUPS } from '../screens/authorisation/authorisation.gql'
import type {
  EmployeeGroupConnection,
  EmployeesQuery,
  EmployeeGroupsQuery,
} from '../graphql/__generated__/graphql'

export type Employee = EmployeesQuery['employees']['nodes'][number]
export type Group = Extract<
  EmployeeGroupsQuery['employeeGroups'],
  { __typename: 'EmployeeGroupConnection' }
>['nodes'][number]

export type Groupies = EmployeeGroupConnection
export type Test = Omit<EmployeeGroupConnection, 'edges' | 'pageInfo'>

export const enum TabsEnum {
  Employees = 'employees',
  Groups = 'groups',
}

type DialogMethods = {
  status: boolean
  open: () => void
  close: () => void
}

export type AuthorisationContext = {
  debouncedSearch: any
  employees: Employee[]
  employee: Employee | undefined
  groups: Group[]
  group: Group | undefined
  isLoadingEmployees: boolean
  isLoadingGroups: boolean
  onChangeTab: ({ tab }: { tab: TabsEnum }) => void
  searchString: string
  selectedTab: TabsEnum
  setSearchString: React.Dispatch<React.SetStateAction<string>>
  selectEmployee: (employee: Employee) => void
  selectGroup: (group: Group) => void
  dialogMethods: Record<string, DialogMethods>
}
const AuthorisationContext = createContext<AuthorisationContext>(undefined!)

function AuthorisationProvider({ children }: { children: ReactNode }) {
  const { data: employeesData, loading: isLoadingEmployees } = useQuery(EMPLOYEES)
  const { data: groupsData, loading: isLoadingGroups } = useQuery(GROUPS)
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null)
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null)
  const [selectedTab, setSelectedTab] = useState<TabsEnum>(TabsEnum.Employees)
  const [searchString, setSearchString] = useState('')
  const [debouncedSearchString, setDebouncedSearchString] = useState(searchString)
  const [isRolesDialogOpen, setIsRolesDialogOpen] = useState(false)
  const [isCreateGroupDialogOpen, setIsCreateGroupDialogOpen] = useState(false)
  const [assignEmployeeToGroupDialog, setAssignEmployeeToGroupDialog] = useState(false)

  const dialogMethods = {
    rolesDialog: {
      status: isRolesDialogOpen,
      open: () => setIsRolesDialogOpen(true),
      close: () => setIsRolesDialogOpen(false),
    },
    createGroupDialog: {
      status: isCreateGroupDialogOpen,
      open: () => setIsCreateGroupDialogOpen(true),
      close: () => setIsCreateGroupDialogOpen(false),
    },
    assignEmployeeToGroupDialog: {
      status: assignEmployeeToGroupDialog,
      open: () => setAssignEmployeeToGroupDialog(true),
      close: () => setAssignEmployeeToGroupDialog(false),
    },
  }

  const debouncedSearch = useMemo(
    () => debounce(({ searchString }) => setDebouncedSearchString(searchString), 200),
    [],
  )

  useEffect(() => {
    debouncedSearch({ searchString })
  }, [searchString])

  const onChangeTab = ({ tab }: { tab: TabsEnum }) => {
    if (selectedTab === TabsEnum.Employees) {
      setSelectedEmployee(null)
    } else {
      setSelectedGroup(null)
    }

    setSelectedTab(tab)
  }

  const employeesList = employeesData?.employees.nodes || []
  const groupsList =
    groupsData?.employeeGroups.__typename === 'EmployeeGroupConnection'
      ? groupsData?.employeeGroups.nodes
      : []

  const filterList = (list: Employee[] | Group[]) =>
    list.filter((element) => element.name.toLowerCase().includes(debouncedSearchString.toLowerCase()))

  const selectEmployee = (employee: Employee) => {
    if (employee.id === selectedEmployee?.id) {
      setSelectedEmployee(null)
      return
    } else {
      setSelectedEmployee(employee)
    }
  }

  const selectGroup = (group: Group) => {
    if (group.id === selectedGroup?.id) {
      setSelectedGroup(null)
      return
    } else {
      setSelectedGroup(group)
    }
  }

  const employees = searchString ? filterList(employeesList) : employeesList
  const groups = searchString ? filterList(groupsList) : groupsList

  const value = {
    debouncedSearch,
    employees,
    employee: employees.find((employee) => employee.id === selectedEmployee?.id),
    groups,
    group: groups.find((group) => group.id === selectedGroup?.id),
    isLoadingEmployees,
    isLoadingGroups,
    onChangeTab,
    searchString,
    selectEmployee,
    selectGroup,
    selectedTab,
    setSearchString,
    dialogMethods,
  }

  // @ts-expect-error sort types here later
  return <AuthorisationContext.Provider value={value}>{children}</AuthorisationContext.Provider>
}

const useAuthorisationContext = () => {
  const context = useContext<AuthorisationContext>(AuthorisationContext)

  if (context === undefined) {
    throw new Error('useAuthorisationContext must be used within a provider')
  }
  return context
}

export { useAuthorisationContext, AuthorisationProvider }
