import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Button, LoadingDots, Paragraph, Stack } from '@qasa/qds-ui'
import { useState } from 'react'

import { Dialog } from '../../../components/_core/dialog'
import { IdentificationStatusEnum } from '../../../graphql/__generated__/graphql'
import { formatDate } from '../../../utils/date'
import { ErrorComponent } from '../../../components/_core/error-component'
import { useResetBankIDVerification } from '../../../queries'

import { IDENTIFICATION } from './identification.gql'
import { IdentificationActions } from './identification-actions'

const VerifiedContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.core.gray20,
  padding: theme.spacing['4x'],
  borderRadius: theme.radii.md,
}))

type IdentificationDialogProps = {
  uid: string
}
export function IdentificationDialog({ uid }: IdentificationDialogProps) {
  const { mutate: resetBankIDVerification } = useResetBankIDVerification(uid)
  const [isOpen, setIsOpen] = useState(false)
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(IDENTIFICATION, {
    variables: {
      uid,
    },
  })

  const closeDialog = () => setIsOpen(false)
  /**
   * TODO: Add reset gql to define mutation in actions function
   */
  const handleReset = () => {
    resetBankIDVerification()
    closeDialog()
  }

  const idStatus = data?.user?.private.identificationStatus
  const idConfirmedAt = data?.user?.identityConfirmedAt
  const isVerified = idStatus === IdentificationStatusEnum.Accepted

  const getContent = () => {
    switch (true) {
      case isLoading:
        return <LoadingDots />
      case Boolean(error):
        return <ErrorComponent title={'User identification data not found'} description={error?.message} />
      default:
        return (
          <Stack gap="4x">
            <Stack>
              <VerifiedContainer>
                <Paragraph color="strong" size="lg">
                  {`Identification status: ${isVerified ? 'Verified' : 'Not verified'}`}
                </Paragraph>
                {idConfirmedAt && (
                  <Paragraph size="lg" color="subtle">{`Confirmed at: ${formatDate(
                    new Date(idConfirmedAt),
                  )}`}</Paragraph>
                )}
              </VerifiedContainer>
            </Stack>
            <Paragraph>
              {'Note: Reset identification is only visible if user has an accepted verification.'}
            </Paragraph>
            <IdentificationActions
              closeDialog={closeDialog}
              handleReset={handleReset}
              uid={uid}
              identificationStatus={idStatus}
            />
          </Stack>
        )
    }
  }

  return (
    <Dialog isOpen={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger>
        <Button variant="tertiary">{'Handle identification'}</Button>
      </Dialog.Trigger>
      <Dialog.Content size="large">
        <Dialog.Header>
          <Dialog.Title>{'Identification'}</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>{getContent()}</Dialog.Body>
      </Dialog.Content>
    </Dialog>
  )
}
