import { graphql } from '../../../../../graphql/__generated__'

export const TRANSITION_MILA_DOCUMENTS_STATE = graphql(/* GraphQL */ `
  mutation MilaTestCommand($command: MilaTestCommand!, $milaId: ID!) {
    milaTestCommand(command: $command, milaId: $milaId) {
      ... on Success {
        success
      }
      ... on Error {
        message
      }
      ... on ValidationError {
        message
        attributeErrors {
          attribute
          message
          type
        }
      }
    }
  }
`)
