import styled from '@emotion/styled'
import type { VariantProps } from '@qasa/qds-ui'
import { Heading, Paragraph, createStyleVariants, AlertCircleIcon } from '@qasa/qds-ui'

const getAlertVariant = createStyleVariants((theme) => ({
  warning: {
    backgroundColor: theme.colors.core.yellow10,
    borderColor: theme.colors.core.yellow20,
  },
  error: {
    backgroundColor: theme.colors.core.red10,
    borderColor: theme.colors.core.red20,
  },
}))
type AlertVariant = VariantProps<typeof getAlertVariant>

const StyledWrapper = styled.div<{ variant: AlertVariant }>(({ theme, variant }) => ({
  border: '1px solid',
  ...getAlertVariant(theme)[variant],
  paddingBlock: theme.spacing['3x'],
  paddingInline: theme.spacing['4x'],
  borderRadius: theme.radii.md,
  display: 'flex',
  gap: 8,
}))
const IconContainer = styled.div({
  // Transform for better alignment with the text
  transform: 'translateY(1px)',
})
const TextContainer = 'div'

type AlertProps = {
  variant: AlertVariant
  title: string
  description: string
}

export function Alert({ variant, title, description }: AlertProps) {
  return (
    <StyledWrapper role="alert" variant={variant}>
      <IconContainer>
        <AlertCircleIcon color={variant === 'error' ? 'negative' : 'warning'} />
      </IconContainer>
      <TextContainer>
        <Heading as="p" size="2xs">
          {title}
        </Heading>
        <Paragraph size="sm">{description}</Paragraph>
      </TextContainer>
    </StyledWrapper>
  )
}
