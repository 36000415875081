import styled from '@emotion/styled'
import { Label } from '@qasa/qds-ui'
import PropTypes from 'prop-types'
import React from 'react'

const RadioButton = styled(Label)(({ theme }) => ({
  marginTop: theme.spacing['3x'],
  display: 'flex',
  minHeight: theme.sizes['12x'],
  cursor: 'pointer',
  border: '1px solid',
  borderRadius: theme.radii.md,
  borderColor: theme.colors.border.defaultSelected,
  alignItems: 'center',
  gap: theme.spacing['4x'],
}))
const RadioCircle = styled.div(({ theme, isChecked }) => ({
  marginLeft: theme.spacing['4x'],
  border: '1px solid',
  borderColor: theme.colors.border.defaultSelected,
  borderRadius: theme.radii.full,
  height: theme.sizes['4x'],
  width: theme.sizes['4x'],
  ...(isChecked && {
    border: '5px solid',
    borderColor: theme.colors.default,
  }),
}))
const LabelContent = 'div'

RadioInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  currentValue: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export function RadioInput({ label, value, currentValue, onClick }) {
  return (
    <RadioButton>
      <input type="radio" hidden onClick={() => onClick(value)} />
      <RadioCircle isChecked={currentValue === value} />
      <LabelContent>{label}</LabelContent>
    </RadioButton>
  )
}
