import { useEffect, useRef, createRef } from 'react'
import { StringParam, useQueryParams } from 'use-query-params'

export const useScrollToContractListItem = ({ dataArray }: { dataArray: any[] }) => {
  const [{ contractId }, setQueryParams] = useQueryParams({ contractId: StringParam })
  const isFirstRender = useRef<boolean>(true)

  const refsArray = useRef(dataArray.map(() => createRef()))

  const foundContractIndex = dataArray.findIndex((item) => item.id === contractId)

  const targetScrollIndex = foundContractIndex === -1 ? 0 : foundContractIndex

  useEffect(() => {
    let targetElement: any = null

    if (targetScrollIndex && dataArray.length) {
      targetElement = refsArray.current[targetScrollIndex]
    }

    // Note: initial value is { current: null } which evaluates to true when checking for targetElement directly
    const isElementRef = targetElement && 'current' in targetElement === false

    // Note: only scrolls on initial render
    if (isElementRef && isFirstRender.current) {
      targetElement.scrollIntoView({ behavior: 'instant', block: 'center' })

      // Removes contractId query param after first render
      isFirstRender.current = false
      setQueryParams({ contractId: undefined })
    }
  }, [dataArray, isFirstRender])

  return { refsArray, targetScrollIndex }
}
