import { Heading, Label, Stack } from '@qasa/qds-ui'

import type { UserContextValue } from './user-context'

export function CompanyHeader({ user }: UserContextValue) {
  const headerData = [
    {
      title: 'Org number:',
      data: user.private.orgNumber,
    },
    {
      title: 'Contact person:',
      data: `${user.firstName} ${user.private.familyName}`,
    },
    {
      title: 'Mail:',
      data: user.private.email,
    },
    {
      title: 'Number:',
      data: user.private.phoneNumber,
    },
  ]

  return (
    <Stack gap="2x">
      <Heading>{user.companyName} 🏢</Heading>
      {headerData.map(({ title, data }, index) => (
        <Stack key={index} gap="3x" direction="row" alignItems="center">
          <Heading size="sm" style={{ fontWeight: 'bold' }}>
            {title}
          </Heading>
          <Label as="p">{data}</Label>
        </Stack>
      ))}
    </Stack>
  )
}
