import { graphql } from '../../graphql/__generated__'

export const BATCHES = graphql(/* GraphQL */ `
  query Batches($contractId: ID!) {
    contract(id: $contractId) {
      invoices {
        amount
        batch
        billectaId
        createdAt
        creditedAmount
        currency
        dueAt
        guaranteed
        id
        remindersDisabledAt
        invoiceEntries {
          amount
          costType
          entryType
          id
          invoiceId
        }
        paidAt
        periodStartsAt
        periodEndsAt
        sendAt
        sentAt
        swishPayments {
          amountCents
          currency
          id
          paymentReferenceId
          status
        }
        type
        updatedAt
        url
      }
    }
  }
`)
