import React, { useState } from 'react'
import styled from '@emotion/styled'
import { ChevronUpIcon, ChevronDownIcon } from '@qasa/qds-ui'

const Header = styled.button<{ isPinned: boolean }>(({ theme, isPinned }) => ({
  backgroundColor: isPinned ? theme.colors.core.yellow20 : theme.colors.core.gray10,
  padding: theme.spacing['5x'],
  paddingRight: theme.spacing['6x'],
  borderRadius: theme.radii.md,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  '&:hover': {
    background: isPinned ? theme.colors.core.yellow30 : theme.colors.core.gray20,
  },
}))
const Wrapper = styled.div(({ theme }) => ({ padding: theme.spacing['4x'] }))

type ContentAccordianProps = {
  HeaderComponent: React.ReactNode
  children: React.ReactNode
  isDefaultExpanded?: boolean
  isPinned?: boolean
}

export function ContentAccordian({
  HeaderComponent,
  children,
  isDefaultExpanded = false,
  isPinned = false,
}: ContentAccordianProps) {
  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded)

  const Chevron = isExpanded ? ChevronUpIcon : ChevronDownIcon

  return (
    <div>
      <Header onClick={() => setIsExpanded(!isExpanded)} isPinned={isPinned}>
        {HeaderComponent}
        <Chevron size={20} />
      </Header>
      {isExpanded && <Wrapper>{children}</Wrapper>}
    </div>
  )
}
