import upperFirst from 'lodash/upperFirst'

import { convertFactorToPercentageWithDecimals, convertFactorToPercentage } from '../../utils/helpers'
import { formatDate } from '../../utils/date'
import { ContractRentalTypeEnum } from '../../graphql/__generated__/graphql'
import { splitSnakeCase } from '../../utils/strings'
import { isUserFinnish } from '../../utils'

import { type KanyeUserContractsType } from './contracts/contracts.gql'

// https://docs.google.com/document/d/1Mw-GOmhX5rnYrCNr5mvgILAHRhrhbCKotnaAn51sSiU/edit Summary of all our pricingModels - Sahin Aslan 2022-02-22
const OLD_PRICING_MODELS = ['landlord_fees_flat', 'landlord_fees_effective']
const SHORTCUT_MODELS = ['shortcut_landlord_monthly_fee']

const getCurrency = (country: string) => {
  switch (country) {
    case 'France':
    case 'Finland':
    case 'Suomi':
      return '€'
    default:
      return 'SEK'
  }
}

type Contract = { contract: KanyeUserContractsType[number] }

export const getVacationPricingModelInfo = ({ contract }: Contract) => {
  const hasInsurance = Boolean(contract.insurance)
  const pricingModel = contract.pricingModel
  const country = contract.home?.location?.country || ''
  const currency = getCurrency(country)

  const info = [
    { label: 'Model', value: pricingModel?.modelName },
    { label: 'Has insurance', value: hasInsurance ? 'Yes' : 'No' },
    {
      label: 'Tenant fee',
      value: `${contract.tenantBaseFee} ${currency} - ${convertFactorToPercentage({
        factor: pricingModel?.tenantBaseFeeFactor ?? 0,
      })}`,
    },
    {
      label: 'Landlord fee',
      value: `${contract.fee} ${currency} - ${convertFactorToPercentage({
        factor: pricingModel?.landlordBaseFeeFactor ?? 0,
      })}`,
    },
  ]

  return info
}

export const getPricingModelInfo = ({ contract }: Contract) => {
  const hasInsurance = Boolean(contract.insurance)
  const hasDepositFree = Boolean(contract.depositFree)
  const pricingModel = contract.pricingModel
  const modelName = pricingModel?.modelName ?? ''
  const isOldModel = OLD_PRICING_MODELS.includes(modelName)
  const isShortcutModel = SHORTCUT_MODELS.includes(modelName)
  const hasGuarantee = contract.qasaGuaranteeMonths
  const country = contract.home?.location?.country || ''
  const currency = getCurrency(country)
  const { mainPlatform } = contract.tenant || {}

  const info = [
    { label: 'Model', value: pricingModel?.modelName },
    { label: 'Has insurance', value: hasInsurance ? 'Yes' : 'No' },
  ]

  if (isUserFinnish({ mainPlatform })) {
    info.push({
      label: 'Water fee',
      value: `${contract?.tenantFees.waterFeePerTenant.cost.total} ${currency}/month`,
    })
  }
  if (hasInsurance) {
    const isInsuranceIncluded = contract.insuranceCost === 0
    const insuranceRecord = contract.insuranceRecord
    info.push(
      {
        label: 'Insurance fee',
        value: isInsuranceIncluded
          ? 'Included'
          : `${contract?.insuranceCost} ${currency}/month  -  ${convertFactorToPercentageWithDecimals({
              factor: pricingModel?.insuranceCostFactor ?? 0,
            })}`,
      },
      {
        label: 'Has active insurance claims',
        value: Boolean(insuranceRecord?.claims) ? 'Yes' : 'No',
      },
      ...(insuranceRecord?.omocomId
        ? [
            {
              label: 'Omocom id',
              value: `${insuranceRecord?.omocomId}`,
            },
          ]
        : []),
    )
  }

  info.push({
    label: 'Guarantee',
    value: hasGuarantee ? `Yes (${contract.qasaGuaranteeMonths} months)` : 'No',
  })
  if (hasGuarantee) {
    info.push({
      label: 'Guarantee fee',
      value: `${contract.qasaGuaranteeCost} ${currency}/month - ${convertFactorToPercentageWithDecimals({
        factor: pricingModel?.qasaGuaranteeCostFactor ?? 0,
      })}`,
    })
  }

  if (isOldModel) {
    info.push({
      label: 'Landlord fee',
      value: `${contract.fee} ${currency}/month`,
    })
  } else {
    info.push(
      {
        label: 'Tenant base fee',
        value: `${contract.tenantBaseFee} ${currency}/month - ${convertFactorToPercentageWithDecimals({
          factor: pricingModel?.tenantBaseFeeFactor ?? 0,
        })}`,
      },
      {
        label: 'Deposit free',
        value: hasDepositFree ? 'Yes' : 'No',
      },
    )

    if (hasDepositFree) {
      const isDepositFreeIncluded = contract.depositFreeCost === 0
      info.push({
        label: 'Deposit free fee',
        value: isDepositFreeIncluded
          ? 'Included'
          : `${contract.depositFreeCost} ${currency}/month - ${convertFactorToPercentageWithDecimals({
              factor: pricingModel?.depositFreeCostFactor ?? 0,
            })}`,
      })
    }

    if (isShortcutModel) {
      info.push({
        label: 'Landlord fee',
        value: `${contract.fee} ${currency}/month - ${convertFactorToPercentageWithDecimals({
          factor: pricingModel?.landlordBaseFeeFactor ?? 0,
        })}`,
      })
    }
  }
  return info
}

export const getContractNames = ({ contract }: Contract) => {
  const tenantCompany = contract.tenant?.companyName
  const landlordCompany = contract.landlord?.companyName
  const tenantFamilyName = contract.tenant?.private?.familyName || ''
  const landlordFamilyName = contract.landlord?.private?.familyName || ''

  const tenantName = `${contract.tenant?.firstName} ${tenantFamilyName}`
  const landlordName = `${contract.landlord?.firstName} ${landlordFamilyName}`

  return {
    landlordName: landlordCompany ? `${landlordName} (${landlordCompany} 🏢)` : `${landlordName}`,
    tenantName: tenantCompany ? `${tenantName} (${tenantCompany} 🏢)` : `${tenantName}`,
  }
}

export const getBaseInfo = ({ contract }: Contract) => {
  const { landlordName, tenantName } = getContractNames({ contract })
  const { mainPlatform } = contract.tenant || {}

  const cosignees = contract.cosigningTenants?.flatMap((user) => [
    { label: 'Cosigning tenant name', value: `${user?.firstName} ${user.private?.familyName}` },
    { label: 'Cosigning tenant email', value: user.private?.email },
  ])

  const isVacation = contract.rentalType === ContractRentalTypeEnum.Vacation

  return [
    { label: 'Contract ID', value: contract.id },
    ...(isUserFinnish({ mainPlatform }) ? [{ label: '# of tenants', value: contract.numberOfTenants }] : []),
    ...(isVacation ? [{ label: 'Booking ID', value: contract.booking?.id }] : []),
    { label: 'Landlord name', value: landlordName },
    { label: 'Landlord email', value: contract.landlord?.private?.email },
    { label: 'Tenant name', value: tenantName },
    { label: 'Tenant email', value: contract.tenant?.private?.email },
    ...(cosignees?.length ? cosignees : []),
  ]
}

export const getOverviewInfo = ({ contract }: Contract) => {
  const signedAtDate = contract.signedAt ? formatDate(new Date(contract.signedAt)) : ''
  const startDate = contract.duration?.startOptimal
    ? formatDate(new Date(contract.duration.startOptimal))
    : signedAtDate
  const endDate = contract.duration?.endOptimal ? formatDate(new Date(contract.duration.endOptimal)) : 'Ufn'
  const pendingTermination = contract.pendingTerminationEndDate

  const isVacation = contract.rentalType === 'vacation'
  const milaId = contract.rentalInsuranceMilaRequest?.milaId
  const takeoverStatus = contract?.externalContractTakeover?.currentState

  const currency = getCurrency(contract.home?.location?.country || '')
  const contractStatus = upperFirst(splitSnakeCase(contract.status))
  return [
    {
      label: 'Contract status',
      value: contractStatus,
    },
    ...(takeoverStatus
      ? [
          {
            label: 'Takeover status',
            value: takeoverStatus,
          },
        ]
      : []),
    ...(isVacation ? [{ label: 'Booking status', value: contract.booking?.status }] : []),
    { label: isVacation ? 'Booked at' : 'Signed at', value: signedAtDate },
    {
      label: 'Rental period',
      value: `${startDate} - ${endDate}`,
    },
    ...(pendingTermination
      ? [
          {
            label: 'Pending termination',
            value: formatDate(new Date(pendingTermination)),
          },
        ]
      : []),
    {
      label: isVacation ? 'Total cost' : 'Rent',
      value: `${contract.rent} ${isVacation ? `${currency}` : `${currency}/month`}`,
    },
    ...(milaId
      ? [
          {
            label: 'Mila state',
            value: contract.rentalInsuranceMilaRequest?.currentState ?? 'No status',
            url: `${process.env.REACT_APP_MILA_DOCUMENT_LINK}/${milaId}`,
          },
        ]
      : []),
    ...(isVacation
      ? []
      : [
          {
            label: 'Scrive ID',
            value: contract?.scriveId ?? 'No scrive ID',
            url: contract?.scriveId
              ? `${process.env.REACT_APP_SCRIVE_DOCUMENT_LINK}${contract?.scriveId}`
              : undefined,
          },
        ]),
  ]
}
