import { Button } from '@qasa/qds-ui'

import { useScheduleInvoices } from '../../../queries'
import { RequiresClearanceLevel } from '../../../components/requires-clearance-level'

type ScheduleInvoiceProps = {
  contractId: string
}
export function ScheduleInvoices({ contractId }: ScheduleInvoiceProps) {
  const {
    mutate: scheduleInvoices,
    endpointInfo: scheduleInvoiceEndpointInfo,
    isLoading: isScheduleInvoiceLoading,
  } = useScheduleInvoices()

  const handleScheduleInvoices = () => scheduleInvoices({ id: contractId })
  return (
    <RequiresClearanceLevel endpoints={[scheduleInvoiceEndpointInfo]}>
      <Button onClick={handleScheduleInvoices} variant="tertiary" isLoading={isScheduleInvoiceLoading}>
        Schedule invoices
      </Button>
    </RequiresClearanceLevel>
  )
}
