import { useState } from 'react'
import includes from 'lodash/includes'
import { Stack, TextField, Label, Paragraph, Button } from '@qasa/qds-ui'
import { useMutation } from '@apollo/client'

import type {
  BatchesQuery,
  InvoiceEntryCostTypeEnum,
  InvoiceTypeEnum,
  InvoiceEntryTypeEnum,
} from '../../graphql/__generated__/graphql'
import {
  sendInvoice,
  markAsCredited,
  resetInvoice,
  destroyInvoice,
  updateInvoiceEntries,
  removeCreditedAmount,
  onInvoiceEnableReminders,
  onInvoiceDisableReminders,
  cancelSelfInvoice,
  checkInvoicePayment,
} from '../../actions/contracts-actions'
import { date } from '../../utils/helpers'
import { Dialog } from '../../components/_core/dialog'
import { getBillectaUrl } from '../overdue-payments/components/invoice/utils'
import { RequiresClearanceLevel } from '../../components/requires-clearance-level'
import type { EndpointInfo } from '../../components/requires-clearance-level/use-user-endpoint-clearance'
import { notifyFailure, notifySuccess } from '../../utils'

import { useUserContext } from './user-context'
import { CreditInvoiceButton } from './invoice-actions/credit-invoice'
import { MARK_INVOICE_AS_PAID } from './invoice.gql'

type InvoiceTypeObject = BatchesQuery['contract']['invoices'][number]
type InvoiceComponentProps = {
  invoice: InvoiceTypeObject
}
export function Invoice({ invoice }: InvoiceComponentProps) {
  const [showInvoiceEntries, setShowInvoiceEntries] = useState(false)

  return (
    <>
      <tr style={{ cursor: 'pointer' }} onClick={() => setShowInvoiceEntries(!showInvoiceEntries)}>
        <td>{invoice.id}</td>
        <td>
          {InvoiceLink({
            billectaId: invoice.billectaId,
            invoiceType: invoice.type,
          })}
        </td>
        <td>{invoice.billectaId}</td>
        <td>{invoice.type}</td>
        <td>{invoice.amount}</td>
        <td>{invoice.sendAt ? date(invoice.sendAt) : '-'}</td>
        <td>{invoice.sentAt ? date(invoice.sentAt) : '-'}</td>
        <td>
          {invoice.paidAt ? (
            <span className="Success">{date(invoice.paidAt)}</span>
          ) : (
            <span className="Danger">{'Unpaid'}</span>
          )}
        </td>
        <td>{date(invoice.dueAt)}</td>
        <td>{invoice.creditedAmount}</td>
      </tr>
      {showInvoiceEntries && (
        <tr>
          <th colSpan={10}>
            <ExpandedInvoice invoice={invoice} />
          </th>
        </tr>
      )}
    </>
  )
}

type ExpandedInvoiceProps = {
  invoice: InvoiceTypeObject
}
function ExpandedInvoice({ invoice }: ExpandedInvoiceProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const toggleDialog = () => setIsDialogOpen(!isDialogOpen)
  return (
    <Stack direction="row" justifyContent="space-between">
      <UpdateEntries isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} invoice={invoice} />
      <table>
        <tbody>
          <tr>
            <td>{'cost type'}</td>
            <td>{'entry type'}</td>
            <td>{'amount'}</td>
          </tr>
          {invoice?.invoiceEntries?.map((entry, index) => <InvoiceEntry key={index} invoiceEntry={entry} />)}
        </tbody>
      </table>
      <div>{CTAs({ invoice, toggleDialog })}</div>
    </Stack>
  )
}

type UpdateEntries = {
  isDialogOpen: boolean
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  invoice: InvoiceTypeObject
}
function UpdateEntries({ isDialogOpen, setIsDialogOpen, invoice }: UpdateEntries) {
  const initialEntryParams: Record<string, any> = {}
  invoice?.invoiceEntries?.forEach((entry) => {
    initialEntryParams[entry.id] = { amount: entry.amount, costType: entry.costType }
  })

  const calculateTotalSum = (entryParams: Record<string, any>) => {
    return Object.keys(entryParams).reduce((sum, key) => {
      const multiplier = entryParams[key].costType === 'deduction' ? -1 : 1
      return sum + entryParams[key].amount * multiplier
    }, 0)
  }

  const formatPayload = (entryParams: Record<string, any>) => {
    return {
      invoice: {
        invoiceEntries: Object.keys(entryParams).map((key) => ({ id: key, amount: entryParams[key].amount })),
      },
    }
  }

  const [entryParams, setEntryParams] = useState(initialEntryParams)
  const onSubmitDesiredTotal = (e: any) => {
    const { value } = e.target
    const ratio = value / invoice.amount
    if (e.key === 'Enter') {
      const newState: Record<string, any> = {}
      invoice?.invoiceEntries?.forEach((ie) => {
        newState[ie.id] = { ...entryParams[ie.id], amount: Math.round(ie.amount * ratio) }
      })
      setEntryParams(newState)
    }
  }

  return (
    <Dialog isOpen={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>{'Update invoice entries'}</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <Stack gap="4x">
            <TextField
              label="Desired total"
              isDisabled={invoice.amount === 0}
              onKeyDown={onSubmitDesiredTotal}
            />
            {invoice?.invoiceEntries?.map((entry) => (
              <TextField
                key={entry.id}
                label={entry.entryType}
                value={entryParams[entry.id].amount}
                onChange={(e) => {
                  const { value } = e.target
                  setEntryParams((prevState) => {
                    return {
                      ...prevState,
                      [entry.id]: { ...prevState[entry.id], amount: Number(value) },
                    }
                  })
                }}
              />
            ))}
            <Stack gap="2x" direction="row" alignItems="center" justifyContent="flex-end">
              <Label as="span">Total:</Label>
              <Paragraph>{calculateTotalSum(entryParams)}</Paragraph>
            </Stack>
          </Stack>
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            variant="primary"
            isFullWidth
            onClick={() => {
              updateInvoiceEntries(invoice.id, formatPayload(entryParams)).then(() => setIsDialogOpen(false))
            }}
          >
            Update invoice
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}

type InvoiceEntryProps = {
  invoiceEntry: {
    __typename?: 'InvoiceEntry' | undefined
    amount: number
    costType: InvoiceEntryCostTypeEnum
    entryType: InvoiceEntryTypeEnum
    id: string
    invoiceId: string
  }
}
function InvoiceEntry({ invoiceEntry }: InvoiceEntryProps) {
  const entryType = invoiceEntry.entryType === 'tenant_base_fee' ? 'service_fee' : invoiceEntry.entryType

  return (
    <tr>
      <td>{invoiceEntry.costType === 'surcharge' ? '+' : '-'}</td>
      <td>{entryType}</td>
      <td>{invoiceEntry.amount}</td>
    </tr>
  )
}

function onDestroyInvoice(id: string) {
  const moveOn = window.confirm('Are you sure you want to delete this invoice?')
  if (moveOn) {
    destroyInvoice(id)
  }
}

type CTAsProps = {
  invoice: InvoiceTypeObject
  toggleDialog: () => void
}
function CTAs({ invoice, toggleDialog }: CTAsProps) {
  const [markDepositAsPaid] = useMutation(MARK_INVOICE_AS_PAID, {
    variables: {
      id: invoice.id,
    },
    onCompleted: (data) => {
      const result = data.markInvoiceAsPaid
      if (result?.__typename === 'Invoice') {
        if (result.paidAt) {
          notifySuccess('Deposit marked as paid')
        }
      } else {
        notifyFailure('An error occurred while marking deposit as paid')
      }
    },
    onError: () => notifyFailure(`could not mark invoice with id ${invoice.id} as paid`),
  })

  const id = invoice.id
  const { user } = useUserContext()
  const [isHandleInvoiceReminderLoading, setIsHandleInvoiceReminderLoading] = useState(false)
  const [isCancelSelfInvoiceLoading, setIsCancelSelfInvoiceLoading] = useState(false)

  let CTAs = [
    <Button variant="tertiary" size="sm" key="destroy" onClick={() => onDestroyInvoice(id)}>
      DESTROY!
    </Button>,
    <Button variant="tertiary" size="sm" key="update_amounts" onClick={toggleDialog}>
      Update amounts
    </Button>,
  ]

  if (invoice.type === 'verification_fee' || (invoice.paidAt && !invoice.creditedAmount)) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {CTAs.map((cta, index) => (
          <span key={index} style={{ marginRight: '8px' }}>
            {cta}
          </span>
        ))}
      </div>
    )
  }

  if (user.mainPlatform === 'finn') {
    return null
  }

  if (invoice.type === 'invoice_rent') {
    const isRemindersDisabled = invoice.remindersDisabledAt

    const handleInvoiceReminder = async (id: string) => {
      setIsHandleInvoiceReminderLoading(true)
      isRemindersDisabled ? await onInvoiceEnableReminders(id) : await onInvoiceDisableReminders(id)
    }

    const onInvoiceEnableRemindersEndpointInfo = [
      {
        endpoint: '/invoices/:id/enable_reminders',
        endpointType: 'put',
      } as EndpointInfo,
    ]

    CTAs = [
      <RequiresClearanceLevel key="invoice_reminder" endpoints={onInvoiceEnableRemindersEndpointInfo}>
        <Button
          variant="tertiary"
          size="sm"
          disabled={isHandleInvoiceReminderLoading}
          onClick={() => handleInvoiceReminder(id)}
        >
          {isRemindersDisabled ? 'Enable reminders' : 'Disable reminders'}
        </Button>
      </RequiresClearanceLevel>,
      ...CTAs,
    ]
  }

  const isRentOrDepositInvoice = ['invoice_rent', 'invoice_deposit'].includes(invoice.type)
  const isInvoiceCreditable =
    Boolean(invoice.billectaId) && !Boolean(invoice.creditedAmount) && !Boolean(invoice.paidAt)

  if (isRentOrDepositInvoice && isInvoiceCreditable) {
    CTAs = [<CreditInvoiceButton key="credit_invoice" id={id} />, ...CTAs]
  }

  const handleCancelSelfInvoiceClick = async (id: string) => {
    setIsCancelSelfInvoiceLoading(true)
    await cancelSelfInvoice(id)
    setIsCancelSelfInvoiceLoading(false)
  }
  const isSelfInvoice = ['selfinvoice_rent', 'selfinvoice_deposit'].includes(invoice.type)
  const isSelfInvoiceCancellable =
    Boolean(invoice.billectaId) && !Boolean(invoice.creditedAmount) && !Boolean(invoice.paidAt)

  if (isSelfInvoice && isSelfInvoiceCancellable) {
    const cancelSelfInvoiceEndpointInfo = [
      {
        endpoint: '/invoices/:id/cancel_selfinvoice',
        endpointType: 'put',
      } as EndpointInfo,
    ]

    CTAs = [
      <RequiresClearanceLevel key="credit_selfinvoice" endpoints={cancelSelfInvoiceEndpointInfo}>
        <Button
          variant="tertiary"
          size="sm"
          disabled={isCancelSelfInvoiceLoading}
          onClick={() => handleCancelSelfInvoiceClick(id)}
        >
          Cancel Self Invoice
        </Button>
      </RequiresClearanceLevel>,
      ...CTAs,
    ]
  }

  if (!invoice.sentAt) {
    CTAs = [
      ...CTAs,
      <Button variant="tertiary" size="sm" key="send" onClick={() => sendInvoice(invoice.id)}>
        Send
      </Button>,
    ]
  }

  if (!invoice.sentAt && !invoice.paidAt && invoice.type === 'selfinvoice_deposit') {
    CTAs = [
      ...CTAs,
      <Button variant="tertiary" size="sm" key="paid" onClick={() => markDepositAsPaid()}>
        Mark as paid
      </Button>,
    ]
  }

  if (invoice.sentAt && !Boolean(invoice.paidAt)) {
    CTAs = [
      ...CTAs,
      <Button variant="tertiary" size="sm" key="check_payment" onClick={() => checkInvoicePayment(id)}>
        {'Check payment'}
      </Button>,
    ]
  }

  if (!invoice.creditedAmount) {
    CTAs = [
      ...CTAs,
      <Button variant="tertiary" size="sm" key="credited" onClick={() => markAsCredited(id)}>
        Mark as credited
      </Button>,
    ]
  }

  if (invoice.creditedAmount) {
    CTAs = [
      ...CTAs,
      <Button variant="tertiary" size="sm" key="reset" onClick={() => resetInvoice(id)}>
        Reset invoice
      </Button>,
      <Button
        variant="tertiary"
        size="sm"
        key="removeCreditedAmount"
        onClick={() => removeCreditedAmount(id)}
      >
        Remove credited amount
      </Button>,
    ]
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      {CTAs.map((cta, index) => (
        <span key={index} style={{ marginRight: '8px' }}>
          {cta}
        </span>
      ))}
    </div>
  )
}

type InvoiceLinkProps = {
  billectaId?: string | null
  invoiceType?: InvoiceTypeEnum
}
function InvoiceLink({ billectaId, invoiceType }: InvoiceLinkProps) {
  const { user } = useUserContext()
  if (user.mainPlatform === 'finn') {
    return null
  }
  let type
  if (!billectaId || !invoiceType) {
    return null
  } else if (includes(invoiceType, 'selfinvoice')) {
    type = 'sfi'
  } else {
    type = 'in'
  }

  const url = getBillectaUrl({ type, id: billectaId })

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      📝
    </a>
  )
}
