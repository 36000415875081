import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { Avatar, Heading, Paragraph, Stack } from '@qasa/qds-ui'

import { Invoice } from './invoice'

const UserAndContractHeader = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing['1x'],
}))
const InvoicesWithOngoingProcesses = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing['4x'],
}))
const ColorSquareContainer = styled.div<{ index: number }>(({ theme, index }) => {
  const colors = [theme.colors.core.blue50, theme.colors.core.green50, theme.colors.core.yellow50]
  return {
    backgroundColor: colors[index % colors.length],
    width: theme.sizes['3x'],
    height: theme.sizes['3x'],
    borderRadius: theme.radii.sm,
  }
})

type UserProps = {
  user: any
  index: number
  dispatch: any
  addComment: {
    isLoading: boolean,
    error: any,
  }
  updateProcess: {
    isLoading: boolean,
    error: any,
  }
}
export function User({ user, index, dispatch, addComment, updateProcess }: UserProps) {
  return (
    <Stack gap={'4x'}>
      <UserAndContractHeader direction="row" gap="4x" alignItems="center">
        <Avatar src={user.photo} />
        <Heading size="2xs">
          <Link target={'_blank'} to={`/user?user=${user.uid}`}>
            {user.name}
          </Link>
        </Heading>
        {Boolean(user.personal_number) && <Paragraph size="sm">{user.personal_number}</Paragraph>}
        <ColorSquareContainer index={index} />
      </UserAndContractHeader>
      <InvoicesWithOngoingProcesses gap="4x">
        {user.invoices.map((invoice: any) => (
          <Invoice key={invoice.id} invoice={invoice} user={user} dispatch={dispatch} addComment={addComment} updateProcess={updateProcess}/>
        ))}
      </InvoicesWithOngoingProcesses>
    </Stack>
  )
}
